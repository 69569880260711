import { Box, Container } from '@mui/material';
import { styled } from '@mui/system';
import { CandidatePortalDataTestIds } from 'data-testids/general';
import { CandidateMatchDashboard } from 'features/Connect/features/ConnectCandidatePortalDashboards/CandidateMatchDashboard/CandidateMatchDashboardContainer';

// TODO: Add tabs navigation for the candidate portal & include url navigation logic.
export const CandidatePortal: React.FC = () => {
  return (
    <CandidatePortalContainer data-testid={CandidatePortalDataTestIds.CANDIDATE_PORTAL_CONTAINER}>
      <CandidatePortalDashboardTabsContainer>
        <CandidateMatchDashboard />
      </CandidatePortalDashboardTabsContainer>
    </CandidatePortalContainer>
  );
};

const CandidatePortalContainer = styled(Container)({
  height: '100%',
  width: '100%',
});

const CandidatePortalDashboardTabsContainer = styled(Box)({
  width: '100%',
});
