import { Theme, styled, Typography } from '@mui/material';

export const ModalHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: theme.typography.h2.fontSize,
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '32px',
  letterSpacing: '0.15px',
  marginBottom: theme.spacing(1),
}));

export const ModalStyles = (theme: Theme) => ({
  borderRadius: theme.shape.borderRadius,
  maxWidth: '417px',
  margin: 'auto',
  padding: theme.spacing(4, 6),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(3, 4),
  },
});
