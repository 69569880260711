import { Box, useTheme } from '@mui/material';

import { Alert } from 'sharedComponents/Alert';
import { AlertContent, ScoreDisplayContainer, SubmittedBy } from '../styles';
import { ATSConnectHiringRequestCandidateFeedbackTestIds } from 'data-testids/ATS';
import { ScorecardProgressStatusProps, ScoreDisplayProps, TotalScoreStatusProps } from './types';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

export const CompletedText = ({ text }) => {
  const theme = useTheme();

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <AccessTimeIcon fontSize="small" sx={{ marginRight: theme.spacing(0.5) }} />
      {text}
    </Box>
  );
};

export const ScoreDisplay: React.FC<ScoreDisplayProps> = ({ score, maxScore, noIcon }) => {
  const theme = useTheme();

  return (
    <ScoreDisplayContainer
      data-testid={ATSConnectHiringRequestCandidateFeedbackTestIds.TOTAL_SCORE}
    >
      {!noIcon && <EmojiEventsIcon fontSize="small" sx={{ marginRight: theme.spacing(0.5) }} />}
      Total score: {score} / {maxScore} &nbsp;
    </ScoreDisplayContainer>
  );
};

export const TotalScoreStatus: React.FC<TotalScoreStatusProps> = ({
  completionText,
  cumulativeScore,
  maxCumulativeScore,
}) => {
  const theme = useTheme();

  return (
    <Alert
      icon={<EmojiEventsIcon />}
      dataTestId={ATSConnectHiringRequestCandidateFeedbackTestIds.TOTAL_SCORE_SECTION}
      sx={{
        width: '100%',
        marginTop: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        '.MuiAlert-message': {
          display: 'flex',
        },
      }}
    >
      <AlertContent>
        <ScoreDisplay score={cumulativeScore} maxScore={maxCumulativeScore} noIcon />
        <CompletedText text={completionText} />
      </AlertContent>
    </Alert>
  );
};

export const ScorecardProgressStatus: React.FC<ScorecardProgressStatusProps> = ({
  completionText,
  sx,
}) => {
  const theme = useTheme();

  return (
    <Alert
      icon={<AccessTimeIcon sx={{ fill: 'grey' }} />}
      dataTestId={ATSConnectHiringRequestCandidateFeedbackTestIds.SCORECARD_PROGRESS_SECTION}
      sx={{ width: '100%', marginTop: theme.spacing(2), ...sx }}
    >
      <SubmittedBy>{completionText}</SubmittedBy>
    </Alert>
  );
};
