import { Box, Checkbox, styled, Typography, useTheme } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { Accordion } from 'sharedComponents/Accordion/Accordion';
import { SelectedFieldsController } from './SelectedFieldsController';
import { CategoryLabel, FieldLabel } from './types';
import { ATSCandidateBulkExportTestIds } from 'data-testids/ATS';

type FieldsCategoryProps = {
  category: CategoryLabel;
  fields: FieldLabel[];
};

const FieldsCategory: React.FC<FieldsCategoryProps> = ({ category, fields }) => {
  const { watch, setValue } = useFormContext();
  const selectedFields = watch('selectedFields', []);
  const isCategorySelected = fields.every((field) => selectedFields.includes(field));
  const areSomeFieldsSelected = fields.some((field) => selectedFields.includes(field));
  const theme = useTheme();

  const handleCategoryChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const isChecked = e.target.checked;
    setValue(
      'selectedFields',
      isChecked
        ? [...selectedFields, ...fields]
        : selectedFields.filter((field: FieldLabel) => !fields.includes(field))
    );
  };

  const AccordionTitle = (
    <AccordionTitleContainer>
      <Checkbox
        checked={isCategorySelected}
        indeterminate={!isCategorySelected && areSomeFieldsSelected}
        onChange={(e) => handleCategoryChange(e)}
        onClick={(e) => e.stopPropagation()}
        data-testid={ATSCandidateBulkExportTestIds.CATEGORY_TITLE_CHECKBOX}
      />
      <CategoryTitle data-testid={`${ATSCandidateBulkExportTestIds.CATEGORY_TITLE}`}>
        {category}
      </CategoryTitle>
    </AccordionTitleContainer>
  );

  return (
    <Container data-testid={`${ATSCandidateBulkExportTestIds.CATEGORY_CONTAINER}`}>
      <Accordion
        title={AccordionTitle}
        dataTestId={ATSCandidateBulkExportTestIds.CATEGORY_ACCORDION}
        sx={{
          backgroundColor: 'transparent',
        }}
        summarySx={{
          '& .Mui-expanded': { marginTop: theme.spacing(2), marginBottom: 0 },
          '& > .Mui-expanded': { marginTop: theme.spacing(2), marginBottom: 0 },
        }}
      >
        <FieldsListContainer>
          {fields.map((field) => (
            <FieldContainer key={field}>
              <SelectedFieldsController fieldName={field} label={field} />
            </FieldContainer>
          ))}
        </FieldsListContainer>
      </Accordion>
    </Container>
  );
};

export default FieldsCategory;

const FieldsListContainer = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
}));

const FieldContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.white.darker,
  border: `1px solid ${theme.palette.border.main}`,
  borderRadius: theme.spacing(1.5),
}));

const AccordionTitleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const CategoryTitle = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMediumBold,
}));
