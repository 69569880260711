import { useTheme } from '@mui/material';

import { Accordion } from 'sharedComponents/Accordion/Accordion';
import { AccordionCTAExpanded } from './AccordionCTAExpanded';
import { AccordionTitleContainer, ActionsTitle, detailsSx, summarySx } from '../styles';
import { ActionsAccordionProps } from '../types';
import { ATSConnectHiringRequestTestIds } from 'data-testids/ATS';
import { ExpandIcon } from './Icons';

export const ActionsAccordion: React.FC<ActionsAccordionProps> = ({
  actions,
  getHasReceivedAllDocuments,
  handleExpansion,
  isExpanded,
}) => {
  const theme = useTheme();

  return (
    <Accordion
      dataTestId={ATSConnectHiringRequestTestIds.ACTIONS_ACCORDION}
      detailsSx={{
        ...detailsSx,
        backgroundColor: getHasReceivedAllDocuments() ? theme.palette.primary.fill : 'inherit',
      }}
      disableGutters
      expandIcon={<ExpandIcon />}
      onChange={handleExpansion}
      summarySx={{
        ...summarySx,
        backgroundColor: getHasReceivedAllDocuments() ? theme.palette.primary.fill : 'inherit',
      }}
      title={
        <AccordionTitleContainer data-testid={ATSConnectHiringRequestTestIds.ACCORDION_TITLE}>
          <ActionsTitle>Required actions (3)</ActionsTitle>
        </AccordionTitleContainer>
      }
    >
      <AccordionCTAExpanded isExpanded={isExpanded} actions={actions} />
    </Accordion>
  );
};
