import { EndOfMatchesIcon, NoPendingMatchesIcon } from './styles';

export const ViewState = {
  NO_PENDING_MATCHES: {
    primaryText: 'You have no pending matches',
    secondaryText: 'Check back next week for your next batch!',
    icon: NoPendingMatchesIcon,
  },
  END_OF_MATCHES: {
    primaryText: "Congrats!\nYou've viewed all\nyour matches.",
    secondaryText: 'Check back next week\nfor your next batch!',
    icon: EndOfMatchesIcon,
  },
  NO_LONGER_ACTIVE: {
    primaryText: "Uh-oh!\nLooks like you're no longer active",
    secondaryText:
      "Would you like to mark yourself as 'open to work' and 'open to being contacted by principals' to reactivate your matching account?",
    icon: EndOfMatchesIcon,
    buttonText: 'Yes, Reactivate me!',
    buttonAction: () => {
      // TODO: Implement reactivation logic post mvp
      console.log('Reactivate me!');
    },
  },
};
