import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import { Box, useTheme } from '@mui/material';
import { Add } from '@mui/icons-material';

import { Alert } from 'sharedComponents/Alert';
import { SecondaryButton } from 'sharedComponents/Buttons';
import RadioCardToggle from '../SharedComponents/RadioCardToggle';

import { CertificationsFormProps } from './types';
import { empty_certification_input } from './constants';
import { CertificationsFormInput } from './CertificationsFormInput';
import { QuestionLabel } from './ResumeAndCertificationsForm';
import { Grid } from 'sharedComponents/Grid';
import { STATE_DROPDOWN_OPTIONS } from 'features/Connect/utils/connectConstants';
import { ConnectQualificationsFormDataTestIds } from 'data-testids/ConnectDataTestIds';
import { LoadingSpinner } from 'sharedComponents/LoadingSpinner/loadingSpinner';

export const CertificationsForm: React.FC<CertificationsFormProps> = ({
  isUserDataLoading,
  handleFormChange,
  userFormData,
  setUserFormData,
  certificationsFormHasErrors,
  getConnectCertifications,
  isFormSubmitting,
  pageDisplayInformation,
  defaultStateID,
  setError,
  error,
}) => {
  const theme = useTheme();

  const { certifications } = userFormData;

  const addCertificationButtonHandler = () => {
    const newCertifications = [...certifications];
    if (getConnectCertifications().length === 0 || !certificationsFormHasErrors()) {
      newCertifications.push(empty_certification_input(defaultStateID));
    }
    setUserFormData({ ...userFormData, certifications: newCertifications });
  };

  return (
    <>
      <Box>
        <QuestionLabel
          required={pageDisplayInformation.requiredFields.includes('isEligibleToWorkInUS')}
        >
          Are you eligible to work in the United States <em>without</em> a sponsored visa?
        </QuestionLabel>
        <RadioGroup
          data-testid={ConnectQualificationsFormDataTestIds.VISA_TOGGLE_RADIO_GROUP}
          aria-labelledby="current-or-expected-certification-radio-buttons-group"
          name="isEligibleToWorkInUS"
          value={userFormData.isEligibleToWorkInUS}
          onChange={handleFormChange}
        >
          <Stack direction={{ xs: 'row' }} spacing={{ xs: 1, sm: 2 }} sx={{ width: '100%' }}>
            <RadioCardToggle
              dataTestId={ConnectQualificationsFormDataTestIds.VISA_TOGGLE_YES_OPTION}
              text="Yes"
              radioValue={true}
              selectedValue={userFormData.isEligibleToWorkInUS}
              width="50%"
              height="54px"
              disabled={isFormSubmitting}
            />
            <RadioCardToggle
              dataTestId={ConnectQualificationsFormDataTestIds.VISA_TOGGLE_NO_OPTION}
              text="No"
              radioValue={false}
              selectedValue={userFormData.isEligibleToWorkInUS}
              width="50%"
              height="54px"
              disabled={isFormSubmitting}
            />
          </Stack>
        </RadioGroup>
      </Box>
      <Box>
        <QuestionLabel required={pageDisplayInformation.requiredFields.includes('certifications')}>
          Do you have a current or expected certification?
        </QuestionLabel>
        <RadioGroup
          data-testid={ConnectQualificationsFormDataTestIds.CERTIFICATION_TOGGLE_RADIO_GROUP}
          aria-labelledby="current-or-expected-certification-radio-buttons-group"
          name="hasCertification"
          value={userFormData.hasCertification}
          onChange={handleFormChange}
        >
          <Stack direction={{ xs: 'row' }} spacing={{ xs: 1, sm: 2 }} sx={{ width: '100%' }}>
            <RadioCardToggle
              dataTestId={ConnectQualificationsFormDataTestIds.CERTIFICATION_TOGGLE_YES_OPTION}
              text="Yes"
              radioValue={true}
              selectedValue={userFormData.hasCertification}
              width="50%"
              height="54px"
              disabled={isFormSubmitting}
            />
            <RadioCardToggle
              dataTestId={ConnectQualificationsFormDataTestIds.CERTIFICATION_TOGGLE_NO_OPTION}
              text="No"
              radioValue={false}
              selectedValue={userFormData.hasCertification}
              width="50%"
              height="54px"
              disabled={isFormSubmitting}
            />
          </Stack>
        </RadioGroup>
      </Box>
      <Grid>
        {isUserDataLoading ? (
          <LoadingSpinner dataTestId={ConnectQualificationsFormDataTestIds.LOADING_SPINNER} />
        ) : (
          userFormData.hasCertification &&
          certifications?.length > 0 &&
          certifications.map((certification, index) => {
            if (!certification.isHidden) {
              return (
                <CertificationsFormInput
                  key={certification.client_id}
                  certification={certification}
                  userFormData={userFormData}
                  setUserFormData={setUserFormData}
                  isFormSubmitting={isFormSubmitting}
                  getConnectCertifications={getConnectCertifications}
                  stateDropdownOptions={STATE_DROPDOWN_OPTIONS}
                  index={index}
                  setError={setError}
                />
              );
            } else {
              return null;
            }
          })
        )}
      </Grid>
      {!isUserDataLoading && (
        <div style={{ margin: `${theme.spacing(1)} 0` }}>
          {userFormData.hasCertification ? (
            <SecondaryButton
              dataTestId={ConnectQualificationsFormDataTestIds.ADD_CERTIFICATION_BUTTON}
              sx={{
                width: '150px',
                height: '40px',
                fontSize: '15px',
                fontWeight: 600,
                borderRadius: '8px',
                padding: '6px 16px',
              }}
              onClick={addCertificationButtonHandler}
              disabled={
                isFormSubmitting || !!error.certificationSubject || !!error.certificationState
              }
            >
              <Add sx={{ marginRight: (theme) => theme.spacing(0.5) }} />
              Add another
            </SecondaryButton>
          ) : (
            <Alert>
              {
                "Don't worry, keep going! You may still be a qualified candidate if you are eligible for emergency certifications."
              }
            </Alert>
          )}
        </div>
      )}
    </>
  );
};
