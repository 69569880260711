export enum ConnectActionButtonsDataTestIds {
  EXPRESS_INTEREST_BUTTON = 'express-interest-button',
  SAVE_SCHOOL_BUTTON = 'connect-save-school-button',
}

export enum ConnectAlertsBannerDataTestIds {
  NOT_SUBSCRIBED_BANNER = 'connect-alerts-banner-not-subscribed',
  SUBSCRIBED_BANNER = 'connect-alerts-banner-subscribed',
}

export enum ConnectAuthenticatedAdminModalDataTestIds {
  ADMIN_MODAL = 'connect-authenticated-admin-modal',
  CANCEL_BUTTON = 'connect-authenticated-admin-modal-cancel-button',
  LOGOUT_BUTTON = 'connect-authenticated-admin-modal-logout-button',
}

export enum ConnectCandidateMatchDashboardDataTestIds {
  ACCEPT_MATCH_MODAL = 'connect-candidate-match-dashboard-accept-match-modal',
  ALREADY_CONNECTED_MODAL = 'connect-candidate-match-dashboard-already-connected-modal',
  BODY_CONTAINER = 'connect-candidate-match-dashboard-body-container',
  DASHBOARD_CONTAINER = 'connect-candidate-match-dashboard-container',
  PAGINATION_HEADER_CONTAINER = 'connect-candidate-match-dashboard-pagination-header-container',
  REJECT_MATCH_MODAL = 'connect-candidate-match-dashboard-reject-match-modal',
}

export enum ConnectCandidateMatchCardDataTestIds {
  BODY_CONTAINER = 'connect-candidate-match-dashboard-match-card-body-container',
  BUTTON_GROUP_CONTAINER = 'connect-candidate-match-dashboard-match-card-button-group-container',
  CARD_TITLE_CONTAINER = 'connect-candidate-match-dashboard-match-card-title-container',
  CARD_TITLE_TEXT = 'connect-candidate-match-dashboard-match-card-title-text',
  DIVIDER = 'connect-candidate-match-dashboard-match-card-divider',
  HEADER_CONTAINER = 'connect-candidate-match-dashboard-match-card-header-container',
  MATCH_CARD_CONTAINER = 'connect-candidate-match-dashboard-match-card-container',
}

export enum ConnectCandidateMatchCardSchoolSectionDataTestIds {
  SCHOOL_HEADER_CONTAINER = 'connect-candidate-match-dashboard-match-card-school-header-container',
  SCHOOL_NAME_CONTAINER = 'connect-candidate-match-dashboard-match-card-school-name-container',
  SCHOOL_NAME_TEXT = 'connect-candidate-match-dashboard-match-card-school-name-text',
  DISTRICT_NAME_TEXT = 'connect-candidate-match-dashboard-match-card-district-name-text',
  SCHOOL_INFO_CONTAINER = 'connect-candidate-match-dashboard-match-card-school-info-container',
  SCHOOL_ROLES_LIST_CONTAINER = 'connect-candidate-match-dashboard-match-card-school-roles-list-container',
  SCHOOL_SECTION_CONTAINER = 'connect-candidate-match-dashboard-match-card-school-section-container',
  SAVE_SCHOOL_BUTTON = 'connect-candidate-match-dashboard-match-card-save-school-button',
  SAVE_SCHOOL_BUTTON_CONTAINER = 'connect-candidate-match-dashboard-match-card-save-school-button-container',
  SCHOOL_PRINCIPAL_NAME_TEXT = 'connect-candidate-match-dashboard-match-card-school-principal-name-text',
  SCHOOL_AND_DISTRICT_NAME_CONTAINER = 'connect-candidate-match-dashboard-match-card-school-and-district-name-container',
}

export enum ConnectCandidateMatchCardButtonGroupDataTestIds {
  ACCEPT_MATCH_BUTTON = 'connect-candidate-match-dashboard-match-card-button-group-accept-match-button',
  ALREADY_CONNECTED_BUTTON = 'connect-candidate-match-dashboard-match-card-button-group-already-connected-button',
  REJECT_MATCH_BUTTON = 'connect-candidate-match-dashboard-match-card-button-group-reject-match-button',
}

export enum ConnectCandidateMatchDashboardPaginationHeaderDataTestIds {
  HEADER_CONTAINER = 'connect-candidate-match-dashboard-pagination-header',
  MATCH_TITLE = 'connect-candidate-match-dashboard-pagination-header-match-title',
  PAGINATION_BUTTON_GROUP = 'connect-candidate-match-dashboard-pagination-header-button-group',
  PREV_BUTTON = 'connect-candidate-match-dashboard-pagination-header-button-group-prev-button',
  NEXT_BUTTON = 'connect-candidate-match-dashboard-pagination-header-button-group-next-button',
  PAGE_LOCATION_TEXT = 'connect-candidate-match-dashboard-pagination-header-button-group-location-text',
  PAGE_NUMBER = 'connect-candidate-match-dashboard-pagination-header-button-group-page-number',
  TOTAL_MATCHES_NUMBER = 'connect-candidate-match-dashboard-pagination-header-button-group-total-pages-number',
}

export enum ConnectCandidateMatchDashboardAcceptMatchModalDataTestIds {
  ACCEPT_MATCH_BUTTON_GROUP = 'connect-candidate-match-dashboard-accept-match-modal-button-group',
  ACCEPT_MATCH_OPTION_GROUP = 'connect-candidate-match-dashboard-accept-match-modal-option-group',
  CANCEL_BUTTON = 'connect-candidate-match-dashboard-accept-match-modal-cancel-button',
  ACCEPT_MATCH_BUTTON = 'connect-candidate-match-dashboard-accept-match-modal-accept-match-button',
  HEADER_TEXT = 'connect-candidate-match-dashboard-accept-match-modal-header-text',
  SCHEDULE_INTERVIEW_OPTION = 'connect-candidate-match-dashboard-accept-match-modal-option-group-schedule-interview-option',
  SCHEDULE_INTERVIEW_OPTION_LABEL = 'connect-candidate-match-dashboard-accept-match-modal-option-group-schedule-interview-option-label',
  SCHEDULE_INTERVIEW_OPTION_CHECKED_ICON = 'connect-candidate-match-dashboard-accept-match-modal-option-group-schedule-interview-option-checked-icon',
  SCHEDULE_INTERVIEW_OPTION_UNCHECKED_ICON = 'connect-candidate-match-dashboard-accept-match-modal-option-group-schedule-interview-option-unchecked-icon',
  EMAIL_OPTION = 'connect-candidate-match-dashboard-accept-match-modal-option-group-email-option',
  EMAIL_OPTION_LABEL = 'connect-candidate-match-dashboard-accept-match-modal-option-group-email-option-label',
  EMAIL_OPTION_CHECKED_ICON = 'connect-candidate-match-dashboard-accept-match-modal-option-group-email-option-checked-icon',
  EMAIL_OPTION_UNCHECKED_ICON = 'connect-candidate-match-dashboard-accept-match-modal-option-group-email-option-unchecked-icon',
}

export enum ConnectCandidateMatchDashboardAlreadyConnectedModalDataTestIds {
  HEADER_TEXT = 'connect-candidate-match-dashboard-already-connected-modal-header-text',
  CANCEL_BUTTON = 'connect-candidate-match-dashboard-already-connected-modal-cancel-button',
  SUBMIT_BUTTON = 'connect-candidate-match-dashboard-already-connected-modal-submit-button',
  OPTION_GROUP = 'connect-candidate-match-dashboard-already-connected-modal-option-group',
  EMAIL_OPTION_LABEL = 'connect-candidate-match-dashboard-already-connected-modal-option-group-email-option-label',
  EMAIL_OPTION_CHECKBOX = 'connect-candidate-match-dashboard-already-connected-modal-option-group-email-option-checkbox',
  TEXT_OPTION_LABEL = 'connect-candidate-match-dashboard-already-connected-modal-option-group-text-option-label',
  TEXT_OPTION_CHECKBOX = 'connect-candidate-match-dashboard-already-connected-modal-option-group-text-option-checkbox',
  PHONE_CALL_OPTION_LABEL = 'connect-candidate-match-dashboard-already-connected-modal-option-group-phone-call-option-label',
  PHONE_CALL_OPTION_CHECKBOX = 'connect-candidate-match-dashboard-already-connected-modal-option-group-phone-call-option-checkbox',
  ON_SITE_INTERVIEW_OPTION_LABEL = 'connect-candidate-match-dashboard-already-connected-modal-option-group-on-site-interview-option-label',
  ON_SITE_INTERVIEW_OPTION_CHECKBOX = 'connect-candidate-match-dashboard-already-connected-modal-option-group-on-site-interview-option-checkbox',
  VIRTUAL_MEETING_OPTION_LABEL = 'connect-candidate-match-dashboard-already-connected-modal-option-group-virtual-meeting-option-label',
  VIRTUAL_MEETING_OPTION_CHECKBOX = 'connect-candidate-match-dashboard-already-connected-modal-option-group-virtual-meeting-option-checkbox',
}

export enum ConnectCandidateMatchDashboardInAppMatchMessagingModalDataTestIds {
  MODAL = 'connect-candidate-match-dashboard-in-app-match-messaging-modal',
  HEADER_TEXT = 'connect-candidate-match-dashboard-in-app-match-messaging-modal-header-text',
  MESSAGE_INPUT = 'connect-candidate-match-dashboard-in-app-match-messaging-modal-message-input',
  SEND_BUTTON = 'connect-candidate-match-dashboard-in-app-match-messaging-modal-send-button',
  CANCEL_BUTTON = 'connect-candidate-match-dashboard-in-app-match-messaging-modal-cancel-button',
  ALERT_MESSAGE = 'connect-candidate-match-dashboard-in-app-match-messaging-modal-alert-message',
  PRINCIPAL_MESSAGE_SECTION = 'connect-candidate-match-dashboard-in-app-match-messaging-modal-principal-message-section',
}

export enum ConnectCandidateMatchDashboardRejectMatchModalDataTestIds {
  REJECT_MATCH_BUTTON_GROUP = 'connect-candidate-match-dashboard-reject-match-modal-button-group',
  CANCEL_BUTTON = 'connect-candidate-match-dashboard-reject-match-modal-cancel-button',
  REJECT_MATCH_BUTTON = 'connect-candidate-match-dashboard-reject-match-modal-reject-match-button',
  HEADER_TEXT = 'connect-candidate-match-dashboard-reject-match-modal-header-text',
  REJECT_MATCH_OPTION_GROUP = 'connect-candidate-match-dashboard-reject-match-modal-option-group',
  NO_LONGER_LOOKING_LABEL = 'connect-candidate-match-dashboard-reject-match-modal-option-group-no-longer-looking-label',
  NO_LONGER_LOOKING_CHECKBOX = 'connect-candidate-match-dashboard-reject-match-modal-option-group-no-longer-looking-checkbox',
  SCHOOL_TOO_FAR_LABEL = 'connect-candidate-match-dashboard-reject-match-modal-option-group-school-too-far-label',
  SCHOOL_TOO_FAR_CHECKBOX = 'connect-candidate-match-dashboard-reject-match-modal-option-group-school-too-far-checkbox',
  NO_MATCHING_OPENING_LABEL = 'connect-candidate-match-dashboard-reject-match-modal-option-group-no-matching-opening-label',
  NO_MATCHING_OPENING_CHECKBOX = 'connect-candidate-match-dashboard-reject-match-modal-option-group-no-matching-opening-checkbox',
  COMPENSATION_BENEFITS_UNMET_LABEL = 'connect-candidate-match-dashboard-reject-match-modal-option-group-compensation-benefits-unmet-label',
  COMPENSATION_BENEFITS_UNMET_CHECKBOX = 'connect-candidate-match-dashboard-reject-match-modal-option-group-compensation-benefits-unmet-checkbox',
  WRONG_ENVIRONMENT_LABEL = 'connect-candidate-match-dashboard-reject-match-modal-option-group-wrong-environment-label',
  WRONG_ENVIRONMENT_CHECKBOX = 'connect-candidate-match-dashboard-reject-match-modal-option-group-wrong-environment-checkbox',
  OTHER_REJECT_REASON_LABEL = 'connect-candidate-match-dashboard-reject-match-modal-option-group-other-label',
  OTHER_REJECT_REASON_CHECKBOX = 'connect-candidate-match-dashboard-reject-match-modal-option-group-other-checkbox',
}

export enum ConnectJobDiscoveryFiltersDataTestIds {
  DISTANCE_FILTER = 'connect-job-discovery-distance-filter-control',
  DISTRICT_FILTER = 'connect-job-discovery-district-filter-dropdown',
  GRADE_FILTER = 'connect-job-discovery-grade-level-filter',
  LOCATION_INPUT = 'connect-job-discovery-location-filter-input',
  LOCATION_MAP_CONTAINER = 'connect-job-discovery-location-filter-map-container',
  SCHOOL_SEARCH_FILTER = 'connect-job-discovery-school-search-filter',
  SUBJECTS_FILTER = 'connect-job-discovery-subject-filter',
  UPDATE_DISTANCE_FROM_LOCATION_SUBMIT_BUTTON = 'connect-job-discovery-location-filter-submit-button',
}

export enum ConnectJobDiscoveryGatedResultsCardDataTestIds {
  GATED_RESULTS_CARD = 'connect-job-discovery-gated-results-card-container',
  HEADER_TEXT = 'connect-job-discovery-gated-results-card-header-text',
  SIGNUP_BUTTON = 'gated-results-card-signup-button',
}

export enum ConnectJobDiscoverySchoolCardDataTestIds {
  SCHOOL_CARD = 'connect-job-discovery-school-card-container',
  LOADING_SPINNER = 'connect-job-discovery-school-card-loading-spinner',
}

export enum ConnectLoginSignUpPageDataTestIds {
  EMAIL_INPUT = 'connect-login-sign-up-page-email-input',
  SUBMIT_BUTTON = 'connect-login-sign-up-page-submit-button-primary-button',
  TERMS_OF_SERVICE_STATEMENT = 'connect-login-sign-up-page-terms-of-service-statement',
  HEADER_TEXT = 'connect-login-sign-up-page-header-text',
}

export enum ConnectMagicLinkModalTestIds {
  HEADER_TEXT = 'magic-link-modal-header-text',
}

export enum ConnectMarketingInputAndButtonDataTestIds {
  COMPLETE_PROFILE_BUTTON = 'connect-marketing-banner-complete-profile-button',
  EMAIL_INPUT = 'marketing-email-input-jobs-discovery',
  SIGN_UP_BUTTON = 'marketing-sign-up-button',
}

export enum ConnectMoreInfoSliderDataTestIds {
  MORE_INFO_SLIDER = 'connect-job-discovery-more-info-slider',
  LOADING_SPINNER = 'connect-job-discovery-more-info-slider-loading-spinner',
}

export enum ConnectValuePropBannerDataTestIds {
  HEADER_TEXT = 'connect-job-discovery-value-prop-banner-header-text',
  SUB_HEADER_TEXT = 'connect-job-discovery-value-prop-banner-sub-header-text',
  SUB_HEADER_LINK_TEXT = 'connect-job-discovery-value-prop-banner-sub-header-link-text',
  IMAGE_ASSET = 'connect-job-discovery-value-prop-banner-image-asset',
  TERMS_OF_SERVICE_STATEMENT = 'connect-job-discovery-value-prop-banner-terms-of-service-statement',
}

export enum ConnectProfileFormDataTestIds {
  HEADER_TEXT = 'connect-profile-flow-profile-form-header-text',
  FIRST_NAME_INPUT = 'connect-profile-flow-profile-form-first-name-input',
  FIRST_NAME_QUESTION_LABEL = 'connect-profile-flow-profile-form-first-name-question-label',
  LAST_NAME_INPUT = 'connect-profile-flow-profile-form-last-name-input',
  LAST_NAME_QUESTION_LABEL = 'connect-profile-flow-profile-form-last-name-question-label',
  PHONE_NUMBER_INPUT = 'connect-profile-flow-profile-form-phone-number-input',
  PHONE_NUMBER_QUESTION_LABEL = 'connect-profile-flow-profile-form-phone-number-question-label',
  STATE_PREFERENCES_DROPDOWN = 'connect-profile-flow-profile-form-state-preferences-dropdown',
  STATE_PREFERENCES_QUESTION_LABEL = 'connect-profile-flow-profile-form-state-preferences-question-label',
  BUTTON_GROUP = 'connect-profile-flow-profile-form-button-group',
  SAVE_AND_CONTINUE_BUTTON = 'connect-profile-flow-profile-form-save-and-continue-button',
  OPT_IN_FOR_TEXT_MESSAGES_CHECKBOX = 'connect-profile-flow-profile-form-opt-in-for-text-messages-checkbox',
}

export enum ConnectQualificationsFormDataTestIds {
  ADD_CERTIFICATION_BUTTON = 'connect-profile-flow-qualifications-form-add-certification-button',
  CERTIFICATION_STATE_ERROR_TEXT = 'connect-profile-flow-qualifications-form-certification-state-error-text',
  CERTIFICATION_STATE_SELECT = 'connect-profile-flow-qualifications-form-certification-state-select',
  CERTIFICATION_SUBJECT_ERROR_TEXT = 'connect-profile-flow-qualifications-form-certification-subject-error-text',
  CERTIFICATION_SUBJECT_SELECT = 'connect-profile-flow-qualifications-form-certification-subject-select',
  CERTIFICATION_TOGGLE_RADIO_GROUP = 'connect-profile-flow-qualifications-form-certification-toggle-radio-group',
  CERTIFICATION_TOGGLE_YES_OPTION = 'connect-profile-flow-qualifications-form-certification-toggle-yes-option',
  CERTIFICATION_TOGGLE_NO_OPTION = 'connect-profile-flow-qualifications-form-certification-toggle-no-option',
  BUTTON_GROUP = 'connect-profile-flow-qualifications-form-button-group',
  BACK_BUTTON = 'connect-profile-flow-qualifications-form-back-button',
  CONTINUE_BUTTON = 'connect-profile-flow-qualifications-form-continue-button',
  REMOVE_CERTIFICATION_BUTTON = 'connect-profile-flow-qualifications-form-remove-certification-button',
  RESUME_ERROR_TEXT = 'connect-profile-flow-qualifications-form-resume-error-text',
  TEACHING_EXPERIENCE_ERROR_TEXT = 'connect-profile-flow-qualifications-form-teaching-experience-error-text',
  TEACHING_EXPERIENCE_SELECT = 'connect-profile-flow-qualifications-form-teaching-experience-select',
  UPLOAD_RESUME_TOOL = 'connect-profile-flow-qualifications-form-upload-resume-tool',
  DELETE_CERTIFICATION_BUTTON = 'connect-profile-flow-qualifications-form-delete-certification-button',
  VISA_TOGGLE_RADIO_GROUP = 'connect-profile-flow-qualifications-form-visa-toggle-radio-group',
  VISA_TOGGLE_YES_OPTION = 'connect-profile-flow-qualifications-form-visa-toggle-yes-option',
  VISA_TOGGLE_NO_OPTION = 'connect-profile-flow-qualifications-form-visa-toggle-no-option',
  LOADING_SPINNER = 'connect-profile-flow-qualifications-form-loading-spinner',
}

export enum ConnectPreferencesFormDataTestIds {
  HEADER_TEXT = 'connect-profile-flow-preferences-form-header-text',
  LOADING_SPINNER = 'connect-profile-flow-preferences-form-loading-spinner',
  CONNECT_OPPORTUNITY_NO = 'connect-profile-flow-preferences-form-connect-opportunity-no-toggle',
  CONNECT_OPPORTUNITY_QUESTION_LABEL = 'connect-profile-flow-preferences-form-connect-opportunity-question-label',
  CONNECT_OPPORTUNITY_RADIO_GROUP = 'connect-profile-flow-preferences-form-connect-opportunity-radio-group',
  CONNECT_OPPORTUNITY_YES = 'connect-profile-flow-preferences-form-connect-opportunity-yes-toggle',
  DISTANCE_SLIDER = 'connect-profile-flow-preferences-form-distance-slider',
  GRADES_DROPDOWN = 'connect-profile-flow-preferences-form-grades-dropdown',
  GRADES_QUESTION_LABEL = 'connect-profile-flow-preferences-form-grades-question-label',
  JOB_SEEKING_STATUS_NO = 'connect-profile-flow-preferences-form-job-seeking-status-no-toggle',
  JOB_SEEKING_STATUS_QUESTION_LABEL = 'connect-profile-flow-preferences-form-job-seeking-status-question-label',
  JOB_SEEKING_STATUS_RADIO_GROUP = 'connect-profile-flow-preferences-form-job-seeking-status-radio-group',
  JOB_SEEKING_STATUS_YES = 'connect-profile-flow-preferences-form-job-seeking-status-yes-toggle',
  LOCATION_INPUT = 'connect-profile-flow-preferences-form-location-text-field-input',
  LOCATION_INPUT_ERROR = `connect-profile-flow-preferences-form-location-text-field-input-error`,
  LOCATION_MAP_CONTAINER = `connect-profile-flow-preferences-form-location-map-container`,
  LOCATION_QUESTION_LABEL = 'connect-profile-flow-preferences-form-location-question-label',
  BACK_BUTTON = 'connect-profile-flow-preferences-form-back-button',
  BUTTON_GROUP = 'connect-profile-flow-preferences-form-button-group',
  SAVE_AND_CONTINUE_BUTTON = 'connect-profile-flow-preferences-form-save-and-continue-button',
  SCHOOLS_TO_HIDE_FROM_DROPDOWN = 'connect-profile-flow-preferences-form-schools-to-hide-from-dropdown',
  SCHOOLS_TO_HIDE_FROM_QUESTION_LABEL = 'connect-profile-flow-preferences-form-schools-to-hide-from-question-label',
  SUBJECTS_DROPDOWN = 'connect-profile-flow-preferences-form-subjects-dropdown',
  SUBJECTS_QUESTION_LABEL = 'connect-profile-flow-preferences-form-subjects-question-label',
}

export enum ConnectProfileFlowDataTestIds {
  CONNECT_ANNOUNCEMENT_BANNER = 'connect-profile-flow-announcement-banner',
  LEARN_MORE_LINK = 'connect-profile-flow-learn-more-link',
  STEPPER = 'connect-profile-flow-stepper',
  STEPPER_MOBILE = 'connect-profile-flow-stepper-mobile',
  STEPPER_DESKTOP = 'connect-profile-flow-stepper-desktop',
  PROFILE_FORM = 'connect-profile-flow-profile-form',
  QUALIFICATIONS_FORM = 'connect-profile-flow-qualifications-form',
  PREFERENCES_FORM = 'connect-profile-flow-preferences-form',
}
