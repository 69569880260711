import { useEffect, useState, useRef } from 'react';
import { Box, useTheme, Typography, styled, Theme, Stack, TextField } from '@mui/material';
import { PrimaryButton, SecondaryButton } from 'sharedComponents/Buttons';
import { ScrollableModal } from 'sharedComponents/Modal/ScrollableModal';
import SendIcon from '@mui/icons-material/Send';

import { InAppMatchMessagingModalProps } from '../../types';
import { MatchCardSchoolSectionHeader } from 'features/Connect/components/MatchCard/SchoolSectionHeader';
import { PrincipalMessage } from 'features/Connect/components/PrincipalMessage/PrincipalMessage';
import { Alert } from 'sharedComponents/Alert';
import { ConnectCandidateMatchDashboardInAppMatchMessagingModalDataTestIds } from 'data-testids/ConnectDataTestIds';
import { ButtonStyle, SecondaryButtonStyle } from 'features/Connect/components/Buttons/styles';
export const InAppMatchMessagingModal: React.FC<InAppMatchMessagingModalProps> = ({
  school,
  principal,
  principalMessage,
  isOpen,
  onClose,
}) => {
  const isMounted = useRef(true);
  const [error, setError] = useState<string | null>(null);
  const [message, setMessage] = useState<string>('');
  const theme = useTheme();

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (error) setError(null);
    setMessage(event.target.value);
  };

  const handleSubmit = async () => {};

  const modalHeader = (
    <HeaderContainer>
      <HeaderText
        data-testid={ConnectCandidateMatchDashboardInAppMatchMessagingModalDataTestIds.HEADER_TEXT}
      >
        Message this Principal
      </HeaderText>
      <MatchCardSchoolSectionHeader school={school} isMatchCard={false} />
    </HeaderContainer>
  );

  const modalBody = (
    <BodyContainer gap={theme.spacing(3)}>
      <InAppMatchMessageBodyContainer>
        <MessageSection
          data-testid={
            ConnectCandidateMatchDashboardInAppMatchMessagingModalDataTestIds.PRINCIPAL_MESSAGE_SECTION
          }
        >
          <PrincipalMessage
            principal={principal}
            messageHeaderText="Message to you:"
            principalMessage={principalMessage}
            isMessageModal={true}
          />
        </MessageSection>
      </InAppMatchMessageBodyContainer>
    </BodyContainer>
  );

  const modalFooter = (
    <FooterContainer>
      <TextField
        data-testid={
          ConnectCandidateMatchDashboardInAppMatchMessagingModalDataTestIds.MESSAGE_INPUT
        }
        multiline
        rows={4}
        onChange={handleChange}
        sx={inputStyles(theme)}
        placeholder="Please include the best ways for the principal to reach you and/or suggest times to connect."
        error={!!error}
        helperText={error}
      />
      <Alert
        data-testid={
          ConnectCandidateMatchDashboardInAppMatchMessagingModalDataTestIds.ALERT_MESSAGE
        }
      >
        Be sure to check your email! Principals will respond via email to your message here.
      </Alert>
      <Stack direction="row" width="100%" gap={theme.spacing(1)}>
        <SecondaryButton
          dataTestId={
            ConnectCandidateMatchDashboardInAppMatchMessagingModalDataTestIds.CANCEL_BUTTON
          }
          size="small"
          color="black"
          onClick={onClose}
          sx={SecondaryButtonStyle(theme)}
        >
          Cancel
        </SecondaryButton>
        <PrimaryButton
          dataTestId={ConnectCandidateMatchDashboardInAppMatchMessagingModalDataTestIds.SEND_BUTTON}
          size="small"
          onClick={handleSubmit}
          sx={ButtonStyle(theme)}
          startIcon={<SendIcon />}
          color="black"
          disabled={!message}
        >
          Send email
        </PrimaryButton>
      </Stack>
    </FooterContainer>
  );

  return (
    <ScrollableModal
      dataTestId={ConnectCandidateMatchDashboardInAppMatchMessagingModalDataTestIds.MODAL}
      open={isOpen}
      onClose={onClose}
      header={modalHeader}
      body={modalBody}
      footer={modalFooter}
      sx={modalStyles(theme)}
    />
  );
};

const HeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'start',
  gap: theme.spacing(2),
}));

const HeaderText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: theme.typography.h2.fontSize,
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightMediumBold,
  lineHeight: theme.typography.h2.lineHeight,
  letterSpacing: '-0.4px',
}));

const BodyContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

const InAppMatchMessageBodyContainer = styled(Box)({
  overflowY: 'auto',
  minHeight: 0,
});

const FooterContainer = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  justifyContent: 'space-between',
  marginTop: theme.spacing(2),
}));

const MessageSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

const modalStyles = (theme: Theme) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  margin: 'auto',
  maxWidth: '600px',
  borderRadius: theme.shape.borderRadius * 0.75,
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3, 2),
  },
});

const inputStyles = (theme: Theme) => ({
  width: '100%',
  borderRadius: theme.shape.borderRadius,
  alignSelf: 'center',
  '& input::placeholder': {
    fontSize: theme.typography.h3.fontSize,
    fontStyle: 'normal',
    fontWeight: theme.typography.fontWeightRegular,
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.shape.borderRadius * 0.75,
  },
});
