import { useTheme } from '@mui/material';

import { ATSTabbedJobViewQuestionsTabTestIds } from 'data-testids/ATS';
import { QuestionTabHeaderProps } from '../types';
import { SectionTitle } from 'features/TabbedJobView/styles';
import { TabbedJobViewChip } from 'features/TabbedJobView/components/TabbedJobViewChip';
import { Header } from '../styles';

export const QuestionTabHeader: React.FC<QuestionTabHeaderProps> = ({ chipText }) => {
  const theme = useTheme();

  return (
    <Header>
      <SectionTitle
        data-testid={ATSTabbedJobViewQuestionsTabTestIds.QUESTIONS_FOR_CANDIDATE_TITLE}
        variant="h2"
        sx={{ paddingRight: theme.spacing(1) }}
      >
        Questions for candidate
      </SectionTitle>
      <TabbedJobViewChip
        dataTestId={ATSTabbedJobViewQuestionsTabTestIds.QUESTIONS_COUNT_CHIP}
        label={chipText}
      />
    </Header>
  );
};
