import { useEffect, useState } from 'react';
import { Box, Link, useTheme } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router';

import auth from 'utils/auth';
import { ATSConnectImportCandidateFormTestIds } from 'data-testids/ATS';
import {
  CTAButtonContainer,
  ModalContainer,
  HelpText,
  Key,
  Row,
  SubContainer,
  Title,
  ErrorText,
  Form,
} from '../ImportForm/styles';
import { JobComboBox } from './JobComboBox';
import { LandingFormProps } from '../ImportForm/types';
import { PrimaryButton } from 'sharedComponents/Buttons';
import {
  useQueryCheckIfCandidateHasApplication,
  useQuerySearchActiveRoles,
} from '../ImportForm/queries';
import { HiringRequestForm } from './HiringRequestForm';

export const LandingForm: React.FC<LandingFormProps> = ({
  candidateEmail,
  candidateId,
  candidateName,
  onSubmit,
  roleId,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [debouncedInputValue, setDebouncedInputValue] = useState(inputValue);

  const theme = useTheme();
  const history = useHistory();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { data } = useQueryCheckIfCandidateHasApplication(candidateId, roleId, Boolean(roleId));

  const candidatesListURL = auth.isSchoolAdmin()
    ? `/school/candidates?query=${candidateEmail}`
    : `/district/candidates?query=${candidateEmail}`;

  const { data: options, isLoading: isDataLoading } = useQuerySearchActiveRoles(
    debouncedInputValue,
    {
      enabled: debouncedInputValue.length > 0,
      keepPreviousData: true,
      staleTime: 60000,
      cacheTime: 120000,
    }
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedInputValue(inputValue);
    }, 300);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [inputValue]);

  const handleInputChange = (text: string) => {
    setInputValue(text);
  };

  const isLoading = isDataLoading && Boolean(inputValue);

  const handleSubmit = (e) => {
    e.preventDefault();

    onSubmit(e);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <ModalContainer sx={{ width: 'inherit' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Title variant="h1" data-testid={ATSConnectImportCandidateFormTestIds.TITLE}>
            Request to Hire {candidateName}
          </Title>
        </Box>
        <SubContainer>
          <Row data-testid={ATSConnectImportCandidateFormTestIds.CANDIDATE_NAME}>
            <Key>Candidate:</Key> &nbsp;{candidateName}
          </Row>
          <Row data-testid={ATSConnectImportCandidateFormTestIds.CANDIDATE_EMAIL}>
            <Key>Candidate email:</Key> &nbsp; {candidateEmail}
          </Row>
        </SubContainer>
        <SubContainer>
          <Key>What role do you want to hire this candidate for?</Key>
          <HelpText sx={{ paddingBottom: theme.spacing(1) }}>
            Don&apos;t see the role you want? Contact your administrator for more information or
            &nbsp;
            <Link sx={{ cursor: 'pointer' }} onClick={() => history.push('/school/jobcreate')}>
              submit a job request
            </Link>
            .
          </HelpText>
          <Controller
            name="role"
            control={control}
            rules={{ required: 'Please select a role' }}
            render={({ field: { onChange } }) => (
              <JobComboBox
                data-testid={ATSConnectImportCandidateFormTestIds.JOB_COMBOBOX}
                onChange={onChange}
                hasError={Boolean(errors.role)}
                onInputChange={handleInputChange}
                isLoading={isLoading}
                options={options}
                inputValue={inputValue}
              />
            )}
          />
          {errors.role && <ErrorText>{errors.role.message}</ErrorText>}
        </SubContainer>
        {data?.has_existing_app === false && <HiringRequestForm candidateName={candidateName} />}
        {data?.has_existing_app === true && (
          <>
            <SubContainer
              data-testid={
                ATSConnectImportCandidateFormTestIds.CANDIDATE_HAS_ALREADY_APPLIED_SECTION
              }
            >
              <Key>
                It looks like this candidate already has an active application for this role.
                Here&apos;s what you can do next:
              </Key>
              <li>
                Look through your candidate list for their application by clicking the button below.
              </li>
              <li>
                If you can&apos;t find this candidate&apos;s application, contact your administrator
                for more information on where the candidate is in the hiring process.
              </li>
            </SubContainer>
            <CTAButtonContainer>
              <PrimaryButton
                dataTestId={ATSConnectImportCandidateFormTestIds.VIEW_CANDIDATES_BUTTON}
                onClick={() => history.push(candidatesListURL)}
              >
                View Candidates
              </PrimaryButton>
            </CTAButtonContainer>
          </>
        )}
      </ModalContainer>
    </Form>
  );
};
