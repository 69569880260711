import { styled, Typography } from '@mui/material';

export const SectionTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.black.dark,
  fontWeight: theme.typography.fontWeightMediumBold,
}));

export const TabTitle = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMediumBold,
  marginBottom: theme.spacing(3),
}));
