import { Box, styled } from '@mui/material';
import ExistingNotesSection from './ExistingNotesSection';
import AddNoteSection from './AddNoteSection';

const InternalNotesTab: React.FC<{ jobId: number }> = ({ jobId }) => {
  return (
    <TabContainer>
      <ExistingNotesSection jobId={jobId} />
      <AddNoteSection jobId={jobId} />
    </TabContainer>
  );
};

export default InternalNotesTab;

const TabContainer = styled(Box)(({ theme }) => ({
  maxWidth: theme.breakpoints.values.md,
}));
