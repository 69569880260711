import Avatar from '@mui/material/Avatar';
import Hidden from '@mui/material/Hidden';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import { Theme } from '@mui/material';
import { useMediaQuery } from '@mui/material';

import { SchoolCardProps } from '../types';
import { ExpressInterestButton } from '../Buttons/ExpressInterestButton';
import { moreInfoOnClickHandler } from 'features/Connect/features/ExpressInterest/utils';
import { SchoolInfoHighlightRow } from '../HighlightRows/HighlightRowSchoolInfo';
import { useConnectContext } from 'features/Connect/features/Context/ConnectContextProvider';
// removing badges from school cards until after state demo's
// import { OnPlatformBadge } from '../InfoBadges/OnPlatformBadge';
// import { RecommendedBadge } from '../InfoBadges/RecommendedBadge';
// import { renderSecondarySchoolTags } from 'features/Connect/features/JobDiscovery/utils';

export const BasicInfoSection: React.FC<SchoolCardProps> = ({ school, isInterestModal }) => {
  const { connectContextActions } = useConnectContext();
  const hasPrincipal = !!school?.principal;
  const principalInitials = [school?.principal?.first_name[0], school?.principal?.last_name[0]];
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          alignItems: 'center',
          width: '100%',
          marginBottom: isInterestModal ? '0px' : isMobile ? '8px' : '16px',
        }}
      >
        {hasPrincipal && (
          <Hidden smDown>
            <PrincipalContainer
              href="#"
              onClick={() => moreInfoOnClickHandler(school, connectContextActions, '0')}
              gap={1}
              sx={{
                alignItems: 'center',
                width: '100%',
                marginBottom: isInterestModal ? '0px' : '16px',
              }}
            >
              <Avatar
                sx={{
                  width: '32px',
                  height: '32px',
                  borderRadius: '200px',
                  background: '#E5ECFF',
                  color: '#475467',
                  fontSize: '14px',
                  fontWeight: '600',
                }}
              >
                {principalInitials}
              </Avatar>
              <Typography sx={{ fontSize: '16px' }}>
                {`Principal ${school?.principal?.first_name} ${school?.principal?.last_name}`}
              </Typography>
            </PrincipalContainer>
          </Hidden>
        )}
        <Hidden smUp>
          <Stack direction={'row'} spacing={'8px'} gap={'4px'}>
            {!isInterestModal && <ExpressInterestButton school={school} />}

            {isInterestModal && hasPrincipal && (
              <Avatar
                sx={{
                  width: '32px',
                  height: '32px',
                  borderRadius: '200px',
                  background: '#E5ECFF',
                  color: '#475467',
                  fontSize: '14px',
                  fontWeight: '600',
                }}
              >
                {principalInitials}
              </Avatar>
            )}
            {hasPrincipal && (
              <Typography
                sx={{
                  fontSize: '16px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  width: 180,
                }}
                alignSelf={'center'}
              >
                {`${school?.principal?.first_name} ${school?.principal?.last_name}`}
              </Typography>
            )}
          </Stack>
        </Hidden>
      </Stack>
      {/* <Stack sx={{ display: { xs: 'block', md: 'none' } }} gap={0.5} spacing={0.5}>
        {school.is_recommended && <RecommendedBadge />}
        {school?.principal?.on_connect && <OnPlatformBadge />}
        {renderSecondarySchoolTags(school)}
      </Stack> */}
      <SchoolInfoHighlightRow school={school} isInterestModal={isInterestModal} />
    </>
  );
};

const PrincipalContainer = styled(Link)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  color: theme.palette.text.light,
  alignItems: 'center',
  textDecoration: 'none',
}));
