import auth from 'utils/auth';

import { TabbedJobViewJob } from './types';

export const shouldShowTabbedJobViewField = (
  job: TabbedJobViewJob,
  fieldName: keyof TabbedJobViewJob
): boolean => {
  const field = job[fieldName];

  if (!field || field === null || (Array.isArray(field) && field.length === 0)) {
    return false;
  }

  if (fieldName === 'salary_max') {
    return !!job['salary_max'] || !!job['salary_min'];
  }

  const alwaysVisibleFields = new Set(['contact', 'description', 'benefits', 'deadline']);

  if (alwaysVisibleFields.has(fieldName)) {
    return true;
  }

  if (fieldName === 'district_jobboard_slug' && auth.inPostingOnlyDistrict()) {
    return false;
  }

  return true;
};

export const getDistrictJobBoardURL = (slug?: string): string => {
  if (!slug || auth.inPostingOnlyDistrict()) return null;
  const colon = window.location.hostname.indexOf('localhost') !== -1 ? ':' : '';
  return `${window.location.protocol}//${window.location.hostname}${colon}${window.location.port}/jobs/${slug}`;
};
