import { PrimaryButton, TextButton } from 'sharedComponents/Buttons';
import { SecondaryButton } from 'sharedComponents/Buttons';
import { Box, Grid, styled, Theme, useMediaQuery, useTheme } from '@mui/material';
import { ConnectCandidateMatchCardButtonGroupDataTestIds } from 'data-testids/ConnectDataTestIds';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import {
  SecondaryButtonContainer,
  PrimaryButtonContainer,
  ButtonStyle,
  SecondaryButtonStyle,
} from './styles';
import {
  CandidateMatchCardInterface,
  DashboardModalTypes,
} from 'features/Connect/features/ConnectCandidatePortalDashboards/CandidateMatchDashboard/utils/types';
import { useConnectContext } from 'features/Connect/features/Context/ConnectContextProvider';

export const MatchCardButtonGroup: React.FC<CandidateMatchCardInterface> = ({
  candidateMatchDashboardActions,
}) => {
  const { isLoading } = useConnectContext();
  const theme = useTheme();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  return (
    <Grid
      container
      gap={isMobile ? theme.spacing(1) : theme.spacing(4)}
      direction="column"
      wrap="nowrap"
      justifyContent={'center'}
      alignContent={'center'}
    >
      <Grid
        container
        item
        xs={12}
        justifyContent={'center'}
        gap={theme.spacing(1)}
        direction={'row'}
        wrap="nowrap"
      >
        <Grid item xs={6} justifyContent={'end'}>
          <SecondaryButtonContainer>
            <SecondaryButton
              onClick={() =>
                candidateMatchDashboardActions.setIsModalOpen(DashboardModalTypes.REJECT, true)
              }
              dataTestId={ConnectCandidateMatchCardButtonGroupDataTestIds.REJECT_MATCH_BUTTON}
              sx={{
                ...SecondaryButtonStyle(theme),
                maxWidth: '180px',
              }}
              color="black"
              startIcon={<ClearIcon sx={IconStyle} />}
              disabled={!!isLoading}
              size="extraLarge"
            >
              No thanks
            </SecondaryButton>
          </SecondaryButtonContainer>
        </Grid>
        <Grid item xs={6} justifyContent={'start'}>
          <PrimaryButtonContainer>
            <PrimaryButton
              dataTestId={ConnectCandidateMatchCardButtonGroupDataTestIds.ACCEPT_MATCH_BUTTON}
              onClick={() =>
                candidateMatchDashboardActions.setIsModalOpen(DashboardModalTypes.ACCEPT, true)
              }
              isLoading={isLoading}
              disabled={!!isLoading}
              loadingSpinnerColor="inherit"
              loadingSpinnerSize={18}
              sx={{
                ...ButtonStyle(theme),
                maxWidth: '180px',
              }}
              size="extraLarge"
              color="primary"
              startIcon={<CheckIcon sx={IconStyle} />}
            >
              I&apos;m Interested
            </PrimaryButton>
          </PrimaryButtonContainer>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TextButtonContainer>
          Already responded to this principal?
          <TextButton
            data-testid={ConnectCandidateMatchCardButtonGroupDataTestIds.ALREADY_CONNECTED_BUTTON}
            onClick={() =>
              candidateMatchDashboardActions.setIsModalOpen(
                DashboardModalTypes.ALREADY_CONNECTED,
                true
              )
            }
            // disabled={isLoading ? true : false}
            sx={TextButtonStyle(theme)}
          >
            Let us know
          </TextButton>
        </TextButtonContainer>
      </Grid>
    </Grid>
  );
};

const TextButtonContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
  flexWrap: 'nowrap',
}));

const TextButtonStyle = (theme: Theme) => ({
  color: theme.palette.secondary.main,
  fontSize: theme.typography.body2.fontSize,
  fontStyle: 'normal',
  fontWeight: theme.typography.body2.fontWeight,
  lineHeight: theme.typography.body2.lineHeight,
  textDecoration: 'underline',
});

const IconStyle = (theme: Theme) => ({
  width: theme.spacing(3),
  height: theme.spacing(3),
  flexShrink: 0,
});
