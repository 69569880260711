import { FeedbackStatusProps } from '../../types';
import { getCompletionText } from '../utils';
import { RecommendationStatus } from './RecommendationStatus';
import { ScorecardProgressStatus, TotalScoreStatus } from './Statuses';

export const FeedbackStatus: React.FC<FeedbackStatusProps> = ({ data }) => {
  const completionText = getCompletionText(data.scorecard_complete, data.created);
  const hasRecommendation = data.final_recommendation && data.show_answer_on_preview;
  const hasScore = data.cumulative_score && data.show_answer_on_preview;

  if (!hasRecommendation && hasScore) {
    return (
      <TotalScoreStatus
        completionText={completionText}
        cumulativeScore={data.cumulative_score}
        maxCumulativeScore={data.max_cumulative_score}
      />
    );
  }

  if (!hasRecommendation && !hasScore) {
    return <ScorecardProgressStatus completionText={completionText} />;
  }

  if (hasRecommendation) {
    return (
      <RecommendationStatus
        cumulativeScore={data.cumulative_score}
        finalRecommendation={data.final_recommendation}
        maxCumulativeScore={data.max_cumulative_score}
        showTotalOnPreview={data.show_total_on_preview}
        completionText={completionText}
      />
    );
  }

  return <></>;
};
