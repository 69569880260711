import { useMediaQuery, useTheme } from '@mui/material';

import { nimbleTheme } from 'theme';

type Breakpoints = keyof typeof nimbleTheme.breakpoints.values;

export const useScreenSize = (breakpoint: Breakpoints) => {
  const theme = useTheme();
  const query = `(min-width: ${theme.breakpoints.values[breakpoint]}px)`;
  return useMediaQuery(query);
};
