import { Box, styled } from '@mui/material';
import {
  ConnectCandidateMatchCardSchoolSectionDataTestIds,
  ConnectMoreInfoSliderDataTestIds,
} from 'data-testids/ConnectDataTestIds';
import { MatchCardSchoolSectionHeader } from './SchoolSectionHeader';
import { PrincipalComponent } from 'features/Connect/components/PrincipalComponent/PrincipalComponent';
import { SchoolInfoHighlightRow } from 'features/Connect/components/HighlightRows';
import { LoadingSpinner } from 'sharedComponents/LoadingSpinner/loadingSpinner';
import { VacancyAccordionRow } from 'features/Connect/components/VacancySection/VacancySectionAccordionRow';
import { CandidateMatchCardInterface } from 'features/Connect/features/ConnectCandidatePortalDashboards/CandidateMatchDashboard/utils/types';
import { Vacancy } from 'types/connectTypes';
import { useEffect, useState } from 'react';
import { CandidateMatchCardJobPostingsLimit } from 'features/Connect/features/ConnectCandidatePortalDashboards/CandidateMatchDashboard/utils/constants';
import { TextButton } from 'sharedComponents/Buttons';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useConnectContext } from 'features/Connect/features/Context/ConnectContextProvider';

export const MatchCardSchoolSection: React.FC<CandidateMatchCardInterface> = ({
  school,
  principal,
  isLoading = false,
  vacancies,
}) => {
  const { connectContextActions } = useConnectContext();
  const [vacanciesToShow, setVacanciesToShow] = useState<Vacancy[]>([]);

  useEffect(() => {
    setVacanciesToShow(vacancies?.slice(0, CandidateMatchCardJobPostingsLimit));
  }, [vacancies]);

  return (
    <SchoolSectionContainer>
      <SchoolHeaderContainer
        data-testid={ConnectCandidateMatchCardSchoolSectionDataTestIds.SCHOOL_HEADER_CONTAINER}
      >
        <MatchCardSchoolSectionHeader school={school} />
        <PrincipalComponent principal={principal} />
      </SchoolHeaderContainer>
      <SchoolInfoContainer
        data-testid={ConnectCandidateMatchCardSchoolSectionDataTestIds.SCHOOL_INFO_CONTAINER}
      >
        <SchoolInfoHighlightRow school={school} />
      </SchoolInfoContainer>
      <RoleListContainer
        data-testid={ConnectCandidateMatchCardSchoolSectionDataTestIds.SCHOOL_ROLES_LIST_CONTAINER}
      >
        {isLoading ? (
          <LoadingSpinner dataTestId={ConnectMoreInfoSliderDataTestIds.LOADING_SPINNER} />
        ) : (
          <VacancyAccordionRow
            school={school}
            vacancies={vacanciesToShow}
            isCandidateDashboard={true}
            isMatchCard={true}
          />
        )}
      </RoleListContainer>
      <ShowMoreButtonContainer>
        <StyledTextButton
          endIcon={<ArrowForwardIcon />}
          onClick={() => connectContextActions.setIsMoreInfoSliderOpen(true)}
        >
          Show more
        </StyledTextButton>
      </ShowMoreButtonContainer>
    </SchoolSectionContainer>
  );
};

const SchoolSectionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: theme.spacing(1.5),
}));

const SchoolHeaderContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(0.5),
  },
}));

const SchoolInfoContainer = styled(Box)(() => ({
  width: '100%',
}));

const RoleListContainer = styled(Box)(() => ({
  width: '100%',
}));

const StyledTextButton = styled(TextButton)(({ theme }) => ({
  alignSelf: 'flex-end',
  alignItems: 'center',
  '& .MuiButton-endIcon': {
    marginLeft: theme.spacing(0.5),
  },
}));

const ShowMoreButtonContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'end',
  margin: theme.spacing(-1.5, 0, -3, 0),
}));
