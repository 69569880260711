import { createContext, useContext, useEffect, useState, ReactNode } from 'react';

import auth from 'utils/auth';

import { SignupSourcesCookie } from 'types/types';

export const SIGNUP_SOURCES_COOKIE_NAME = 'signup_data';

const COOKIE_MAX_AGE = 365;
const MILLISECONDS_IN_A_DAY = 24 * 60 * 60 * 1000;

const SignupSourcesContext = createContext(null);

export interface SignupSourcesContextData {
  signupSourcesCookie: SignupSourcesCookie;
  deleteSignupSourcesCookie: () => void;
}

export const useSignupSourcesContext = (): SignupSourcesContextData => {
  const signupSourcesCookie = useContext(SignupSourcesContext);

  const deleteSignupSourcesCookie = () => deleteCookie(SIGNUP_SOURCES_COOKIE_NAME);

  return { signupSourcesCookie, deleteSignupSourcesCookie };
};

const deleteCookie = (name: string) => {
  document.cookie = name + '=; Max-Age=0; path=/';
};

const setCookie = (name: string, value: string, days: number) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * MILLISECONDS_IN_A_DAY);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
};

interface SignupSourcesProviderProps {
  children: ReactNode;
}

export const SignupSourcesProvider = ({ children }: SignupSourcesProviderProps): JSX.Element => {
  const [signupSources, setSignupSources] = useState(null);

  useEffect(() => {
    const checkAuthUserStatusAndSetCookie = async () => {
      const isUserLoggedIn = await auth.loggedIn();

      if (isUserLoggedIn) return;

      const queryParamsData = new URLSearchParams(window.location.search);
      const referrer = document.referrer;

      if (referrer && referrer.includes('hirenimble.com') && !queryParamsData) return;

      const cookieData = { referrer };
      queryParamsData.forEach((value, key) => {
        if (key.startsWith('utm_')) {
          cookieData[key] = value;
        }
      });

      setCookie(SIGNUP_SOURCES_COOKIE_NAME, JSON.stringify(cookieData), COOKIE_MAX_AGE);
      setSignupSources(cookieData);
    };

    checkAuthUserStatusAndSetCookie();
  }, []);

  return (
    <SignupSourcesContext.Provider value={signupSources}>{children}</SignupSourcesContext.Provider>
  );
};
