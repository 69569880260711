import { BlankModal } from 'sharedComponents/Modal';
import { Box, Theme, styled, useTheme } from '@mui/material';
import { ModalButtonGroup } from '../../Buttons/ModalButtonGroup';
import { useState } from 'react';
import { ModalOptionGroup } from '../../ModalFormOptions/ModalOptionGroup';
import {
  ConnectCandidateMatchDashboardDataTestIds,
  ConnectCandidateMatchDashboardAcceptMatchModalDataTestIds,
} from 'data-testids/ConnectDataTestIds';
import { ModalHeader } from '../utils/styles';
import { ACCEPT_MATCH_OPTIONS } from '../utils/constants';

export const AcceptMatchModal = ({ isOpen, onClose, openInAppMessagingModal }) => {
  const [selectedOption, setSelectedOption] = useState<string>('');
  const theme = useTheme();

  const CancelButton = {
    label: 'Cancel',
    action: onClose,
    dataTestId: ConnectCandidateMatchDashboardAcceptMatchModalDataTestIds.CANCEL_BUTTON,
  };
  const acceptMatch = () => {
    if (selectedOption === 'email') {
      openInAppMessagingModal();
      onClose();
    }
  };

  const AcceptMatchButton = {
    label: 'Continue',
    action: acceptMatch,
    dataTestId: ConnectCandidateMatchDashboardAcceptMatchModalDataTestIds.ACCEPT_MATCH_BUTTON,
    color: 'primary',
    disabled: !selectedOption,
  };

  const handleReasonChange = (value: string) => {
    setSelectedOption(value);
  };

  const acceptHeader = (
    <AcceptModalHeader
      data-testid={ConnectCandidateMatchDashboardAcceptMatchModalDataTestIds.HEADER_TEXT}
    >
      How would you like to connect?
    </AcceptModalHeader>
  );

  const acceptMatchButtonGroup = () => {
    return (
      <ModalButtonGroup
        secondaryButton={CancelButton}
        primaryButton={AcceptMatchButton}
        dataTestId={
          ConnectCandidateMatchDashboardAcceptMatchModalDataTestIds.ACCEPT_MATCH_BUTTON_GROUP
        }
        sx={{ marginTop: theme.spacing(2) }}
      />
    );
  };

  const acceptMatchBody = () => {
    return (
      <AcceptModalBody>
        <ModalOptionGroup
          modalOptions={ACCEPT_MATCH_OPTIONS}
          selectedOption={selectedOption}
          handleReasonChange={handleReasonChange}
          dataTestId={
            ConnectCandidateMatchDashboardAcceptMatchModalDataTestIds.ACCEPT_MATCH_OPTION_GROUP
          }
          optionType="radio"
        />
        {acceptMatchButtonGroup()}
      </AcceptModalBody>
    );
  };

  return (
    <BlankModal
      dataTestId={ConnectCandidateMatchDashboardDataTestIds.ACCEPT_MATCH_MODAL}
      header={acceptHeader}
      body={acceptMatchBody()}
      open={isOpen}
      onClose={onClose}
      sx={AcceptModalStyles(theme)}
    />
  );
};

const AcceptModalStyles = (theme: Theme) => ({
  display: 'flex',
  maxWidth: '401px',
  borderRadius: theme.shape.borderRadius,
  margin: 'auto',
  alignItems: 'flex-end',
  alignContent: 'flex-end',
  gap: theme.spacing(3, 1),
  flexWrap: 'wrap',
  padding: theme.spacing(4, 6),

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(3, 4),
  },
});

const AcceptModalHeader = styled(ModalHeader)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

const AcceptModalBody = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));
