import { useState, useEffect } from 'react';
import { ReportColumn } from '../../../types/types';
import styled from 'styled-components';
import {
  FlatFileTransferPropsInterface,
  ColumnAction,
  ColumnActionType,
  FlatFileTransferOptionsInterface,
} from '../types/types';
import {
  ColumnNameInput,
  DataSourceInput,
  ConstantValueInput,
  UserProfileFieldInput,
  CustomProfileFieldInput,
  DataFormattingInput,
  HelloSignFormsInput,
  HelloSignFormFieldsInput,
  ApplicationStatusDateInput,
  DistrictApplicationRoleInput,
  ApplicationFieldInput,
} from './FlatFileTransferInputs';
import deleteIcon from 'assets/icon-delete.svg';
import mappingIcon from 'assets/icon-plus.svg';
import draggableIcon from 'assets/icon-draggable.svg';
import DeleteModal from 'components/JobEdit/DeleteModal';
import { dataSourceOptions } from '../DropdownOptions';
import { ATSDataFFTDataTestIds } from '../../../data-testids/ATS';

const acceptedColumnTypes: string[] = dataSourceOptions.map((option) => option.value);

export default function FlatFileTransferColumn({
  index,
  columnState,
  dispatch,
  customProfileFields,
  openModal,
  helloSignTemplateOptions,
  applicationStatusOptions,
}: {
  index: ColumnAction['index'];
  columnState: ReportColumn;
  dispatch: FlatFileTransferPropsInterface['dispatch'];
  customProfileFields: FlatFileTransferOptionsInterface[];
  openModal: (index) => void;
  helloSignTemplateOptions: FlatFileTransferOptionsInterface[];
  applicationStatusOptions: FlatFileTransferOptionsInterface[];
}): React.ReactElement {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showFormatsColumn, setShowFormatsColumn] = useState<boolean>(false);
  const [isBackendOnlyField, setIsBackendOnlyField] = useState<boolean>(false);

  const deleteColumn = (): void => {
    dispatch({ type: ColumnActionType.DELETE_COLUMN, id: columnState.id });
  };

  useEffect(() => {
    if (!columnState.type || acceptedColumnTypes.includes(columnState.type)) {
      setIsBackendOnlyField(false);
    } else if (!acceptedColumnTypes.includes(columnState.type)) {
      setIsBackendOnlyField(true);
    }
  }, [columnState.type]);

  return (
    <DraggableContainer data-testid={ATSDataFFTDataTestIds.COLUMN_ROW}>
      <DragAndDropIcon src={draggableIcon} />
      <ColumnContainer style={{ height: isBackendOnlyField ? '129px' : '104px' }}>
        <InputsContainer>
          <ColumnNameInput index={index} columnState={columnState} dispatch={dispatch} />
          <DataSourceInput
            index={index}
            columnState={columnState}
            dispatch={dispatch}
            isBackendOnlyField={isBackendOnlyField}
            setShowFormatsColumn={setShowFormatsColumn}
          />
          {columnState.type && columnState.type === 'constant_value' && (
            <ConstantValueInput index={index} columnState={columnState} dispatch={dispatch} />
          )}
          {columnState.type && columnState.type === 'user_profile' && (
            <UserProfileFieldInput
              index={index}
              columnState={columnState}
              dispatch={dispatch}
              setShowFormatsColumn={setShowFormatsColumn}
            />
          )}
          {columnState.type && columnState.type === 'custom_profile_field' && (
            <CustomProfileFieldInput
              index={index}
              columnState={columnState}
              dispatch={dispatch}
              customProfileFields={customProfileFields}
              setShowFormatsColumn={setShowFormatsColumn}
            />
          )}
          {columnState.type && columnState.type === 'hellosign' && (
            <HelloSignFormsInput
              index={index}
              columnState={columnState}
              dispatch={dispatch}
              setShowFormatsColumn={setShowFormatsColumn}
              helloSignTemplateOptions={helloSignTemplateOptions}
            />
          )}
          {columnState.type && columnState.type === 'application_status_date' && (
            <ApplicationStatusDateInput
              index={index}
              columnState={columnState}
              dispatch={dispatch}
              setShowFormatsColumn={setShowFormatsColumn}
              applicationStatusOptions={applicationStatusOptions}
            />
          )}
          {columnState.type && columnState.type === 'district_application_role' && (
            <DistrictApplicationRoleInput
              index={index}
              columnState={columnState}
              dispatch={dispatch}
              setShowFormatsColumn={setShowFormatsColumn}
            />
          )}
          {columnState.type && columnState.type === 'application' && (
            <ApplicationFieldInput
              index={index}
              columnState={columnState}
              dispatch={dispatch}
              setShowFormatsColumn={setShowFormatsColumn}
            />
          )}
          {columnState.template_id && (
            <HelloSignFormFieldsInput
              index={index}
              columnState={columnState}
              dispatch={dispatch}
              setShowFormatsColumn={setShowFormatsColumn}
            />
          )}
          {showFormatsColumn && columnState.type !== 'constant_value' && (
            <DataFormattingInput index={index} columnState={columnState} dispatch={dispatch} />
          )}
        </InputsContainer>
        <ActionMenuContainer>
          {isBackendOnlyField && (
            <BackendOnlyFieldMessage>
              This column has been pre-configured by Nimble staff to support this data type. Please
              contact <a href="mailto:support@hirenimble.com">support@hirenimble.com</a>
              to edit.
            </BackendOnlyFieldMessage>
          )}
        </ActionMenuContainer>
      </ColumnContainer>
      <ButtonContainer>
        <DeleteIcon
          src={deleteIcon}
          onClick={() => setShowDeleteModal(true)}
          data-testid={ATSDataFFTDataTestIds.DELETE_ROW_BUTTON}
        />
        <MappingIcon src={mappingIcon} onClick={() => openModal(index)} />
      </ButtonContainer>
      <DeleteModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onSave={() => deleteColumn()}
        title={'Delete'}
        message={'Are you sure you want to delete this column?'}
        cancelLabel={'Cancel'}
        submitLabel={'Delete'}
        buttonColorRed
      />
    </DraggableContainer>
  );
}

const DraggableContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  width: 100%;
  min-width: 1366px;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: flex-start;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: #f2f2f2;
  padding: 12px 16px 14px 22px;

  @media screen and (max-width: 1535px) {
    width: 1318px;
  }

  @media screen and (max-width: 1440px) {
    width: 1246px;
  }

  @media screen and (min-width: 1536px) {
    width: 1400px;
  }

  @media screen and (min-width: 1900px) {
    width: 1760px;
  }
`;

const InputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  gap: 12px;
  margin-bottom: 8px;
`;

const ActionMenuContainer = styled.section`
  display: flex;
  flex-direction: column;
  height: 40px;
  justify-content: space-between;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: none;
  justify-content: center;
  padding-left: 16px;
  padding-right: 22px;
  width: 76px;
  min-width: 76px;
`;

const DeleteIcon = styled.img`
  width: 40px;
  height: 40px;
  flex-basis: 50%;
  cursor: pointer;
`;

const MappingIcon = styled.img`
  width: 40px;
  height: 40px;
  flex-basis: 50%;
  cursor: pointer;
  margin-top: 8px;
`;

const BackendOnlyFieldMessage = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: #333333;

  > a {
    color: #2f58d2;
    margin-right: 4px;
  }
`;

const DragAndDropIcon = styled.img`
  width: 20px;
  min-width: 20px;
  margin: 0 12px 0 12px;
  flex-shrink: 0;
`;
