import Box from '@mui/material/Box';
import { styled } from '@mui/material';
import Cancel from '@mui/icons-material/Close';
import { SwipeableDrawerProps } from './types';
import { SwipeableDrawer as MUISwipeableDrawer } from '@mui/material';

export const SwipeableDrawer = ({
  children,
  closeHandler,
  openHandler,
  anchor = 'right',
  isOpen = false,
  showCloseButton = true,
  sx,
  dataTestId,
  paperProps,
}: SwipeableDrawerProps): JSX.Element => {
  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
  };

  return (
    <MUISwipeableDrawer
      anchor={anchor}
      open={isOpen}
      onClose={closeHandler}
      onOpen={openHandler}
      sx={sx}
      data-testid={dataTestId}
      PaperProps={paperProps}
    >
      <SwipeableDrawerContainer onClick={handleClick} sx={sx} anchor={anchor}>
        {showCloseButton && (
          <CloseButton onClick={closeHandler}>
            <Cancel sx={{ width: '20px', height: '20px' }} />
          </CloseButton>
        )}
        {children}
      </SwipeableDrawerContainer>
    </MUISwipeableDrawer>
  );
};

const SwipeableDrawerContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'anchor',
})<{ anchor: string }>(({ theme, anchor }) => ({
  width: anchor === 'left' || anchor === 'right' ? '50vw' : '100vw',

  [theme.breakpoints.down('sm')]: {
    width: '100vw',
  },
}));

export const CloseButton = styled('button')({
  position: 'absolute',
  top: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#F0F0F0',
  color: '#3A3A3A',
  borderStyle: 'none',
  borderRadius: '50%',
  padding: '10px',
  margin: '12px',
});
