import { createContext, useState } from 'react';

// name of the flags created in the backend must coincide with the names in this object
export const featureTags = {
  CREATE_JOBS: 'create_jobs_v2', //placeholder
  DELETE_JOBS: 'delete_jobs_v2', //placeholder
  TABBED_JOB_POSTING: 'tabbed_job_posting',
  LOGGED_IN_JOB_BOARD: 'logged_in_job_board',
  FLAT_FILE_TRANSFERS: 'flat_file_transfers',
  TREVOR_DASHBOARD: 'trevor_dashboard',
  LOCATION_PREFERENCES: 'location_preferences',
  SOURCE_FILTER: 'source_filter',
  BULK_FORMS: 'bulk_forms',
  JOB_TEMPLATES: 'job_templates',
  CONNECT_JOB_ALERTS: 'connect_job_alerts',
  CONNECT_SEARCH_RESULTS: 'connect_search_results',
  DISABLE_CANDIDATE_LIVE_SEARCH: 'disable_candidate_live_search',
  JOB_REQUEST_UPDATE: 'job_request_update',
  TABBED_JOB_VIEW: 'tabbed_job_view',
  DEPRECATE_LOCAL_QUESTION_SETS: 'deprecate_local_question_sets',
  EXPORT_CANDIDATE_TAGS: 'export_candidate_tags',
  ATS_CONNECT_CANDIDATE_PROCESSING: 'ats_connect_candidate_processing',
} as const;

type FeatureTagKey = keyof typeof featureTags;
type FeatureTagValue = (typeof featureTags)[FeatureTagKey];

export class Features {
  feature_name: FeatureTagValue;
  feature_enabled: boolean;

  constructor(feature_name: FeatureTagValue, feature_enabled: boolean) {
    this.feature_name = feature_name;
    this.feature_enabled = feature_enabled;
  }
}

export interface FeatureContextValue {
  features: string[];
  setFeatures: React.Dispatch<React.SetStateAction<string[]>>;
}

const Context = createContext<FeatureContextValue>({
  features: [],
  setFeatures: () => {},
});

interface FeatureContextProviderProps {
  children: React.ReactNode;
}

export const FeatureContextProvider: React.FC<FeatureContextProviderProps> = ({ children }) => {
  const [features, setFeatures] = useState<string[]>([]);

  return <Context.Provider value={{ features, setFeatures }}>{children}</Context.Provider>;
};

export default Context;
