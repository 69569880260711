import { Box, useTheme } from '@mui/material';

import { ATSTabbedJobViewVacanciesTabTestIds } from 'data-testids/ATS';
import { Row, TableSubtitle, TableTitle } from '../styles';
import { SchoolVacanciesProps } from '../types';
import { SectionTitle } from 'features/TabbedJobView/styles';
import { constructVacancyRows } from '../utils';
import { BasicTable, BasicTableColumn } from 'sharedComponents/Table/BasicTable';

export const SchoolVacancies: React.FC<SchoolVacanciesProps> = ({ schoolRoles }) => {
  const theme = useTheme();
  const rows = constructVacancyRows(schoolRoles);
  type RowKey = keyof (typeof rows)[number];

  const columns: BasicTableColumn<RowKey>[] = [
    {
      id: 0,
      content: (
        <>
          <TableTitle
            data-testid={ATSTabbedJobViewVacanciesTabTestIds.SCHOOL_VACANCIES_COLUMN_TITLE}
          >
            Locations
          </TableTitle>
          <TableSubtitle>Where might candidates be hired?</TableSubtitle>
        </>
      ),
      field: 'location',
      align: 'left',
      sx: { backgroundColor: theme.palette.white.darker },
    },
    {
      id: 1,
      content: (
        <>
          <TableTitle
            data-testid={ATSTabbedJobViewVacanciesTabTestIds.SCHOOL_VACANCIES_COLUMN_TITLE}
          >
            Vacancies &nbsp;
          </TableTitle>
          <TableSubtitle>How many vacancies at this location?</TableSubtitle>
        </>
      ),
      field: 'vacancies',
      align: 'left',
      sx: { backgroundColor: theme.palette.white.darker, maxWidth: '120px' },
    },
    {
      id: 2,
      content: (
        <>
          <TableTitle
            data-testid={ATSTabbedJobViewVacanciesTabTestIds.SCHOOL_VACANCIES_COLUMN_TITLE}
          >
            School admin access
          </TableTitle>
          <TableSubtitle>Can admins view candidates?</TableSubtitle>
        </>
      ),
      field: 'isVisibleToSchoolAdmin',
      align: 'left',
      sx: { backgroundColor: theme.palette.white.darker },
    },
  ];

  return (
    <Box>
      <Row>
        <SectionTitle variant="h3">School-based vacancies</SectionTitle>
      </Row>
      <Row data-testid={ATSTabbedJobViewVacanciesTabTestIds.VACANCIES_TABLE}>
        <BasicTable columns={columns} rows={rows} />
      </Row>
    </Box>
  );
};
