import { createContext, useContext, ReactNode } from 'react';
import { useLocation, useRouteMatch } from 'react-router';
import { connectUrlParams } from '../JobDiscovery/constants';
import { CONNECT_JOBBOARD_STATES } from 'utils/constants';

const StateCodeContext = createContext<string | undefined>(undefined);

const StateParamProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { search } = useLocation();
  const match = useRouteMatch<{ id: string }>('/connect/:id');
  const stateURLParam = match?.params.id;
  const searchParams = new URLSearchParams(search);
  const stateRedirectParam = searchParams.get(connectUrlParams.REDIRECT);
  // when/if we eventually add states with '-' we will need to add functionality to convert '-' to '_'
  const collectedParam = stateRedirectParam?.toUpperCase() || stateURLParam?.toUpperCase();
  const isParamValid = !!CONNECT_JOBBOARD_STATES[collectedParam];

  const jobboardState = isParamValid ? collectedParam : CONNECT_JOBBOARD_STATES.MISSOURI.stateName;

  return <StateCodeContext.Provider value={jobboardState}>{children}</StateCodeContext.Provider>;
};

export const useStateParam = () => {
  const context = useContext(StateCodeContext);
  if (context === undefined) {
    throw new Error('useStateParam must be used within a StateParamProvider');
  }
  return context;
};

export { StateParamProvider };
