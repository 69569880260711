import { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  ColumnActionType,
  FlatFileTransferOptionsInterface,
  FlatFileTransferInputPropsInterface,
} from '../types/types';
import {
  acceptedUserProfileFieldAttributes,
  acceptedDistrictApplicationRoleFields,
  dataFormatOptions,
  dataSourceOptions,
  applicationFieldOptions,
} from '../DropdownOptions';
import { CustomSelect } from './Dropdown';
import { formatDropdownOptionLabel } from 'utils/util';
import HellosignTemplatesAPI from 'api/hellosignTemplatesAPI';
import { ATSDataFFTDataTestIds } from '../../../data-testids/ATS';

export function ColumnNameInput({
  index,
  columnState,
  dispatch,
}: FlatFileTransferInputPropsInterface): React.ReactElement {
  const [isMissingInput, setIsMissingInput] = useState<boolean>(false);

  useEffect(() => {
    setIsMissingInput(!columnState.title);
  }, [columnState.title]);
  return (
    <InputContainer>
      <InputTitleContainer>
        {isMissingInput && <RequiredAsterix>* &nbsp;</RequiredAsterix>}
        <InputTitle>Column Name</InputTitle>
      </InputTitleContainer>
      <Input
        type="text"
        title="Column Name"
        data-testid={ATSDataFFTDataTestIds.COLUMN_NAME_INPUT}
        value={columnState.title}
        style={{ borderColor: isMissingInput ? '#CC0000' : '#d7d7d7' }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          dispatch({ type: ColumnActionType.SET_TITLE, value: event.target.value, index: index });
        }}
        autoFocus
      />
    </InputContainer>
  );
}

export function DataSourceInput({
  index,
  columnState,
  dispatch,
  isBackendOnlyField,
  setShowFormatsColumn,
}: FlatFileTransferInputPropsInterface): React.ReactElement {
  const [selectedDropdownOption, setSelectedDropdownOption] =
    useState<FlatFileTransferOptionsInterface>(null);
  const [isMissingInput, setIsMissingInput] = useState<boolean>(false);

  useEffect(() => {
    if (columnState.type) {
      setSelectedDropdownOption(
        dataSourceOptions.find((option) => option.value === columnState.type)
      );
    }
    setIsMissingInput(!columnState.type);
    setShowFormatsColumn(columnState.type === 'date_sent');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnState.type, isMissingInput]);

  const handleOnChange = (option) => {
    setSelectedDropdownOption(option);
    dispatch({
      type: ColumnActionType.SET_TYPE,
      value: option.value,
      index: index,
      fieldName: 'type',
    });
  };

  return (
    <InputContainer data-testid={ATSDataFFTDataTestIds.DATA_SOURCE_SELECT}>
      <InputTitleContainer>
        {isMissingInput && <RequiredAsterix>* &nbsp;</RequiredAsterix>}
        <InputTitle>Data Source</InputTitle>
      </InputTitleContainer>
      <CustomSelect
        value={
          isBackendOnlyField
            ? { label: formatDropdownOptionLabel(columnState.type), value: columnState.type }
            : selectedDropdownOption
        }
        isMissingInput={isMissingInput}
        onChange={(option: FlatFileTransferOptionsInterface) => handleOnChange(option)}
        options={dataSourceOptions}
        isDisabled={isBackendOnlyField}
      />
    </InputContainer>
  );
}

export function ConstantValueInput({
  index,
  columnState,
  dispatch,
}: FlatFileTransferInputPropsInterface): React.ReactElement {
  return (
    <InputContainer>
      <InputTitleContainer>
        <InputTitle>INPUT VALUE</InputTitle>
      </InputTitleContainer>
      <Input
        value={columnState.column_value || ''}
        style={{ borderColor: '#d7d7d7' }}
        placeholder={'Enter value'}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          dispatch({
            type: ColumnActionType.SET_VALUE,
            value: event.target.value,
            index: index,
            fieldName: 'column_value',
          })
        }
      ></Input>
    </InputContainer>
  );
}

export function UserProfileFieldInput({
  index,
  columnState,
  setShowFormatsColumn,
  dispatch,
}: FlatFileTransferInputPropsInterface): React.ReactElement {
  const [selectedDropdownOption, setSelectedDropdownOption] =
    useState<FlatFileTransferOptionsInterface>(null);
  const [isMissingInput, setIsMissingInput] = useState<boolean>(false);

  useEffect(() => {
    if (columnState['profile_field']) {
      setSelectedDropdownOption(
        acceptedUserProfileFieldAttributes.find(
          (option) => option.value === columnState['profile_field']
        )
      );
      setShowFormatsColumn(true);
    }

    setIsMissingInput(!columnState.profile_field);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnState.profile_field]);

  return (
    <InputContainer data-testid={ATSDataFFTDataTestIds.CANDIDATE_PROFILE_SELECT}>
      <InputTitleContainer>
        {isMissingInput && <RequiredAsterix>* &nbsp;</RequiredAsterix>}
        <InputTitle>Candidate profile</InputTitle>
      </InputTitleContainer>
      <CustomSelect
        isMissingInput={isMissingInput}
        value={selectedDropdownOption}
        onChange={(option: FlatFileTransferOptionsInterface) => {
          setSelectedDropdownOption(option);
          setShowFormatsColumn(true);
          dispatch({
            type: ColumnActionType.SET_VALUE,
            value: option.value,
            index: index,
            fieldName: 'profile_field',
          });
        }}
        options={acceptedUserProfileFieldAttributes}
      />
    </InputContainer>
  );
}

export function CustomProfileFieldInput({
  index,
  columnState,
  dispatch,
  customProfileFields,
  setShowFormatsColumn,
}: FlatFileTransferInputPropsInterface): React.ReactElement {
  const [selectedDropdownOption, setSelectedDropdownOption] =
    useState<FlatFileTransferOptionsInterface>(null);
  const [isMissingInput, setIsMissingInput] = useState<boolean>(false);

  useEffect(() => {
    if (columnState.custom_profile_field_id) {
      setSelectedDropdownOption(
        customProfileFields.find((option) => option.value === columnState.custom_profile_field_id)
      );
      setShowFormatsColumn(true);
    }

    setIsMissingInput(!columnState.custom_profile_field_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnState, customProfileFields]);

  return (
    <InputContainer>
      <InputTitleContainer>
        {isMissingInput && <RequiredAsterix>* &nbsp;</RequiredAsterix>}
        <InputTitle>Custom Profile Field</InputTitle>
      </InputTitleContainer>
      <CustomSelect
        value={selectedDropdownOption}
        isMissingInput={isMissingInput}
        onChange={(option: FlatFileTransferOptionsInterface) => {
          setSelectedDropdownOption(option);
          setShowFormatsColumn(true);
          dispatch({
            type: ColumnActionType.SET_VALUE,
            value: option.value,
            index: index,
            fieldName: 'custom_profile_field_id',
          });
        }}
        options={customProfileFields}
      />
    </InputContainer>
  );
}

export function DataFormattingInput({
  index,
  columnState,
  dispatch,
}: FlatFileTransferInputPropsInterface): React.ReactElement {
  const [selectedDropdownOption, setSelectedDropdownOption] =
    useState<FlatFileTransferOptionsInterface>(null);

  useEffect(() => {
    if (columnState.formats && columnState.formats.length > 0) {
      setSelectedDropdownOption(
        dataFormatOptions.find((option) => option.value === columnState.formats[0])
      );
    }
  }, [columnState]);
  return (
    <InputContainer data-testid={ATSDataFFTDataTestIds.DATA_FORMAT_SELECT}>
      <InputTitleContainer>
        <InputTitle>DATA FORMAT (OPTIONAL)</InputTitle>
      </InputTitleContainer>
      <CustomSelect
        value={selectedDropdownOption || null}
        onChange={(option: FlatFileTransferOptionsInterface) => {
          setSelectedDropdownOption(option || null);
          dispatch({
            type: ColumnActionType.SET_VALUE,
            value: option?.value || [],
            index: index,
            fieldName: 'formats',
          });
        }}
        options={dataFormatOptions}
        isClearable
      />
    </InputContainer>
  );
}

export function HelloSignFormFieldsInput({
  index,
  columnState,
  dispatch,
  setShowFormatsColumn,
}: FlatFileTransferInputPropsInterface): React.ReactElement {
  const [selectedDropdownOption, setSelectedDropdownOption] =
    useState<FlatFileTransferOptionsInterface>(null);
  const [helloSignTemplateFieldsOptions, setHelloSignTemplateFieldsOptions] = useState<
    FlatFileTransferOptionsInterface[]
  >([]);
  const [isMissingInput, setIsMissingInput] = useState<boolean>(false);

  useEffect(() => {
    if (columnState['hellosign_field_name']) {
      setShowFormatsColumn(true);
    }
    async function getHelloSignTemplateFields(template_id) {
      if (columnState['hellosign_template_id']) {
        const id = template_id;
        const response = await HellosignTemplatesAPI.fetchHelloSignTemplateFields(id);
        const templateFields = response.documents[0].form_fields;
        setHelloSignTemplateFieldsOptions(
          templateFields.map((helloSignTemplateField) => {
            return {
              value: helloSignTemplateField.name,
              label: helloSignTemplateField.name,
            };
          })
        );
      }
    }
    getHelloSignTemplateFields(columnState.hellosign_template_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnState.hellosign_template_id]);

  useEffect(() => {
    if (helloSignTemplateFieldsOptions.length > 0 && columnState.hellosign_template_id) {
      setSelectedDropdownOption(
        helloSignTemplateFieldsOptions.find(
          (option) => option.value === columnState.hellosign_field_name
        )
      );
    }

    setIsMissingInput(!columnState.hellosign_field_name);
  }, [
    helloSignTemplateFieldsOptions,
    columnState.hellosign_template_id,
    columnState.hellosign_field_name,
  ]);

  return (
    <InputContainer>
      <InputTitleContainer>
        {isMissingInput && <RequiredAsterix>* &nbsp;</RequiredAsterix>}
        <InputTitle>Fields</InputTitle>
      </InputTitleContainer>
      <CustomSelect
        value={selectedDropdownOption}
        isMissingInput={isMissingInput}
        onChange={(option: FlatFileTransferOptionsInterface) => {
          setSelectedDropdownOption(option);
          setShowFormatsColumn(true);
          dispatch({
            type: ColumnActionType.SET_VALUE,
            value: option.value,
            index: index,
            fieldName: 'hellosign_field_name',
          });
        }}
        options={helloSignTemplateFieldsOptions}
      />
    </InputContainer>
  );
}

export function HelloSignFormsInput({
  index,
  dispatch,
  helloSignTemplateOptions,
  setShowFormatsColumn,
  columnState,
}: FlatFileTransferInputPropsInterface): React.ReactElement {
  const [selectedDropdownOption, setSelectedDropdownOption] =
    useState<FlatFileTransferOptionsInterface>(null);
  const [isMissingInput, setIsMissingInput] = useState<boolean>(false);

  const handleChange = (option: FlatFileTransferOptionsInterface) => {
    setShowFormatsColumn(false);
    setSelectedDropdownOption(option);
    setIsMissingInput(false);
    dispatch({
      type: ColumnActionType.SET_VALUE,
      value: option.value,
      index: index,
      fieldName: 'template_id',
    });
    dispatch({
      type: ColumnActionType.SET_VALUE,
      value: option.hellosign_template_id,
      index: index,
      fieldName: 'hellosign_template_id',
    });
    dispatch({
      type: ColumnActionType.SET_VALUE,
      value: null,
      index: index,
      fieldName: 'hellosign_field_name',
    });
    dispatch({
      type: ColumnActionType.SET_VALUE,
      value: [],
      index: index,
      fieldName: 'formats',
    });
  };

  useEffect(() => {
    if (columnState.template_id && helloSignTemplateOptions.length > 0) {
      setSelectedDropdownOption(
        helloSignTemplateOptions.find((option) => option.value === columnState.template_id)
      );
    }

    setIsMissingInput(!columnState.template_id);
  }, [columnState.template_id, helloSignTemplateOptions]);

  return (
    <InputContainer>
      <InputTitleContainer>
        {isMissingInput && <RequiredAsterix>* &nbsp;</RequiredAsterix>}
        <InputTitle>FORMS</InputTitle>
      </InputTitleContainer>
      <CustomSelect
        value={selectedDropdownOption}
        isMissingInput={isMissingInput}
        onChange={handleChange}
        options={helloSignTemplateOptions}
      />
    </InputContainer>
  );
}

export function ApplicationFieldInput({
  index,
  columnState,
  dispatch,
  setShowFormatsColumn,
}: FlatFileTransferInputPropsInterface): React.ReactElement {
  const [selectedDropdownOption, setSelectedDropdownOption] =
    useState<FlatFileTransferOptionsInterface>(null);
  const [isMissingInput, setIsMissingInput] = useState<boolean>(false);

  useEffect(() => {
    if (columnState.application_field) {
      setSelectedDropdownOption(
        applicationFieldOptions.find((option) => option.value === columnState.application_field)
      );
      setShowFormatsColumn(true);
    }

    setIsMissingInput(!columnState.application_field);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnState, applicationFieldOptions]);

  return (
    <InputContainer data-testid={ATSDataFFTDataTestIds.APPLICATION_FIELD_SELECT}>
      <InputTitleContainer>
        {isMissingInput && <RequiredAsterix>* &nbsp;</RequiredAsterix>}
        <InputTitle>Application Field</InputTitle>
      </InputTitleContainer>
      <CustomSelect
        value={selectedDropdownOption}
        isMissingInput={isMissingInput}
        onChange={(option: FlatFileTransferOptionsInterface) => {
          setSelectedDropdownOption(option);
          setShowFormatsColumn(true);
          dispatch({
            type: ColumnActionType.SET_VALUE,
            value: option.value,
            index: index,
            fieldName: 'application_field',
          });
        }}
        options={applicationFieldOptions}
      />
    </InputContainer>
  );
}

export function ApplicationStatusDateInput({
  index,
  columnState,
  dispatch,
  setShowFormatsColumn,
  applicationStatusOptions,
}: FlatFileTransferInputPropsInterface): React.ReactElement {
  const [selectedDropdownOption, setSelectedDropdownOption] =
    useState<FlatFileTransferOptionsInterface>(null);
  const [isMissingInput, setIsMissingInput] = useState<boolean>(false);

  useEffect(() => {
    if (applicationStatusOptions.length > 0 && columnState.application_status_id) {
      setSelectedDropdownOption(
        applicationStatusOptions.find(
          (option) => option.value === columnState.application_status_id
        )
      );
      setShowFormatsColumn(true);
    }

    setIsMissingInput(!columnState.application_status_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationStatusOptions, columnState.application_status_id]);

  return (
    <InputContainer>
      <InputTitleContainer>
        {isMissingInput && <RequiredAsterix>* &nbsp;</RequiredAsterix>}
        <InputTitle>Application Statuses</InputTitle>
      </InputTitleContainer>
      <CustomSelect
        value={selectedDropdownOption}
        isMissingInput={isMissingInput}
        onChange={(option: FlatFileTransferOptionsInterface) => {
          setSelectedDropdownOption(option);
          setShowFormatsColumn(true);
          dispatch({
            type: ColumnActionType.SET_VALUE,
            value: option.value,
            index: index,
            fieldName: 'application_status_id',
          });
        }}
        options={applicationStatusOptions}
      />
    </InputContainer>
  );
}

export function DistrictApplicationRoleInput({
  index,
  columnState,
  dispatch,
  setShowFormatsColumn,
}: FlatFileTransferInputPropsInterface): React.ReactElement {
  const [selectedDropdownOption, setSelectedDropdownOption] =
    useState<FlatFileTransferOptionsInterface>(null);
  const [isMissingInput, setIsMissingInput] = useState<boolean>(false);

  useEffect(() => {
    if (columnState.role_field) {
      setSelectedDropdownOption(
        acceptedDistrictApplicationRoleFields.find(
          (option) => option.value === columnState.role_field
        )
      );
      setShowFormatsColumn(true);
    }
    setIsMissingInput(!columnState.role_field);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnState.role_field]);

  const handleOnChange = (option) => {
    setSelectedDropdownOption(option);
    setShowFormatsColumn(true);
    dispatch({
      type: ColumnActionType.SET_VALUE,
      value: option.value,
      index: index,
      fieldName: 'role_field',
    });
  };

  return (
    <InputContainer data-testid={ATSDataFFTDataTestIds.FIELD_SELECT}>
      <InputTitleContainer>
        {isMissingInput && <RequiredAsterix>* &nbsp;</RequiredAsterix>}
        <InputTitle>Field</InputTitle>
      </InputTitleContainer>
      <CustomSelect
        value={selectedDropdownOption}
        isMissingInput={isMissingInput}
        onChange={(option: FlatFileTransferOptionsInterface) => handleOnChange(option)}
        options={acceptedDistrictApplicationRoleFields}
      />
    </InputContainer>
  );
}

export const InputContainer = styled.div`
  flex: none;
  order: 1;
  flex-grow: 0;

  @media screen and (max-width: 1535px) {
    width: 246px;
  }

  @media screen and (max-width: 1440px) {
    width: 232px;
  }

  @media screen and (min-width: 1536px) {
    width: 262px;
  }

  @media screen and (min-width: 1900px) {
    width: 333px;
  }
`;

export const InputTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const InputTitle = styled.div`
  height: 14px;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;

  display: flex;
  align-items: center;
  text-transform: uppercase;

  color: #828282;
`;
export const Input = styled.input`
  width: 100%;
  height: 48px;
  left: 0px;
  top: 0px;

  background: #ffffff;
  border: 1px solid;
  border-radius: 3px;
  margin: 8px 0px;
  padding: 12px;
  color: #4f4f4f;
  font-weight: 400;

  ::placeholder {
    color: #828282;
  }
`;

export const RequiredAsterix = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;

  display: flex;
  align-items: center;
  text-transform: uppercase;

  color: #cc0000;
`;
