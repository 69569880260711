import { useTheme } from '@mui/material';

import { ATSConnectHiringRequestCandidateFeedbackTestIds } from 'data-testids/ATS';
import { RowCTAContainer, StyledLink } from './styles';
import { RowCTAProps } from '../types';

export const RowCTA: React.FC<RowCTAProps> = ({ isComplete }) => {
  const theme = useTheme();

  return (
    <RowCTAContainer>
      <StyledLink
        sx={{ paddingRight: theme.spacing(3) }}
        data-testid={ATSConnectHiringRequestCandidateFeedbackTestIds.REMIND_OR_EDIT_BUTTON}
      >
        {isComplete ? 'Edit' : 'Finish'}
      </StyledLink>
      <StyledLink data-testid={ATSConnectHiringRequestCandidateFeedbackTestIds.DELETE_BUTTON}>
        Delete
      </StyledLink>
    </RowCTAContainer>
  );
};
