export const PROFILE_FORM_ERROR_MESSAGES = {
  firstName: 'Please enter your first name',
  lastName: 'Please enter your last name',
  phoneNumber: 'Please enter your phone number',
  statePreference: 'Please select your state preference',
};

export const QUALIFICATIONS_FORM_ERROR_MESSAGES = {
  noFileSelected: 'Please select a file to upload',
  yearsOfTeachingExperience: 'Please select an option',
  certificationState: 'Please select your state preference',
  certificationSubject: 'Please select your subject preference',
};

export const CONNECT_OPPORTUNITY_RADIO_GROUP = 'connect-opportunities-radio-group';
export const JOB_SEEKING_STATUS_RADIO_GROUP = 'job-seeking-status-radio-group';

export const DEFAULT_OPT_IN_FOR_TEXT_MESSAGES = false;
