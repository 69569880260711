import { CandidateFeedbackData } from './types';

export const mockFeedbackData: CandidateFeedbackData[] = [
  {
    id: 123,
    hiring_request_id: 58034,
    template: {
      title: 'My scorecard is a draft',
      id: 123,
      visibility: 'me-only',
    },
    submitted_by: {
      name: 'Amanda Row',
      email: 'julie+Ruby@hirenimble.com',
    },
    created: '2025-01-10T19:28:14.555695Z',
    scorecard_complete: false,
    include_cumulative_score: true,
    include_rating_questions: null,
    include_rubric_questions: null,
    show_total_on_preview: true,
    cumulative_score: 55,
    max_cumulative_score: 100,
    include_final_recommendation: false,
    include_maybe_option: null,
    show_answer_on_preview: true,
    final_recommendation: 'final_recommendation/maybe',
    answers: [],
    is_universal: false,
  },
  {
    id: 1574,
    hiring_request_id: 58034,
    template: {
      title: 'YES rec, YES total score',
      id: 123,
      visibility: 'me-only',
    },
    submitted_by: {
      name: 'Ruby Rose',
      email: 'test@hirenimble.com',
    },
    created: '2025-01-10T19:28:14.555695Z',
    scorecard_complete: true,
    include_cumulative_score: false,
    include_rating_questions: null,
    include_rubric_questions: null,
    cumulative_score: 100,
    max_cumulative_score: 100,
    include_final_recommendation: true,
    include_maybe_option: null,
    show_total_on_preview: true,
    show_answer_on_preview: true,
    final_recommendation: 'final_recommendation/yes',
    answers: [],
    is_universal: false,
  },
  {
    id: 1575,
    hiring_request_id: 58034,
    template: {
      title: 'My scorecard',
      id: 123,
      visibility: 'all',
    },
    submitted_by: {
      name: 'Ruby Rose',
      email: 'test@hirenimble.com',
    },
    created: '2025-01-10T19:28:14.555695Z',
    scorecard_complete: true,
    include_cumulative_score: false,
    include_rating_questions: null,
    include_rubric_questions: null,
    cumulative_score: 70,
    max_cumulative_score: 100,
    include_final_recommendation: true,
    include_maybe_option: null,
    show_total_on_preview: true,
    show_answer_on_preview: true,
    final_recommendation: 'final_recommendation/no',
    answers: [],
    is_universal: false,
  },
  {
    id: 1577,
    hiring_request_id: 58034,
    template: {
      title: 'My scorecard',
      id: 123,
      visibility: 'me-only',
    },
    submitted_by: {
      name: 'Amanda Row',
      email: 'julie+Ruby@hirenimble.com',
    },
    created: '2025-01-10T19:28:14.555695Z',
    scorecard_complete: true,
    include_cumulative_score: false,
    include_rating_questions: null,
    include_rubric_questions: null,
    show_total_on_preview: true,
    cumulative_score: 55,
    max_cumulative_score: 100,
    include_final_recommendation: true,
    include_maybe_option: null,
    show_answer_on_preview: true,
    final_recommendation: 'final_recommendation/maybe',
    answers: [],
    is_universal: false,
  },
  {
    id: 1234,
    hiring_request_id: 5803423,
    template: {
      title: 'NO rec, YES total score',
      id: 123,
      visibility: 'me-only',
    },
    submitted_by: {
      name: 'Amanda Row',
      email: 'julie+Ruby@hirenimble.com',
    },
    created: '2025-01-10T19:28:14.555695Z',
    scorecard_complete: true,
    include_cumulative_score: true,
    include_rating_questions: null,
    include_rubric_questions: null,
    show_total_on_preview: true,
    cumulative_score: 55,
    max_cumulative_score: 100,
    include_final_recommendation: false,
    include_maybe_option: null,
    show_answer_on_preview: true,
    final_recommendation: null,
    answers: [],
    is_universal: false,
  },
  {
    id: 12345555,
    hiring_request_id: 5803423,
    template: {
      title: 'YES rec, NO total score',
      id: 123,
      visibility: 'me-only',
    },
    submitted_by: {
      name: 'Amanda Row',
      email: 'julie+Ruby@hirenimble.com',
    },
    created: '2025-01-10T19:28:14.555695Z',
    scorecard_complete: true,
    include_cumulative_score: false,
    include_rating_questions: null,
    include_rubric_questions: null,
    show_total_on_preview: true,
    cumulative_score: null,
    max_cumulative_score: null,
    include_final_recommendation: true,
    include_maybe_option: null,
    show_answer_on_preview: true,
    final_recommendation: 'final_recommendation/yes',
    answers: [],
    is_universal: false,
  },
  {
    id: 12352,
    hiring_request_id: 5803423,
    template: {
      title: 'NO rec and NO total score',
      id: 123,
      visibility: 'me-only',
    },
    submitted_by: {
      name: 'Amanda Row',
      email: 'julie+Ruby@hirenimble.com',
    },
    created: '2025-01-10T19:28:14.555695Z',
    scorecard_complete: true,
    include_cumulative_score: false,
    include_rating_questions: null,
    include_rubric_questions: null,
    show_total_on_preview: true,
    cumulative_score: null,
    max_cumulative_score: null,
    include_final_recommendation: false,
    include_maybe_option: null,
    show_answer_on_preview: true,
    final_recommendation: null,
    answers: [],
    is_universal: false,
  },
];
