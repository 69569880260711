import { Box, styled, Theme, Typography } from '@mui/material';

export const PaginationButtonGroupContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'end',
  width: '100%',
  gap: theme.spacing(2),

  [theme.breakpoints.down('md')]: {
    justifyContent: 'space-between',
  },
}));

export const PaginationLocationTextContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '4px',
}));

export const PaginationLocationText = styled(Typography)(({ theme }) => ({
  color: theme.palette.gray.mediumDark,
  textAlign: 'right',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightMediumBold,
  lineHeight: '16.71px',
}));

export const PaginationButtonStyles = (theme: Theme, isDisabled: boolean) => ({
  display: 'flex',
  width: '42px',
  height: '42px',
  padding: '0px',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: 'auto',
  border: `1.05px solid ${theme.palette.gray.mediumLight}`,
  background: theme.palette.background.paper,
  ...(isDisabled && {
    background: theme.palette.gray.light,
    border: `1.05px solid ${theme.palette.gray.mediumLight}`,
  }),
});

export const PaginationButtonIconStyles = (theme: Theme, isDisabled: boolean) => ({
  color: isDisabled ? theme.palette.gray.mediumLight : theme.palette.black.main,
});
