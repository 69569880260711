import { CircularProgress, Divider } from '@mui/material';

import { ATSTabbedJobViewSetUpTabTestIds } from 'data-testids/ATS';
import { Alert } from 'sharedComponents/Alert';
import { ApplicationSectionsSettings } from './components/ApplicationSectionsSettings';
import { Container } from './styles';
import { SectionTitle } from '../styles';
import { SetUpTabProps } from './types';
import { StatusWorkflow } from './components/StatusWorkflow';
import { VisibilityAndRequisitionNumber } from './components/VisibilityAndRequisitionNumber';
import { getAxiosErrorMessageByStatusCode } from 'hooks/errorHandling/errorHandlingHooks';
import { useQueryGetJobSettingsByJobId } from './queries';

export const SetUpTab: React.FC<SetUpTabProps> = ({ jobId }) => {
  const { data, error, isError, isLoading } = useQueryGetJobSettingsByJobId(jobId);
  const errorMessage = getAxiosErrorMessageByStatusCode(error);
  const isOpenOnlyForInternalCandidates = data?.internal_only && data?.external_date === null;

  return (
    <Container>
      <SectionTitle data-testid={ATSTabbedJobViewSetUpTabTestIds.JOB_SETTINGS_TITLE} variant="h2">
        Job Settings
      </SectionTitle>

      {isError && (
        <Alert type="error" dataTestId={ATSTabbedJobViewSetUpTabTestIds.SETTINGS_ERROR_ALERT}>
          {errorMessage || 'Something went wrong'}
        </Alert>
      )}

      {isLoading && !data ? (
        <CircularProgress data-testid={ATSTabbedJobViewSetUpTabTestIds.LOADING_SPINNER} />
      ) : (
        <>
          {data.statuses_available.length > 0 && (
            <StatusWorkflow statuses={data.statuses_available} />
          )}
          <VisibilityAndRequisitionNumber
            externalDate={data.external_date}
            internalOnly={data.internal_only}
            password={data.district_jobboard_code}
            requisitionNumber={data.requisition_number}
          />
          <Divider />
          <ApplicationSectionsSettings
            hasInternalRequirementsSpecified={data.internal_requirements_specified}
            roleSections={data.role_sections}
            isOpenOnlyForInternalCandidates={isOpenOnlyForInternalCandidates}
          />
        </>
      )}
    </Container>
  );
};
