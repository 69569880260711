import auth from 'utils/auth';
import JobViewTabs from './Tabs';
import { Redirect, useParams } from 'react-router';
import { Box, styled } from '@mui/material';
import { TabbedJobViewHeader } from './TabbedJobViewHeader/TabbedJobViewHeader';

const TabbedJobView: React.FC = () => {
  const isDistrictUserOrHigher = auth.isDistrictUser();
  const hasTabbedJobView = auth.hasTabbedJobView();
  const { id } = useParams<{ id: string }>();
  const jobId = Number(id);

  if (!hasTabbedJobView) {
    if (isDistrictUserOrHigher) {
      return <Redirect to={`/district/jobview/${jobId}`} />;
    }

    return <Redirect to={`/school/jobslist`} />;
  }

  return (
    <Container>
      <TabbedJobViewHeader jobId={jobId} />
      <JobViewTabs jobId={jobId} />
    </Container>
  );
};

export default TabbedJobView;

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.contrastText,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  padding: theme.spacing(4),
  width: '100%',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2),
    flexDirection: 'column',
    alignItems: 'end',
    height: '100%',
  },
}));
