import { USStates } from 'utils/enums';

export const getStateIDFromStateCode = (stateCode: string): number | string => {
  const state = USStates().find((state) => state.label === stateCode);
  if (!state) {
    return '';
  } else {
    return Number(state.id);
  }
};

export const milesToMeters = (miles: number): number => {
  return miles * 1609.34; // 1 mile = 1609.34 meters
};

export const principalInitials = (principal) => {
  return [principal?.first_name[0], principal?.last_name[0]];
};

export const formatTestId = (stringToFormat: string): string => {
  const stringWithoutParentheses = stringToFormat.replace(/\([^)]*\)/g, '');

  const trimmedString = stringWithoutParentheses.trim();

  return trimmedString.toLowerCase().replace(/[\s_]+/g, '-');
};
