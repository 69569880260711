import { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import axios from 'axios';
import ReactTooltip from 'react-tooltip';

import auth from 'utils/auth';

import { AltModal, Title, Body, Actions } from 'ui-kit/AltModal';
import { Textarea, Button, theme } from 'ui-kit';

import { ReactComponent as CloseIcon } from 'assets/icon-close.svg';
import { ReactComponent as UploadIcon } from 'assets/icon-download.svg';
import facebookIcon from 'ui-kit/icons/social-media-icons/facebook.png';
import linkedinIcon from 'ui-kit/icons/social-media-icons/linkedin.png';
import twitterIcon from 'ui-kit/icons/social-media-icons/twitter.svg';
import nimbleHiring from '../../assets/nimble-hiring.png';
import { ComboBox } from 'sharedComponents/ComboBox';
import roleSearchAPI from 'api/roleSearchAPI';
import { Box } from '@mui/material';
import { UploadFileHelperMessage } from 'components/uploadFileHelperMessage';
import { ErrorSnackbar } from 'sharedComponents/Snackbar';
import _ from 'lodash';
import { ATSSocialMediaShareTestIds } from 'data-testids/ATS';

// Sharing flow:
// 1. user clicks on greyed icon, new tab/window appears with the networks login and authorization
// 2. User logins/approves, and is redirected to a nimble page which stores the ACCESS TOKEN, this page closes itself
// 3. The user submits this modal, the backend view calls the appropriate APIs to create the share
// post on each network.

const FACEBOOK_APP_ID = '547852093108751';
const POST_CHAR_MAX = 280;

export default function ShareToSocialMedia({ isOpen, onClose, startPane = 'EDIT', roleToShare }) {
  // PROMPT: Ask the user if they want to share...
  // EDIT: normal mode, shows the form and share functionality
  // PREVIEW: Confirmation step
  // DONE: Success message
  const [currentPane, setCurrentPane] = useState(startPane);
  const [error, setError] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [isAuthenticatedWithFacebook, setIsAuthenticatedWithFacebook] = useState(false);
  const [isAuthenticatedWithLinkedin, setIsAuthenticatedWithLinkedin] = useState(false);
  const [isAuthenticatedWithTwitter, setIsAuthenticatedWithTwitter] = useState(false);
  const [isLoadingPreviousData, setIsLoadingPreviousItems] = useState(false);
  const [linkedinEmail, setLinkedinEmail] = useState('');
  const [postCharCount, setPostCharCount] = useState(0);
  const [postText, setPostText] = useState('');
  const [searchResults, setsSearchResults] = useState([]);
  const [selectedRole, setSelectedRole] = useState(roleToShare);
  const [shareToFacebook, setShareToFacebook] = useState(false);
  const [shareToLinkedin, setShareToLinkedin] = useState(false);
  const [shareToTwitter, setShareToTwitter] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [twitterEmail, setTwitterEmail] = useState('');
  const [uploadedImage, setUploadedImage] = useState('nimble');
  const [uploadedImageSrc, setUploadedImageSrc] = useState(nimbleHiring);
  // This is the URL we want to share to FB only
  const [urlForFacebook, setUrlForFacebook] = useState('');
  const customImage = uploadedImage !== 'nimble';

  const user = auth.getUser();
  const districtName = user?.profile.district?.name || '';

  const [showErrorToast, setShowErrorToast] = useState(false);

  useEffect(() => {
    let isUnmounted = false;

    if (inputValue) {
      const timeoutId = setTimeout(() => {
        setsSearchResults([]);
        setIsLoadingPreviousItems(true);

        roleSearchAPI
          .searchSharableRoles(inputValue)
          .then((templates) => {
            if (isUnmounted) return;
            setsSearchResults(templates.results);
            setIsLoadingPreviousItems(false);
          })
          .catch((error) => {
            console.log(error);
            setIsLoadingPreviousItems(false);
          });
      }, 250);

      return () => clearTimeout(timeoutId);
    }

    return () => {
      isUnmounted = true;
    };
  }, [inputValue]);

  useEffect(() => {
    const roleName = selectedRole === null ? roleToShare?.title : selectedRole?.title;
    const textToReturn = `Join our team! ${districtName} is hiring a ${roleName} via Nimble`;
    setPostText(textToReturn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRole, isOpen]);

  useEffect(() => {
    setCurrentPane(startPane);
  }, [startPane]);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  useEffect(() => {
    if (selectedRole) {
      auth.checkAuthenticated().then((user) => {
        if (!user || (user && _.isEmpty(user))) {
          return;
        } else {
          axios
            .get('/api/socialmedia/share/', {
              params: {
                role_id: selectedRole.id,
                user_id: user.id,
                prefix: 'f-',
              },
            })
            .then((r) => {
              setUrlForFacebook(`https://${window.location.host}/j/${user.code}/`);
            })
            .catch((e) => {
              setShowErrorToast(true);
            });
        }
      });
    }
  }, [roleToShare, selectedRole]);

  useEffect(() => {
    const currentLength = postText.length + urlForFacebook.length + 5;
    setPostCharCount(currentLength);
  }, [postText, urlForFacebook]);

  useEffect(() => {
    setError('');
  }, [currentPane]);

  // Utility to open a browser window/tab and listen for it to close.
  // This is how we send the used to authenticate and know when he is done so that we can get
  // the information from the backend.
  // This function needs to be the onclick handler otherwise it will be considered a popup.
  const openWindowForAuthentication = (clickEvent) => {
    const network = clickEvent.target.name;

    const LINKEDIN_CLIENT_ID = '861tfm6i4xgvsx';
    const LINKEDIN_AUTH_CALLBACK =
      window.location.protocol + '//' + window.location.host + '/socialmedia/auth/linkedin';
    const TWITTER_AUTH_CALLBACK =
      window.location.protocol + '//' + window.location.host + '/socialmedia/auth/twitter';

    let authWindow = null;

    switch (network) {
      case 'linkedin':
        if (isAuthenticatedWithLinkedin) {
          setShareToLinkedin(!shareToLinkedin);
          return;
        }
        window.localStorage.removeItem('linkedin_email');

        // Per: https://docs.microsoft.com/en-us/linkedin/shared/authentication/authorization-code-flow
        const linkedinAuthUrl =
          'https://www.linkedin.com/oauth/v2/authorization' +
          '?response_type=code' +
          `&client_id=${LINKEDIN_CLIENT_ID}` +
          `&redirect_uri=${LINKEDIN_AUTH_CALLBACK}` +
          `&state=${selectedRole.id}` +
          `&scope=${encodeURIComponent('r_liteprofile r_emailaddress w_member_social')}`;

        authWindow = window.open(linkedinAuthUrl);
        authWindow.addEventListener('unload', (closeEvent) => {
          const checkForLnAuth = () => {
            // if the backend was able to get the token, we will have the email in localStorage:
            const localLinkedinEmail = window.localStorage.getItem('linkedin_email');
            const maxTries = 3;
            let tries = 0;
            if (localLinkedinEmail) {
              setLinkedinEmail(localLinkedinEmail);
              setShareToLinkedin(true);
              setIsAuthenticatedWithLinkedin(true);
            } else {
              tries += 1;
              if (tries <= maxTries) {
                setTimeout(checkForLnAuth, 1000);
              } else {
                setShareToLinkedin(false);
              }
            }
          };
          setTimeout(checkForLnAuth, 1000);
        });
        break;

      case 'twitter':
        if (isAuthenticatedWithTwitter) {
          setShareToTwitter(!shareToTwitter);
          return;
        }
        window.localStorage.removeItem('twitter_email');

        // Ask the backend to generate the request token/secret
        // send the user to oauth/authorize with the token info
        axios
          .get('/api/socialmedia/twitter/', {
            params: { callback_url: encodeURIComponent(TWITTER_AUTH_CALLBACK) },
          })
          .then((r) => {
            authWindow = window.open(r.data);
            authWindow.addEventListener('unload', (closeEvent) => {
              const checkForTwAuth = () => {
                const maxTries = 3;
                let tries = 0;
                const localTwitterEmail = window.localStorage.getItem('twitter_email');
                if (localTwitterEmail) {
                  setTwitterEmail(localTwitterEmail);
                  setIsAuthenticatedWithTwitter(true);
                  setShareToTwitter(true);
                } else {
                  tries += 1;
                  if (tries <= maxTries) {
                    setTimeout(checkForTwAuth, 1000);
                  } else {
                    setShareToTwitter(false);
                  }
                }
              };
              setTimeout(checkForTwAuth, 1000);
            });
          });
        break;

      case 'facebook':
        if (!isAuthenticatedWithFacebook) {
          setIsAuthenticatedWithFacebook(true);
          setShareToFacebook(true);
        } else {
          setShareToFacebook(!shareToFacebook);
        }
        break;

      default:
        break;
    }
  };

  const networksToShare = () => {
    const networks = [];
    if (shareToLinkedin) networks.push('LinkedIn');
    if (shareToTwitter) networks.push('Twitter');
    return networks.join(', ');
  };

  const submit = async () => {
    setSubmitting(true);
    setError('');
    const data = new FormData();

    data.append('shareToLinkedin', shareToLinkedin);
    data.append('shareToTwitter', shareToTwitter);
    data.append('shareToFacebook', shareToFacebook);

    data.append('role_id', selectedRole.id);
    data.append('user_id', user.id);

    data.append('text', postText);

    if (uploadedImage) {
      if (uploadedImage !== 'nimble') {
        data.append('img_name', uploadedImage.name);
      }
      data.append('img', uploadedImage);
    }

    const post = () => {
      return axios
        .post('/api/socialmedia/share/', data)
        .then((r) => {
          setSubmitting(false);
          setCurrentPane('DONE');
          return r;
        })
        .catch((e) => {
          setCurrentPane('EDIT');
          setSubmitting(false);
          setError('Something went wrong.');
        });
    };

    // We can't put this in the promise's then because it would be a popup.
    // But if the user is very fast doing the facebook flow, the initial facebook
    // scrape will be wrong.
    if (shareToFacebook) {
      const response = await post();
      const fbText = postText;
      const fbShareUrl = `https://${window.location.host}/j/${response.data.fb_code}/`;
      const fbUrl =
        `https://www.facebook.com/dialog/share?app_id=${FACEBOOK_APP_ID}` +
        `&href=${encodeURIComponent(fbShareUrl)}&quote=${fbText}`;
      window.open(fbUrl);
    } else {
      post();
    }
  };

  const closeAction = () => {
    window.localStorage.removeItem('linkedin_email');
    window.localStorage.removeItem('twitter_email');

    setCurrentPane('EDIT');
    onClose();
  };

  const handleSelectChange = (option) => {
    setSelectedRole(option);
  };

  const handleSetSearchInputValue = (text) => {
    setInputValue(text);
  };

  return (
    <>
      <AltModal isOpen={isOpen} onClose={closeAction} data-testid="share-modal">
        <Title>Share Job</Title>
        <StyledBody>
          {currentPane === 'PROMPT' && (
            <CenteredParagraph>
              Your job has been posted! Help find the right candidates by sharing it on social
              media!
            </CenteredParagraph>
          )}
          {currentPane === 'PREVIEW' && (
            <>
              <CenteredParagraph>Are you sure?</CenteredParagraph>
              <CenteredParagraph>
                {(shareToTwitter || shareToLinkedin) &&
                  `By clicking share job, you will immediately share this post to ${networksToShare()}.`}
                {shareToFacebook &&
                  ' For Facebook, you will be prompted to post directly through the app.'}
              </CenteredParagraph>
            </>
          )}
          {currentPane === 'DONE' && (
            <>
              <CenteredParagraph>Success!</CenteredParagraph>
              <CenteredParagraph>Your job has been shared.</CenteredParagraph>
            </>
          )}
          {currentPane === 'EDIT' && (
            <>
              <ShareFormField>
                <StyledLabel>Job to share:</StyledLabel>

                <ComboBox
                  defaultValue={roleToShare ? roleToShare.title : selectedRole?.title}
                  getOptionLabel={(option) => option.title || ''}
                  handleSetSearchInputValue={handleSetSearchInputValue}
                  inputValue={inputValue}
                  loading={isLoadingPreviousData}
                  onChange={handleSelectChange}
                  options={searchResults}
                  placeholder="Start typing to search"
                  renderOption={(props, option) => (
                    <Box {...props} component="li" key={option.id}>
                      {option.title}
                    </Box>
                  )}
                  sx={{ backgroundColor: 'white' }}
                />
              </ShareFormField>

              <ShareFormField>
                <StyledLabel>Share to:</StyledLabel>
                <ShareIcons>
                  <ShareIconContainer disabled={!isAuthenticatedWithLinkedin}>
                    <input
                      name="linkedin"
                      type="checkbox"
                      checked={shareToLinkedin}
                      onChange={openWindowForAuthentication}
                    />
                    <img
                      src={linkedinIcon}
                      alt="linkedin-brand"
                      data-tip={
                        linkedinEmail ? `Authenticated as ${linkedinEmail}` : 'Login with Linkedin'
                      }
                      data-for="social-modal-tip"
                    />
                  </ShareIconContainer>

                  <ShareIconContainer disabled={!isAuthenticatedWithTwitter}>
                    <input
                      name="twitter"
                      type="checkbox"
                      checked={shareToTwitter}
                      onChange={openWindowForAuthentication}
                    />
                    <img
                      src={twitterIcon}
                      width="50%"
                      alt="twitter-brand"
                      data-tip={
                        twitterEmail ? `Authenticated as ${twitterEmail}` : 'Login with X / Twitter'
                      }
                      data-for="social-modal-tip"
                    />
                  </ShareIconContainer>

                  <ShareIconContainer disabled={!isAuthenticatedWithFacebook}>
                    <input
                      name="facebook"
                      type="checkbox"
                      checked={shareToFacebook}
                      onChange={openWindowForAuthentication}
                    />
                    <img src={facebookIcon} alt="facebook-brand" />
                  </ShareIconContainer>
                </ShareIcons>
              </ShareFormField>

              <ShareFormField>
                <StyledLabel>Post text:</StyledLabel>
                <div>
                  <StyledTextarea
                    value={postText}
                    onChange={(e) => {
                      setPostText(e.target.value);
                    }}
                  />
                  <p>
                    {postCharCount} of {POST_CHAR_MAX} (including job URL)
                  </p>
                </div>
              </ShareFormField>

              <ShareFormField>
                <StyledLabel>Image:</StyledLabel>
                <ImageUploadContainer hasImage={!!uploadedImageSrc}>
                  {uploadedImageSrc && (
                    <RemoveUploadButton
                      onClick={() => {
                        setUploadedImage(false);
                        setUploadedImageSrc('');
                      }}
                    >
                      Remove <StyledCloseIcon />
                    </RemoveUploadButton>
                  )}

                  <UploadButton hasImage={!!uploadedImageSrc}>
                    {uploadedImageSrc ? 'Replace' : 'Upload'} image <UploadIcon />
                    <input
                      type="file"
                      name="uploadSocialImage"
                      onChange={(e) => {
                        const uploadedFile = e.target.files[0];
                        if (uploadedFile) {
                          setUploadedImage(uploadedFile);
                          setUploadedImageSrc(URL.createObjectURL(uploadedFile));
                        }
                      }}
                    />
                  </UploadButton>

                  {uploadedImageSrc && <UploadedImage src={uploadedImageSrc} alt="" />}
                </ImageUploadContainer>
              </ShareFormField>
              {customImage && shareToTwitter && (
                <TwitterCustomImageMessage>
                  X / Twitter doesn't allow custom image sharing. Your post will be text-only.
                </TwitterCustomImageMessage>
              )}
            </>
          )}
          {error !== '' && <ErrorMessage>{error}</ErrorMessage>}
          <UploadFileHelperMessage />
        </StyledBody>
        <Actions padding="2.353em">
          <Buttons>
            {currentPane !== 'DONE' && (
              <Button
                variant="secondary"
                onClick={() => {
                  if (currentPane === 'PROMPT') closeAction();
                  if (currentPane === 'EDIT') closeAction();
                  if (currentPane === 'PREVIEW') setCurrentPane('EDIT');
                }}
              >
                {currentPane === 'PROMPT' && 'No, thanks'}
                {currentPane === 'EDIT' && 'Cancel'}
                {currentPane === 'PREVIEW' && 'Back to edit'}
              </Button>
            )}
            {currentPane === 'DONE' && <div />}

            <div />

            <Button
              variant="primary"
              onClick={() => {
                if (currentPane === 'PROMPT') setCurrentPane('EDIT');
                if (currentPane === 'EDIT') {
                  if (shareToFacebook && !shareToLinkedin && !shareToTwitter) {
                    setCurrentPane('PREVIEW');
                    !submitting && submit();
                  }

                  if (shareToFacebook || shareToLinkedin || shareToTwitter) {
                    setCurrentPane('PREVIEW');
                  } else {
                    setError('Please select at least one platform');
                  }
                }
                if (currentPane === 'PREVIEW') !submitting && submit();
                if (currentPane === 'DONE') closeAction();
              }}
            >
              {currentPane === 'PROMPT' && 'Continue'}
              {currentPane === 'EDIT' && 'Continue'}
              {currentPane === 'PREVIEW' && !submitting && 'Share job'}
              {currentPane === 'PREVIEW' && submitting && 'Sharing...'}
              {currentPane === 'DONE' && 'Got it!'}
            </Button>
          </Buttons>
        </Actions>
        <ReactTooltip id="social-modal-tip" />
      </AltModal>
      <ErrorSnackbar
        message="Something went wrong"
        onClose={() => setShowErrorToast(false)}
        open={showErrorToast}
        dataTestId={ATSSocialMediaShareTestIds.ERROR_SNACKBAR}
      />
    </>
  );
}

const StyledBody = styled(Body)`
  position: relative;
`;

const ShareFormField = styled.div`
  display: grid;
  grid-template-columns: 25% 75%;
  margin-bottom: 25px;
`;

const StyledTextarea = styled(Textarea)`
  resize: vertical;
  width: 100%;
  max-height: 300px;
`;

const StyledLabel = styled.label`
  font-size: 17px;
  line-height: 26px;
  font-weight: 400;
  padding: 12px;
`;

const Buttons = styled.div`
  height: 50px;
  display: grid;
  grid-template-columns: auto 1fr auto;
`;

const ShareIcons = styled.div`
  display: grid;
  grid-template-columns: repeat(3, max-content);
  grid-gap: 25px;

  img {
    width: 50px;
    margin-left: 5px;
    margin-bottom: 10px;
  }
`;

const ShareIconContainer = styled.label`
  display: block;
  cursor: pointer;

  ${(props) =>
    props.disabled &&
    css`
      * {
        opacity: 0.2;
      }
    `}
`;

const RemoveUploadButton = styled.p`
  cursor: pointer;
`;

const StyledCloseIcon = styled(CloseIcon)`
  vertical-align: middle;

  path,
  circle {
    stroke: ${theme.uiColors.greens.full};
  }
`;

const UploadButton = styled.label`
  display: block;
  position: absolute;
  width: 200px;
  height: 50px;
  line-height: 50px;
  top: 50%;
  left: 50%;
  margin-top: -25px;
  margin-left: -100px;
  text-align: center;
  cursor: pointer;

  color: ${(props) => (props.hasImage ? theme.uiColors.greens.full : theme.uiColors.gray)};
  border: 1px solid
    ${(props) => (props.hasImage ? theme.uiColors.greens.full : theme.uiColors.gray)};
  border-radius: 25px;
  background: ${theme.uiColors.white};

  > input {
    opacity: 0;
    position: absolute;
  }

  svg {
    vertical-align: middle;
    padding-left: 4px;
  }

  path,
  polyline {
    stroke: ${(props) => (props.hasImage ? theme.uiColors.greens.full : theme.uiColors.gray)};
  }
`;

const UploadedImage = styled.img`
  width: 100%;
`;

const ImageUploadContainer = styled.div`
  position: relative;
  width: 66%;

  > p {
    opacity: 0;
    pointer-events: none;

    color: ${theme.uiColors.greens.full};
    text-align: right;
  }

  ${(props) =>
    props.hasImage &&
    css`
      > ${UploadButton} {
        opacity: 0;
        pointer-events: none;
      }
    `}

  &:hover > p,
  &:hover > ${UploadButton} {
    opacity: 1;
    pointer-events: auto;
  }
`;

const CenteredParagraph = styled.p`
  text-align: center;
`;

const ErrorMessage = styled.div`
  font-size: 15px;
  position: absolute;
  bottom: -20px;
  right: 40px;
  color: ${theme.uiColors.greens.full};
`;

const TwitterCustomImageMessage = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  font-size: 15px;
  text-indent: 100px;
  text-align: start;
  color: #1f3d99;
`;
