export * from './utils';

import { nimbleTheme } from 'theme';
import {
  JobDiscoveryAction,
  JobDiscoveryInitialStateInterface,
  School,
  UrlSearchParamsFromState,
  Vacancy,
} from 'types/connectTypes';
import {
  JobDiscoveryActionTypes,
  ConnectBadgeLabels,
  ConnectFilterName,
  ConnectURLActions,
} from 'features/Connect/utils/connectEnums';
import { SecondarySchoolTagsBadge } from 'features/Connect/components/InfoBadges/SecondarySchoolTagsBadge';
import { CONNECT_JOBBOARD_STATES } from 'utils/constants';

export const Inter = nimbleTheme.typography.fontFamily['Inter'];
export const Aeonik = nimbleTheme.typography.fontFamily['Aeonik TRIAL'];
import queryString from 'query-string';

const SCHOOL_TAG_LIMIT = 2;

export const initialState: JobDiscoveryInitialStateInterface = {
  schools: [],
  state_abbreviation: 'MO',
  page: 1,
  query: '',
  subcategories: [],
  district_nces_ids: [],
  school_nces_ids: [],
  grades: [],
  location: {
    distance_radius_miles: '',
    location_latitude: '',
    location_longitude: '',
  },
  activeFilters: {
    location: false,
    distance_radius_miles: false,
    grades: false,
    subcategories: false,
    school_nces_ids: false,
    district_nces_ids: false,
  },
  isLoadingInitialFilterResults: false,
  useDebounce: false,
};

const castToArray = (value: string | string[] | undefined | null): string[] => {
  if (value === undefined || value === null) {
    return [];
  }

  return Array.isArray(value) ? value : [value];
};

export const buildInitialState = (locationSearch: string): JobDiscoveryInitialStateInterface => {
  const initialQuery = queryString.parse(locationSearch);

  const schoolNcesIds = castToArray(initialQuery.school_nces_ids);
  const grades = castToArray(initialQuery.grades);
  const subcategories = castToArray(initialQuery.subcategories);
  const districtNcesIds = castToArray(initialQuery.district_nces_ids);

  return {
    ...initialState,
    school_nces_ids: schoolNcesIds,
    grades,
    subcategories,
    district_nces_ids: districtNcesIds,
    activeFilters: {
      ...initialState.activeFilters,
      school_nces_ids: schoolNcesIds.length > 0,
      grades: grades.length > 0,
      subcategories: subcategories.length > 0,
      district_nces_ids: districtNcesIds.length > 0,
    },
  };
};

export const reducer = (
  state: JobDiscoveryInitialStateInterface,
  action: JobDiscoveryAction
): JobDiscoveryInitialStateInterface => {
  switch (action.type) {
    case JobDiscoveryActionTypes.SET_PAGE:
      return { ...state, useDebounce: false, page: action.payload };
    case JobDiscoveryActionTypes.SET_QUERY:
      return { ...state, query: action.payload, useDebounce: false, page: 1 };
    case JobDiscoveryActionTypes.SET_FILTER:
      return {
        ...state,
        [action.filterName]: action.payload,
        useDebounce: action.filterName === ConnectFilterName.LOCATION ? false : true,
        page: 1,
      };
    case JobDiscoveryActionTypes.SET_FILTER_IS_ACTIVE:
      return {
        ...state,
        activeFilters: { ...state.activeFilters, [action.filterName]: action.payload },
        page: 1,
      };
    case JobDiscoveryActionTypes.SET_USE_DEBOUNCE:
      return { ...state, useDebounce: action.payload };
    case JobDiscoveryActionTypes.SET_SCHOOLS:
      return { ...state, schools: action.payload };
    case JobDiscoveryActionTypes.SET_IS_LOADING_INITIAL_FILTER_RESULTS:
      return { ...state, isLoadingInitialFilterResults: action.payload };
    default:
      return state;
  }
};

export const formatDistance = (distance: string | number): string => {
  distance = Number(distance);

  if (distance < 1) {
    return distance.toFixed(2);
  } else {
    return distance.toFixed(1);
  }
};

export const getConnectUrlPath = (jobboardState) => {
  if (jobboardState && CONNECT_JOBBOARD_STATES[jobboardState]?.urlParam) {
    return `/connect/${CONNECT_JOBBOARD_STATES[jobboardState].urlParam}`;
  }
  return '/connect';
};

export function getGradeRange(gradeLevels: string[]): string {
  const numericGrades: number[] = gradeLevels.map((grade) => {
    if (grade === 'PK') {
      return 0;
    } else if (grade === 'KG') {
      return 13;
    } else {
      return Number(grade);
    }
  });

  // return string if school only has single grade
  if (numericGrades.length === 1) {
    if (numericGrades[0] === 0) {
      return 'Pre-K';
    } else if (numericGrades[0] === 13) {
      return 'Kindergarten';
    } else return `${numericGrades[0]}`;
  }

  let minGrade: string;

  if (numericGrades.includes(0)) {
    minGrade = 'Pre-K';
  } else if (numericGrades.includes(13)) {
    minGrade = 'Kindergarten';
  } else {
    minGrade = Math.min(...numericGrades).toString();
  }

  const nonPreKGrades = numericGrades.filter((grade) => grade !== 0);

  let maxGrade: string = Math.max(
    ...numericGrades.filter((grade) => grade !== 0 && grade !== 13)
  ).toString();

  if (nonPreKGrades.length === 1 && nonPreKGrades[0] === 13) {
    maxGrade = 'Kindergarten';
  }

  return `${minGrade} - ${maxGrade}`;
}

export const getSecondarySchoolTags = (school: School) => {
  const allTags = Object.keys(ConnectBadgeLabels).reduce((validTags, tag) => {
    const lowerCaseTag = tag.toLowerCase();
    if (school[`has_${lowerCaseTag}`]) {
      validTags.push(tag);
    }
    return validTags;
  }, []);
  return allTags;
};

export const getStateLabel = (jobboardState: string) => {
  return jobboardState
    ? CONNECT_JOBBOARD_STATES[jobboardState]?.label
    : CONNECT_JOBBOARD_STATES.MISSOURI.label;
};

export const getStateAbbreviation = (jobboardState: string) => {
  return jobboardState
    ? CONNECT_JOBBOARD_STATES[jobboardState]?.stateCode
    : CONNECT_JOBBOARD_STATES.MISSOURI.stateCode;
};

export const getStateOrganizationName = (jobboardState: string) => {
  return jobboardState ? CONNECT_JOBBOARD_STATES[jobboardState]?.organizationName : '';
};

const shouldRenderSecondaryTags = (school: School) => {
  return !school?.principal?.on_connect || !school?.is_recommended;
};

export const renderSecondarySchoolTags = (school: School) => {
  if (!shouldRenderSecondaryTags(school)) {
    return null;
  }
  const tags = getSecondarySchoolTags(school).slice(0, SCHOOL_TAG_LIMIT);
  return tags.map((tag) => (
    <SecondarySchoolTagsBadge key={tag} tagLabel={tag as keyof typeof ConnectBadgeLabels} />
  ));
};

export function sortVacancies(vacancyArray: Vacancy[], vacancyId: string): Vacancy[] {
  const index = vacancyArray.findIndex((obj) => obj.uuid === vacancyId);
  if (index !== -1) {
    const objToMove = vacancyArray[index];
    return [objToMove, ...vacancyArray.slice(0, index), ...vacancyArray.slice(index + 1)];
  }
  return vacancyArray;
}

export function buildUrlSearchParamsFromState({
  searchParams,
  isMoreInfoSliderOpen,
  selectedVacancyId,
  selectedSchoolId,
  schoolNcesIds,
  grades,
  subcategories,
  districtNcesIds,
}: UrlSearchParamsFromState): URLSearchParams {
  const newQuery = queryString.stringify({
    ...queryString.parse(searchParams.toString()),
    action: isMoreInfoSliderOpen ? ConnectURLActions.INFO : undefined,
    school: isMoreInfoSliderOpen ? selectedSchoolId : undefined,
    vacancy: isMoreInfoSliderOpen ? selectedVacancyId : undefined,
    school_nces_ids: schoolNcesIds,
    grades: grades,
    subcategories: subcategories,
    district_nces_ids: districtNcesIds,
  });

  return new URLSearchParams(newQuery);
}
