import { CircularProgress } from '@mui/material';

import { ATSTabbedJobViewVacanciesTabTestIds } from 'data-testids/ATS';
import { Container } from './styles';
import { DistrictVacancies } from './components/DistrictVacancies';
import { HRBPSection } from './components/HRBPSection';
import { VacanciesTabHeader } from './components/VacanciesTabHeader';
import { useQueryGetHRBPByJobId, useQueryGetVacanciesByJobId } from './queries';
import { SchoolVacancies } from './components/SchoolVacancies';

export interface VacanciesTabProps {
  jobId: number;
}

export const VacanciesTab: React.FC<VacanciesTabProps> = ({ jobId }) => {
  const { data: vacanciesData, isLoading: isVacanciesDataLoading } =
    useQueryGetVacanciesByJobId(jobId);
  const { data: hrbpData, isLoading: isHRBPDataLoading } = useQueryGetHRBPByJobId(jobId);

  const schoolRoles = vacanciesData?.schoolroles;
  const hasSchoolVacancies = schoolRoles?.length > 0;
  const numberOfSchoolVacancies = schoolRoles?.reduce((sum, item) => sum + item.ftes_total, 0);

  const totalNumberOfVacancies = !hasSchoolVacancies
    ? vacanciesData?.district_ftes_total
    : numberOfSchoolVacancies;

  const isLoadingTabData = isVacanciesDataLoading || isHRBPDataLoading;

  return (
    <Container>
      <VacanciesTabHeader
        totalNumberOfVacancies={totalNumberOfVacancies}
        isLoading={isVacanciesDataLoading}
      />

      {isLoadingTabData ? (
        <CircularProgress
          data-testid={ATSTabbedJobViewVacanciesTabTestIds.VACANCIES_LOADING_SPINNER}
        />
      ) : (
        <>
          {!hasSchoolVacancies && (
            <DistrictVacancies numberOfVacancies={vacanciesData?.district_ftes_total} />
          )}

          {hasSchoolVacancies && <SchoolVacancies schoolRoles={schoolRoles} />}

          {hrbpData?.length > 0 && <HRBPSection hrbpData={hrbpData} />}
        </>
      )}
    </Container>
  );
};

export default VacanciesTab;
