import { Box, styled, Typography } from '@mui/material';
import { nimbleTheme } from 'theme';

export const AccordionTitleContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

export const ActionsTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: theme.typography.fontWeightMedium,
}));

export const ConnectActionsContainer = styled(Box)(({ theme }) => ({
  borderBottomRightRadius: theme.spacing(1),
  borderLeft: `1px solid ${theme.palette.gray.darker}`,
  borderTopRightRadius: theme.spacing(1),
  padding: `${theme.spacing(3.5)} ${theme.spacing(3)}`,
  width: '30%',
  [theme.breakpoints.down('md')]: {
    borderBottomLeftRadius: theme.spacing(1),
    borderLeft: 'none',
    borderTop: `1px solid ${theme.palette.gray.darker}`,
    borderTopRightRadius: 0,
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    width: '100%',
  },
}));

export const CTAIconBox = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  borderRadius: theme.shape.borderRadius * 1,
  display: 'flex',
  height: theme.spacing(3),
  justifyContent: 'center',
  width: theme.spacing(3),
}));

export const detailsSx = {
  padding: 0,
  [nimbleTheme.breakpoints.down('md')]: {
    padding: '0 !important',
  },
};

export const ExpandedActionRow = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  paddingBottom: theme.spacing(1.25),
  '&:last-child': {
    paddingBottom: 0,
  },
}));

export const ExpandedCTAContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isexpanded',
})<{ isexpanded?: boolean }>(({ isexpanded }) => ({
  alignItems: 'start',
  display: 'flex',
  flexDirection: 'column',
  opacity: isexpanded ? 1 : 0,
  transition: 'opacity 0.5s ease-in-out',
  visibility: isexpanded ? 'visible' : 'hidden',
  width: '100%',
  pointerEvents: isexpanded ? 'auto' : 'none',
}));

export const ExpandIconContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.gray.light,
  borderRadius: '50%',
  padding: theme.spacing(1),
}));

export const IconWrapper = styled(Box)(() => ({
  borderRadius: '50%',
  height: '21px',
}));

export const summarySx = {
  alignItems: 'flex-start',
  display: 'flex',
  minHeight: 0,
  padding: 0,
  width: '100%',
  [nimbleTheme.breakpoints.down('md')]: {
    padding: 0,
    width: '100%',
  },

  '.MuButtonBase-root': {
    minHeight: 0,
  },

  '.MuiAccordionSummary-content': {
    margin: 0,
    minHeight: 0,
  },

  '.MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded': {
    margin: 0,
    minHeight: 0,
  },
  '& > .Mui-expanded': { minHeight: 0 },
};

export const TitleCTAContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isexpanded',
})<{ isexpanded?: boolean }>(({ isexpanded }) => ({
  height: isexpanded ? 0 : 'inherit',
  opacity: isexpanded ? 0 : 1,
  transition: 'opacity 0.5s ease-in-out',
  visibility: isexpanded ? 'hidden' : 'visible',
  pointerEvents: isexpanded ? 'none' : 'auto',
}));
