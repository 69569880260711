import { BaseNimbleAPI } from './baseAPI';

import { nimbleProduct } from 'types/types';

interface LazyRegisterResponse {
  id: number;
  profile: {
    user_type: number;
  };
  preference: {
    created: string;
    modified: string;
    job_seeking_status: string | null;
  };
  email: string;
  login_required: boolean;
}

class LazyRegistrationAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/api/auth/lazy_register/' };
  }

  async registerUser(
    email: string,
    agreeTos: boolean,
    nimbleProduct: nimbleProduct,
    redirectTo: string,
    state: string,
    signupAction?: string
  ) {
    const url = '/';
    const response = await this.post(url, {
      email: email,
      agree_tos: agreeTos,
      nimble_product: nimbleProduct,
      redirect_to: redirectTo,
      signup_action: signupAction,
      state: state,
    });

    const responseData: LazyRegisterResponse = {
      id: response.data.id,
      profile: response.data.profile,
      preference: response.data.preference,
      email: response.data.email,
      login_required: response.data.login_required,
    };

    return responseData;
  }
}

export default new LazyRegistrationAPI();
