import { useState } from 'react';

import { JobViewHeader } from 'features/JobView/components/JobViewHeader';
import { TabbedJobViewHeaderProps } from './types';
import { useQueryGetHeaderDateByJobId, useToggleJobStatusMutation } from './queries';
import auth from 'utils/auth';
import ShareToSocialMedia from 'components/ShareToSocialMedia';
import { Alert } from 'sharedComponents/Alert';
import { getAxiosErrorMessageByStatusCode } from 'hooks/errorHandling/errorHandlingHooks';
import { HeaderContainer } from './styles';
import { TabbedJobViewHeaderSkeleton } from './TabbedJobViewHeaderSkeleton';

export const TabbedJobViewHeader: React.FC<TabbedJobViewHeaderProps> = ({ jobId }) => {
  const [shouldShowShareModal, setShouldShowShareModal] = useState(false);
  const { data, error, isError, isLoading } = useQueryGetHeaderDateByJobId(jobId);
  const currentUser = auth.getUser();
  const errorMessage = getAxiosErrorMessageByStatusCode(error);
  const handleOpenShareModal = () => setShouldShowShareModal(true);
  const jobToShare = { ...data, id: jobId };
  const toggleStatusMutation = useToggleJobStatusMutation({});
  const userDistrict = currentUser?.profile.district?.id;

  const handleToggle = () => {
    return toggleStatusMutation.mutate({ jobId, activeStatus: data?.active_status });
  };

  if (isError) {
    return <Alert type="error">{errorMessage}</Alert>;
  }

  if (isLoading) {
    return <TabbedJobViewHeaderSkeleton />;
  }

  return (
    <HeaderContainer>
      {data && (
        <>
          <JobViewHeader
            userDistrict={userDistrict}
            isPreview={false}
            isInternalCandidate={false}
            job={jobToShare}
            handleOpenShareModal={handleOpenShareModal}
            toggleJobStatus={handleToggle}
            isPublicJobView={false}
          />
          {shouldShowShareModal && (
            <ShareToSocialMedia
              isOpen={shouldShowShareModal}
              onClose={() => {
                setShouldShowShareModal(false);
              }}
              roleToShare={jobToShare}
            />
          )}
        </>
      )}
    </HeaderContainer>
  );
};
