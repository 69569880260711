import { useTheme } from '@mui/material';

import { ActiveStatus, JobStatusByNumber, roleStatusByValue } from 'utils/typedEnums';
import { activeStatusByValue } from 'utils/enums';
import { ConnectHiringRequestAction } from './enum';
import { IndividualHiringRequestAction } from './types';

export const getConnectCandidateRowActionText = (
  actionKey: IndividualHiringRequestAction,
  actionIsMissing: boolean
) => {
  if (actionIsMissing) {
    return `Awaiting ${actionKey}`;
  } else {
    if (actionKey === ConnectHiringRequestAction.scorecard) {
      return 'Scorecard completed';
    } else if (actionKey === ConnectHiringRequestAction.reference) {
      return 'Completed reference check';
    } else if (actionKey === ConnectHiringRequestAction.application) {
      return 'Application submitted';
    } else {
      return 'Something went wrong';
    }
  }
};

export function useJobStatusToGetStylingForHiringRequests(
  activeStatus: ActiveStatus,
  status: JobStatusByNumber
) {
  const theme = useTheme();

  switch (status) {
    case roleStatusByValue.archived:
      return {
        textColor: theme.palette.warning.main,
      };
    case roleStatusByValue.active:
      return activeStatus === activeStatusByValue.activeOpen
        ? {
            textColor: theme.palette.primary.main,
          }
        : {
            textColor: theme.palette.error.main,
          };
    default:
      return {
        textColor: theme.palette.text.tertiary,
      };
  }
}
