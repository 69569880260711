import { BaseNimbleAPI } from '../../../api/baseAPI';
import { MatchActionsResponse } from 'types/connectTypes';
import { CandidateMatchAPIViewType } from './types';
class CandidateMatchingAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/api/connect-interested-schools' };
  }

  async getMatches(viewType: CandidateMatchAPIViewType): Promise<MatchActionsResponse> {
    const response = await this.get(`/?view_type=${viewType}`);
    return response.data;
  }
}

export default new CandidateMatchingAPI();
