import { Component } from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';

import { Box } from '@mui/material';
import axios from 'axios';
import FeatureContext from 'context/featureContext';
import { JobView } from 'features/JobView/JobView';
import styled from 'styled-components';
import { District } from 'types/types';
import customScorecardListAPI from '../api/customScorecardListAPI';
import auth from '../utils/auth';
import { checkInternal } from '../utils/util';
import { JobViewContainerState, RouteParams } from './types';
import { activeStatusByValue } from 'utils/enums';
import { JobNotFound } from 'features/JobView/components/JobNotFound';
import { FetchedAuthUser } from 'types';

class JobViewContainer extends Component<RouteComponentProps<RouteParams>, JobViewContainerState> {
  static contextType = FeatureContext;
  getUser: FetchedAuthUser;
  checkInternal: (user: FetchedAuthUser, districtId: number) => void;
  ignoreLastFetch: boolean;

  constructor(props) {
    super(props);

    this.state = {
      job: null,
      isInternalCandidate: false,
      jobNotFound: false,
      district: {} as District,
      isLoading: true,
    };
    this.getUser = auth.getUser();
    this.checkInternal = checkInternal.bind(this);
  }

  componentDidMount() {
    document.body.classList.add('job-view-page');
    this.fetchDistrictInfo();
    this.fetchJob();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.fetchDistrictInfo();
      this.fetchJob();
    }
  }

  componentWillUnmount() {
    this.ignoreLastFetch = true;
    document.body.classList.remove('job-view-page');
  }

  // fetch district information for cases where the job is not active and
  // returns a 404
  fetchDistrictInfo = () => {
    axios.get(`/api/role/${this.props.match.params.id}/district_info/`).then(({ data }) => {
      this.setState({ district: data });
    });
  };

  fetchJob() {
    let url = '/api/role/';
    // Add a query string for anonymous jobview viewers
    let queryString = '';
    if (
      window.location.href.indexOf('district') === -1 &&
      window.location.href.indexOf('school') === -1
    ) {
      queryString = '?jobview=true';
    }

    const isSchoolRoleFromShare = window.location.search.indexOf('is_school_role=1') > -1;
    const isAtSchoolRoute = location.pathname.includes('/school/');

    if ((isAtSchoolRoute && auth.isSchoolUser()) || isSchoolRoleFromShare) {
      url = '/api/schoolrole/';
    }

    axios
      .get(`${url}${this.props.match.params.id}/${queryString}`)
      .then(async (r) => {
        const district_role =
          auth.isSchoolUser() || isSchoolRoleFromShare ? r.data.district_role : r.data.id;

        if (!this.ignoreLastFetch) {
          const job = r.data;
          // eslint-disable-next-line no-prototype-builtins
          if (job.hasOwnProperty('schoolroles')) {
            // Only show schoolroles that are explicitly tagged on the role
            // (automatic_routing=True). Schoolroles with automatic_routing=False
            // get created when users create one-off schoolapplications for certain
            // candidates using the schoolapplications modal on QuickView/FullProfile.
            job.schoolroles = job.schoolroles.filter((sr) => sr.automatic_routing === true);
          }

          // Only district and super admins can see scorecards on the job preview
          // page so no need to fetch them for other user types
          if (auth.isDistrictAdmin() || auth.isSuperAdminUser()) {
            const scorecards =
              await customScorecardListAPI.fetchCustomScorecardsByRole(district_role);
            job.scorecards = scorecards;
          }
          this.setState({ isLoading: false });
          this.setState({ job }, () => this.checkInternal(this.getUser, r.data.district.id));
        }
      })
      .catch(() => {
        this.setState({ jobNotFound: true });
      });
  }

  toggleJobStatus = () => {
    const newActiveStatus =
      this.state.job.active_status === activeStatusByValue.activeOpen
        ? activeStatusByValue.activeClosed
        : activeStatusByValue.activeOpen;

    return axios
      .patch(`/api/role/${this.state.job.id}/update_active_status/`, {
        active_status: newActiveStatus,
      })
      .then(() => {
        return this.setState((previousState) => ({
          ...previousState,
          job: { ...previousState.job, active_status: newActiveStatus },
        }));
      });
  };

  render() {
    const hasTabbedJobView = auth.hasTabbedJobView();
    const isSchoolAdmin = auth.isSchoolAdmin();

    if (hasTabbedJobView && isSchoolAdmin && this.state.job?.district_role) {
      return <Redirect to={`/jobs/${this.state.job.district_role.id}/details`} />;
    }

    if (this.state.jobNotFound) {
      return <JobNotFound district={this.state.district} />;
    }

    return (
      <Container>
        {this.state.job && (
          <JobView
            isPreview={false}
            job={this.state.job}
            isLoading={this.state.isLoading}
            isInternalCandidate={this.state.isInternalCandidate}
            toggleJobStatus={this.toggleJobStatus}
          />
        )}
      </Container>
    );
  }
}

export default withRouter(JobViewContainer);

export const Container = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
});
