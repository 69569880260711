import { Avatar, Stack, Typography, useMediaQuery, useTheme, styled } from '@mui/material';
import { principalInitials } from 'features/Connect/utils/connectUtils';
import { PrincipalComponentInterface } from './types';
import PersonIcon from '@mui/icons-material/Person';

export const PrincipalComponent: React.FC<PrincipalComponentInterface> = ({
  principal,
  showIconOrAvatar = 'none',
  isPrincipalMessage = false,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const firstName = principal?.first_name;
  const lastName = principal?.last_name;

  const principalName = isMobile
    ? `${firstName} ${lastName}`
    : `Principal ${firstName} ${lastName}`;

  return (
    <PrincipalContainer gap={1} marginTop={showIconOrAvatar === 'icon' ? '4px' : '0px'}>
      {showIconOrAvatar === 'avatar' && (
        <PrincipalAvatar>{principalInitials(principal)}</PrincipalAvatar>
      )}
      {showIconOrAvatar === 'icon' && <PersonIcon fontSize="medium" />}
      {isPrincipalMessage && <PrincipalMessageNameText>{principalName}</PrincipalMessageNameText>}
      {!isPrincipalMessage && <PrincipalNameText>{principalName}</PrincipalNameText>}
    </PrincipalContainer>
  );
};

const PrincipalContainer = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

const PrincipalAvatar = styled(Avatar)(() => ({
  width: '32px',
  height: '32px',
  borderRadius: '200px',
  background: '#E5ECFF',
  color: '#475467',
}));

const PrincipalNameText = styled(Typography)(({ theme }) => ({
  color: theme.palette.gray.medium,
  fontSize: theme.typography.subtitle.fontSize,
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightLight,
  lineHeight: theme.typography.h2.lineHeight,
}));

const PrincipalMessageNameText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: theme.typography.subtitle.fontSize,
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: theme.typography.h2.lineHeight,
}));
