enum RadiusOptions {
  Five = 5,
  Ten = 10,
  TwentyFive = 25,
  Fifty = 50,
  SeventyFive = 75,
  Hundred = 100,
}

export const DISTANCE_RADIUS_MARKS = [
  { value: RadiusOptions.Five, label: RadiusOptions.Five.toString() },
  { value: RadiusOptions.Ten, label: RadiusOptions.Ten.toString() },
  { value: RadiusOptions.TwentyFive, label: RadiusOptions.TwentyFive.toString() },
  { value: RadiusOptions.Fifty, label: RadiusOptions.Fifty.toString() },
  { value: RadiusOptions.SeventyFive, label: RadiusOptions.SeventyFive.toString() },
  { value: RadiusOptions.Hundred, label: RadiusOptions.Hundred.toString() },
];
