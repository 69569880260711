import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';

import { captureException } from '@sentry/browser';

import { ResumeForm } from './ResumeForm';
import { CertificationsForm } from './CertificationsForm';
import { TeachingExperienceInput } from './TeachingExperienceInput';

import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { HorizontalLinearStepper } from 'sharedComponents/Stepper/HorizontalStepper';
import { QualificationsFormData } from 'types/connectTypes';
import { ConnectProfileFlowButtonGroup } from '../SharedComponents/ConnectProfileFlowButtonGroup';

import {
  getBannerContent,
  getGatedActionDisplayInfo,
  getInitialUSVisaEligibility,
  updateConnectFirstProfileSetupDateTime,
} from '../utils';

import auth from 'utils/auth';
import expressInterestAPI from 'features/Connect/api/expressInterestAPI';
import { empty_certification_input } from './constants';
import { useStateParam } from '../../Context/ConnectStateCodeContextProvider';
import { getStateIDFromStateCode } from 'features/Connect/utils/connectUtils';
import {
  ConnectProfileFlowContainer,
  ConnectProfileFlowFormContainer,
  ConnectProfileFlowOuterContainer,
  ConnectProfileFlowPaperContainer,
  ConnectProfileFlowTitle,
} from '../styles';
import { CONNECT_JOBBOARD_STATES } from 'utils/constants';
import { ConnectAnnouncementBanner } from 'features/Connect/components/ConnectAnnouncementBanner/ConnectAnnouncmentBanner';
import { ConnectQualificationsFormDataTestIds } from 'data-testids/ConnectDataTestIds';
import { ValidationErrors, ValidationResult } from '../types';
import { QUALIFICATIONS_FORM_ERROR_MESSAGES } from '../constants';
import { merge as deepmerge } from 'lodash';

export const ResumeAndCertificationsForm = (): React.ReactElement => {
  const user = auth.getUser();
  const stateParam = useStateParam();
  const stateCode = CONNECT_JOBBOARD_STATES[stateParam]?.stateCode;
  const history = useHistory();
  const { flowDisplayInformation, pageDisplayInformation } = getGatedActionDisplayInfo();
  const defaultStateID = useMemo(() => {
    return getStateIDFromStateCode(stateCode);
  }, [stateCode]);

  const [resumeIsUploading, setResumeIsUploading] = useState<boolean>(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
  const [userDataIsLoading, setUserDataIsLoading] = useState<boolean>(true);
  const [userFormData, setUserFormData] = useState<QualificationsFormData>({
    hasCertification: true,
    certifications: [empty_certification_input(defaultStateID)],
    resume: null,
    yearsOfTeachingExperience: user.profile.years_of_teaching_experience || '',
    isEligibleToWorkInUS: getInitialUSVisaEligibility(user),
  });

  const [error, setError] = useState<ValidationErrors>({
    resume: null,
    yearsOfTeachingExperience: null,
    certificationState: null,
    certificationSubject: null,
  });

  const getConnectCertifications = () => {
    return userFormData.certifications.filter((certification) => certification.isHidden === false);
  };

  const removeIncompleteCertifications = () => {
    return userFormData.certifications.filter((certification) => {
      const isHiddenCredential = certification.isHidden;
      const hasValidStateAndSubject = !!certification.state && !!certification.subject?.id;
      return isHiddenCredential || hasValidStateAndSubject;
    });
  };

  const handleYearsOfExperienceChange = (optionValue: string) => {
    setError({ ...error, yearsOfTeachingExperience: null });
    setUserFormData({ ...userFormData, yearsOfTeachingExperience: optionValue });
  };

  const handleFormChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;

    setError({ ...error, [name]: null });
    if (name === 'hasCertification') {
      if (value === 'true' && getConnectCertifications().length === 0) {
        setUserFormData({
          ...userFormData,
          hasCertification: true,
          certifications: [
            ...userFormData.certifications,
            empty_certification_input(defaultStateID),
          ],
        });
        return;
      } else if (value === 'false') {
        const updatedCertifications = getConnectCertifications().map((certification) => {
          const errors = {
            hasSubjectError: false,
            hasStateError: false,
          };
          certification.errors = errors;
          setError((prev) => ({ ...prev, certificationState: null, certificationSubject: null }));
          return certification;
        });
        setUserFormData({
          ...userFormData,
          hasCertification: true,
          certifications: [...updatedCertifications],
        });
      }

      setUserFormData({ ...userFormData, hasCertification: value === 'true' });
      return;
    }

    setUserFormData({ ...userFormData, [name]: value });
  };

  const fetchUserData = async () => {
    try {
      setUserDataIsLoading(true);
      const userData = await auth.getUserAsync();
      const { resume, credentials } = await expressInterestAPI.fetchUserProfileAndCredentials(
        user.id
      );

      const consolidatedUserData = {
        ...userFormData,
        isEligibleToWorkInUS: getInitialUSVisaEligibility(userData),
        yearsOfTeachingExperience:
          userData.profile.years_of_teaching_experience == null
            ? ''
            : userData.profile.years_of_teaching_experience,
        certifications: credentials,
        resume,
      };

      setUserFormData(consolidatedUserData);
    } catch (error) {
      captureException(error);
    } finally {
      setUserDataIsLoading(false);
    }
  };

  const validateCertifications = () => {
    let hasErrors = false;

    getConnectCertifications().forEach((certification) => {
      const certificationStateMissing = !certification?.state;
      const certificationSubjectMissing = !certification?.subject?.id;
      const formCertificationIndex = userFormData.certifications.findIndex(
        (formCertification) => formCertification.client_id === certification.client_id
      );

      if (certificationSubjectMissing) {
        setError((prev) => ({
          ...prev,
          certificationSubject: QUALIFICATIONS_FORM_ERROR_MESSAGES.certificationSubject,
        }));
        hasErrors = true;
      }
      if (certificationStateMissing) {
        setError((prev) => ({
          ...prev,
          certificationState: QUALIFICATIONS_FORM_ERROR_MESSAGES.certificationState,
        }));
        hasErrors = true;
      }

      const newCertifications = deepmerge([], userFormData.certifications);
      newCertifications[formCertificationIndex].errors = {
        hasSubjectError: certificationSubjectMissing,
        hasStateError: certificationStateMissing,
      };
      setUserFormData({ ...userFormData, certifications: newCertifications });
    });

    return hasErrors;
  };

  const validateForm = async (formData: QualificationsFormData): Promise<ValidationResult> => {
    const { resume, yearsOfTeachingExperience, hasCertification, certifications } = formData;
    const errors: ValidationErrors = {};
    let hasCertificationErrors = false;

    if (!resume) {
      errors.resume = QUALIFICATIONS_FORM_ERROR_MESSAGES.noFileSelected;
    }

    if (!yearsOfTeachingExperience) {
      errors.yearsOfTeachingExperience =
        QUALIFICATIONS_FORM_ERROR_MESSAGES.yearsOfTeachingExperience;
    }

    if (hasCertification) {
      if (certifications.length === 0) {
        hasCertificationErrors = true;
      } else {
        hasCertificationErrors = validateCertifications();
      }
    }

    return {
      isValid: Object.keys(errors).length === 0 && !hasCertificationErrors,
      errors,
    };
  };

  const hasFormErrors = (error: ValidationErrors) => {
    return (
      !!error.resume ||
      !!error.yearsOfTeachingExperience ||
      (userFormData.hasCertification && !!error.certificationState) ||
      !!error.certificationSubject
    );
  };

  const handleSaveAndContinue = async () => {
    const { isValid, errors } = await validateForm(userFormData);

    if (!isValid) {
      setError((prev) => ({ ...prev, ...errors }));
      setIsFormSubmitting(false);
      return;
    }

    const { yearsOfTeachingExperience, hasCertification, certifications, isEligibleToWorkInUS } =
      userFormData;

    try {
      setIsFormSubmitting(true);
      let certificationsToSave = certifications;

      if (!hasCertification) {
        certificationsToSave = certifications.filter((certification) => certification.isHidden);
      } else {
        certificationsToSave = removeIncompleteCertifications();
      }

      const updatedUserData = {
        userId: user.id,
        credentials: certificationsToSave,
        yearsOfTeachingExperience: yearsOfTeachingExperience,
        isEligibleToWorkInUS: isEligibleToWorkInUS,
      };

      await expressInterestAPI.updateUserCredentialsAndProfile(updatedUserData);
      setIsFormSubmitting(false);
      history.push(pageDisplayInformation.continueToUrl);
    } catch (error) {
      captureException(error);
      setIsFormSubmitting(false);
    }
  };

  const handleResumeUploadError = (event: string | ((prevState: string) => string)) => {
    setError((prev) => ({
      ...prev,
      resume: typeof event === 'function' ? event(prev.resume) : event,
    }));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userFormData.hasCertification && getConnectCertifications().length === 0) {
      setUserFormData({
        ...userFormData,
        certifications: [...userFormData.certifications, empty_certification_input(defaultStateID)],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userFormData.certifications, userFormData.hasCertification]);

  useEffect(() => {
    updateConnectFirstProfileSetupDateTime(user, stateParam);
  }, [user, stateParam]);

  return (
    <ConnectProfileFlowOuterContainer>
      {!!getBannerContent() && <ConnectAnnouncementBanner />}
      <ConnectProfileFlowContainer>
        <HorizontalLinearStepper
          activeStep={pageDisplayInformation.step}
          steps={flowDisplayInformation.stepper.steps}
          mobileSteps={flowDisplayInformation.stepper.mobileSteps}
        />
        <ConnectProfileFlowPaperContainer elevation={3}>
          <ConnectProfileFlowFormContainer component="form">
            <ConnectProfileFlowTitle variant="h1">
              {pageDisplayInformation.headerText}
            </ConnectProfileFlowTitle>
            <Stack spacing={1.75} px={{ xs: 2, sm: 7 }} py={{ xs: 2, sm: 1.25 }}>
              <ResumeForm
                userFormData={userFormData}
                setUserFormData={setUserFormData}
                userDataIsLoading={userDataIsLoading}
                resumeIsUploading={resumeIsUploading}
                setResumeIsUploading={setResumeIsUploading}
                resumeUploadError={error.resume}
                setResumeUploadError={handleResumeUploadError}
                pageDisplayInformation={pageDisplayInformation}
                doesUserHaveApplications={user?.has_applications}
              />
              <TeachingExperienceInput
                isUserDataIsLoading={userDataIsLoading}
                userFormData={userFormData}
                handleYearsOfExperienceChange={handleYearsOfExperienceChange}
                isTeachingExperienceError={!!error.yearsOfTeachingExperience}
                pageDisplayInformation={pageDisplayInformation}
              />
              <CertificationsForm
                isUserDataLoading={userDataIsLoading}
                handleFormChange={handleFormChange}
                userFormData={userFormData}
                setUserFormData={setUserFormData}
                certificationsFormHasErrors={validateCertifications}
                getConnectCertifications={getConnectCertifications}
                isFormSubmitting={isFormSubmitting}
                pageDisplayInformation={pageDisplayInformation}
                defaultStateID={defaultStateID}
                setError={setError}
                error={error}
              />
            </Stack>
          </ConnectProfileFlowFormContainer>
        </ConnectProfileFlowPaperContainer>
        <ConnectProfileFlowButtonGroup
          dataTestId={ConnectQualificationsFormDataTestIds.BUTTON_GROUP}
          primaryButton={{
            dataTestId: ConnectQualificationsFormDataTestIds.CONTINUE_BUTTON,
            primaryButtonLabel: pageDisplayInformation?.primaryButtonLabel,
            primaryAction: handleSaveAndContinue,
            disabled: hasFormErrors(error),
            isLoading: isFormSubmitting,
          }}
          secondaryButton={{
            dataTestId: ConnectQualificationsFormDataTestIds.BACK_BUTTON,
            secondaryButtonLabel: 'Back',
            secondaryAction: () => history.push(pageDisplayInformation.backUrl),
          }}
          hasError={false}
        />
      </ConnectProfileFlowContainer>
    </ConnectProfileFlowOuterContainer>
  );
};

export const QuestionLabel = styled(InputLabel)(() => ({
  textWrap: 'wrap',
}));
