import { Box, CircularProgress, styled, Typography, useTheme } from '@mui/material';
import { useQueryGetJobClosureNotifications } from '../queries';
import { SectionTitle } from 'features/TabbedJobView/styles';
import { Switch } from 'sharedComponents/Switch/Switch';
import { getAxiosErrorMessageByStatusCode } from 'hooks/errorHandling/errorHandlingHooks';
import { JobClosureNotificationDetailAlert, Subscriber } from '../types';
import { ATSTabbedJobViewHiringTabTestIds } from 'data-testids/ATS';
import { Alert } from 'sharedComponents/Alert';

export const JobClosureNotificationSection: React.FC<{ jobId: number }> = ({ jobId }) => {
  const theme = useTheme();

  return (
    <>
      <SectionTitle
        variant="h2"
        data-testid={ATSTabbedJobViewHiringTabTestIds.NOTIFICATIONS_SECTION_TITLE}
        sx={{ marginBottom: theme.spacing(3) }}
      >
        Job Closure Notifications
      </SectionTitle>
      <NotificationDisplay jobId={jobId} />
    </>
  );
};

const NotificationDisplay: React.FC<{ jobId: number }> = ({ jobId }) => {
  const { data, isError, error, isLoading } = useQueryGetJobClosureNotifications(jobId);
  const errorMessage = getAxiosErrorMessageByStatusCode(error);

  if (isLoading) {
    return (
      <CircularProgress
        data-testid={ATSTabbedJobViewHiringTabTestIds.NOTIFICATIONS_LOADING_SPINNER}
      />
    );
  }

  if (isError) {
    return (
      <Alert type="error" dataTestId={ATSTabbedJobViewHiringTabTestIds.NOTIFICATIONS_ALERT}>
        {errorMessage}
      </Alert>
    );
  }

  if (data.length === 0) {
    return <NotificationSwitch disabled />;
  }

  return (
    <>
      <NotificationSwitch disabled defaultChecked />
      <AlertDisplay alert={data[0].alerts[0]} />
      <UsersToNotify subscribers={data[0].subscribers} />
    </>
  );
};

const NotificationSwitch: React.FC<{ disabled: boolean; defaultChecked?: boolean }> = ({
  disabled,
  defaultChecked = false,
}) => {
  const theme = useTheme();

  return (
    <SwitchContainer>
      <Switch
        disabled={disabled}
        defaultChecked={defaultChecked}
        dataTestId={ATSTabbedJobViewHiringTabTestIds.NOTIFICATIONS_SWITCH}
        sx={{
          '& > .MuiSwitch-switchBase.Mui-disabled': { color: theme.palette.text.secondary },
          '& > .MuiSwitch-track': { backgroundColor: `${theme.palette.text.secondary} !important` },
        }}
      />
      <Typography data-testid={ATSTabbedJobViewHiringTabTestIds.NOTIFICATIONS_SWITCH_LABEL}>
        Send email when job is ready to be closed
      </Typography>
    </SwitchContainer>
  );
};

const AlertDisplay: React.FC<{ alert: JobClosureNotificationDetailAlert }> = ({ alert }) => {
  return (
    <AlertDisplayContainer>
      <Typography
        data-testid={ATSTabbedJobViewHiringTabTestIds.NOTIFICATIONS_READY_TO_BE_CLOSED_TEXT}
      >
        This job is ready to be closed when:
      </Typography>
      <Typography
        data-testid={ATSTabbedJobViewHiringTabTestIds.NOTIFICATIONS_ALERT_CONDITION_DESCRIPTION}
      >
        this number of candidates <b>&nbsp;{alert.context.threshold}&nbsp;</b> reach the status of{' '}
        <b>&nbsp;{alert.context.application_status.label}</b>
      </Typography>
    </AlertDisplayContainer>
  );
};

const UsersToNotify: React.FC<{ subscribers: Subscriber[] }> = ({ subscribers }) => {
  return (
    <UsersToNotifyContainer>
      <UsersToNotifyLabel
        data-testid={ATSTabbedJobViewHiringTabTestIds.NOTIFICATIONS_SELECT_USERS_TEXT}
      >
        Select users to notify
      </UsersToNotifyLabel>
      {subscribers.map((subscriber) => (
        <Typography
          key={subscriber.id}
          data-testid={ATSTabbedJobViewHiringTabTestIds.NOTIFICATIONS_SUBSCRIBER}
        >
          {subscriber.first_name} {subscriber.last_name}
        </Typography>
      ))}
    </UsersToNotifyContainer>
  );
};

const SwitchContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  margin: `${theme.spacing(3)} 0`,
}));

const AlertDisplayContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(3),
}));

const UsersToNotifyLabel = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
}));

const UsersToNotifyContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));
