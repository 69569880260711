import { useState } from 'react';
import BulkExportSelectFieldsModal from './SelectFieldsModal/SelectFieldsModal';
import BulkExportSuccessModal from './SuccessModal';

type BulkExportV2Props = {
  onClose: () => void;
  open: boolean;
  onProspectList: boolean;
  selectedCandidateIds: Set<number>;
};

const BulkExportV2: React.FC<BulkExportV2Props> = ({
  onClose,
  open,
  onProspectList,
  selectedCandidateIds,
}) => {
  const [success, setSuccess] = useState<boolean>(false);

  const onExport = () => {
    setSuccess(true);
  };

  const handleCloseSuccess = () => {
    setSuccess(false);
    onClose();
  };

  if (success) {
    return <BulkExportSuccessModal open={open} onClose={handleCloseSuccess} />;
  }

  return (
    <BulkExportSelectFieldsModal
      open={open}
      onExport={onExport}
      onClose={onClose}
      onProspectList={onProspectList}
      selectedCandidateIds={selectedCandidateIds}
    />
  );
};

export default BulkExportV2;
