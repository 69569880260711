import { Box, Button, InputBase, Link, styled, Typography } from '@mui/material';
import { nimbleTheme } from 'theme';
import { WhiteRoundedBox } from '../styles';

export const ApplyButton = styled(Button)(({ theme }) => ({
  borderRadius: `${theme.spacing(1)} !important`,
  fontWeight: theme.typography.fontWeightMediumBold,
  paddingBottom: theme.spacing(1),
  paddingLeft: nimbleTheme.spacing(2),
  paddingTop: theme.spacing(1),
}));

export const AttachmentsContainer = styled(Box)({
  backgroundColor: nimbleTheme.palette.white.darker,
  borderRadius: nimbleTheme.spacing(1),
  display: 'flex',
  flexWrap: 'wrap',
  padding: nimbleTheme.spacing(2),

  [nimbleTheme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
});

export interface AttachmentItemProps {
  isLastAndOddNumber?: boolean;
}

export const AttachmentItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isLastAndOddNumber',
})<AttachmentItemProps>(({ isLastAndOddNumber }) => ({
  backgroundColor: nimbleTheme.palette.white.main,
  border: `1px solid ${nimbleTheme.palette.gray.darker}`,
  borderRadius: nimbleTheme.spacing(1),
  display: 'flex',
  flex: '48%',
  marginBottom: nimbleTheme.spacing(2),
  marginRight: nimbleTheme.spacing(1),
  padding: `${nimbleTheme.spacing(1.25)} ${nimbleTheme.spacing(1.75)}`,

  ':nth-of-type(even)': {
    marginRight: 0,
  },

  ':last-of-type': {
    flex: isLastAndOddNumber ? '46%' : null,
    flexGrow: '0.06',
    marginBottom: isLastAndOddNumber ? 0 : null,
  },

  [nimbleTheme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export const AttachmentTitle = styled(Typography)({
  fontWeight: nimbleTheme.typography.fontWeightBold,
  lineHeight: nimbleTheme.spacing(2.5),
});

export const BackToJobsLink = styled(Link)({
  alignItems: 'space-between',
  display: 'flex',
  fontWeight: nimbleTheme.typography.fontWeightBold,
  width: nimbleTheme.spacing(25),
  marginBottom: nimbleTheme.spacing(2),
  textDecoration: 'none',
});

export const ChipAndDistrict = styled(Box)({
  alignItems: 'center',
  display: 'flex',
});

export const ChipContainer = styled(Box)({
  alignItems: 'center',
  display: 'inline',
  paddingRight: nimbleTheme.spacing(2),
});

export interface ChipDistrictToggleContainerProps {
  showStatusToggle: boolean;
}

export const ChipDistrictToggleContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'showStatusToggle',
})<ChipDistrictToggleContainerProps>(({ showStatusToggle }) => ({
  alignItems: 'center',
  display: 'flex',
  fontSize: nimbleTheme.typography.body1.fontSize,
  width: '100%',
  [nimbleTheme.breakpoints.down('md')]: {
    order: 2,
    paddingBottom: showStatusToggle ? 0 : nimbleTheme.spacing(1),
  },

  [nimbleTheme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

export const ContentContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  [nimbleTheme.breakpoints.down('sm')]: {
    alignItems: 'left',
    flexDirection: 'column',
  },
});

export const CopyButton = styled(Button)({
  borderLeft: `1px solid ${nimbleTheme.palette.gray.darker}`,
  borderRadius: 0,
  color: nimbleTheme.palette.text.primary,
  fontWeight: nimbleTheme.typography.fontWeightBold,
  lineHeight: nimbleTheme.spacing(3),
  padding: nimbleTheme.spacing(1.5),
});

export const CTAContainer = styled(Box)({
  display: 'flex',
  height: '40px',
  gap: nimbleTheme.spacing(1),
  [nimbleTheme.breakpoints.down('md')]: {
    alignItems: 'space-between',
  },
});

export const DetailContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: nimbleTheme.spacing(1),
  paddingBottom: nimbleTheme.spacing(2),
});

export const DetailsContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

export const HeaderContainer = styled(Box)({
  width: '100%',
  [nimbleTheme.breakpoints.down('md')]: {
    alignItems: 'flex-start',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    padding: nimbleTheme.spacing(2),
  },
});

export const InformationContainer = styled(WhiteRoundedBox)({
  height: 'fit-content',
  marginRight: nimbleTheme.spacing(3),
  padding: nimbleTheme.spacing(1),
  paddingBottom: nimbleTheme.spacing(5),
  width: '65%',
  maxWidth: '1150px',
  [nimbleTheme.breakpoints.down('md')]: {
    backgroundColor: nimbleTheme.palette.white.main,
    marginBottom: nimbleTheme.spacing(2),
    marginLeft: 0,
    marginRight: 0,
    padding: 0,
    width: 'inherit',
  },
});

export const JobViewSectionTitle = styled(Typography)({
  color: nimbleTheme.palette.gray.dark,
});

export const InlineBlock = styled(Box)({
  display: 'inline',
  paddingRight: nimbleTheme.spacing(2),
  ':last-child': {
    paddingRight: 0,
  },
});

export const JobLink = styled(InputBase)({
  marginLeft: nimbleTheme.spacing(1),
  flex: 1,
  borderRadiusX: nimbleTheme.spacing(1),
});

export const JobTitle = styled(Typography)({
  alignItems: 'center',
  color: nimbleTheme.palette.gray.dark,
  display: 'flex',
  textAlign: 'left',
  width: '100%',
  padding: 0,
  [nimbleTheme.breakpoints.down('lg')]: {
    alignItems: 'flex-start',
    flexDirection: 'column',
    lineHeight: nimbleTheme.spacing(4),
  },
  [nimbleTheme.breakpoints.down('md')]: {
    fontSize: nimbleTheme.spacing(3),
  },
  [nimbleTheme.breakpoints.down('sm')]: {
    display: '-webkit-box',
    WebkitLineClamp: Number(2),
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
});

export const JobDetailSubtitle = styled(Typography)({
  color: nimbleTheme.palette.text.light,
});

export const Left = styled(Box)({
  width: '55%',
  float: 'left',
  [nimbleTheme.breakpoints.down('md')]: {
    maxWidth: '100%',
    order: 1,
    width: '100%',
  },
});

export const ListBox = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'left',
  listStyle: 'none',
  paddingTop: nimbleTheme.spacing(0.5),
});

export const PublicShareInfoText = styled(Typography)({
  color: nimbleTheme.palette.text.light,
  lineHeight: nimbleTheme.spacing(2.5),
  paddingBottom: nimbleTheme.spacing(2),
});

export const PublicShareLinkContainer = styled(Box)({
  alignItems: 'center',
  border: `1px solid ${nimbleTheme.palette.gray.darker}`,
  borderRadius: nimbleTheme.spacing(1),
  display: 'flex',
});

export const SectionTitle = styled(Typography)({
  color: nimbleTheme.palette.text.primary,
  fontWeight: nimbleTheme.typography.fontWeightMediumBold,
  lineHeight: nimbleTheme.spacing(3.5),
  paddingBottom: nimbleTheme.spacing(1),
});

export const Right = styled(Box)({
  alignItems: 'space-between',
  display: 'flex',
  justifyContent: 'right',
  width: '45%',
  [nimbleTheme.breakpoints.down('md')]: {
    justifyContent: 'left',
    width: '100%',
    order: 3,
  },
});

export const RightAttachmentsSection = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: nimbleTheme.spacing(1),
});

export const StatusAndDistrict = styled(Box)({
  color: nimbleTheme.palette.text.light,
  display: 'flex',
  fontSize: nimbleTheme.spacing(2),
  lineHeight: nimbleTheme.spacing(3),
  marginTop: nimbleTheme.spacing(1),
  [nimbleTheme.breakpoints.down('md')]: {
    flexDirection: 'column-reverse',
  },
});

export const StyledLink = styled(Link)({
  alignItems: 'center',
  color: nimbleTheme.palette.success.main,
  display: 'flex',
  margin: 0,
  padding: 0,
  textDecoration: 'none',
});

export const Value = styled(Typography)({
  color: nimbleTheme.palette.gray.mediumDark,
  fontWeight: nimbleTheme.typography.fontWeightBold,
  lineHeight: nimbleTheme.spacing(3),
});

export const InlineList = styled('ul')({
  li: {
    display: 'inline',
  },
  'li:after': {
    content: '", "',
  },
  'li:last-child:after': {
    content: '""',
  },
});
