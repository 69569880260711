import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { useAutocompleteLocation } from 'hooks/connect/useAutocompleteLocation';
import { ENVIRONMENT } from 'utils/constants/environment';
import { AutocompleteRenderProps, LocationMapSearchProps } from './types';
import { styled } from '@mui/material/styles';
import LocationSearchInput from './LocationSearchInput';
import { RadiusControl } from './DistanceRadiusControl';
import { useConnectContext } from 'features/Connect/features/Context/ConnectContextProvider';
import debounce from 'lodash/debounce';

export const PreferencesInputField = ({ autocomplete }: AutocompleteRenderProps): React.ReactNode =>
  autocomplete;

export const DiscoveryInputField = ({ autocomplete }: AutocompleteRenderProps): React.ReactNode => (
  <Grid item xs={12} md={8}>
    <Typography variant="body1" marginBottom={1}>
      Where will you commute from?
    </Typography>
    <Box>{autocomplete}</Box>
  </Grid>
);

export const LocationMapSearch = forwardRef<HTMLDivElement, LocationMapSearchProps>(
  (
    {
      onLocationChange,
      onRadiusChange,
      radius,
      location,
      renderInputField,
      onGeocodedLocationChange,
      label,
      stateCode,
      mapService,
      helperText,
      debounceTime = ENVIRONMENT.CONNECT_LOCATION_AUTOCOMPLETE_DEBOUNCE_TIME_MILLIS,
      variant = 'preferences',
      testIds = {},
      error,
      sx,
    },
    _ref
  ) => {
    const {
      inputValue,
      setInputValue,
      predictions,
      mapContainerRef,
      initializeMap,
      resolveAddress,
      geocodedLocation,
    } = useAutocompleteLocation({
      mapService,
      radius,
      location,
      debounceTimeMillis: debounceTime,
      stateCode,
    });

    const { connectContextActions } = useConnectContext();

    const [previousRadius, setPreviousRadius] = useState(radius);

    useEffect(() => {
      // Small delay to ensure DOM is ready
      const timer = setTimeout(() => {
        if (mapContainerRef.current) {
          initializeMap();
        }
      }, 100);

      return () => clearTimeout(timer);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapContainerRef]);

    useEffect(() => {
      const debouncedLocationChange = debounce(
        (rad: string | number, loc: { lat: number; lng: number }) => {
          onGeocodedLocationChange?.(rad, loc);
        },
        2000
      );

      if (geocodedLocation && previousRadius !== radius) {
        connectContextActions.setIsLoading(true);
        debouncedLocationChange(radius, geocodedLocation);
        setPreviousRadius(radius);
      } else if (geocodedLocation) {
        onGeocodedLocationChange?.(radius, geocodedLocation);
      }

      return () => {
        debouncedLocationChange.cancel();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [geocodedLocation, radius]);

    const updateFreeTypedLocation = useCallback(
      async (address: string) => {
        const isAddressFromSuggestions = address === location;
        if (!isAddressFromSuggestions) {
          const resolvedAddress = await resolveAddress(address);

          onLocationChange(resolvedAddress);
          setInputValue(resolvedAddress);
        }
      },
      [setInputValue, onLocationChange, location, resolveAddress]
    );

    const autocompleteField = (
      <LocationSearchInput
        onLocationChange={onLocationChange}
        predictions={predictions}
        inputValue={inputValue}
        dataTestId={testIds.autocomplete}
        setInputValue={setInputValue}
        updateFreeTypedLocation={updateFreeTypedLocation}
        label={label}
        helperText={helperText}
        error={error}
        sx={sx}
      />
    );

    const radiusControl = (
      <RadiusControl
        radius={radius}
        onRadiusChange={onRadiusChange}
        dataTestId={testIds.radiusControl}
      />
    );

    return (
      <Stack spacing={2}>
        {variant === 'preferences' && (
          <>
            {renderInputField({ autocomplete: autocompleteField })}
            {radiusControl}
            <div data-testid={testIds.mapContainer}>
              <MapContainer ref={mapContainerRef} />
            </div>
          </>
        )}
        {variant === 'discovery' && (
          <>
            <div data-testid={testIds.mapContainer}>
              <MapContainer ref={mapContainerRef} variant="discovery" />
            </div>
            <InputsContainer>
              <Grid container spacing={4} alignItems="center">
                {renderInputField({ autocomplete: autocompleteField })}
                <Grid item xs={12} md={4}>
                  {radiusControl}
                </Grid>
              </Grid>
            </InputsContainer>
          </>
        )}
      </Stack>
    );
  }
);

LocationMapSearch.displayName = 'LocationMapSearchRef';

const MapContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'variant',
})<{ variant?: 'preferences' | 'discovery' }>(({ theme, variant }) => ({
  height: '240px',
  width: '100%',
  borderRadius:
    variant === 'discovery'
      ? `${theme.shape.borderRadius * 2}px ${theme.shape.borderRadius * 2}px 0 0`
      : theme.shape.borderRadius * 3,
  overflow: 'hidden',
  border: `1px solid ${theme.palette.gray.darker}`,
}));

const InputsContainer = styled(Box)(({ theme }) => ({
  marginTop: '0 !important',
  padding: '24px',
  backgroundColor: theme.palette.white.medium,
  width: '100%',
  height: '100%',
  borderBottomLeftRadius: theme.shape.borderRadius * 2,
  borderBottomRightRadius: theme.shape.borderRadius * 2,
  border: `1px solid ${theme.palette.border.main}`,
}));
