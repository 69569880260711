import { Alert } from '@mui/material';
import { ErrorSnackbarProps } from './types';
import { Snackbar } from './Snackbar';

export const ErrorSnackbar: React.FC<ErrorSnackbarProps> = ({
  message,
  onClose,
  open,
  duration = 3000,
  position = { vertical: 'top', horizontal: 'center', open: open },
  dataTestId,
}) => {
  return (
    <Snackbar
      onClose={onClose}
      open={open}
      message={message}
      duration={duration}
      position={position}
      dataTestId={dataTestId}
    >
      <Alert
        onClose={onClose}
        severity="error"
        sx={{ fontSize: '14px', width: '100%' }}
        variant="filled"
      >
        <strong>{message}</strong>
      </Alert>
    </Snackbar>
  );
};
