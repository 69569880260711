import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';

import {
  createHiringRequestFromConnectWithNewEndpoint,
  createHiringRequestFromConnectWithOldEndpoint,
} from './queries';
import { CandidateApplicationConnectData, EnhancedHiringRequestResponse } from './types';

export function useCreateHiringRequestNewEndpointMutation(
  options?: Omit<
    UseMutationOptions<
      EnhancedHiringRequestResponse,
      unknown,
      CandidateApplicationConnectData,
      unknown
    >,
    'mutationFn'
  >
): UseMutationResult<
  EnhancedHiringRequestResponse,
  unknown,
  CandidateApplicationConnectData,
  unknown
> {
  return useMutation({
    mutationFn: async (payload) => {
      const response = await createHiringRequestFromConnectWithNewEndpoint(payload);

      return {
        candidate_id: response.candidate_id,
        hiring_request_id: response.hiring_request_id,
        internal_candidate_note_content: payload.internal_candidate_note_content,
        schools: payload.schools,
        contact_methods: payload.contact_methods,
      };
    },
    ...(options || {}),
  });
}

export function useCreateHiringRequestWithOldEndpointMutation(
  options?: Omit<
    UseMutationOptions<
      EnhancedHiringRequestResponse,
      unknown,
      CandidateApplicationConnectData,
      unknown
    >,
    'mutationFn'
  >
): UseMutationResult<
  EnhancedHiringRequestResponse,
  unknown,
  CandidateApplicationConnectData,
  unknown
> {
  return useMutation({
    mutationFn: async (payload) => {
      const response = await createHiringRequestFromConnectWithOldEndpoint(payload);

      return {
        candidate_id: response.candidate_id,
        hiring_request_id: response.application_id,
        internal_candidate_note_content: payload.internal_candidate_note_content,
        schools: payload.schools,
        contact_methods: payload.contact_methods,
      };
    },
    ...(options || {}),
  });
}
