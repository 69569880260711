import { Theme, useTheme } from '@mui/material';
import auth from 'utils/auth';
import { JobDiscoveryLocationMapSearchProps } from 'types/connectTypes';
import { useState, useCallback } from 'react';
import { ConnectFilterName } from 'features/Connect/utils/connectEnums';
import { ConnectJobDiscoveryFiltersDataTestIds } from 'data-testids/ConnectDataTestIds';
import { useStateParam } from '../../Context/ConnectStateCodeContextProvider';
import { CONNECT_JOBBOARD_STATES } from 'utils/constants';
import { DistanceOptions } from '../types';
import { DiscoveryInputField } from 'features/Connect/components/LocationMapSearch/LocationMapSearch';
import { LocationMapSearch } from 'features/Connect/components/LocationMapSearch/LocationMapSearch';
import { GoogleMapsService } from 'integrations/maps/googleMapsService';
import { ENVIRONMENT } from 'utils/constants/environment';

export const JobDiscoveryLocationMapFilter: React.FC<JobDiscoveryLocationMapSearchProps> = ({
  jobDiscoveryActions,
}) => {
  const user = auth.getUser();
  const jobboardState = useStateParam();
  const theme = useTheme();
  const stateCode =
    user?.preference?.state_code || CONNECT_JOBBOARD_STATES[jobboardState]?.stateCode;

  const [streetAddress, setStreetAddress] = useState<string>('');
  const [distanceRadius, setDistanceRadius] = useState<string>(DistanceOptions.TWENTY_FIVE_MILES);

  const mapService = new GoogleMapsService(ENVIRONMENT.GOOGLE_MAPS_API_KEY);

  const handleUserLocationChange = (streetAddress: string) => {
    setStreetAddress(streetAddress);
  };

  const handleDistanceChange = (selectedOption: number) => {
    setDistanceRadius(selectedOption.toString());
  };

  const handleGetSearchResults = useCallback(
    (distanceRadius: string | number, geocodedLocation: { lat: number; lng: number }) => {
      jobDiscoveryActions.setSelectedLocation({
        distance_radius_miles: distanceRadius.toString(),
        location_latitude: geocodedLocation.lat.toString(),
        location_longitude: geocodedLocation.lng.toString(),
      });
      jobDiscoveryActions.setFilterIsActive(ConnectFilterName.LOCATION, true);
      jobDiscoveryActions.setFilterIsActive(
        ConnectFilterName.DISTANCE_RADIUS_MILES,
        !!distanceRadius
      );
    },
    [jobDiscoveryActions]
  );

  return (
    <LocationMapSearch
      onLocationChange={handleUserLocationChange}
      onRadiusChange={handleDistanceChange}
      radius={parseInt(distanceRadius, 10)}
      location={streetAddress}
      renderInputField={DiscoveryInputField}
      onGeocodedLocationChange={handleGetSearchResults}
      stateCode={stateCode}
      mapService={mapService}
      variant="discovery"
      testIds={{
        autocomplete: ConnectJobDiscoveryFiltersDataTestIds.LOCATION_INPUT,
        mapContainer: ConnectJobDiscoveryFiltersDataTestIds.LOCATION_MAP_CONTAINER,
        radiusControl: ConnectJobDiscoveryFiltersDataTestIds.DISTANCE_FILTER,
      }}
      sx={selectStyles(theme)}
    />
  );
};

const selectStyles = (theme: Theme) => ({
  width: '100%',
  minWidth: 110,
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.shape.borderRadius * 0.5,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.body1.fontSize,
    },
  },
});
