import { roleStatusByValue } from 'utils/typedEnums';
import { BaseNimbleAPI } from './baseAPI';
import {
  CoreSchoolRole,
  FilterApplicationStatus,
  FullRole,
  RoleNoDistrict,
  RoleProspectConversion,
  RoleVacancy,
} from 'types';
import { AxiosResponse } from 'axios';

class RolesAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/api/role' };
  }

  async featureRole(roleId: number): Promise<RoleNoDistrict> {
    const url = `/${roleId}/feature/`;
    const response = await this.post(url);
    return response.data;
  }

  async unfeatureRole(roleId: number): Promise<RoleNoDistrict> {
    const url = `/${roleId}/feature/`;
    const response = await this.delete(url);
    return response.data;
  }

  async mergeRole(
    roleBeingMerged: { id: number },
    roleMergingInto: { id?: number }
  ): Promise<FullRole | 'Role merged.'> {
    const url = `/${roleMergingInto.id}/merge/`;
    const data = { role_being_merged: roleBeingMerged.id };
    const response = await this.post(url, data);
    return response.data;
  }

  async returnSubmittedRole(
    roleId: number,
    data: { text: string; created_at: Date }
  ): Promise<undefined> {
    const url = `/${roleId}/return_submitted_role/`;
    const response = await this.post(url, data);
    return response.data;
  }

  async fetchRole(roleId: number): Promise<CoreSchoolRole> {
    const url = `/${roleId}/`;
    const response = await this.get(url);
    return response.data;
  }

  async fetchProspectConversionRoles(): Promise<RoleProspectConversion> {
    const url = '/get_prospect_conversion_roles/';
    const response = await this.get(url);
    return response.data;
  }

  async fetchFilterStatuses(roleId: number): Promise<FilterApplicationStatus[]> {
    const url = `/${roleId}/filter_statuses/`;
    const response = await this.get(url);
    return response.data;
  }

  async update(roleId: number, data: Partial<RoleNoDistrict>): Promise<RoleNoDistrict> {
    const url = `/${roleId}/`;
    const response = await this.put(url, data);
    return response.data;
  }

  async createTemplateFromRole(
    roleId: number,
    title: string
  ): Promise<{ message: string; id: number }> {
    const url = `/template_from_role/`;
    const newData = { title: title, role_id: roleId };
    const response = await this.post(url, newData);
    return response.data;
  }

  async createRoleFromTemplate(
    roleId: number,
    title: string
  ): Promise<{ message: string; id: number }> {
    const url = `/role_from_template/`;
    const newData = { title: title, template_id: roleId };
    const response = await this.post(url, newData);
    return response.data;
  }

  async createRoleFromRequest(
    title: string,
    roleRequestId: number,
    templateId: number
  ): Promise<{ message: string; id: number }> {
    const url = `/convert_request_to_role/`;
    const newData = { title: title, role_request_id: roleRequestId, template_id: templateId };
    const response = await this.post(url, newData);
    return response.data;
  }

  async archiveRole(roleId: number): Promise<FullRole> {
    const url = `/${roleId}/archive_role/`;
    const data = { status: roleStatusByValue.archived };
    const response = await this.put(url, data);
    return response.data;
  }

  async fetchVacancies(roleId: number): Promise<AxiosResponse<RoleVacancy>> {
    const url = `/${roleId}/vacancies/`;
    const response = await this.get(url);
    return response;
  }
}

export default new RolesAPI();
