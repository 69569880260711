import { useTheme } from '@mui/material';
import { SecondaryButton } from './SecondaryButton';
import { secondaryButtonOverrideSx } from './styles';

export interface GreySecondaryButtonProps {
  children: string;
  dataTestId: string;
  onClick: () => void;
  startIcon?: JSX.Element;
}

export const GreySecondaryButton: React.FC<GreySecondaryButtonProps> = ({
  children,
  dataTestId,
  onClick,
  startIcon,
}) => {
  const theme = useTheme();

  return (
    <SecondaryButton
      onClick={onClick}
      startIcon={startIcon}
      sx={secondaryButtonOverrideSx(theme)}
      dataTestId={dataTestId}
    >
      {children}
    </SecondaryButton>
  );
};
