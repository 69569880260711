import { useState } from 'react';
import { useTheme } from '@mui/material';

import { ActionsAccordion } from './components/ActionsAccordion';
import { CandidateRowActionsProps } from '../../types';
import { ConnectActionsContainer } from './styles';
import { RequiredActionsCTA } from './components/RequiredActionsCTAs';

export const CandidateRowActions: React.FC<CandidateRowActionsProps> = ({
  actions,
  candidateId,
  onOpenModal,
  roleId,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const theme = useTheme();

  const handleExpansion = () => setIsExpanded(!isExpanded);

  const getHasReceivedAllDocuments = () => {
    return Object.values(actions).every((action) => action);
  };

  return (
    <ConnectActionsContainer
      sx={{
        backgroundColor: getHasReceivedAllDocuments() ? theme.palette.primary.fill : 'inherit',
      }}
    >
      <ActionsAccordion
        actions={actions}
        getHasReceivedAllDocuments={getHasReceivedAllDocuments}
        handleExpansion={handleExpansion}
        isExpanded={isExpanded}
      />
      <RequiredActionsCTA
        actions={actions}
        candidateId={candidateId}
        isExpanded={isExpanded}
        onOpenModal={onOpenModal}
        roleId={roleId}
      />
    </ConnectActionsContainer>
  );
};
