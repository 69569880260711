import auth from 'utils/auth';
import JobviewContainer from './JobviewContainer';
import { Redirect, useParams } from 'react-router';

const JobViewWrapper: React.FC = () => {
  const isDistrictUserOrHigher = auth.isDistrictUser();
  const hasTabbedJobView = auth.hasTabbedJobView();
  const { id } = useParams<{ id: string }>();
  const jobId = parseInt(id);

  if (isDistrictUserOrHigher && hasTabbedJobView) {
    return <Redirect to={`/jobs/${jobId}/details`} />;
  }

  return <JobviewContainer />;
};

export default JobViewWrapper;
