import { Divider, FormControlLabel, Radio } from '@mui/material';
import { useState } from 'react';
import styled from 'styled-components';

import PasswordReset from './PasswordReset';

export default function HelpSection() {
  const [mode, setMode] = useState({
    passwordReset: true,
    createAdmin: false,
    other: false,
  });

  const getCopy = () => {
    if (mode.passwordReset) {
      return (
        <>
          <MessageHeader>Password Reset</MessageHeader>
          <Message>
            Enter your account’s email address and we’ll send you a secure link to reset your
            password.
          </Message>
        </>
      );
    } else if (mode.createAdmin) {
      return (
        <>
          <MessageHeader>Admin Account</MessageHeader>
          <Message>
            For assistance creating an admin account, please reach out to your organization’s Human
            Resources team directly. Creating a new account on this page is only for candidates.
          </Message>
          <br />
          <Message>
            If you already have an admin account but have forgotten your password, select “I forgot
            my password” above for help with that!
          </Message>
        </>
      );
    } else if (mode.other) {
      return (
        <>
          <MessageHeader>Contact Support</MessageHeader>
          <Message>
            If you need further technical assistance, you can email us at{' '}
            <StyledLink href="mailto:support@hirenimble.com">support@hirenimble.com</StyledLink>.{' '}
            Remember, you don’t need to contact us to reset your password. Simply click “I forgot my
            password” above for help with that!
          </Message>
          <br />
          <Message>
            If you have questions about the application process or materials, please reach out
            directly to the organization’s HR team.
          </Message>
        </>
      );
    }
  };

  return (
    <OuterContainer>
      <GridContainer>
        <HeaderContainer>
          <HeaderText>Need help?</HeaderText>
        </HeaderContainer>
        <HelpContainer>
          <FormControlLabel
            label="I forgot my password"
            sx={{ marginBottom: 0 }}
            control={
              <Radio
                checked={mode.passwordReset}
                onClick={() =>
                  setMode({
                    passwordReset: true,
                    createAdmin: false,
                    other: false,
                  })
                }
              />
            }
          />
          <FormControlLabel
            label="I need to create an admin account"
            sx={{ marginBottom: 0 }}
            control={
              <Radio
                checked={mode.createAdmin}
                onClick={() =>
                  setMode({
                    passwordReset: false,
                    createAdmin: true,
                    other: false,
                  })
                }
              />
            }
          />
          <FormControlLabel
            label="I have a different issue"
            sx={{ marginBottom: 0 }}
            control={
              <Radio
                text="I have a different issue"
                checked={mode.other}
                onClick={() =>
                  setMode({
                    passwordReset: false,
                    createAdmin: false,
                    other: true,
                  })
                }
              />
            }
          />
          <Divider sx={{ margin: '18px 0' }} />
          <MessageContainer>{getCopy()}</MessageContainer>
          {mode.passwordReset && <PasswordReset />}
        </HelpContainer>
        <CopyrightText>&copy; 2020 Nimble Hiring, PBC, All Rights Reserved</CopyrightText>
      </GridContainer>
    </OuterContainer>
  );
}

const OuterContainer = styled.div`
  display: flex;
  align-items: center;

  @media screen and (min-width: 1200px) {
    background-color: #e5e5e5;
  }
`;

const GridContainer = styled.div`
  border: 1px solid #c4c4c4;
  border-radius: 8px;
  color: #1a1a1a;
  margin: 0 auto 10px;
  margin-top: 36px;
  position: relative;
  grid-template-rows: 60px 1fr 24px;
  background-color: #fff;
  padding: 0 32px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 300px 1fr;
  height: 490px;
  @media screen and (min-width: 1200px) {
    width: unset;
  }

  @media screen and (max-width: 1200px) {
    grid-template-columns: auto;
    grid-template-rows: auto;
    padding: 32px;
    padding-top: 16px;
    height: 100%;
    margin-top: 32px;
    margin-left: 0;
    margin-right: 0;
  }
`;

const HeaderContainer = styled.div`
  grid-column-start: 1;
  grid-column-end: 4;
  align-self: center;
  text-align: center;
  @media screen and (max-width: 1200px) {
    padding-bottom: 16x;
  }
`;

const HeaderText = styled.p`
  font-weight: 600;
  font-size: 18px;
  color: rgb(26, 26, 26);
  margin-top: 16px;
`;

const HelpContainer = styled.div`
  grid-column-start: 2;
  grid-row-start: 2;
`;

const MessageContainer = styled.div`
  padding: 0;
`;

const MessageHeader = styled.p`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 16px;
`;

const Message = styled.p`
  font-size: 13px;
  line-height: 22px;
  &:last-child {
    margin-bottom: 24px;
  }
`;

const CopyrightText = styled.div`
  position: absolute;
  bottom: -28px;
  font-size: 11px;
  line-height: 23px;
  text-align: center;
  width: 100%;

  color: rgba(24, 26, 33, 0.5);

  @media screen and (min-width: 1200px) {
    display: none;
  }
`;

const StyledLink = styled.a`
  color: #008060;
  font-weight: bold;

  &:focus,
  &:hover {
    color: #008060;
  }
`;
