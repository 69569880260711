import { ATSConnectHiringRequestTestIds } from 'data-testids/ATS';
import { CandidateFeedbackModal } from './CandidateFeedback/components/CandidateFeedbackModal';
import { CandidateRow } from '../styles';
import { CandidateRowActions } from './Actions/CandidateRowActions';
import { ConnectCandidateRowProps } from '../types';
import { RowCandidateAndRequestorInfo } from './RowCandidateAndRequestorInfo';
import { RowJobInfo } from './RowJobInfo';

export const ConnectCandidateRow: React.FC<ConnectCandidateRowProps> = ({
  actions,
  candidate,
  created,
  currentModalOpen,
  notesCount,
  numberOfScorecards,
  onCloseModal,
  onOpenModal,
  requestor,
  role,
  isModalOpen,
}) => {
  const candidateName = `${candidate.first_name} ${candidate.last_name}`;

  return (
    <>
      <CandidateRow data-testid={ATSConnectHiringRequestTestIds.CANDIDATE_ROW}>
        <RowCandidateAndRequestorInfo
          candidate={candidate}
          created={created}
          requestor={requestor}
        />
        <RowJobInfo role={role} notesCount={notesCount} />
        <CandidateRowActions
          actions={actions}
          onOpenModal={onOpenModal}
          candidateId={candidate.id}
          roleId={role.id}
        />
      </CandidateRow>
      {isModalOpen && (
        <CandidateFeedbackModal
          candidateName={candidateName}
          open={Boolean(currentModalOpen)}
          onClose={onCloseModal}
          roleTitle={role.title}
          currentModalOpen={currentModalOpen}
          numberOfScorecards={numberOfScorecards}
        />
      )}
    </>
  );
};
