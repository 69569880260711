import { CheckboxOption } from 'sharedComponents/List/types';

import { StatusAvailable, TabbedJobViewRoleSection } from './types';

export const formatRoleSectionsText = (
  section: TabbedJobViewRoleSection,
  isInternal: boolean
): string => {
  const sectionName = section.section || 'Unnamed Section';
  let visibilityText: string;
  let requirementText: string;

  if (isInternal) {
    visibilityText = section.visible_internal ? 'Visible' : 'Not Visible';
    requirementText = section.required_internal ? 'Required' : 'Not Required';
  } else {
    visibilityText = section.visible ? 'Visible' : 'Not Visible';
    requirementText = section.required ? 'Required' : 'Not Required';
  }

  return `${sectionName}: ${visibilityText}, ${requirementText}`;
};

export const transformStatusesForListData = (data: StatusAvailable[]): CheckboxOption[] => {
  return data?.map((item, index) => ({
    title: item.label,
    isChecked: true,
    id: index,
  }));
};

interface InternalSettings {
  id: number;
  section: string;
  visible_internal: boolean;
  required_internal: boolean;
}

interface ExternalSettings {
  id: number;
  section: string;
  visible: boolean;
  required: boolean;
}

interface InternalExternalReturnObject {
  internalCandidateSettings: InternalSettings[];
  externalCandidateSettings: ExternalSettings[];
}

export const separateArrayForInternalExternalSettings = (
  inputArray: TabbedJobViewRoleSection[]
): InternalExternalReturnObject => {
  const internalCandidateSettings = [];
  const externalCandidateSettings = [];

  inputArray.forEach(({ id, section, visible, required, visible_internal, required_internal }) => {
    internalCandidateSettings.push({ id, section, visible_internal, required_internal });

    externalCandidateSettings.push({
      id,
      section,
      visible,
      required,
    });
  });

  return { internalCandidateSettings, externalCandidateSettings };
};
