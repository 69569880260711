import React from 'react';
import { FormGroup, FormControl, styled } from '@mui/material';
import { CheckboxOption } from 'features/Connect/components/ModalFormOptions/CheckboxOption';
import { RadioOption } from 'features/Connect/components/ModalFormOptions/RadioOption';
import { ModalOptionGroupProps } from '../ModalFormOptions/utils/types';
import { OptionGroupType } from '../ModalFormOptions/utils/constants';

export const ModalOptionGroup: React.FC<ModalOptionGroupProps> = ({
  modalOptions,
  selectedOption,
  handleReasonChange,
  dataTestId,
  optionType = OptionGroupType.CHECKBOX,
  isMultiSelect = false,
}) => {
  return (
    <FormControl component="fieldset" fullWidth>
      <StyledFormGroup data-testid={dataTestId}>
        {modalOptions.map((option) =>
          optionType === OptionGroupType.CHECKBOX ? (
            <CheckboxOption
              key={option.value}
              option={option}
              selectedOption={selectedOption}
              handleChange={handleReasonChange}
              dataTestId={dataTestId}
              isMultiSelect={isMultiSelect}
            />
          ) : (
            <RadioOption
              key={option.value}
              option={option}
              selectedOption={selectedOption}
              handleChange={handleReasonChange}
              dataTestId={dataTestId}
            />
          )
        )}
      </StyledFormGroup>
    </FormControl>
  );
};

const StyledFormGroup = styled(FormGroup)(() => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
}));
