import { JobViewQuestion, Question, QuestionSetOrderObj } from 'types';
import { JobViewQuestions, JobViewQuestionSetItem } from './types';
import { QuestionType } from 'utils/constants';
import { generateId } from 'utils/util';

interface SortableItem {
  order?: number;
}

export function sortItemsByOrder<T extends SortableItem>(unsortedItems: T[]): T[] {
  return [...unsortedItems].sort((a, b) => a.order - b.order);
}

const applyLocalId = <T extends JobViewQuestion | JobViewQuestionSetItem>(question: T): T => ({
  ...question,
  localId: generateId(),
});

export const combineSetsWithOrder = (
  sets: JobViewQuestionSetItem[] = [],
  orderList: QuestionSetOrderObj[] = []
): Array<JobViewQuestionSetItem> => {
  const orderByUUID = orderList.reduce((acc, item) => {
    acc[item.uuid] = item.order;

    return acc;
  }, {});

  return sets.map((set) => ({
    ...set,
    order: orderByUUID[set.uuid],
    isShowingEditOptions: false,
  }));
};

export const sortAndCombineQuestionsAndAttachments = ({
  questions = [],
  custom_questions = [],
  requiredapplicationattachment_set = [],
  question_sets = [],
  question_sets_order = [],
  school_preferences_question,
}: JobViewQuestions): Array<JobViewQuestion | JobViewQuestionSetItem> => {
  const unsorted = [
    ...questions,
    ...custom_questions,
    ...requiredapplicationattachment_set,
    ...combineSetsWithOrder(question_sets, question_sets_order),
  ];

  if (school_preferences_question) {
    unsorted.push(school_preferences_question);
  }

  const sorted = sortItemsByOrder(unsorted);

  return sorted.map(applyLocalId);
};

export const getQuestionText = (question: Question | JobViewQuestion): string => {
  if (question.question_type === QuestionType.schoolPreferences) {
    if (question.is_automatic_list) {
      return 'Automatic list';
    } else {
      return `Manual list - ${question.school_choices.length} school${
        question.school_choices.length !== 1 ? 's' : ''
      } currently selected`;
    }
  } else {
    return question.prompt || question.title || question.question;
  }
};
