import { AdminLandingForm } from './ImportForm/AdminLandingForm';
import { FormContainer, LandingFormContainer } from './ImportForm/styles';

export const LandingFormPage = () => {
  const queryParams = new URLSearchParams(location.search);
  const candidateId = Number(queryParams.get('candidate_id'));

  return (
    <LandingFormContainer>
      <FormContainer>
        <AdminLandingForm candidateId={candidateId} />
      </FormContainer>
    </LandingFormContainer>
  );
};
