import { Box, styled } from '@mui/material';

export const accordionSx = {
  minHeight: 0,
  padding: 0,
  margin: 0,
  '& .MuiAccordionSummary-root': {
    minHeight: '0 !important',
  },
  '& .Mui-expanded': {
    margin: '0 !important',
  },
  '& .MuiButtonBase-root': {
    paddingLeft: 0,
  },
  '& .MuiAccordionDetails-root': {
    paddingLeft: 0,
  },
};

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
  },
}));

export const DescriptionAndBenefitsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginRight: theme.spacing(3),
  width: '60%',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    marginRight: 0,
  },
}));

export const JobDetailsContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.white.darker,
  borderRadius: theme.shape.borderRadius * 4,
  padding: theme.spacing(4),
  [theme.breakpoints.down('lg')]: {},
}));

export const Right = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '40%',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
}));
