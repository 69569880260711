import auth from '../../../utils/auth';
import styled from 'styled-components';
import ErrorText from '../../../components/errortext';
import { roleStatusByValue, roleErrorMessages } from '../../../utils/enums';
import {
  TabNamesObj,
  ErrorsByTabObj,
  IFirstTabWithErrors,
  RoleHeaderBarProps,
  IRoleErrorMessages,
} from '../types/types';
import { GreyActionButton } from 'components/JobEdit';
import { Box } from '@mui/material';
import { BackButton } from 'sharedComponents/Buttons';

const roleErrorMsgs: IRoleErrorMessages = { ...roleErrorMessages };

export default function RoleHeaderBar({
  job,
  activelySubmitting,
  isEditing = false,
  selectedTabIndex,
  saveDraft,
  publishJob,
  errors,
  setMergeRoleModalOpen,
  apiResponseErrors,
}: RoleHeaderBarProps): React.ReactElement {
  const initialErrorFields = Object.entries(errors)
    .filter((e) => e[1])
    .map((e) => e[0]);

  let apiResponseErrorFields = [];

  if (apiResponseErrors) {
    apiResponseErrorFields = Object.entries(apiResponseErrors)
      .filter((e) => e[1])
      .map((e) => e[0]);
  }

  const errorFields = initialErrorFields.concat(apiResponseErrorFields);

  let errorsByTab: ErrorsByTabObj = {};
  let tabNames: TabNamesObj = {};
  if (auth.inPostingOnlyDistrict()) {
    errorsByTab = {
      0: ['title', 'link_to_ats', 'duplicate_title'],
      1: ['job_description'],
    };
    tabNames = {
      0: 'start',
      1: 'details',
    };
  } else {
    errorsByTab = {
      0: ['title', 'role_type', 'link_to_ats', 'duplicate_title'],
      1: ['hrbp_assignments'],
      2: ['job_description'],
      3: [],
      4: [],
    };
    tabNames = {
      0: 'start',
      1: 'assignments',
      2: 'details',
      3: 'questions',
      4: 'scorecards',
    };
  }

  const getErrorText = () => {
    const errorsInCurrentTab = errorFields.filter((err) =>
      errorsByTab[selectedTabIndex as keyof ErrorsByTabObj]?.includes(err)
    );

    if (errorsInCurrentTab[0]) {
      return <ErrorText message={roleErrorMsgs[errorsInCurrentTab[0]]} />;
    } else if (errorFields.length - errorsInCurrentTab.length > 0) {
      if (!errorsByTab) throw new Error('errorsByTab is undefined');

      const firstTabWithErrors: IFirstTabWithErrors = Object.entries(errorsByTab).filter((err) =>
        err[1].some((err: string) => errorFields.includes(err))
      )[0];

      return (
        <ErrorText
          message={`Please resolve the errors in the ${tabNames[firstTabWithErrors[0]]} tab`}
        />
      );
    } else {
      return <></>;
    }
  };

  return (
    <Container>
      <ButtonContainer>
        {job.is_template ? (
          <BackButton href={'/district/templates'}>Back to Templates</BackButton>
        ) : (
          <BackButton href={'/district/jobslist'}>Back to Jobs</BackButton>
        )}
      </ButtonContainer>

      <BannerContainer>
        <TitleAndButtons>
          {job.is_template ? (
            <Title>{isEditing ? 'Edit Template' : 'New Template'}</Title>
          ) : (
            <Title>{isEditing ? 'Edit Job' : 'Create New Job'}</Title>
          )}
          <ErrorAndActionButtons>
            {errorFields.length > 0 && <ErrorTextContainer>{getErrorText()}</ErrorTextContainer>}
            <ActionButtonsContainer>
              {job.status === roleStatusByValue.submitted && auth.isDistrictAdmin() && (
                <MergeButton
                  onClick={() => setMergeRoleModalOpen(true)}
                  disabled={activelySubmitting}
                >
                  Merge
                </MergeButton>
              )}
              {!job.is_template && (
                <>
                  <GreyActionButton disabled={activelySubmitting} onClick={saveDraft}>
                    Save as Draft
                  </GreyActionButton>
                  <SubmitButton
                    disabled={activelySubmitting}
                    onClick={publishJob}
                    style={{ marginRight: '16px' }}
                  >
                    Save & Preview
                  </SubmitButton>
                </>
              )}

              {job.is_template && (
                <SubmitButton disabled={activelySubmitting} onClick={saveDraft}>
                  Save
                </SubmitButton>
              )}
            </ActionButtonsContainer>
          </ErrorAndActionButtons>
        </TitleAndButtons>
      </BannerContainer>
    </Container>
  );
}

const ButtonContainer = styled(Box)({
  padding: '16px 35px',
  maxWidth: '1225px',
  margin: 'auto',
  '@media(min-width: 1200px )': {
    padding: '16px 48px',
  },
});

const Container = styled.div`
  position: relative;
`;

const ErrorTextContainer = styled.h4`
  margin-right: 12px;
  margin-top: 30px;
`;

const ErrorAndActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

const BannerContainer = styled.div`
  background-color: #ffffff;
  height: 80px;
  z-index: 0;
`;

const ActionButtonsContainer = styled.div`
  padding-top: 15px;
  display: flex;
  flex-direction: reverse-row;
  justify-content: flex-end;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  display: flex;
  color: #333333;
  padding-top: 28px;
`;

const TitleAndButtons = styled.div`
  padding: 0 48px;
  max-width: 1200px;
  margin: auto;
  display: flex;
  justify-content: space-between;
`;

const BaseButton = styled.button`
  background-color: #cacaca;
  font-size: 14px;
  float: right;
  color: white;
  border-radius: 3px;
  border: none;
  padding: 0 20px;
  height: 50px;
`;

const SubmitButton = styled(BaseButton)`
  ${(props) => (props.disabled ? 'cursor: not-allowed' : '')};
  color: 'white';
  background-color: #00b88d;
`;

const MergeButton = styled(BaseButton)`
  background-color: #ffffff;
  margin-right: 16px;
  color: #4f4f4f;
  border: 0px;
`;
