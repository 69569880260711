import { ATSTabbedJobViewVacanciesTabTestIds } from 'data-testids/ATS';
import { DistrictVacanciesProps } from '../types';
import { DistrictVacancyContainer, Row } from '../styles';
import { SectionTitle } from 'features/TabbedJobView/styles';

export const DistrictVacancies: React.FC<DistrictVacanciesProps> = ({ numberOfVacancies }) => {
  return (
    <DistrictVacancyContainer data-testid={ATSTabbedJobViewVacanciesTabTestIds.DISTRICT_VACANCIES}>
      <Row>
        <SectionTitle
          variant="h3"
          data-testid={ATSTabbedJobViewVacanciesTabTestIds.DISTRICT_VACANCIES_TITLE}
        >
          District vacancies
        </SectionTitle>
      </Row>
      <Row data-testid={ATSTabbedJobViewVacanciesTabTestIds.NUMBER_OF_DISTRICT_VACANCIES}>
        <SectionTitle>Number of vacancies:</SectionTitle>&nbsp; {numberOfVacancies}
      </Row>
    </DistrictVacancyContainer>
  );
};
