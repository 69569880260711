import { getAxiosErrorMessageByStatusCode } from 'hooks/errorHandling/errorHandlingHooks';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Redirect } from 'react-router';
import { Alert } from 'sharedComponents/Alert';

import auth from 'utils/auth';
import { LandingForm } from '../components/LandingForm';
import { SuccessPage } from '../components/SuccessPage';
import { useQueryFetchCandidateForConnectATSIntegration } from './queries';
import { AdminLandingFormProps, FormState } from './types';
import {
  useCreateHiringRequestNewEndpointMutation,
  useCreateHiringRequestWithOldEndpointMutation,
} from './mutations';

const notFoundErrorMessage = 'Candidate not found. Please try again';

export const AdminLandingForm: React.FC<AdminLandingFormProps> = React.forwardRef(
  ({ candidateId }, _ref) => {
    const {
      data: candidateInfo,
      error,
      isError: isCandidateNotFoundError,
    } = useQueryFetchCandidateForConnectATSIntegration(candidateId);

    const {
      data: dataFromNewEndpoint,
      mutate: mutateFromNewEndpoint,
      isSuccess: isSuccessFromNewEndpoint,
    } = useCreateHiringRequestNewEndpointMutation();
    const {
      data: dataFromOldEndpoint,
      mutate: mutateFromOldEndpoint,
      isSuccess: isSuccessFromOldEndpoint,
    } = useCreateHiringRequestWithOldEndpointMutation();

    const errorMessage = getAxiosErrorMessageByStatusCode(error, {
      404: notFoundErrorMessage,
    });

    const isSuccess = isSuccessFromNewEndpoint || isSuccessFromOldEndpoint;
    const data = dataFromNewEndpoint || dataFromOldEndpoint;

    const methods = useForm<FormState>({
      defaultValues: {
        role: null,
        internalNote: '',
      },
    });
    const { watch } = methods;
    const role = watch('role');
    const roleId = auth.isSchoolAdmin() ? role?.district_role?.id : role?.id;

    const schoolsToSend = auth.isSchoolAdmin() ? [role?.school] : role?.schoolroles;

    const handleSubmit = (event) => {
      event.preventDefault();
      const submitHandler = methods.handleSubmit((formState) => {
        const formStateToSubmit = {
          candidate_id: candidateId,
          contact_methods: formState.contactMethods,
          internal_candidate_note_content: formState.internalNote,
          role_id: roleId,
          schools: schoolsToSend,
        };

        if (auth.hasConnectCandidateProcessing()) {
          return mutateFromNewEndpoint(formStateToSubmit);
        } else {
          return mutateFromOldEndpoint(formStateToSubmit);
        }
      });

      submitHandler(event);
    };

    if (!auth.hasConnectIntegrationModule()) {
      window.pendo?.track('invalid_connect_import_no_module_access', {
        eventTime: new Date().toISOString(),
      });
      const redirectPath = auth.isDistrictUser() ? '/district/home' : '/school/home';
      return <Redirect to={redirectPath} />;
    }

    const getLocationText = (): string => {
      const schools = data?.schools;

      if (auth.isSchoolAdmin() || (schools?.length ?? 0) > 0) {
        const schoolNames = schools.map((school) =>
          'school' in school ? school.school?.name : school?.name
        );
        return schoolNames.join(', ');
      }

      return 'District';
    };

    const hasInvalidCandidateId = isNaN(candidateId) || candidateId < 0;

    if (hasInvalidCandidateId) {
      return <Alert type="error">{notFoundErrorMessage}</Alert>;
    }

    return (
      <>
        {isCandidateNotFoundError && (
          <Alert type="error">{errorMessage || notFoundErrorMessage}</Alert>
        )}
        {!isSuccess && !isCandidateNotFoundError && (
          <FormProvider {...methods}>
            <LandingForm
              candidateId={candidateId}
              candidateEmail={candidateInfo?.email}
              candidateName={candidateInfo?.name}
              onSubmit={handleSubmit}
              roleId={roleId}
            />
          </FormProvider>
        )}
        {isSuccess && (
          <SuccessPage
            title={role?.title}
            candidateEmail={candidateInfo?.email}
            candidateName={candidateInfo?.name}
            locationText={getLocationText()}
            internalNote={data.internal_candidate_note_content}
            contactMethods={data.contact_methods}
          />
        )}
      </>
    );
  }
);

AdminLandingForm.displayName = 'AdminLandingForm';
