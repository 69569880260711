import { useState } from 'react';
import { InternalRoleNoteDisplayData as NoteData } from 'types';
import { useMutationEditInternalRoleNote } from '../queries';
import { Box, Button, styled } from '@mui/material';
import { PrimaryButton } from 'sharedComponents/Buttons';
import NoteInput from '../NoteInput';
import { NoteContainer } from './styles';
import { getTaggedUsers } from '../utils';
import { ATSTabbedJobViewInternalRoleNotesTestIds } from 'data-testids/ATS';
import { getAxiosErrorMessageByStatusCode } from 'hooks/errorHandling/errorHandlingHooks';
import { ErrorSnackbar } from 'sharedComponents/Snackbar';

type EditableNoteProps = {
  note: NoteData;
  onFinish: () => void;
  jobId: number;
};

const EditableNote: React.FC<EditableNoteProps> = ({ note, onFinish, jobId }) => {
  const [noteText, setNoteText] = useState<string>(note.text);
  const { mutate, isError, error, reset } = useMutationEditInternalRoleNote(jobId);
  const errorMessage = getAxiosErrorMessageByStatusCode(error, {
    500: 'Unable to save note. Please try again.',
  });

  const handleSave = () => {
    const taggedUsers = getTaggedUsers(noteText);
    mutate({ noteId: note.id, noteText, taggedUsers });
    onFinish();
  };

  return (
    <>
      <ErrorSnackbar
        message={errorMessage ?? 'Something went wrong'}
        open={isError}
        onClose={reset}
        duration={6000}
        dataTestId={ATSTabbedJobViewInternalRoleNotesTestIds.EDIT_NOTE_ERROR_SNACKBAR}
      />
      <NoteContainer data-testid={ATSTabbedJobViewInternalRoleNotesTestIds.EDITABLE_NOTE}>
        <NoteInput value={noteText} onChange={setNoteText} />
        <ButtonsContainer>
          <Button
            data-testid={ATSTabbedJobViewInternalRoleNotesTestIds.CANCEL_EDIT_NOTE_BUTTON}
            onClick={onFinish}
          >
            Cancel
          </Button>
          <PrimaryButton
            onClick={handleSave}
            disabled={!noteText}
            dataTestId={ATSTabbedJobViewInternalRoleNotesTestIds.SAVE_EDIT_NOTE_BUTTON}
          >
            Save
          </PrimaryButton>
        </ButtonsContainer>
      </NoteContainer>
    </>
  );
};

export default EditableNote;

const ButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'end',
  gap: theme.spacing(3),
  marginTop: theme.spacing(1.25),
}));
