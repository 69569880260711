import { useMemo } from 'react';
import { AdminUser } from 'types';

export const useReactQuillModules = (adminUsers: AdminUser[]) => {
  const modules = useMemo(() => {
    let displayUsers: Array<{ id: number; value: string }> = [];

    if (adminUsers) {
      displayUsers = adminUsers.map((user: AdminUser) => ({
        id: user.id,
        value: `${user.first_name} ${user.last_name}`,
      }));
    }

    return {
      mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ['@'],
        source: function (
          searchTerm: string,
          renderItem: (values: string[], searchTerm: string) => void,
          mentionChar: string
        ) {
          let values = [];
          if (mentionChar === '@') {
            values = displayUsers;
          }
          if (searchTerm.length === 0) {
            renderItem(values, searchTerm);
          } else {
            const matches = [];
            for (let i = 0; i < values?.length; i++) {
              if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) {
                matches.push(values[i]);
              }
            }
            renderItem(matches, searchTerm);
          }
        },
      },
    };
  }, [adminUsers]);

  return modules;
};
