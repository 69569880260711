import { useState } from 'react';
import { useQueryInternalRoleNotes } from '../queries';
import { Alert } from 'sharedComponents/Alert';
import EditableNote from './EditableNote';
import { TextButton } from 'sharedComponents/Buttons';
import ExistingNote from './ExistingNote';
import { Box, CircularProgress } from '@mui/material';
import { InternalRoleNoteDisplayData } from 'types';
import { getAxiosErrorMessageByStatusCode } from 'hooks/errorHandling/errorHandlingHooks';
import { ATSTabbedJobViewInternalRoleNotesTestIds } from 'data-testids/ATS';

const NOTES_PREVIEW_COUNT = 4;

const NoteList: React.FC<{
  jobId: number;
}> = ({ jobId }) => {
  const [noteIdBeingEdited, setNoteBeingEdited] = useState<number | null>(null);
  const [areNotesExpanded, setAreNotesExpanded] = useState<boolean>(false);

  const { data: notes, isLoading, isError, error } = useQueryInternalRoleNotes(jobId);
  const errorMessage = getAxiosErrorMessageByStatusCode(error);

  if (isLoading)
    return (
      <CircularProgress data-testid={ATSTabbedJobViewInternalRoleNotesTestIds.NOTE_LIST_LOADER} />
    );
  if (isError) {
    return (
      <Alert
        type="error"
        dataTestId={ATSTabbedJobViewInternalRoleNotesTestIds.NOTE_LIST_ERROR_ALERT}
      >
        {errorMessage ?? 'Something went wrong'}
      </Alert>
    );
  }
  if (notes.length === 0) {
    return (
      <Alert type="info" dataTestId={ATSTabbedJobViewInternalRoleNotesTestIds.NO_NOTES_INFO}>
        No notes have been added yet.
      </Alert>
    );
  }

  const notesToDisplay = areNotesExpanded ? notes : notes.slice(0, NOTES_PREVIEW_COUNT);

  const handleEditNote = (noteId: number) => {
    setNoteBeingEdited(noteId);
  };

  const renderNote = (note: InternalRoleNoteDisplayData) => {
    if (note.id === noteIdBeingEdited) {
      return (
        <EditableNote
          key={note.id}
          note={note}
          onFinish={() => setNoteBeingEdited(null)}
          jobId={jobId}
        />
      );
    }
    return <ExistingNote key={note.id} note={note} handleEdit={handleEditNote} jobId={jobId} />;
  };

  return (
    <Box>
      {notesToDisplay.map(renderNote)}
      <ExpandNotesButton
        onClick={() => setAreNotesExpanded((prev) => !prev)}
        visible={notes.length > NOTES_PREVIEW_COUNT}
        text={areNotesExpanded ? 'View fewer notes' : 'View all notes'}
      />
    </Box>
  );
};

type ExpandNotesButtonProps = {
  onClick: () => void;
  visible: boolean;
  text: string;
};

const ExpandNotesButton: React.FC<ExpandNotesButtonProps> = ({ onClick, visible, text }) => {
  if (!visible) return null;
  return (
    <Box>
      <TextButton
        dataTestId={ATSTabbedJobViewInternalRoleNotesTestIds.EXPAND_NOTES_BUTTON}
        onClick={onClick}
      >
        {text}
      </TextButton>
    </Box>
  );
};

export default NoteList;
