import { Alert, AlertTitle } from '@mui/material';
import { Snackbar } from './Snackbar';
import { SuccessSnackbarProps } from './types';

export const SuccessSnackbar: React.FC<SuccessSnackbarProps> = ({
  alertTitle,
  icon,
  message,
  onClose,
  open,
  severity = 'success',
  sx,
  variant = 'filled',
  dataTestId,
}) => {
  return (
    <Snackbar
      onClose={onClose}
      open={open}
      message={message}
      duration={3000}
      sx={sx}
      dataTestId={dataTestId}
    >
      <Alert onClose={onClose} severity={severity} icon={icon} variant={variant}>
        {alertTitle && <AlertTitle>{alertTitle}</AlertTitle>}
        {message}
      </Alert>
    </Snackbar>
  );
};
