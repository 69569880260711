import { useState, useEffect, useRef } from 'react';
import { convertIntegerToTime } from '../../../utils/util';

import styled from 'styled-components';
import IconRightCaret from './icons/icon_accordion_right.svg';
import IconDownCaret from './icons/icon_accordion_down.svg';
import AccordionCheckbox from './AccordionCheckbox';
import { space } from 'styled-system';

function Accordion(props) {
  const [expanded, setExpanded] = useState(props.expanded);
  const [setHeight, setHeightState] = useState(props.expanded ? props.height : '0px');
  const [completedStepCount, setCompletedStepCount] = useState(0);

  const content = useRef(null);

  function toggleAccordion() {
    setExpanded(!expanded);
    setHeightState(!expanded ? `${props.height}` : '0px');
  }

  useEffect(() => {
    const getCompletedStepCount = () => {
      // determine how many steps in a checklistItem have been completed
      const completedSteps = props.steps.filter((step) => step.completed);
      setCompletedStepCount(completedSteps.length);
    };
    // get completed step count of passed steps
    props.steps && getCompletedStepCount();
  }, [props.steps]);

  return (
    <AccordionContainer
      data-testid={props.testid}
      aria-expanded={expanded}
      expanded={expanded}
      className={`${props.className}`}
      style={props.styleOverrides}
      innerAccordion={props.innerAccordion}
      margin={['40px 0', '40px 0', '40px 0', '40px 60px']}
    >
      <AccordionHeader
        expanded={expanded}
        onClick={toggleAccordion}
        innerAccordion={props.innerAccordion}
      >
        <label>
          <AccordionCheckbox
            checked={props.completed}
            onChange={() => props.handleChange(props.id)}
          />
        </label>
        <AccordionTitle>{props.title}</AccordionTitle>
        {/* Render ChecklistItem step count and duration */}
        {props.steps ? (
          completedStepCount === props.steps.length ? (
            // all item steps completed
            <AccordionDuration completed={props.completed}>
              {`${props.steps.length} of ${props.steps.length} steps complete`}
            </AccordionDuration>
          ) : (
            // Item is partially completed OR item not started
            <AccordionDuration>
              {completedStepCount > 0
                ? `${completedStepCount} of ${props.steps.length} steps complete`
                : `${props.steps.length} ${props.steps.length === 1 ? 'step' : 'steps'} in ${
                    props.duration >= 60
                      ? convertIntegerToTime(props.duration)
                      : convertIntegerToTime(props.duration, false)
                  }`}
            </AccordionDuration>
          )
        ) : (
          // Render ChecklistItemStep duration
          <AccordionDuration completed={props.completed}>
            {props.completed
              ? 'Complete'
              : `${
                  props.duration >= 60
                    ? convertIntegerToTime(props.duration)
                    : convertIntegerToTime(props.duration, false)
                }`}
          </AccordionDuration>
        )}
        <AccordionButton>
          {expanded ? <Icon src={IconDownCaret} alt="" /> : <Icon src={IconRightCaret} alt="" />}
        </AccordionButton>
      </AccordionHeader>
      <AccordionContent>
        {/* THIS IS IMPORTANT FOR POSITIONING ACCORDION PANEL */}
        <AccordionInnerContent
          ref={content}
          expanded={expanded}
          style={{ maxHeight: `${setHeight}` }}
        >
          {props.children}
        </AccordionInnerContent>
      </AccordionContent>
    </AccordionContainer>
  );
}

export default Accordion;

const AccordionContainer = styled.div`
  ${space}

  display: flex;
  flex-direction: column;
  width: 100%;
  height: max-content;
  min-height: initial;
  // video player doesn't fit if width is smaller than 578px
  min-width: 578px;
  background: #ffffff;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 5px;

  ${({ innerAccordion }) =>
    innerAccordion &&
    `
    border: 1px solid #A6A7AD;
    width: 87%;
    margin: 40px 20px;

    // hover effect for inner accordion
    &:hover {
      border: 1px solid #00b88d;
  }
  `}

  // remove hover when innerAccordion is expanded
  ${({ expanded, innerAccordion }) =>
    expanded &&
    innerAccordion &&
    `
  &:hover {
    border: 1px solid #A6A7AD;
  }
  `};
`;

const AccordionHeader = styled.header`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid transparent;

  ${({ expanded, innerAccordion }) =>
    !expanded &&
    !innerAccordion &&
    `
  &:hover {
    border: 1px solid #00b88d;
  }
  `};

  // add bottom-border to non-inner accordion header when expanded
  ${({ expanded, innerAccordion }) =>
    expanded &&
    !innerAccordion &&
    `
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 1px solid #00b88d;
  `};
`;

const AccordionTitle = styled.h2`
  justify-self: start;
  align-self: center;
  flex: 1;
  text-align: left;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 26px;
  color: #444444;
`;

const AccordionContent = styled.div`
  background-color: white;
  overflow: hidden;
  height: max-content;
`;

// This inner div manages the nested accordion postioning
const AccordionInnerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  transition: max-height 0.4s ease;

  ${({ expanded }) =>
    expanded &&
    `
    height: 90%;
    margin-top: 40px;
    padding-bottom: 40px;
  `}
`;

const AccordionButton = styled.button`
  background-color: transparent;
  padding: 18px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
`;

const Icon = styled.img`
  max-width: 100%;
`;

const AccordionDuration = styled.span`
  align-self: center;
  text-align: center;

  ${({ completed }) =>
    completed &&
    `
    color: #00b88d;
  `}
`;
