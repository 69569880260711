import { useState, useEffect, useRef, useCallback } from 'react';
import { debounce } from 'lodash';
import { MapService, LatLng, Prediction } from '../../integrations/maps/mapService';
import { UseAutocompleteLocationProps } from './types';
import { ENVIRONMENT } from 'utils/constants/environment';
import { STATE_MAP_CONFIG, DEFAULT_MAP_CONFIG } from 'utils/constants/connect';

const getDefaultInitialLocation = (stateCode: string) => {
  const stateConfig = Object.values(STATE_MAP_CONFIG).find(
    (config) => config.stateCode === stateCode
  );

  return stateConfig.coordinates;
};

export function useAutocompleteLocation({
  mapService,
  radius,
  location,
  stateCode,
  debounceTimeMillis = ENVIRONMENT.CONNECT_LOCATION_AUTOCOMPLETE_DEBOUNCE_TIME_MILLIS,
}: UseAutocompleteLocationProps) {
  const [inputValue, setInputValue] = useState<string>(location);

  const [predictions, setPredictions] = useState<Prediction[]>([]);
  const [isInitializing, setIsInitializing] = useState<boolean>(false);
  const didInitializeMap = useRef<boolean>(false);

  const [geocodedLocation, setGeocodedLocation] = useState(null);

  const markerRef = useRef<ReturnType<MapService['createMarker']> | null>(null);
  const circleRef = useRef<ReturnType<MapService['createCircle']> | null>(null);
  const mapInstanceRef = useRef<any>(null);
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const isUpdatingMapRef = useRef(false);

  const updateMapOverlays = useCallback(
    (location: LatLng, radius): void => {
      if (!mapInstanceRef.current) {
        console.log('Map not yet initialized, storing location for later');
        return;
      }

      // Prevent redundant updates
      if (isUpdatingMapRef.current) {
        console.log('Skipping redundant map update - update already in progress');
        return;
      }

      console.log('Updating map overlays with location:', location, 'and radius:', radius);

      try {
        // Set the update flag
        isUpdatingMapRef.current = true;

        // Get direct access to the Google Maps instance
        const mapInstance = mapInstanceRef.current;

        // Remove existing marker if any
        if (markerRef.current) {
          markerRef.current.remove();
        }

        // Create new marker
        const marker = mapService.createMarker(location);

        // Store the new marker
        markerRef.current = marker;

        // Remove existing circle if any
        if (circleRef.current) {
          circleRef.current.remove();
        }

        // Create new circle
        circleRef.current = mapService.createCircle(location, radius);

        // Make sure objects are on the map
        if (typeof markerRef.current.attachToMap === 'function') {
          markerRef.current.attachToMap(mapInstance);
        }

        if (typeof circleRef.current.attachToMap === 'function') {
          circleRef.current.attachToMap(mapInstance);
        }

        // Center map on location
        mapInstance.setCenter(location);

        // KEY TECHNIQUE: Use circle bounds to determine zoom
        setTimeout(() => {
          try {
            const bounds = mapService.getCircleBounds(location, radius);
            if (bounds) {
              console.log('Fitting map to circle bounds');
              // Add a small padding to the bounds (in pixels)
              const padding = { top: 20, right: 20, bottom: 20, left: 20 };
              mapInstance.fitBounds(bounds, padding);
            }

            // Clear the update flag
            isUpdatingMapRef.current = false;
          } catch (e) {
            console.error('Error during bounds fitting:', e);
            isUpdatingMapRef.current = false;
          }
        }, 100); // Short delay to ensure circle is rendered
      } catch (error) {
        console.error('Error updating map overlays:', error);
        isUpdatingMapRef.current = false;
      }
    },
    [mapService]
  );

  const initializeMap = useCallback(async () => {
    if (isInitializing || didInitializeMap.current || !mapContainerRef.current) {
      console.log('Skipping map init - already initialized or in progress or no container');
      return;
    }

    setIsInitializing(true);

    try {
      console.log('Initializing map, storing instance...');
      // Only proceed if the container ref exists
      if (!mapContainerRef.current) {
        console.error('Map container element not found');
        setIsInitializing(false);
        return;
      }

      let center = getDefaultInitialLocation(stateCode);

      if (location) {
        const result = await mapService.geocodeAddress(location);

        if (result?.location) {
          center = result.location;
        }

        setGeocodedLocation(center);
      }

      const mapConfig = {
        ...DEFAULT_MAP_CONFIG,
        center: center,
      };

      const mapInstance = await mapService.initialize(mapContainerRef.current, mapConfig);

      mapInstanceRef.current = mapInstance;

      didInitializeMap.current = true;
    } catch (error) {
      console.error('Error initializing map:', error);
    } finally {
      setIsInitializing(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapService, updateMapOverlays, isInitializing, stateCode]);

  // Fetch address predictions
  const fetchPredictions = useCallback(
    async (input: string): Promise<void> => {
      if (!input || !input.trim()) {
        setPredictions([]);
        return;
      }

      try {
        // Ensure Google Maps API is loaded before attempting to fetch predictions
        if (typeof google === 'undefined' || !google.maps) {
          console.log('Google Maps API not loaded yet, waiting...');
          setTimeout(() => fetchPredictions(input), 500);
          return;
        }

        const results = await mapService.getPlacePredictions(input);
        setPredictions(results);
      } catch (error) {
        console.error('Error fetching predictions:', error);
        // Don't set empty predictions on error - keep the last valid ones
      }
    },
    [mapService]
  );

  // Debounced version of fetchPredictions
  /* eslint-disable react-hooks/exhaustive-deps */
  const debouncedFetchPredictions = useCallback(debounce(fetchPredictions, debounceTimeMillis), [
    fetchPredictions,
    debounceTimeMillis,
  ]);

  // Fetch predictions when input changes
  useEffect(() => {
    debouncedFetchPredictions(inputValue);
    return () => {
      debouncedFetchPredictions.cancel();
    };
  }, [inputValue, debouncedFetchPredictions]);

  useEffect(() => {
    return () => {
      if (markerRef.current) {
        markerRef.current.remove();
      }
      if (circleRef.current) {
        circleRef.current.remove();
      }
    };
  }, []);

  useEffect(() => {
    if (geocodedLocation) {
      updateMapOverlays(geocodedLocation, radius);
    }
  }, [geocodedLocation, radius]);

  useEffect(() => {
    const updateGeocode = async () => {
      if (didInitializeMap.current && location) {
        const result = await mapService.geocodeAddress(location);

        if (result?.location) {
          setGeocodedLocation(result.location);
        }
      }
    };

    updateGeocode();
  }, [location, didInitializeMap]);

  const resolveAddress = useCallback(
    async (address: string): Promise<string> => {
      const result = await mapService.geocodeAddress(address);

      return result?.address || address;
    },
    [mapService]
  );

  return {
    inputValue,
    setInputValue,
    predictions,
    resolveAddress,
    mapContainerRef,
    initializeMap,
    mapInstance: mapInstanceRef.current,
    radiusCircle: circleRef.current,
    geocodedLocation,
  };
}
