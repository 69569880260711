import { ChangeEvent } from 'react';
import { AxiosError } from 'axios';

import Stack from '@mui/material/Stack';
import { updateCandidateProfileResume } from 'utils/fileUpload';
import { checkFileNameAndSize, formatResumeFileName } from '../utils';

import { FileUpload } from 'sharedComponents/FileUpload';
import { QuestionLabel } from './ResumeAndCertificationsForm';
import { ResumeFormProps } from './types';
import { ConnectQualificationsFormDataTestIds } from 'data-testids/ConnectDataTestIds';

export const ResumeForm: React.FC<ResumeFormProps> = ({
  userFormData,
  setUserFormData,
  userDataIsLoading,
  resumeUploadError,
  setResumeUploadError,
  resumeIsUploading,
  setResumeIsUploading,
  pageDisplayInformation,
  doesUserHaveApplications,
}) => {
  const uploadResume = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      setResumeIsUploading(true);
      const { fileData, error } = checkFileNameAndSize(event);
      if (!error) {
        const response = await updateCandidateProfileResume(fileData);
        setUserFormData((prevFormData) => ({
          ...prevFormData,
          resume: formatResumeFileName(response.data.resume),
        }));
        setResumeUploadError('');
      } else {
        setResumeUploadError(error);
      }
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        setResumeUploadError(error.response.data.error);
      }
    } finally {
      setResumeIsUploading(false);
    }
  };

  return (
    <Stack spacing={1}>
      <QuestionLabel required={pageDisplayInformation.requiredFields.includes('resume')}>
        Upload your resume
      </QuestionLabel>
      <FileUpload
        dataTestId={ConnectQualificationsFormDataTestIds.UPLOAD_RESUME_TOOL}
        uploadedFileName={userFormData.resume}
        handleChange={uploadResume}
        errorMessage={resumeUploadError}
        setErrorMessage={setResumeUploadError}
        inputName="resume"
        isLoading={userDataIsLoading || resumeIsUploading}
        fileNameMaxWidth="240px"
        showUpdateResumeConfirmationModal={doesUserHaveApplications && !!userFormData?.resume}
      />
    </Stack>
  );
};
