import { Box, Skeleton, styled } from '@mui/material';

export const BackToJobsLinkSkeleton = styled(Skeleton)(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  paddingBottom: theme.spacing(2),
  width: '20%',
}));

export const CTAButtonSkeleton = styled(Skeleton)(({ theme }) => ({
  height: '50px',
  marginRight: theme.spacing(0.5),
  width: '90px',
  '&:last-child': {
    marginRight: 0,
  },
}));

export const DistrictInfoSkeleton = styled(Skeleton)(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  height: '30px',
  width: '50%',
}));

export const HeaderContainer = styled(Box)(({ theme }) => ({
  maxWidth: '1150px',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export const Left = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(0.5),
  width: '70%',
}));

export const Right = styled(Box)({
  alignItems: 'center',
  display: 'flex',
});

export const SkeletonContainer = styled(Box)(() => ({
  display: 'flex',
  height: '115px',
  justifyContent: 'space-between',
  maxWidth: '1150px',
}));

export const TitleSkeleton = styled(Skeleton)(({ theme }) => ({
  fontSize: theme.typography.h1.fontSize,
  width: '50%',
}));
