import tndoeStateLogo from 'assets/state_logos/tn-doe-logo.png';
import modoeStateLogo from 'assets/state_logos/mo-doe-logo.png';
import missouriValuePropBannerGraphic from 'assets/connect/state-value-prop-banner-graphics/mo-value-prop-banner-graphic.png';
import tennesseeValuePropBannerGraphic from 'assets/connect/state-value-prop-banner-graphics/tn-value-prop-banner-graphic.png';

export const nimbleConnectUrlFlag = 'connect';

export const CAROUSEL_SLIDE_TYPES = {
  IMAGE_WITH_TEXT: 'image_with_text',
  QUOTE: 'quote',
};

export const CONNECT_JOBBOARD_STATES = {
  INDIANA: {
    stateName: 'INDIANA',
    stateCode: 'IN',
    urlParam: 'indiana',
    logo: null,
    defaultMapLocation: 'Indiana',
    defaultMapUrl:
      'https://maps.googleapis.com/maps/api/staticmap?center=40.5512165%2C-85.60236429999999&zoom=6&size=565x114&maptype=roadmap&key=AIzaSyCJ6H3o-E-VR2tIiJBOsbw8T2RkfP0cukI&scale=2',
    label: 'Indiana',
    organizationName: 'IN Department of Education',
    valuePropBannerGraphic: missouriValuePropBannerGraphic,
  },
  MISSOURI: {
    stateName: 'MISSOURI',
    stateCode: 'MO',
    urlParam: 'missouri',
    logo: modoeStateLogo,
    defaultMapLocation: 'Missouri',
    defaultMapUrl:
      'https://maps.googleapis.com/maps/api/staticmap?center=37.9642529%2C-91.8318334&zoom=6&size=565x114&maptype=roadmap&key=AIzaSyCJ6H3o-E-VR2tIiJBOsbw8T2RkfP0cukI&scale=2',
    label: 'Missouri',
    organizationName: 'MO Department of Elementary & Secondary Education',
    valuePropBannerGraphic: missouriValuePropBannerGraphic,
  },
  TENNESSEE: {
    stateName: 'TENNESSEE',
    stateCode: 'TN',
    urlParam: 'tennessee',
    logo: tndoeStateLogo,
    defaultMapLocation: 'Tennessee',
    defaultMapUrl:
      'https://maps.googleapis.com/maps/api/staticmap?center=35.5174913%2C-86.5804473&zoom=6&size=565x114&maptype=roadmap&key=AIzaSyCJ6H3o-E-VR2tIiJBOsbw8T2RkfP0cukI&scale=2',
    label: 'Tennessee',
    organizationName: 'TN Department of Education',
    valuePropBannerGraphic: tennesseeValuePropBannerGraphic,
  },
};

interface StateMapConfig {
  stateCode: string;
  coordinates: {
    lat: number;
    lng: number;
  };
  mapConfig: {
    defaultZoom: number;
    discoveryZoom: number;
    preferencesZoom: number;
  };
}

export const STATE_MAP_CONFIG: { [key: string]: StateMapConfig } = {
  MISSOURI: {
    stateCode: CONNECT_JOBBOARD_STATES.MISSOURI.stateCode,
    coordinates: {
      lat: 38.3674, // Geographic center of Missouri (near Lebanon, MO)
      lng: -92.4787,
    },
    mapConfig: {
      defaultZoom: 6,
      discoveryZoom: 7,
      preferencesZoom: 7,
    },
  },
  TENNESSEE: {
    stateCode: CONNECT_JOBBOARD_STATES.TENNESSEE.stateCode,
    coordinates: {
      lat: 35.858, // Geographic center of Tennessee (near Murfreesboro, TN)
      lng: -86.3505,
    },
    mapConfig: {
      defaultZoom: 6,
      discoveryZoom: 7,
      preferencesZoom: 7,
    },
  },
};

export const DEFAULT_MAP_CONFIG = {
  center: { lat: 39.8283, lng: -98.5795 }, // US center
  zoom: 6,
  styles: [],
  disableDefaultUI: false,
  streetViewControl: false,
};
