import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom';

import auth from '../../utils/auth';
import { CONNECT_JOBBOARD_STATES } from 'utils/constants';
import { connectUrlParams } from 'features/Connect/features/JobDiscovery/constants';

const PROTECTED_CONNECT_ROUTES = [
  '/connect/profile',
  '/connect/qualifications',
  '/connect/preferences',
];

const AuthenticatedRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const location = useLocation();

  return (
    <Route
      {...rest}
      render={() => {
        if (!auth.loggedIn()) {
          const isProtectedConnectRoute = PROTECTED_CONNECT_ROUTES.some((route) =>
            location.pathname.startsWith(route)
          );

          const returnTo = isProtectedConnectRoute
            ? '/connect/profile'
            : `${location.pathname}/${location.search}`;

          const searchParams = new URLSearchParams(location.search);
          searchParams.set('return_to', returnTo);

          if (isProtectedConnectRoute && !searchParams.get(connectUrlParams.REDIRECT)) {
            searchParams.set(connectUrlParams.REDIRECT, CONNECT_JOBBOARD_STATES.MISSOURI.urlParam);
          }

          const search = searchParams.toString();

          return (
            <Redirect
              to={{
                pathname: isProtectedConnectRoute ? '/connect/signup' : '/login',
                search,
              }}
            />
          );
        }

        return <>{children}</>;
      }}
    />
  );
};

export default AuthenticatedRoute;
