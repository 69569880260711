import { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

import { Input } from 'ui-kit';
import { WhiteCheckmark } from 'ui-kit/icons';
import { PrimaryButton } from 'sharedComponents/Buttons';
import { useTheme } from '@mui/material';

export default function PasswordReset() {
  const [emailAddress, setEmailAddress] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const theme = useTheme();

  const submit = () => {
    try {
      checkForErrors();
    } catch (error) {
      setErrorMessage(error.message);
      return;
    }

    sendPasswordResetEmail();
  };

  const checkForErrors = () => {
    if (!emailAddress) {
      throw new Error('Please enter your email address');
    }
  };

  const sendPasswordResetEmail = () => {
    const data = {
      email: emailAddress,
    };
    axios
      .post('/api/user/send_password_reset_link/', data)
      .then(() => setSubmitted(true))
      .catch((err) => {
        if ('response' in err && err.response.status === 404) {
          setErrorMessage('Email address does not exist');
        } else if ('response' in err && err.response.status === 401) {
          setErrorMessage('This account has been deactivated');
        }
      });
  };

  return (
    <>
      <StyledInput
        hasError={errorMessage !== ''}
        type="text"
        value={emailAddress}
        placeholder="Email address"
        onChange={(e) => {
          setEmailAddress(e.target.value);
          setErrorMessage('');
          setSubmitted(false);
        }}
      />
      <PrimaryButton
        onClick={submit}
        sx={{
          marginTop: theme.spacing(2),
          width: '100%',
          height: '50px',
          fontSize: theme.typography.body2.fontSize,
        }}
        disabled={errorMessage !== ''}
      >
        {submitted ? <WhiteCheckmark height="31px" width="32px" /> : <span>Send Link</span>}
      </PrimaryButton>

      {submitted && <SubmittedText>A reset link has been sent to that email!</SubmittedText>}
      {!submitted && errorMessage && <SubmittedText>{errorMessage}</SubmittedText>}
    </>
  );
}

const SubmittedText = styled.div`
  position: absolute;
  bottom: 20px;
  font-size: 12px;
  font-weight: normal;
  color: #cc3340;
  width: 260px;
`;

const StyledInput = styled(Input)`
  text-indent: unset !important;
`;
