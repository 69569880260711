import NoteList from './NoteList';
import { Box, styled, useTheme } from '@mui/material';
import { TabbedJobViewChip } from 'features/TabbedJobView/components/TabbedJobViewChip';
import { useQueryInternalRoleNotes } from '../queries';
import { InternalRoleNoteDisplayData } from 'types';
import { ATSTabbedJobViewInternalRoleNotesTestIds } from 'data-testids/ATS';
import { SectionTitle } from 'features/TabbedJobView/styles';

const ExistingNotesSection: React.FC<{ jobId: number }> = ({ jobId }) => {
  const theme = useTheme();

  const { data: notesLength } = useQueryInternalRoleNotes<number>(jobId, {
    select: (notes: InternalRoleNoteDisplayData[]): number => notes?.length,
  });

  const getChipLabel = () => {
    const pluralizedNote = notesLength === 1 ? 'note' : 'notes';
    return `${notesLength} ${pluralizedNote}`;
  };

  return (
    <SectionContainer component="section">
      <SectionHeaderContainer>
        <SectionTitle
          variant="h2"
          sx={{ color: theme.palette.gray.dark }}
          data-testid={ATSTabbedJobViewInternalRoleNotesTestIds.EXISTING_NOTES_SECTION_TITLE}
        >
          Internal Notes
        </SectionTitle>
        {notesLength !== undefined && (
          <TabbedJobViewChip
            label={getChipLabel()}
            dataTestId={ATSTabbedJobViewInternalRoleNotesTestIds.NOTES_LENGTH_CHIP}
          />
        )}
      </SectionHeaderContainer>
      <NoteList jobId={jobId} />
    </SectionContainer>
  );
};

export default ExistingNotesSection;

const SectionHeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

const SectionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
}));
