import { SyntheticEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Divider, IconButton, List, ListItem, Typography, useTheme } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';

import { ATSTabbedJobViewNavigationTestIds } from 'data-testids/ATS';
import { DrawerHeader, MobileToolbar, TabDrawer, ToolbarHeader } from './styles';
import { MobileNavigationProps, TabValue } from '../types';
import auth from 'utils/auth';

export const MobileNavigation: React.FC<MobileNavigationProps> = ({
  bottomTabsData,
  onChange,
  shouldShowDivider,
  topTabsData,
}) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const history = useHistory();
  const theme = useTheme();

  const toggleDrawer = (): void => setMobileOpen(!mobileOpen);

  const onTabClick = (event: SyntheticEvent<Element, Event>, value: TabValue): void => {
    toggleDrawer();
    onChange(event, value);
  };

  const handleBackClick = (): void => {
    const backToJobsListLink = auth.isSchoolAdmin() ? '/school/jobslist' : '/district/jobslist';
    toggleDrawer();
    history.push(backToJobsListLink);
  };

  return (
    <MobileToolbar
      data-testid={ATSTabbedJobViewNavigationTestIds.MOBILE_NAVIGATION}
      variant="dense"
    >
      <ToolbarHeader>
        <IconButton
          color="inherit"
          data-testid={ATSTabbedJobViewNavigationTestIds.BACK_BUTTON}
          edge="start"
          onClick={handleBackClick}
        >
          <ArrowBackIosIcon />
        </IconButton>
        <Typography variant="h6" data-testid={ATSTabbedJobViewNavigationTestIds.MOBILE_TITLE}>
          View Job
        </Typography>
        <IconButton
          color="inherit"
          data-testid={ATSTabbedJobViewNavigationTestIds.HAMBURGER_BUTTON}
          edge="start"
          onClick={toggleDrawer}
        >
          <MenuIcon />
        </IconButton>
      </ToolbarHeader>

      <TabDrawer anchor="left" open={mobileOpen} onClose={toggleDrawer}>
        <List>
          <DrawerHeader>
            <Typography
              data-testid={ATSTabbedJobViewNavigationTestIds.MOBILE_DRAWER_TITLE}
              variant="body2"
              sx={{
                fontWeight: theme.typography.fontWeightMedium,
                lineHeight: theme.spacing(6),
              }}
            >
              View job
            </Typography>
            <IconButton
              data-testid={ATSTabbedJobViewNavigationTestIds.CLOSE_MOBILE_NAVIGATION_DRAWER}
              onClick={toggleDrawer}
            >
              <CloseIcon />
            </IconButton>
          </DrawerHeader>

          {topTabsData.map((item, index) => (
            <ListItem key={index} onClick={(e) => onTabClick(e, item.value)} value={item.value}>
              {item.label}
            </ListItem>
          ))}
          <Divider sx={{ display: shouldShowDivider ? 'block' : 'none' }} />
          {bottomTabsData.map((item, index) => (
            <ListItem key={index} onClick={(e) => onTabClick(e, item.value)} value={item.value}>
              {item.label}
            </ListItem>
          ))}
        </List>
      </TabDrawer>
    </MobileToolbar>
  );
};
