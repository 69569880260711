import { Box, styled, Typography } from '@mui/material';

export const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: theme.spacing(1),
}));

export const IconBackground = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  background: theme.palette.primary.fill,
  display: 'flex',
}));

export const IconContainer = styled(Box)(({ theme }) => ({
  paddingRight: theme.spacing(),
}));

export const IndividualQuestionContainer = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  border: `1px solid ${theme.palette.border.main}`,
  borderRadius: theme.spacing(1),
  minHeight: theme.spacing(11),
  marginBottom: theme.spacing(2),
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
}));

export const QuestionSetContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.white.darker,
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

export const QuestionHeader = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  paddingBottom: theme.spacing(1),
}));

export const QuestionText = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.subtitle.fontSize,
}));

export const QuestionTypeText = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMediumBold,
}));
