import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { AdminUser, InternalRoleNoteDisplayData } from 'types';

export const useQueryInternalRoleNotes = <TData = InternalRoleNoteDisplayData[]>(
  jobId: number,
  options?: Omit<
    UseQueryOptions<InternalRoleNoteDisplayData[], AxiosError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const queryFn = (): Promise<InternalRoleNoteDisplayData[]> =>
    axios
      .get<InternalRoleNoteDisplayData[]>(`/api/internal-role-notes/?role_id=${jobId}`)
      .then((res) => res.data);
  const queryKey = ['existingInternalRoleNotes', jobId];

  return useQuery<InternalRoleNoteDisplayData[], AxiosError, TData>({
    queryFn,
    queryKey,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options,
  });
};

export const useMutationDeleteInternalRoleNote = (jobId: number) => {
  const queryClient = useQueryClient();

  const mutationFn = async ({ noteId }: { noteId: number }) => {
    await axios.delete(`/api/internal-role-notes/${noteId}/`);
  };
  return useMutation({
    mutationFn,
    onSuccess: (_, variables) => {
      const existingNotes = queryClient.getQueryData<InternalRoleNoteDisplayData[]>([
        'existingInternalRoleNotes',
        jobId,
      ]);

      queryClient.setQueryData<InternalRoleNoteDisplayData[]>(
        ['existingInternalRoleNotes', jobId],
        existingNotes.filter((note) => note.id !== variables.noteId)
      );
    },
  });
};

type EditNoteVariables = { noteId: number; noteText: string; taggedUsers: number[] };

export const useMutationEditInternalRoleNote = (jobId: number) => {
  const queryClient = useQueryClient();

  const mutationFn = async ({ noteId, noteText, taggedUsers }: EditNoteVariables) => {
    await axios.patch(`/api/internal-role-notes/${noteId}/`, {
      text: noteText,
      tagged_users: taggedUsers,
      is_edited: true,
    });
  };

  return useMutation({
    mutationFn,
    onSuccess: (_, variables) => {
      const currentQueryData = queryClient.getQueryData<InternalRoleNoteDisplayData[]>([
        'existingInternalRoleNotes',
        jobId,
      ]);
      queryClient.setQueryData<InternalRoleNoteDisplayData[]>(
        ['existingInternalRoleNotes', jobId],
        currentQueryData.map((note) =>
          note.id === variables.noteId ? { ...note, text: variables.noteText } : note
        )
      );
    },
  });
};

export const useMutationCreateInternalRoleNote = (jobId: number) => {
  const queryClient = useQueryClient();
  const mutationFn = async ({ noteText, taggedUserIds }) => {
    return await axios
      .post(`/api/internal-role-notes/?role_id=${jobId}`, {
        text: noteText,
        tagged_users: taggedUserIds,
      })
      .then((res) => res.data);
  };
  return useMutation({
    mutationFn,
    onSuccess: (data) => {
      const existingNotes = queryClient.getQueryData<InternalRoleNoteDisplayData[]>([
        'existingInternalRoleNotes',
        jobId,
      ]);

      queryClient.setQueryData<InternalRoleNoteDisplayData[]>(
        ['existingInternalRoleNotes', jobId],
        [data, ...existingNotes]
      );
    },
  });
};

export const useQueryAdminsInDistrict = () => {
  const queryFn = () =>
    axios.get<AdminUser[]>('/api/user/get_all_admins_in_district/').then((res) => res.data);
  return useQuery<AdminUser[], AxiosError>({
    queryKey: ['adminsInDistrict'],
    queryFn,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};
