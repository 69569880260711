import { Box, styled, useTheme } from '@mui/material';
import { SectionTitle } from '../../styles';
import { useQueryGetScorecards } from '../queries';
import { TabbedJobViewChip } from '../../components/TabbedJobViewChip';
import { ScorecardDisplay } from './ScorecardDisplay';
import { ATSTabbedJobViewHiringTabTestIds } from 'data-testids/ATS';

const ScorecardSection: React.FC<{ jobId: number }> = ({ jobId }) => {
  const { data } = useQueryGetScorecards<number>(jobId, {
    select: (scorecards) => scorecards.length,
  });

  return (
    <SectionContainer>
      <SectionHeaderContainer>
        <SectionTitle
          variant="h2"
          data-testid={ATSTabbedJobViewHiringTabTestIds.SCORECARD_SECTION_TITLE}
        >
          Scorecards
        </SectionTitle>
        {data !== undefined && <ScorecardChip numberOfScorecards={data} />}
      </SectionHeaderContainer>
      <ScorecardDisplay jobId={jobId} />
    </SectionContainer>
  );
};

const ScorecardChip: React.FC<{ numberOfScorecards: number }> = ({ numberOfScorecards }) => {
  const theme = useTheme();
  const noScorecards = numberOfScorecards === 0;

  const getChipLabel = () => {
    if (noScorecards) {
      return 'None selected';
    }

    return `${numberOfScorecards} selected`;
  };

  const sx = noScorecards
    ? { backgroundColor: theme.palette.gray.light, color: theme.palette.text.secondary }
    : {};

  return (
    <TabbedJobViewChip
      label={getChipLabel()}
      dataTestId={ATSTabbedJobViewHiringTabTestIds.SCORECARD_CHIP}
      sx={sx}
    />
  );
};

export default ScorecardSection;

const SectionHeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

const SectionContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));
