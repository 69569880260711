import { Checkbox, FormControlLabel, styled } from '@mui/material';
import { formatTestId } from 'features/Connect/utils/connectUtils';
import { baseLabelStyles } from '../ModalFormOptions/utils/styles';
import { ModalOption } from '../ModalFormOptions/utils/types';

interface CheckboxOptionProps {
  option: ModalOption;
  selectedOption: string | string[];
  handleChange: (event: any) => void;
  dataTestId: string;
  isMultiSelect?: boolean;
}

export const CheckboxOption = ({
  option,
  selectedOption,
  handleChange,
  dataTestId,
  isMultiSelect = false,
}: CheckboxOptionProps) => {
  const isSelected = isMultiSelect
    ? selectedOption.includes(option.value)
    : selectedOption === option.value;

  const handleCheckboxChange = (event) => {
    handleChange(event);
  };

  return (
    <CheckboxOptionLabel
      data-testid={`${dataTestId}-${formatTestId(option.value)}-option-label`}
      key={option.value}
      value={option.value}
      control={
        <Checkbox
          data-testid={`${dataTestId}-${formatTestId(option.value)}-option-checkbox`}
          checked={isSelected}
          onChange={handleCheckboxChange}
          value={option.value}
        />
      }
      label={option.label}
    />
  );
};

const CheckboxOptionLabel = styled(FormControlLabel)(({ theme }) => ({
  ...baseLabelStyles(theme),
}));
