import { useState } from 'react';
import NoteInput from './NoteInput';
import { PrimaryButton } from 'sharedComponents/Buttons';
import { Box, styled, Typography, useTheme } from '@mui/material';
import { useMutationCreateInternalRoleNote } from './queries';
import { getTaggedUsers } from './utils';
import { SectionTitle } from '../styles';
import { getAxiosErrorMessageByStatusCode } from 'hooks/errorHandling/errorHandlingHooks';
import { ErrorSnackbar } from 'sharedComponents/Snackbar';
import { ATSTabbedJobViewInternalRoleNotesTestIds } from 'data-testids/ATS';
import { removeJSXFromText } from 'utils/text';

const AddNoteSection: React.FC<{ jobId: number }> = ({ jobId }) => {
  const theme = useTheme();

  const [noteText, setNoteText] = useState('');
  const { mutate, error, isError, reset } = useMutationCreateInternalRoleNote(jobId);
  const errorMessage = getAxiosErrorMessageByStatusCode(error);

  const handleAddNote = () => {
    const taggedUsers = getTaggedUsers(noteText);
    mutate({ noteText, taggedUserIds: taggedUsers });
    setNoteText('');
  };

  return (
    <>
      <ErrorSnackbar
        message={errorMessage}
        open={isError}
        onClose={reset}
        duration={6000}
        dataTestId={ATSTabbedJobViewInternalRoleNotesTestIds.ADD_NOTE_ERROR_SNACKBAR}
      />
      <SectionContainer>
        <SectionTitle
          variant="h2"
          sx={{ color: theme.palette.gray.dark, marginBottom: theme.spacing(1) }}
          data-testid={ATSTabbedJobViewInternalRoleNotesTestIds.ADD_NOTE_SECTION_TITLE}
        >
          Add a Note
        </SectionTitle>
        <HelpText data-testid={ATSTabbedJobViewInternalRoleNotesTestIds.ADD_NOTE_HELP_TEXT}>
          Mention people using &quot;@&quot;
        </HelpText>
        <NoteInput value={noteText} onChange={setNoteText} />
        <ButtonContainer>
          <PrimaryButton
            onClick={handleAddNote}
            disabled={removeJSXFromText(noteText).length === 0}
            dataTestId={ATSTabbedJobViewInternalRoleNotesTestIds.ADD_NOTE_BUTTON}
          >
            Add Note
          </PrimaryButton>
        </ButtonContainer>
      </SectionContainer>
    </>
  );
};

export default AddNoteSection;

const SectionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  marginTop: theme.spacing(4),
}));

const ButtonContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'end',
});

const HelpText = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  color: theme.palette.gray.medium,
}));
