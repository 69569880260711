import styled from 'styled-components';
import { nimbleTheme } from 'theme';
import auth from 'utils/auth';
import { user_type } from 'utils/enums';
import { getTagProperty } from '../util';
import { ATSCandidateTagDataTestIds } from 'data-testids/ATS';

const USERTYPE = user_type().reduce((obj, item) => {
  obj[item.value] = item.key;
  return obj;
}, {});

function CustomTag({ tag, parentId, removeTag, isAssignedView = false, dataTestId }) {
  const tagColor = getTagProperty(tag);

  const validateUserType = () => {
    const userType = auth.getUser().profile.user_type;

    if (userType === USERTYPE.district_user || userType === USERTYPE.school_admin) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <Tag backgroundColor={tagColor} data-testid={dataTestId}>
      <TagLabel data-testid={ATSCandidateTagDataTestIds.LABEL}>{tag.label}</TagLabel>
      {/* Render removal icon if viewing the tag in profile and valid user type */}
      {isAssignedView && validateUserType() && (
        <RemoveTagIcon
          data-testid={ATSCandidateTagDataTestIds.REMOVE_ICON}
          backgroundColor={tagColor}
          onClick={() => removeTag(parentId)}
        >
          &#10005;
        </RemoveTagIcon>
      )}
    </Tag>
  );
}

export default CustomTag;

const TagLabel = styled.p`
  font-size: 100%;
  font-weight: 400;
  padding: 15.5px;
  overflow: visible;
  white-space: nowrap;
  text-overflow: wrap;
  flex-grow: 2;
  color: rgba(0, 0, 0, 0.6);

  @media print {
    font-size: ${nimbleTheme.typography.print.fontSize};
    padding: 0;
  }
`;

const Tag = styled.div`
  height: 32px;
  padding: 12px;
  text-align: center;
  width: fit-content;
  min-width: min-content;
  margin: 5px;
  line-height: 20px;
  background-color: ${(props) => props.backgroundColor};

  display: flex;
  align-items: center;
  justify-content: space-around;

  @media print {
    height: inherit;
    margin: 0;
    padding: 0;

    &:after {
      content: ', ‎';
    }

    &:last-child {
      &:after {
        content: '';
      }
    }
  }
`;

const RemoveTagIcon = styled.span`
  font-size: 14px;
  cursor: pointer;
  margin-right: 10px;
  color: #444;

  @media print {
    display: none;
  }
`;
