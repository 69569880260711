import { TableTitle } from 'features/TabbedJobView/VacanciesTab/styles';
import { BasicTable, BasicTableColumn } from 'sharedComponents/Table/BasicTable';
import { useQueryGetScorecards } from '../queries';
import { ScorecardDataWithQuestionCount, ScorecardRow, ScorecardRowKey } from '../types';
import { getAxiosErrorMessageByStatusCode } from 'hooks/errorHandling/errorHandlingHooks';
import { Box, CircularProgress, styled } from '@mui/material';
import { constructScorecardRows, getScorecardsWithQuestionCount } from '../utils';
import { Alert } from 'sharedComponents/Alert';
import { ATSTabbedJobViewHiringTabTestIds } from 'data-testids/ATS';

export const ScorecardDisplay: React.FC<{ jobId: number }> = ({ jobId }) => {
  const { data, isLoading, isError, error } = useQueryGetScorecards<
    ScorecardDataWithQuestionCount[]
  >(jobId, {
    select: (scorecards) => getScorecardsWithQuestionCount(scorecards),
  });
  const errorMessage = getAxiosErrorMessageByStatusCode(error);

  if (isLoading) {
    return <CircularProgress data-testid={ATSTabbedJobViewHiringTabTestIds.SCORECARD_LOADER} />;
  }

  if (isError) {
    return (
      <Alert type="error" dataTestId={ATSTabbedJobViewHiringTabTestIds.SCORECARD_ALERT}>
        {errorMessage ?? 'Something went wrong'}
      </Alert>
    );
  }

  if (data.length === 0) return null;

  const rows = constructScorecardRows(data);
  const columns = getScorecardColumns();

  return (
    <TableContainer data-testid={ATSTabbedJobViewHiringTabTestIds.SCORECARD_TABLE_CONTAINER}>
      <BasicTable<ScorecardRow, BasicTableColumn<ScorecardRowKey>> rows={rows} columns={columns} />
    </TableContainer>
  );
};

const getScorecardColumns = (): BasicTableColumn<ScorecardRowKey>[] => {
  const fieldToTitle: Omit<{ [k in ScorecardRowKey]: string }, 'id' | 'dataTestId' | 'sx'> = {
    title: 'Scorecards',
    view_permissions: 'Permissions',
    questionCount: 'Number of questions',
  };

  return Object.keys(fieldToTitle).map((field, index) => ({
    id: index,
    content: (
      <ColumnHeader
        dataTestId={`${ATSTabbedJobViewHiringTabTestIds.SCORECARD_TABLE_COLUMN_HEADER}-${field}`}
        title={fieldToTitle[field]}
      />
    ),
    field: field as ScorecardRowKey,
  }));
};

const ColumnHeader: React.FC<{ dataTestId: string; title: string }> = ({ dataTestId, title }) => (
  <TableTitle data-testid={dataTestId}>{title}</TableTitle>
);

const TableContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  '& > .MuiPaper-elevation': {
    boxShadow: 'none',
  },
}));
