import { SelectAllListItem } from 'sharedComponents/List/SelectAllListWithRHF';

export const contactOptions: SelectAllListItem[] = [
  {
    label: 'Email',
    id: 0,
  },
  {
    label: 'Phone call',
    id: 1,
  },
  {
    label: 'Virtual interview',
    id: 2,
  },
  {
    label: 'Onsite interview',
    id: 3,
  },
];
