import { Box } from '@mui/material';

import { Bottom, CandidateFeedbackRowContainer, Top, TopLeft } from './styles';
import { ReviewerAndCompletionStatus } from './ReviewerAndCompletionStatus';
import { CandidateFeedbackRowProps } from '../types';
import { CompletionChip } from './CompletionChip';
import { RowCTA } from './RowCTA';
import { TemplateAndVisibility } from './TemplateAndVisibility';
import { FeedbackStatus } from './Statuses/FeedbackStatus';
import { ATSConnectHiringRequestCandidateFeedbackTestIds } from 'data-testids/ATS';

export const CandidateFeedbackRow: React.FC<CandidateFeedbackRowProps> = ({ data }) => {
  const { submitted_by: submittedBy, created, scorecard_complete: isScorecardComplete } = data;

  const isComplete = isScorecardComplete;
  const amAuthorOfFeedback = true;

  return (
    <CandidateFeedbackRowContainer
      data-testid={ATSConnectHiringRequestCandidateFeedbackTestIds.MODAL_ROW}
    >
      <Top>
        <TopLeft>
          <CompletionChip isComplete={isComplete} />
          <Box sx={{ width: '100%' }}>
            <ReviewerAndCompletionStatus
              date={created}
              isComplete={isComplete}
              submittedBy={submittedBy}
            />

            <Bottom>
              {isComplete && <FeedbackStatus data={data} />}
              <TemplateAndVisibility template={data.template} />
            </Bottom>
          </Box>
        </TopLeft>
        {amAuthorOfFeedback && <RowCTA isComplete={isComplete} />}
      </Top>
    </CandidateFeedbackRowContainer>
  );
};
