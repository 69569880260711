import { Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ComponentsLoadingSpinnerTestIds } from 'data-testids/Components';

interface LoadingSpinnerProps {
  dataTestId: string;
  color?: 'primary' | 'secondary' | 'inherit';
  size?: number | string;
  style?: React.CSSProperties;
  sx?: React.CSSProperties;
}

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  dataTestId,
  color = 'inherit',
  size,
  style,
  sx,
}) => {
  return (
    <LoadingSpinnerContainer
      data-testid={ComponentsLoadingSpinnerTestIds.SPINNER_CONTAINER}
      style={style}
    >
      <CircularProgress
        color={color}
        size={size}
        aria-label="loading-spinner"
        data-testid={dataTestId}
        sx={sx}
      />
    </LoadingSpinnerContainer>
  );
};

const LoadingSpinnerContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
});
