import MuiToggleButton from '@mui/material/ToggleButton';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledSecondaryButton = styled(Button)({
  backgroundColor: 'white',
});

export const StyledTextButton = styled(Button)({
  color: '#3A3A3A',
});

export const StyledToggleButton = styled(MuiToggleButton)({
  backgroundColor: 'white',
  color: 'rgb(0, 128, 96)',
  ':hover': {
    transition: '0.3s',
    backgroundColor: 'rgba(0, 128, 96, 0.04)',
  },

  '&.Mui-selected': {
    color: 'white',
    backgroundColor: 'rgb(0, 128, 96)',
  },

  '&.Mui-selected:hover': {
    backgroundColor: '#429f80',
    transition: '0.3s',
  },
});

export const primaryButtonOverrideSx = (theme) => ({
  borderRadius: theme.spacing(1),
  fontWeight: theme.typography.fontWeightMediumBold,
  paddingBottom: theme.spacing(1),
  paddingTop: theme.spacing(1),
});

export const secondaryButtonOverrideSx = (theme) => ({
  border: `1px solid ${theme.palette.gray.darker}`,
  borderRadius: theme.spacing(1),
  color: theme.palette.gray.dark,
  fontWeight: theme.typography.fontWeightMediumBold,
  paddingBottom: theme.spacing(1),
  paddingTop: theme.spacing(1),
});
