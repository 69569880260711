import { useTheme } from '@mui/material';

import { AdminLandingForm } from '../ImportForm/AdminLandingForm';
import { CloseButton } from 'sharedComponents/Buttons/CloseButton';
import { HiringRequestsFormModalProps } from '../ImportForm/types';
import { Modal } from 'sharedComponents/Modal';
import { ModalCloseButtonContainer } from '../ImportForm/styles';

export const HiringRequestsFormModal: React.FC<HiringRequestsFormModalProps> = ({
  candidateId,
  handleClose,
  isOpen,
}) => {
  const theme = useTheme();

  return (
    <Modal
      bodySx={{
        margin: 0,
        padding: theme.spacing(4),
        width: '500px',
        maxHeight: '90%',
        overflow: 'scroll',
      }}
      closeButton={
        <ModalCloseButtonContainer>
          <CloseButton onClick={handleClose} size="small" />
        </ModalCloseButtonContainer>
      }
      borderRadius={theme.shape.borderRadius * 2}
      title={null}
      open={isOpen}
      onClose={handleClose}
      childrenSx={{ marginTop: 0 }}
    >
      <AdminLandingForm candidateId={candidateId} />
    </Modal>
  );
};
