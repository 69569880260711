import { Box, Hidden, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SchoolCardLoadingSkeleton = () => {
  return (
    <SchoolCardSkeletonContainer>
      {/* Desktop Header */}
      <Hidden smDown>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Box>
            <Skeleton variant="text" width={200} height={28} sx={{ mb: 1 }} />
            <Skeleton variant="text" width={150} height={24} />
          </Box>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Skeleton variant="rounded" width={32} height={32} />
            <Skeleton variant="rounded" width={120} height={32} />
          </Box>
        </Box>
      </Hidden>

      {/* Mobile Header */}
      <Hidden smUp>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Box sx={{ flex: 1 }}>
            <Skeleton variant="text" width="100%" height={24} sx={{ mb: 1, maxWidth: 180 }} />
            <Skeleton variant="text" width="80%" height={20} />
          </Box>
          <Skeleton variant="rounded" width={32} height={32} sx={{ ml: 1 }} />
        </Box>
      </Hidden>

      {/* Mobile Principal Info */}
      <Hidden smUp>
        <Box sx={{ display: 'flex', gap: 1, mb: 2, alignItems: 'center' }}>
          <Skeleton variant="rounded" width={32} height={32} />
          <Skeleton variant="text" width={180} height={24} />
          <Skeleton variant="rounded" width={80} height={32} />
        </Box>
      </Hidden>

      {/* Desktop Principal Info */}
      <Hidden smDown>
        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
          <Skeleton variant="rounded" width={32} height={32} />
          <Skeleton variant="text" width={200} height={24} />
        </Box>
      </Hidden>

      {/* School Info Highlights - Responsive */}
      <Box
        sx={{
          display: 'flex',
          gap: { xs: 1, sm: 2 },
          flexWrap: { xs: 'wrap', sm: 'nowrap' },
        }}
      >
        <Skeleton variant="text" width={120} height={24} />
        <Skeleton variant="text" width={120} height={24} />
        <Skeleton variant="text" width={120} height={24} />
      </Box>
    </SchoolCardSkeletonContainer>
  );
};

const SchoolCardSkeletonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(4),
  marginBottom: theme.spacing(2),
  borderRadius: theme.shape.borderRadius * 3,
  border: `1px solid ${theme.palette.gray.light}`,
  background: theme.palette.white.main,
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2.5, 2, 2),
    gap: theme.spacing(1),
  },
}));
