import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { Activity } from 'features/JobActivityFeed/types';
import jobActivityFeed from 'api/jobActivityFeed';

export const useQueryGetActivityFeedDataByJobId = (
  jobId: number,
  options?: Omit<UseQueryOptions<Activity[], AxiosError>, 'queryKey' | 'queryFn'>
): UseQueryResult<Activity[], AxiosError> => {
  return useQuery({
    queryKey: ['tabbedJobViewActivityFeedByJobId', jobId],
    queryFn: () => jobActivityFeed.getAllActivity(jobId).then((res) => res),
    enabled: Number.isInteger(jobId),
    ...options,
  });
};
