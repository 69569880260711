import { Box, styled, Typography } from '@mui/material';

export const CandidatesListContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

export const CandidateRequestorInfoContainer = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(3.5)} ${theme.spacing(3)}`,
  paddingRight: 0,
  width: '37.5%',
  [theme.breakpoints.down('md')]: {
    marginRight: 0,
    padding: theme.spacing(2),
    width: '100%',
  },
}));

export const CandidateRow = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  border: `1px solid ${theme.palette.gray.darker}`,
  borderRadius: theme.shape.borderRadius * 2,
  display: 'flex',
  marginBottom: theme.spacing(3),
  maxWidth: theme.breakpoints.values.mdlg,
  minHeight: '121px',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

export const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.white.darker,
  height: '100%',
  padding: theme.spacing(4),
}));

export const Date = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.tertiary,
  paddingLeft: theme.spacing(1),
  [theme.breakpoints.down('lg')]: {
    paddingLeft: 0,
  },
}));

export const DateAndName = styled(Box)(({ theme }) => ({
  color: theme.palette.text.primary,
  display: 'flex',
  justifyContent: 'start',
  marginTop: theme.spacing(0.5),
  [theme.breakpoints.down('lg')]: {
    alignItems: 'start',
    flexDirection: 'column',
    justifyContent: 'start',
    marginTop: 0,
  },
}));

export const Detail = styled(Typography)(({ theme }) => ({
  color: theme.palette.gray.dark,
  fontWeight: theme.typography.fontWeightMedium,
}));

export const JobInfoContainer = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(3.5)} ${theme.spacing(3)}`,
  paddingRight: 0,
  width: '35%',
  [theme.breakpoints.down('md')]: {
    marginRight: 0,
    padding: `0 ${theme.spacing(2)}`,
    width: '100%',
  },
}));

export const Notes = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  marginLeft: theme.spacing(2),
}));

export const Status = styled(Box)({
  alignItems: 'center',
  display: 'flex',
});

export const StatusAndNotes = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  marginTop: theme.spacing(0.5),
}));

export const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.black.dark,
  fontWeight: theme.typography.fontWeightMedium,
}));
