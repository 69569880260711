import { useState } from 'react';
import { Redirect, useLocation } from 'react-router';

import { ATSConnectHiringRequestTestIds } from 'data-testids/ATS';
import { ConnectCandidateRowSkeleton } from './components/ConnectCandidateRowSkeleton';
import { ConnectCandidatesList } from './components/ConnectCandidatesList';
import { Container, Title } from './styles';
import { HiringRequestsFormModal } from './HiringRequestsFormModal';
import { useQueryGetHiringRequestListData } from './queries';
import auth from 'utils/auth';
import { Alert } from 'sharedComponents/Alert';
import { getAxiosErrorMessageByStatusCode } from 'hooks/errorHandling/errorHandlingHooks';

export const HiringRequests = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const candidateId = Number(queryParams.get('candidate_id'));
  const [isOpen, setIsOpen] = useState(Number.isInteger(candidateId) && candidateId > 0);
  const districtId = auth.getUser()?.profile.district.id;

  const { data, error, isError, isLoading } = useQueryGetHiringRequestListData(districtId);

  const handleClose = () => setIsOpen(false);

  if (!auth.hasConnectCandidateProcessing()) {
    const redirectPrefix = auth.isSchoolAdmin() ? '/school' : '/district';

    return <Redirect to={`${redirectPrefix}/home/`} />;
  }

  if (isError) {
    const message = getAxiosErrorMessageByStatusCode(error);
    return <Alert type="error">{message}</Alert>;
  }

  return (
    <>
      <Container>
        <Title data-testid={ATSConnectHiringRequestTestIds.HIRING_REQUEST_TITLE} variant="h2">
          Connect candidates
        </Title>
        {isLoading && !data ? (
          <ConnectCandidateRowSkeleton />
        ) : (
          <ConnectCandidatesList results={data?.results} />
        )}
      </Container>
      <HiringRequestsFormModal
        isOpen={isOpen}
        candidateId={candidateId}
        handleClose={handleClose}
      />
    </>
  );
};
