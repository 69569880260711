import { Box, styled, Theme, Typography } from '@mui/material';
import { Alert } from 'sharedComponents/Alert';
import { useTheme } from '@mui/material';

export const PreferencesAlertMessage: React.FC = () => {
  const theme = useTheme();

  return (
    <Alert sx={AlertStyles(theme)} icon={false}>
      <TextContainer>
        <BoldWeightText>Get matched with schools </BoldWeightText>
        <RegularWeightText>
          that fit your preferences! Principals will email if interested — check your inbox and
          respond to stay opted in.
        </RegularWeightText>
      </TextContainer>
    </Alert>
  );
};

const TextContainer = styled(Box)(({ theme }) => ({
  display: 'inline',
  gap: theme.spacing(0.5),
}));

const BoldWeightText = styled('span')(({ theme }) => ({
  color: theme.palette.info.medium,
  fontSize: theme.typography.body2.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: '24px',
  display: 'inline',
}));

const RegularWeightText = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.medium,
  fontSize: theme.typography.body2.fontSize,
  fontWeight: theme.typography.fontWeightRegular,
  lineHeight: '24px',
  display: 'inline',
}));

const AlertStyles = (theme: Theme) => ({
  borderAlignment: 'inside',
  borderRadius: theme.shape.borderRadius * 0.5,
  padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifySelf: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    height: 'auto',
  },
});
