import {
  BackToJobsLinkSkeleton,
  CTAButtonSkeleton,
  DistrictInfoSkeleton,
  Left,
  Right,
  SkeletonContainer,
  TitleSkeleton,
} from './styles';

export const TabbedJobViewHeaderSkeleton = () => {
  return (
    <SkeletonContainer>
      <Left>
        <BackToJobsLinkSkeleton variant="text" />
        <TitleSkeleton variant="text" />
        <DistrictInfoSkeleton variant="text" />
      </Left>
      <Right>
        <CTAButtonSkeleton variant="text" />
        <CTAButtonSkeleton variant="text" />
        <CTAButtonSkeleton variant="text" />
      </Right>
    </SkeletonContainer>
  );
};
