import { Box, useTheme } from '@mui/material';

import { ATSConnectHiringRequestCandidateFeedbackTestIds } from 'data-testids/ATS';
import { ReviewerAndCompletionStatusProps } from '../types';
import { SubmittedByTitle } from './styles';
import { getCompletionText } from './utils';
import { ScorecardProgressStatus } from './Statuses/Statuses';

export const ReviewerAndCompletionStatus: React.FC<ReviewerAndCompletionStatusProps> = ({
  date,
  isComplete,
  submittedBy,
}) => {
  const theme = useTheme();
  const completionText = getCompletionText(isComplete, date);

  return (
    <Box>
      <SubmittedByTitle
        data-testid={ATSConnectHiringRequestCandidateFeedbackTestIds.SUBMITTER_NAME}
      >
        {submittedBy.name}
      </SubmittedByTitle>
      {!isComplete && (
        <ScorecardProgressStatus
          sx={{ marginTop: theme.spacing(2) }}
          completionText={completionText}
        />
      )}
    </Box>
  );
};
