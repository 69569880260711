import { questionType } from '../../utils/enums';
import { ReactComponent as TextIcon } from 'assets/icon-pen-tip.svg';
import { ReactComponent as YesNoIcon } from 'assets/icon-check-with-circle.svg';
import { ReactComponent as NimbleLogoBlack } from 'assets/nimble-logo-black.svg';
import { ReactComponent as MultipleChoiceIcon } from 'assets/icon-multiple-choice.svg';
import { ReactComponent as AttachmentIcon } from 'assets/icon-paperclip-black.svg';
import { ReactComponent as VideoIcon } from 'assets/icon-video.svg';
import { SchoolPreferencesQuestionIcon } from '../../ui-kit/icons';
import { CheckmarkBlackBox } from '../../ui-kit/icons';
import { QuestionType } from 'utils/constants';
import { School } from '../../types/types';
import { Question, ApplicationStatus } from 'types';

export const blankQuestion = {
  id: null,
  text: '',
  is_visible_external: true,
  is_required_external: false,
  is_visible_internal: true,
  is_required_internal: false,
  // multiple choice options
  mc_options: ['', ''],
  // when actually implementing knockout questions, we'll probably have to make a new
  // multiple choice object that has the answer and a foreign key to the status
  // it should go to. For now (demo purposes) we'll just add a new list in state
  // to keep track of the related statuses.
  demo_mc_options: [
    {
      optionText: '',
      knockoutStatus: {},
    },
    {
      optionText: '',
      knockoutStatus: {},
    },
  ],
  multi_select: false,
  description: '',
  is_required: true,
  is_automatic_list: true,
  school_choices: [],
  is_knockout_question: false,
  title: null,
  isShowingEditOptions: false,
  prompt: null,
  localId: null,
  question_type: null,
  nimblequestion_id: null,
  is_district_default: false,
  is_legal: false,
  district: null,
  source: null,
  hint: '',
  question: null,
  autotags: [],
};

export const questionMapping = {
  /** mapping between question type and intended copy */
  [questionType.yes_no]: {
    icon: YesNoIcon,
    header: 'Yes/No Question',
    info: 'Candidate can select "yes" or "no"',
    instruction: 'Type yes/no question here:',
    placeholder: 'E.g. Do you like working with kids?',
  },
  [questionType.open_response]: {
    icon: TextIcon,
    header: 'Text Question',
    info: 'Candidate can respond in free form text',
    instruction: 'Type text question here:',
    placeholder: 'E.g. What is your pedagogical approach?',
  },
  [questionType.text_question_model]: {
    icon: TextIcon,
    header: 'Text Question',
    info: 'Candidate can respond in free form text',
    instruction: 'Type text question here:',
    placeholder: 'E.g. What is your pedagogical approach?',
  },
  [questionType.nimble]: {
    icon: NimbleLogoBlack,
    header: 'Nimble Question',
    info: 'Candidate can respond in free form text',
    instruction: 'Select a Nimble question here:',
    placeholder: 'Select a question',
    options: 'questionBank',
    optionText: 'question',
  },
  [questionType.multiple_choice]: {
    icon: MultipleChoiceIcon,
    header: 'Multiple Choice Question',
    info: 'Candidate can select one option',
    info2: 'Candidate can select multiple options',
    instruction: 'Type multiple choice question here:',
    placeholder: 'E.g. What is your pedagogical approach?',
  },
  [questionType.attachment]: {
    icon: AttachmentIcon,
    header: 'Attachment',
    info: 'Candidate can upload a file',
    instruction: 'Attachment name',
    placeholder: 'e.g. cover letter',
    optionText: 'title',
  },
  [questionType.videoLink]: {
    icon: VideoIcon,
    header: 'Video Link Question',
    info: 'Candidate can add a link to an interview video',
    instruction: 'Video name',
    placeholder: 'e.g. "Teaching demonstration video"',
    optionText: 'title',
  },
  [questionType.schoolPreferences]: {
    icon: SchoolPreferencesQuestionIcon,
    header: 'School Preferences Question',
    info: 'Candidate can select schools they are interested in',
  },
  [questionType.statementCheckbox]: {
    icon: CheckmarkBlackBox,
    header: 'Statement Checkbox',
    info: 'Asks the candidate to check the box to agree to a statement',
    placeholder:
      'e.g. By submitting this application I agree to allow the district to complete a background check',
  },
};

export type JobQuestionModalProps = {
  questionData: Question | null;
  show: boolean;
  onHide: () => void;
  type: QuestionType;
  questionBank: Question[];
  onSaveQuestion: (arg0: Question, arg1: QuestionType) => void;
  schoolOptions: School[];
  schools: School[];
  internal_requirements_specified: boolean;
  applicationStatuses: ApplicationStatus[];
  handleTagModalOpen?: () => void;
  shouldShowTagModal?: boolean;
  handleTagModalClose?: () => void;
};
