import { useState, useCallback } from 'react';
import styled from 'styled-components';
import { flexbox, layout } from 'styled-system';
import axios from 'axios';

import { scorecardQType } from 'utils/enums';
import ErrorText from 'components/errortext';

import ScorecardEditorQuestions from 'components/ScorecardModal/ScorecardEditor/ScorecardEditorQuestions';

import scorecardAPI from 'api/scorecardAPI';
import { useLocation } from 'react-router-dom';

const isAttachment = (answer) => answer.question_type === scorecardQType.attachment;

export default function SubmitScorecardEditor({ customScorecard, setSubmitted }) {
  const location = useLocation();

  const getMaxCumulativeScore = useCallback((customScorecard, question) => {
    let maxScore = 0;
    // Loop through other questions in the template and add them to the sum
    // if specified by "include_rating_questions" or "include_rubric_questions"
    customScorecard.questions.forEach((item) => {
      if (item.question_type === scorecardQType.rating && question.include_rating_questions) {
        maxScore += item.scale;
      }
      if (item.question_type === scorecardQType.rubric && question.include_rubric_questions) {
        // scoreToAdd will be the largest number in the rubric, which is found by looking
        // at the "number" property of the last column
        let scoreToAdd = item.rubric_columns[item.rubric_columns.length - 1].number;
        maxScore += scoreToAdd;
      }
    });

    return maxScore;
  }, []);

  const makeNewScorecardFromTemplate = useCallback(
    (customScorecard) => {
      const scorecard = {
        answers: [],
        include_cumulative_score: false,
      };

      customScorecard.questions.forEach((question) => {
        // Copy over the question, but not its id. This is easier than
        // copying each field besides id explicitly.
        const newAnswer = {
          ...question,
          question_id: question.id,
        };
        delete newAnswer.id;

        switch (question.question_type) {
          case scorecardQType.multiple_choice:
            newAnswer.mc_answer = [];
            break;
          case scorecardQType.nimble:
          case scorecardQType.text:
            newAnswer.answer_text = '';
            break;
          case scorecardQType.rating:
            newAnswer.answer_rating = null;
            break;
          case scorecardQType.direction_text:
            // no answer for direction text type, it's just a set of
            // directions for the editor to read.
            break;
          case scorecardQType.rubric:
            // answer_rubric will store the number of the selection.
            // If the question is not required and the editor did not select anything,
            // it will be null.
            newAnswer.answer_rubric = null;
            break;
          case scorecardQType.final_recommendation:
            // Options are yes and no, with maybe being an option if the admin
            // includes it in the template. Start off with nothing selected (null).
            // final recommendation information is stored at the scorecard level
            // because there can only be one recommendation max per scorecard.
            scorecard.include_final_recommendation = true;
            scorecard.include_maybe_option = question.include_maybe_option;
            scorecard.show_answer_on_preview = question.show_answer_on_preview;
            scorecard.final_recommendation = null;
            break;
          case scorecardQType.cumulative_score:
            // similar to final recommendation, there can only be one cumulative score per
            // scorecard, so cumulative score information is stored at the scorecard level.
            scorecard.include_cumulative_score = true;
            scorecard.include_rating_questions = question.include_rating_questions;
            scorecard.include_rubric_questions = question.include_rubric_questions;
            scorecard.show_total_on_preview = question.show_total_on_preview;
            scorecard.cumulative_score = 0;
            scorecard.max_cumulative_score = getMaxCumulativeScore(customScorecard, question);
            break;
          case scorecardQType.attachment:
            // Start with no value in the attachment
            newAnswer.attachment = '';
            break;
          default:
            break;
        }
        scorecard.answers.push(newAnswer);
      });

      return scorecard;
    },
    [getMaxCumulativeScore]
  );

  const [scorecard, setScorecard] = useState(makeNewScorecardFromTemplate(customScorecard));
  const [errorMessage, setErrorMessage] = useState('');

  const updateField = (e, index) => {
    const updatedScorecard = { ...scorecard };
    updatedScorecard.answers[index].answer_text = e.target.value;

    setScorecard(updatedScorecard);
    setErrorMessage('');
  };

  const updateRating = (e, index) => {
    const updatedScorecard = { ...scorecard };
    updatedScorecard.answers[index].answer_rating = e.target.value;
    const cumulativeScore = getCumulativeScore(updatedScorecard);
    updatedScorecard.cumulative_score = cumulativeScore;

    setScorecard(updatedScorecard);
    setErrorMessage('');
  };

  const updateMultipleChoiceField = (e, index, isMultiSelect) => {
    const updatedScorecard = { ...scorecard };
    const multipleChoiceAnswers = updatedScorecard.answers[index].mc_answer;

    const foundIndex = multipleChoiceAnswers.findIndex((a) => a === e.target.value);
    if (foundIndex > -1) {
      // answer is already selected, so remove it
      multipleChoiceAnswers.splice(foundIndex, 1);
    } else {
      // Answer is not already selected.
      // If it's not a multi-select, we have to deselect the other options.
      if (!isMultiSelect) {
        // setting length to 0 empties the array without reassigning it to a new one
        multipleChoiceAnswers.length = 0;
      }
      // add in new answer at end.
      multipleChoiceAnswers.push(e.target.value);
    }

    setScorecard(updatedScorecard);
    setErrorMessage('');
  };

  const updateFinalRecommendation = (value, index) => {
    const updatedScorecard = { ...scorecard };
    updatedScorecard.final_recommendation = value;
    setScorecard(updatedScorecard);
    setErrorMessage('');
  };

  const updateRubricSelection = (value, index) => {
    const updatedScorecard = { ...scorecard };
    updatedScorecard.answers[index].answer_rubric = value;
    const cumulativeScore = getCumulativeScore(updatedScorecard);
    updatedScorecard.cumulative_score = cumulativeScore;

    setScorecard(updatedScorecard);
    setErrorMessage('');
  };

  const uploadAttachment = (fileData, index) => {
    const updatedScorecard = { ...scorecard };
    updatedScorecard.answers[index].attachment = fileData;
    setScorecard(updatedScorecard);
    setErrorMessage('');
  };

  const removeAttachment = (e, index) => {
    e.preventDefault();
    const updatedScorecard = { ...scorecard };
    updatedScorecard.answers[index].attachment = null;
    setScorecard(updatedScorecard);
    setErrorMessage('');
  };

  const getCumulativeScore = (scorecard) => {
    let cumulativeScore = 0;

    // Loop through other answers in the Reference/Scorecard and add them to the sum
    // if specified by "include_rating_questions" or "include_rubric_questions"
    scorecard.answers.forEach((item) => {
      if (item.question_type === scorecardQType.rating && scorecard.include_rating_questions) {
        cumulativeScore += Number(item.answer_rating) ?? 0;
      }
      if (item.question_type === scorecardQType.rubric && scorecard.include_rubric_questions) {
        cumulativeScore += item.answer_rubric ?? 0;
      }
    });

    return cumulativeScore;
  };

  const onSave = () => {
    try {
      checkForErrors();
    } catch (error) {
      setErrorMessage(error.message);
      return;
    }

    setEmptyRatingAnswers();
    createScorecard();
  };

  const checkForErrors = () => {
    scorecard.answers.forEach((answer) => {
      if (answer.question_type === scorecardQType.rating) {
        checkRating(answer);
      }
      if (
        answer.is_required &&
        ((answer.question_type === scorecardQType.attachment && !answer.attachment) ||
          (answer.question_type === scorecardQType.text && !answer.answer_text) ||
          (answer.question_type === scorecardQType.nimble && !answer.answer_text) ||
          (answer.question_type === scorecardQType.multiple_choice && !answer.mc_answer.length) ||
          (answer.question_type === scorecardQType.rubric && answer.answer_rubric === null) ||
          (answer.question_type === scorecardQType.final_recommendation &&
            !scorecard.final_recommendation))
      ) {
        throw new Error('Please fill out all required fields.');
      }
    });
  };

  const checkRating = (answer) => {
    /**
     * Rating answers will be one of the following:
     * 1. Empty string (if number typed in and then backspaced)
     * 2. null
     * 3. A number (possibly not in the allowed range (0 - <answer.scale>))
     */
    if (answer.answer_rating === '') {
      if (answer.is_required) {
        throw new Error('Please fill out all required fields.');
      }
    } else if (answer.answer_rating === null) {
      if (answer.is_required) {
        throw new Error('Please fill out all required fields.');
      }
    } else {
      // The answer is a number, so make sure it's in the allowed range,
      // even for drafts and autosaves. We don't want to allow a draft
      // save that has negative numbers or crazy high numbers.
      let rating = Number(answer.answer_rating);
      if (rating > answer.scale || rating < 0) {
        throw new Error('Rating score must be in the allowed range.');
      }
    }
  };

  const setEmptyRatingAnswers = () => {
    // The backend stores empty ratings as null, but they're sometimes empty strings
    // on the frontend due to html input values. Set all to null.
    const newAnswers = scorecard.answers.map((answer) =>
      answer.answer_rating === '' ? { ...answer, answer_rating: null } : answer
    );
    scorecard.answers = newAnswers;
  };

  const createScorecard = () => {
    // make a copy of scorecard so answers aren't affected by the split below.
    const finalScorecard = { ...scorecard };

    // Answers are split into two groups: attachment answers and non-attachment answers.
    // This is because attachment answers are submitted as form data and weird results
    // were occurring when trying to submit all answers (even non attachments) as form data.
    const attachmentAnswers = finalScorecard.answers.filter(isAttachment);
    finalScorecard.answers = finalScorecard.answers.filter((answer) => !isAttachment(answer));

    const searchParams = new URLSearchParams(location.search);
    const signature = searchParams.get('signature');
    axios.post('/api/submitscorecard/', { signature, ...finalScorecard }).then(async (response) => {
      const newScorecard = response.data;

      const updatedScorecard = {
        // take id and other fields from newScorecard
        ...newScorecard,
        // take answers from existing scorecard in case the user made edits while
        // the scorecard was saving.
        ...scorecard,
      };

      const answerMapping = {};

      // wait for all attachments to post before moving on
      for (let answer of attachmentAnswers) {
        const formData = getFormData(answer);
        const newAttachment = await scorecardAPI.createAttachment(newScorecard.id, formData);
        // store the attachments so we can access them easily below by looking
        // up question_id.
        answerMapping[newAttachment.question_id] = newAttachment;
      }

      // At this point, new answers have been created but they don't have ids.
      // They can't be added as they are because they're not in the proper order
      // due to them being sent as two separate groups (see above). So iterate
      // over the answers in state, which are in order, and find their id that way.
      updatedScorecard.answers = updatedScorecard.answers.map((answer) => {
        if (isAttachment(answer)) {
          return answerMapping[answer.question_id];
        } else {
          // find answer using question_id and add the id to our answer in state
          const answerWithId = newScorecard.answers.find(
            (newAnswer) => newAnswer.question_id === answer.question_id
          );
          return {
            id: answerWithId.id,
            ...answer,
          };
        }
      });

      // now that all answers have an id, save the answer order
      await scorecardAPI.setAnswerOrder(updatedScorecard);

      setSubmitted(true);
    });
  };

  const getFormData = (answer) => {
    const formData = new FormData();
    if (answer.attachment instanceof File) {
      formData.append('attachment', answer.attachment, answer.attachment.name);
    } else {
      // file is empty
      formData.append('attachment', '');
    }
    formData.append('is_required', answer.is_required);
    formData.append('question_id', answer.question_id);
    formData.append('question_type', answer.question_type);
    formData.append('question_text', answer.question_text);
    formData.append('attachment_directions', answer.attachment_directions);
    return formData;
  };

  return (
    <div className="scorecard-request">
      <ScorecardEditorQuestions
        scorecard={scorecard}
        updateField={updateField}
        updateFinalRecommendation={updateFinalRecommendation}
        updateMultipleChoiceField={updateMultipleChoiceField}
        updateRating={updateRating}
        updateRubricSelection={updateRubricSelection}
        uploadAttachment={uploadAttachment}
        removeAttachment={removeAttachment}
      />

      <FooterButtonsGridContainer justifyItems={['start', 'start', 'end']}>
        <ErrorText message={errorMessage} />
        <SubmitButton width={[1, 1, '186px']} onClick={onSave} disabled={errorMessage !== ''}>
          {'Complete & Submit'}
        </SubmitButton>
      </FooterButtonsGridContainer>
    </div>
  );
}

const SubmitButton = styled.button(
  {
    height: '50px',

    background: '#00B88D',
    borderRadius: '2px',
    border: 'none',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '22px',

    color: '#ffffff',
    justifySelf: 'end',
  },
  layout
);

const FooterButtonsGridContainer = styled.div(
  {
    display: 'grid',
    gridGap: '16px',
    gridTemplateColumns: '1fr',
    alignItems: 'center',
  },
  flexbox
);
