import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { styled, Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { moreInfoOnClickHandler } from 'features/Connect/features/ExpressInterest/utils';
import { ExpressInterestButton } from '../Buttons/ExpressInterestButton';
import { SaveSchoolButton } from '../Buttons/SaveSchoolButton';
import { SchoolCardProps } from '../types';
import { useConnectContext } from 'features/Connect/features/Context/ConnectContextProvider';
// removing badges from school cards until after state demo's
// import { OnPlatformBadge } from '../InfoBadges/OnPlatformBadge';
// import { RecommendedBadge } from '../InfoBadges/RecommendedBadge';
// import { renderSecondarySchoolTags } from 'features/Connect/features/JobDiscovery/utils';

export const Header: React.FC<SchoolCardProps> = ({ school }) => {
  const { connectContextActions } = useConnectContext();
  return (
    <HeaderContainer direction="row">
      <InnerHeaderStack direction="column">
        <SchoolNameContainer
          href="#"
          onClick={() => moreInfoOnClickHandler(school, connectContextActions, '0')}
        >
          <SchoolName>{school?.name}</SchoolName>
          <DistrictName>{school?.district?.name}</DistrictName>
        </SchoolNameContainer>
        {/* <ChipStack>
          {school?.is_recommended && <RecommendedBadge />}
          {school?.principal?.on_connect && <OnPlatformBadge />}
          {renderSecondarySchoolTags(school)}
        </ChipStack> */}
      </InnerHeaderStack>
      <ActionButtonsStack direction="row" spacing={1}>
        <SaveSchoolButton school={school} />
        <ExpressInterestButton school={school} />
      </ActionButtonsStack>
    </HeaderContainer>
  );
};

export const MobileHeader: React.FC<SchoolCardProps> = ({ school }) => {
  const { connectContextActions } = useConnectContext();
  const theme = useTheme();
  return (
    <MobileHeaderContainer direction="row" gap={1} alignItems="center">
      <SchoolNameContainer
        href="#"
        onClick={() => moreInfoOnClickHandler(school, connectContextActions, '0')}
      >
        <SchoolNameMobile>{school?.name}</SchoolNameMobile>
        <DistrictNameMobile>{school?.district?.name}</DistrictNameMobile>
      </SchoolNameContainer>
      <SaveSchoolButton school={school} size="small" sx={saveSchoolButtonStyles(theme)} />
    </MobileHeaderContainer>
  );
};

const HeaderContainer = styled(Stack)(() => ({
  justifyContent: 'space-between',
  width: '100%',
}));

const InnerHeaderStack = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(1),
  flexWrap: 'wrap',
}));

const SchoolNameContainer = styled(Link)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'baseline',
  gap: theme.spacing(1),
  color: theme.palette.gray.dark,
  flexWrap: 'wrap',
  textDecoration: 'none',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  '&:hover': {
    textDecoration: 'underline',
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const SchoolName = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  fontWeight: theme.typography.fontWeightMediumBold,
  color: theme.palette.gray.dark,
  lineHeight: 1.2,
  flexWrap: 'wrap',
}));

const DistrictName = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.subtitle.fontSize,
  fontWeight: theme.typography.fontWeightRegular,
  color: theme.palette.black.main,
  lineHeight: 1.2,
}));

const SchoolNameMobile = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.subtitle.fontSize,
  fontWeight: theme.typography.fontWeightMediumBold,
  color: theme.palette.gray.dark,
  alignItems: 'center',
  padding: 'auto',
}));

const DistrictNameMobile = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.subtitle.fontSize,
  fontWeight: theme.typography.fontWeightRegular,
  color: theme.palette.black.main,
  alignItems: 'center',
  padding: 'auto',
}));

// const ChipStack = styled(Stack)(({ theme }) => ({
//   display: 'flex',
//   flexDirection: 'row',
//   gap: theme.spacing(0.5),
//   justifySelf: 'start',
// }));

const ActionButtonsStack = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  spacing: theme.spacing(1),
}));

const MobileHeaderContainer = styled(Stack)(({ theme }) => ({
  justifyContent: 'space-between',
  alignItems: 'center',
  alignContent: 'center',
  width: '100%',
  marginBottom: theme.spacing(2),
}));

const saveSchoolButtonStyles = (theme: Theme) => ({
  borderRadius: theme.shape.borderRadius * 0.5,
  minWidth: '32px',
  width: '32px',
  height: '32px',
  alignItems: 'center',
});
