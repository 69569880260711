import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { SxProps } from '@mui/material';

export interface BasicTableColumn<ColumnType extends string> {
  id: number;
  content: string | JSX.Element;
  field: ColumnType;
  align?: 'left' | 'center' | 'right';
  sx?: SxProps;
}

export interface BasicTableRow {
  id: number;
  dataTestId?: string;
  sx?: SxProps;
}

interface BasicTableProps<
  RowType extends BasicTableRow,
  ColumnType extends BasicTableColumn<keyof RowType & string>,
> {
  rows: RowType[];
  columns: ColumnType[];
}

export const BasicTable = <
  RowType extends BasicTableRow,
  ColumnType extends BasicTableColumn<keyof RowType & string>,
>({
  rows,
  columns,
}: BasicTableProps<RowType, ColumnType>) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.id} align={column.align || 'left'} sx={column.sx || null}>
                {column.content}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id} sx={row.sx || null} data-testid={row.dataTestId}>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align || 'left'}>
                  {row[column.field]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
