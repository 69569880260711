import { PrimaryButton } from 'sharedComponents/Buttons';
import { SecondaryButton } from 'sharedComponents/Buttons';
import { Grid, useTheme } from '@mui/material';
import {
  SecondaryButtonContainer,
  PrimaryButtonContainer,
  ButtonStyle,
  SecondaryButtonStyle,
} from './styles';

export const ModalButtonGroup = ({ dataTestId, primaryButton, secondaryButton, sx }) => {
  const theme = useTheme();
  return (
    <Grid
      container
      gap={theme.spacing(2)}
      direction="row"
      wrap="nowrap"
      data-testid={dataTestId}
      sx={sx}
    >
      <Grid item xs={6} justifyContent="end">
        <SecondaryButtonContainer>
          <SecondaryButton
            onClick={secondaryButton.action}
            dataTestId={secondaryButton.dataTestId}
            sx={SecondaryButtonStyle(theme)}
            color="black"
            size="extraLarge"
          >
            {secondaryButton.label}
          </SecondaryButton>
        </SecondaryButtonContainer>
      </Grid>
      <Grid item xs={6} justifyContent="start">
        <PrimaryButtonContainer>
          <PrimaryButton
            dataTestId={primaryButton.dataTestId}
            onClick={primaryButton.action}
            isLoading={primaryButton.isLoading ? true : false}
            disabled={primaryButton.disabled}
            loadingSpinnerColor="inherit"
            sx={ButtonStyle(theme)}
            size="extraLarge"
            color={primaryButton.color}
          >
            {primaryButton.label}
          </PrimaryButton>
        </PrimaryButtonContainer>
      </Grid>
    </Grid>
  );
};
