import { SxProps } from '@mui/material';
import { Switch as MuiSwitch } from '@mui/material';

export interface SwitchProps {
  color?: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  dataTestId: string;
  defaultChecked?: boolean;
  disabled?: boolean;
  inputProps?: Record<string, string>;
  onChange?: () => void;
  size?: 'small' | 'medium';
  sx?: SxProps;
}

export const Switch: React.FC<SwitchProps> = ({
  color,
  dataTestId,
  defaultChecked,
  disabled,
  inputProps,
  onChange,
  size,
  sx,
}) => {
  return (
    <MuiSwitch
      color={color}
      data-testid={dataTestId}
      defaultChecked={defaultChecked}
      disabled={disabled}
      inputProps={inputProps}
      onChange={onChange}
      size={size}
      sx={sx}
    />
  );
};
