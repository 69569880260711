import { useEffect, useState, useCallback } from 'react';
import auth from '../../utils/auth';
import axios from 'axios';

import styled from 'styled-components';
import LoadingSpinner from '../loadingSpinner';

import Accordion from './Accordion/Accordion';
import { Link } from 'react-router-dom';
/**
 * Replaces "district-slug" in the provided url.
 *
 * ie.
 * hirenimble.com/jobs/district-slug --> hirenimble.com/jobs/nimble-public-schools
 *
 * @param {*} url - url to be search
 * @param {*} district - district to be added to link
 */
const replaceDistrictSlug = (url) =>
  url.replace(/district-slug/, auth.getUser().profile.district.slug);

function GettingStarted(props) {
  const checklistId = auth.getUser().checklist?.id;
  const [checklist, setChecklist] = useState({});

  // update the changed item
  const handleChange = async (id) => {
    // aggregate all items and steps into single list for id search
    const stepList = checklist.items.flatMap((item) => item.steps);
    const tempList = checklist.items.concat(stepList);

    // find item/step with matching id
    const item = tempList.find((item) => item.id === id);
    const updatedItem = { ...item, completed: !item.completed };

    if ('link' in updatedItem) {
      await axios.patch(`/api/checklist-items/steps/${updatedItem.id}/`, updatedItem);
    } else {
      await axios.patch(`/api/checklist-items/${updatedItem.id}/`, updatedItem);
    }
    // refresh checklist data
    fetchChecklistData();
  };

  const fetchChecklistData = useCallback(async () => {
    if (checklistId === undefined) return;

    const { data } = await axios.get(`/api/checklist/${checklistId}/`);

    setChecklist(data);
  }, [checklistId]);

  /**
   * Once user visits the page, this function will update the seen status
   * of the checklist.
   *
   * NOTE: The next time the page reloads the items will no longer be expanded.
   */

  const updateChecklistSeenStatus = useCallback(async () => {
    if (checklistId === undefined) return;

    checklist.seen = true;
    await axios.patch(`/api/checklist/${checklistId}/`, checklist);
  }, [checklist, checklistId]);

  // update the checklist status when user visits page
  useEffect(() => {
    if (!auth.getUser().checklist?.seen) {
      updateChecklistSeenStatus();
    }
  }, [updateChecklistSeenStatus]);

  useEffect(() => {
    fetchChecklistData();
  }, [fetchChecklistData]);

  return (
    <Container>
      {!checklist.items ? (
        <LoadingSpinner
          margin={0}
          fontSize={2}
          additionalStyles={{
            position: 'absolute',
            top: '16px',
            right: '8px',
          }}
        />
      ) : (
        checklist.items.map((item, itemIdx) => (
          <Accordion
            testid={`checklist-item-${itemIdx}`}
            key={item.id}
            id={item.id}
            title={item.title}
            steps={item.steps}
            duration={item.steps.reduce((acc, step) => acc + step.duration, 0)}
            expanded={!checklist.seen && itemIdx === 0 ? true : false}
            completed={item.completed}
            handleChange={handleChange}
            height="fit-content"
          >
            {item.steps.map((step, stepIdx) => (
              <Accordion
                testid={`checklist-step-${stepIdx}`}
                key={step.id}
                id={step.id}
                title={step.title}
                duration={step.duration}
                expanded={!checklist.seen && stepIdx === 0 ? true : false}
                completed={step.completed}
                handleChange={handleChange}
                innerAccordion={true}
                height="fit-content"
              >
                <AccordionInnerContent>
                  <LeftPanelContainer>
                    <Description>{step.description}</Description>
                    {/* Only render link if present */}
                    {step.link &&
                      (step.link.internal_url ? (
                        <StyledButton to={step.link.internal_url} target="_blank">
                          {step.link.title}
                        </StyledButton>
                      ) : (
                        // Render anchor tag if internal link is null
                        <StyledExternalButton href={step.link.external_url} target="_blank">
                          {step.link.title}
                        </StyledExternalButton>
                      ))}
                  </LeftPanelContainer>
                  <RightPanelContainer>
                    <VideoContainer videoExists={step.videos.length > 0}>
                      {step.videos.length > 0 &&
                        step.videos.map((video) => (
                          <ResponsiveIframe
                            key={video.id}
                            title={`video-${video.id}`}
                            src={video.url}
                            frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                          ></ResponsiveIframe>
                        ))}
                    </VideoContainer>
                    {/* extra resource links */}
                    <ResourceContainer>
                      {step.resources.length > 0 &&
                        step.resources
                          .map((resource) =>
                            resource.url.includes('district-slug') ? (
                              <span key={resource.id}>
                                Your organization's new job board link is: <br />
                                <ResourceLink
                                  href={replaceDistrictSlug(resource.url)}
                                  rel="noopener noreferrer"
                                  target="_blank"
                                >
                                  {replaceDistrictSlug(resource.url)}
                                </ResourceLink>
                              </span>
                            ) : (
                              <ResourceLink
                                key={resource.id}
                                href={resource.url}
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                {resource.title}
                              </ResourceLink>
                            )
                          )
                          .reverse()}
                    </ResourceContainer>
                  </RightPanelContainer>
                </AccordionInnerContent>
              </Accordion>
            ))}
          </Accordion>
        ))
      )}
    </Container>
  );
}

export default GettingStarted;

const Container = styled.div`
  margin: 59px 65px 179px 65px;
  padding: 0;
  min-height: 100vh;

  @media screen and (min-width: 1024px) {
    padding-right: 80px;
  }
`;

// This inner div manages the nested accordion postioning
const AccordionInnerContent = styled.div`
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: start;
  transition: max-height 0.6s ease;

  @media screen and (min-width: 1200px) {
    flex-direction: row;
  }
`;

const LeftPanelContainer = styled.div`
  width: 50%;
  padding: 0 30px;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 1200px) {
    width: 100%;
    flex-grow: 1;
  }
`;

const RightPanelContainer = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 25px;

  @media screen and (max-width: 1200px) {
    width: 100%;
    flex: 1;
  }
`;

const ResourceContainer = styled.div`
  margin-top: 20px;
  flex: 1;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: start;
  justify-content: flex-start;

  @media screen and (max-width: 1200px) {
    width: 100%;
    flex-grow: 1;
  }
`;

const VideoContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  overflow: hidden;

  // Assign container height if video exists
  ${({ videoExists }) =>
    videoExists &&
    `
    height: 344px;
    `}
`;

const Description = styled.p`
  font-weight: 400;
  font-size: 14px;
  text-align: justify;
  word-wrap: break-word;
  /* Renders new line (\n) characters found in JS string */
  white-space: pre-wrap;
`;

const StyledButton = styled(Link)`
  /* button outer */
  padding: 15px;
  background: #00b88d;
  border-radius: 2px;
  width: 100%;

  /* button text */
  font-style: normal;
  font-weight: 600;
  text-align: center;
  font-size: 15px;
  line-height: 20px;
  color: #ffffff;

  &:hover {
    background-color: #038869;
    color: #ffffff;
    text-decoration: none;
  }
`;

const StyledExternalButton = styled.a`
  /* button outer */
  padding: 15px;
  margin-top: 25px;
  background: #00b88d;
  border-radius: 2px;
  width: 100%;

  /* button text */
  font-style: normal;
  font-weight: 600;
  text-align: center;
  font-size: 15px;
  line-height: 20px;
  color: #ffffff;

  &:hover {
    background-color: #038869;
    color: #ffffff;
    text-decoration: none;
  }
`;

// For key resources links
const ResourceLink = styled.a`
  color: #00b88d;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: #038869;
  }
`;

const ResponsiveIframe = styled.iframe`
  width: 100%;
  height: 100%;
`;
