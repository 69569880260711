import { BlankModal } from 'sharedComponents/Modal';
import { Box } from '@mui/material';
import { ModalButtonGroup } from '../../Buttons/ModalButtonGroup';
import { useState } from 'react';
import { ModalOptionGroup } from '../../ModalFormOptions/ModalOptionGroup';
import {
  ConnectCandidateMatchDashboardDataTestIds,
  ConnectCandidateMatchDashboardRejectMatchModalDataTestIds,
} from 'data-testids/ConnectDataTestIds';
import { useTheme } from '@mui/material';
import { ModalHeader, ModalStyles } from '../utils/styles';
import { REJECT_MATCH_OPTIONS } from '../utils/constants';

export const RejectMatchModal = ({ isOpen, onClose }) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const theme = useTheme();

  const rejectHeader = (
    <ModalHeader
      data-testid={ConnectCandidateMatchDashboardRejectMatchModalDataTestIds.HEADER_TEXT}
    >
      Tell us more
    </ModalHeader>
  );

  const CancelButton = {
    label: 'Cancel',
    action: () => onClose(),
    dataTestId: ConnectCandidateMatchDashboardRejectMatchModalDataTestIds.CANCEL_BUTTON,
  };

  // TODO: Add action to reject match in connective tissue PR
  const RejectMatchButton = {
    label: 'Reject Match',
    action: () => {
      onClose(selectedOptions);
    },
    dataTestId: ConnectCandidateMatchDashboardRejectMatchModalDataTestIds.REJECT_MATCH_BUTTON,
    color: 'black',
    disabled: selectedOptions.length === 0,
  };

  const handleReasonChange = (event) => {
    const value = event.target.value;
    setSelectedOptions((prevOptions) => {
      if (prevOptions.includes(value)) {
        return prevOptions.filter((option) => option !== value);
      } else {
        return [...prevOptions, value];
      }
    });
  };

  const rejectMatchButtonGroup = () => {
    return (
      <ModalButtonGroup
        secondaryButton={CancelButton}
        primaryButton={RejectMatchButton}
        dataTestId={
          ConnectCandidateMatchDashboardRejectMatchModalDataTestIds.REJECT_MATCH_BUTTON_GROUP
        }
        sx={{ marginTop: theme.spacing(1) }}
      />
    );
  };

  const rejectMatchBody = () => {
    return (
      <Box>
        <ModalOptionGroup
          modalOptions={REJECT_MATCH_OPTIONS}
          selectedOption={selectedOptions}
          handleReasonChange={handleReasonChange}
          isMultiSelect={true}
          dataTestId={
            ConnectCandidateMatchDashboardRejectMatchModalDataTestIds.REJECT_MATCH_OPTION_GROUP
          }
        />
        {rejectMatchButtonGroup()}
      </Box>
    );
  };

  return (
    <BlankModal
      dataTestId={ConnectCandidateMatchDashboardDataTestIds.REJECT_MATCH_MODAL}
      header={rejectHeader}
      body={rejectMatchBody()}
      open={isOpen}
      onClose={onClose}
      sx={ModalStyles(theme)}
    />
  );
};
