import { Box, styled, Typography } from '@mui/material';

export const Container = styled(Box)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

export const DistrictVacancyContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

export const HRBPContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

export const HRBPRow = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
}));

export const Row = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(4),
}));

export const TableSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.body3.fontSize,
  fontWeight: theme.typography.fontWeightRegular,
  lineHeight: theme.spacing(3),
}));

export const TableTitle = styled(Typography)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  fontWeight: theme.typography.fontWeightMediumBold,
  lineHeight: theme.spacing(3),
}));

export const UnsureSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.body3.fontSize,
  fontStyle: 'italic',
  marginLeft: theme.spacing(1),
}));

export const UnsureTextContainer = styled(Box)(({ theme }) => ({
  color: theme.palette.gray.medium,
  display: 'flex',
  maxWidth: '200px',
}));

export const VisibilityRow = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  color: theme.palette.gray.medium,
  display: 'flex',
}));
