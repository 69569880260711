import axios, { AxiosError } from 'axios';
import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import { JobViewQuestions } from './types';

export const useQueryGetQuestionsByJobId = (
  jobId: number,
  options?: Omit<UseQueryOptions<JobViewQuestions, AxiosError>, 'queryKey' | 'queryFn'>
): UseQueryResult<JobViewQuestions, AxiosError> => {
  return useQuery({
    queryKey: ['tabbedJobViewQuestionsByJobId', jobId],
    queryFn: () => axios.get(`/api/roles/${jobId}/questions`).then((res) => res.data),
    enabled: Number.isInteger(jobId),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options,
  });
};
