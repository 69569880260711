import { Typography, useTheme } from '@mui/material';

import { ATSTabbedJobViewSetUpTabTestIds } from 'data-testids/ATS';
import { CandidateSettingsSectionProps } from '../types';
import { Section, Subtitle } from '../styles';
import { formatRoleSectionsText } from '../utils';

export const CandidateSettingsSection: React.FC<CandidateSettingsSectionProps> = ({
  isInternal,
  isOpenOnlyForInternalCandidates,
  settings = [],
  title,
}) => {
  const theme = useTheme();

  return (
    <Section>
      <Subtitle
        variant="subtitle1"
        data-testid={ATSTabbedJobViewSetUpTabTestIds.CANDIDATE_SETTINGS_SECTION_TITLE}
        sx={{ fontWeight: theme.typography.fontWeightMediumBold }}
      >
        {title}
      </Subtitle>
      {isOpenOnlyForInternalCandidates ? (
        <Typography
          sx={{ fontStyle: 'italic' }}
          data-testid={ATSTabbedJobViewSetUpTabTestIds.NOT_OPEN_EXTERNAL_CANDIDATES_TEXT}
        >
          This posting is not open to external candidates
        </Typography>
      ) : (
        <>
          {settings.map((setting) => (
            <Typography
              key={setting.id}
              data-testid={
                isInternal
                  ? ATSTabbedJobViewSetUpTabTestIds.INTERNAL_CANDIDATE_SETTINGS
                  : ATSTabbedJobViewSetUpTabTestIds.EXTERNAL_CANDIDATE_SETTINGS
              }
            >
              {formatRoleSectionsText(setting, isInternal)}
            </Typography>
          ))}
        </>
      )}
    </Section>
  );
};
