import { Modal } from 'sharedComponents/Modal';
import React, { BaseSyntheticEvent } from 'react';
import { styled, Typography, useTheme } from '@mui/material';
import { ErrorSnackbar } from 'sharedComponents/Snackbar';
import { getAxiosErrorMessageByStatusCode } from 'hooks/errorHandling/errorHandlingHooks';
import { useMutationRequestBulkExport } from '../mutations';
import { FormProvider, useForm } from 'react-hook-form';
import { SelectFieldsForm } from './SelectFieldsForm';
import { CloseButton } from 'sharedComponents/Buttons';
import { getFieldFromLabel } from './utils';
import { ATSCandidateBulkExportTestIds } from 'data-testids/ATS';
import { FieldLabel } from './types';

type BulkExportSelectFieldsModalProps = {
  open: boolean;
  onClose: () => void;
  onProspectList: boolean;
  selectedCandidateIds: Set<number>;
  onExport: () => void;
};

const BulkExportSelectFieldsModal: React.FC<BulkExportSelectFieldsModalProps> = ({
  open,
  onClose,
  onProspectList,
  selectedCandidateIds,
  onExport,
}) => {
  const theme = useTheme();
  const { mutate, isError, error, reset } = useMutationRequestBulkExport({ onSuccess: onExport });
  const errorMessage = getAxiosErrorMessageByStatusCode(error);
  const methods = useForm<{ selectedFields: FieldLabel[] }>({
    defaultValues: { selectedFields: [] },
  });

  const onSubmit = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    const submitHandler = methods.handleSubmit((formState) => {
      const formStateToSubmit = {
        selectedFields: formState.selectedFields.map((f) => getFieldFromLabel(f)),
        userIds: Array.from(selectedCandidateIds),
        prospectExport: onProspectList,
      };

      return mutate(formStateToSubmit);
    });

    submitHandler(e);
  };

  const getModalTitle = () => {
    const entity = onProspectList ? 'Prospect' : 'Candidate';
    const plural = selectedCandidateIds.size === 1 ? '' : 's';
    return `Export Data for ${selectedCandidateIds.size} ${entity}${plural}`;
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={
        <ModalTitle
          variant="h2"
          data-testid={ATSCandidateBulkExportTestIds.SELECT_FIELDS_MODAL_TITLE}
        >
          {getModalTitle()}
        </ModalTitle>
      }
      closeButton={
        <CloseButton
          onClick={onClose}
          dataTestId={ATSCandidateBulkExportTestIds.SELECT_FIELDS_MODAL_CLOSE_BUTTON}
        />
      }
      bodySx={{ borderRadius: theme.spacing(2), maxWidth: '535px' }}
    >
      <ErrorSnackbar
        open={isError}
        onClose={reset}
        message={errorMessage}
        duration={6000}
        dataTestId={ATSCandidateBulkExportTestIds.ERROR_SNACKBAR}
      />
      <Subtitle data-testid={ATSCandidateBulkExportTestIds.SELECT_FIELDS_MODAL_SUBTITLE}>
        Select fields for export below
      </Subtitle>
      <FormProvider {...methods}>
        <SelectFieldsForm onSubmit={onSubmit} onClose={onClose} onProspectList={onProspectList} />
      </FormProvider>
    </Modal>
  );
};

export default BulkExportSelectFieldsModal;

const ModalTitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  color: theme.palette.text.primary,
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
}));
