import { ATSTabbedJobViewVacanciesTabTestIds } from 'data-testids/ATS';
import { HRBPRow, HRBPContainer } from '../styles';
import { HRBPSectionProps } from '../types';
import { SectionTitle } from 'features/TabbedJobView/styles';

export const HRBPSection: React.FC<HRBPSectionProps> = ({ hrbpData }) => {
  return (
    <HRBPContainer data-testid={ATSTabbedJobViewVacanciesTabTestIds.HRBP_SECTION}>
      <SectionTitle data-testid={ATSTabbedJobViewVacanciesTabTestIds.HRBP_TITLE}>
        HR/Onboarding Partner
      </SectionTitle>
      &nbsp;
      {hrbpData?.map((hrbp) => {
        const isSchoolRole = hrbp.schools.length;
        const hrbpText = isSchoolRole
          ? `${hrbp?.admin_users?.join(', ')} - ${hrbp.schools.join(', ')}`
          : hrbp?.admin_users?.join(', ');

        return (
          <HRBPRow key={hrbp.id} data-testid={ATSTabbedJobViewVacanciesTabTestIds.HRBP_ROW}>
            {hrbpText}
          </HRBPRow>
        );
      })}
    </HRBPContainer>
  );
};
