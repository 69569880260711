import { IndividualQuestion } from 'components/Question/IndividualQuestion';
import { JobViewQuestion } from 'types';
import { JobViewQuestionSetItem, QuestionsListProps } from '../types';
import { QuestionSet } from 'components/Question/QuestionSet';
import { getQuestionText, sortItemsByOrder } from '../utils';
import { ATSTabbedJobViewQuestionsTabTestIds } from 'data-testids/ATS';

export const QuestionList: React.FC<QuestionsListProps> = ({ questions }) => {
  return (
    <>
      {questions.map((question: JobViewQuestion | JobViewQuestionSetItem) => {
        if ('custom_questions' in question && question.is_qs) {
          const sortedInternalQuestionSetQuestions = sortItemsByOrder(question.items);

          return (
            <QuestionSet key={question.id} questionSetTitle={question.title}>
              {sortedInternalQuestionSetQuestions.map((question) => (
                <IndividualQuestion
                  key={question.id}
                  questionText={getQuestionText(question)}
                  questionType={question.question_type}
                  dataTestId={`${ATSTabbedJobViewQuestionsTabTestIds.INDIVIDUAL_QUESTION}-${question.id}`}
                />
              ))}
            </QuestionSet>
          );
        } else if ('question_type' in question) {
          return (
            <IndividualQuestion
              key={question.id}
              questionText={getQuestionText(question)}
              questionType={question.question_type}
              dataTestId={`${ATSTabbedJobViewQuestionsTabTestIds.INDIVIDUAL_QUESTION}-${question.id}`}
            />
          );
        } else {
          return <></>;
        }
      })}
    </>
  );
};
