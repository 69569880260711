import styled from 'styled-components';

interface OverviewProps {
  yearsExperience?: number;
}

const Overview: React.FC<OverviewProps> = ({ yearsExperience }) => {
  return (
    <MarketplaceOverviewContainer>
      <MarketplaceHeaderLabel>Overview</MarketplaceHeaderLabel>
      <MarketplaceLineItemContainer>
        <MarketplaceLineItem>
          <p>
            <b>{yearsExperience} years</b> of experience
          </p>
        </MarketplaceLineItem>
      </MarketplaceLineItemContainer>
    </MarketplaceOverviewContainer>
  );
};

export default Overview;

const MarketplaceHeaderLabel = styled.div`
  color: #4f4f4f;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  display: flex;
  align-items: center;
  order: 0;
  flex-grow: 0;
  margin-bottom: 8px;
`;

const MarketplaceOverviewContainer = styled.div`
  box-sizing: border-box;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  width: 95%;
  padding-top: 16px;
  padding-left: 19px;
`;

const MarketplaceLineItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const MarketplaceLineItem = styled.div`
  color: #828282;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  font-size: 14px;
  font-style: normal;
  line-height: 24px;
  margin-bottom: 16px;
  align-items: center;
  order: 1;
  flex-grow: 0;
`;
