import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Education from '../../ApplicationConfirmation/Education';
import Exam from '../../ApplicationConfirmation/Exams';
import QuickProfilePreferenceSection from './QuickProfilePreferenceSection';
import WorkExperienceSection from './WorkExperienceSection';
import { Role, QuickProfileUser, FullUser, CustomAnswers, SchoolPreferenceAnswer } from 'types';
import { nimbleTheme } from 'theme';
import { DefaultContainer } from 'features/PrintView/styles';
import { ATSQuickProfileApplicationSection } from 'data-testids/ATS';

interface Props {
  user: QuickProfileUser | FullUser;
  districtApplication?: {
    applicationattachment_set: Array<Record<string, unknown>>;
    applicationattachmentvideourl_set: Array<Record<string, unknown>>;
    custom_answers: Array<CustomAnswers>;
    role: Role;
    flexible_school_preferences: boolean;
    high_retention: boolean;
    id: number;
    is_in_shortage_area: boolean;
    is_popular: boolean;
    preferenced_school: boolean;
    new_status: {
      color: number;
      id: number;
      label: string;
      status_type: number;
    };
    school_preferences_answer: SchoolPreferenceAnswer;
  };
  isTalentMarketplace?: boolean;
  isCandidateList?: boolean;
}

interface SectionProps {
  isExpanded: boolean;
}

interface ExpandedState {
  all: boolean;
  experiences: boolean;
  education: boolean;
  languages: boolean;
  exams: boolean;
  certificates_held: boolean;
  applicationQuestions: boolean;
  attached_files: boolean;
  answers?: boolean;
  preference: boolean;
}

export default function QuickProfileApplicationSection({
  user,
  districtApplication,
  isCandidateList,
  isTalentMarketplace,
}: Props): JSX.Element {
  const [expanded, setExpanded] = useState<ExpandedState>({
    all: false,
    experiences: false,
    education: false,
    languages: false,
    exams: false,
    certificates_held: false,
    applicationQuestions: false,
    attached_files: false,
    answers: false,
    preference: false,
  });

  const expandAllSections = () => {
    setExpanded({
      all: true,
      experiences: true,
      education: true,
      languages: true,
      exams: true,
      certificates_held: true,
      applicationQuestions: true,
      attached_files: true,
      answers: true,
      preference: true,
    });
  };

  const collapseAllSections = () => {
    setExpanded({
      all: false,
      experiences: false,
      education: false,
      languages: false,
      exams: false,
      certificates_held: false,
      applicationQuestions: false,
      attached_files: false,
      answers: false,
      preference: false,
    });
  };

  const toggleCardsSection = (cardSection) => {
    setExpanded((prevState) => {
      const newState = {
        ...prevState,
        [cardSection]: !prevState[cardSection],
      };

      // if all sections have been expanded (but not via the Expand All button), update
      // the "all" value to true.
      const allExpanded = Object.values(newState).every((item) => item === true);
      if (allExpanded) {
        newState.all = true;
      }

      // if all sections have been collapsed (but not via the Close All button), update
      // the "all" value to false.
      const allCollapsed = Object.values(newState).every((item) => item === false);
      if (allCollapsed) {
        newState.all = false;
      }

      return newState;
    });
  };

  const sortApplicationQuestions = (answers, questionSetOrder) => {
    if (!questionSetOrder) {
      // There is no set order on the Role, so we only sort within the answers.
      return answers.sort((a, b) => a.order - b.order);
    }

    // We do have a role sort order.
    // The role order only shows the order of questionSets
    // The order for loose questions is in the questions with question_set_uuid null
    // We need to combine the two, and make sure we sort the questions in question sets
    // related to that order, in the same order field as the loose questions.
    const looseAnswers = answers.filter((a) => !a.question_set_uuid);
    const orderedAnswers = questionSetOrder
      .concat(looseAnswers)
      .map((q) => {
        let items;
        if (q.uuid) {
          items = answers
            .filter((a) => a.question_set_uuid === q.uuid)
            .sort((a, b) => a.order - b.order);
        } else {
          items = [q];
        }
        return { order: q.order, items: items };
      })
      .sort((a, b) => a.order - b.order);

    const formattedOrderedAnswers = [];
    orderedAnswers.forEach((a) => a.items.forEach((i) => formattedOrderedAnswers.push(i)));
    return formattedOrderedAnswers;
  };

  return (
    <>
      {isCandidateList && (
        <Container
          className="application-section application-confirmation-cards-section"
          style={{ border: '1px solid #e0e0e0', borderRadius: '4px', padding: '16px' }}
        >
          <div className="flex space-between">
            <HeaderLabel>Application</HeaderLabel>
            <DefaultContainer>
              {expanded.all ? (
                <div className="inline-block expand-all" onClick={collapseAllSections}>
                  Close all
                </div>
              ) : (
                <div className="inline-block expand-all" onClick={expandAllSections}>
                  Expand all
                </div>
              )}
            </DefaultContainer>
          </div>
          {user.experiences.length > 0 && (
            <CardsSection className="cards-section one">
              <CardTitle
                className="cards-expander-title experiences"
                onClick={() => toggleCardsSection('experiences')}
              >
                <span className="plus-sign">+</span>{' '}
                <span className="darker-text">Work History</span> -{' '}
                {user.experiences && user.experiences.length === 1
                  ? '1 Entry'
                  : `${user.experiences.length} Entries`}
              </CardTitle>
              <Section isExpanded={expanded.experiences}>
                <WorkExperienceSection experiences={user.experiences} />
              </Section>
            </CardsSection>
          )}

          {user.education.length > 0 && (
            <div className="cards-section">
              <CardTitle
                className="cards-expander-title education"
                onClick={() => toggleCardsSection('education')}
              >
                <span className="plus-sign">+</span> <span className="darker-text">Education</span>{' '}
                -{' '}
                {user.education && user.education.length === 1
                  ? '1 Entry'
                  : `${user.education.length} Entries`}
              </CardTitle>
              <Section isExpanded={expanded.education}>
                <Education education={user.education} simpleCard={true} />
              </Section>
            </div>
          )}

          {'exams' in user && user.exams.length > 0 && (
            <div className="cards-section">
              <CardTitle
                data-testid={ATSQuickProfileApplicationSection.EXAMS_EXPANDER}
                className="cards-expander-title exams"
                onClick={() => toggleCardsSection('exams')}
              >
                <span className="plus-sign">+</span> <span className="darker-text">Exams</span> -{' '}
                {user.exams && user.exams.length === 1 ? '1 Entry' : `${user.exams.length} Entries`}
              </CardTitle>
              <Section isExpanded={expanded.exams}>
                <Exam exams={user.exams.length > 0 ? user.exams : []} simpleCard={true} />
              </Section>
            </div>
          )}

          {districtApplication && districtApplication.custom_answers.length > 0 && (
            <div className="cards-section">
              <CardTitle
                className="cards-expander-title applicationQuestions"
                onClick={() => toggleCardsSection('applicationQuestions')}
              >
                <span className="plus-sign">+</span>{' '}
                <span className="darker-text">Application Questions</span> -{' '}
                {`${districtApplication.custom_answers.length} Entr${
                  districtApplication.custom_answers.length === 1 ? 'y' : 'ies'
                }`}
              </CardTitle>
              <Section isExpanded={expanded.applicationQuestions}>
                {sortApplicationQuestions(
                  districtApplication.custom_answers,
                  districtApplication.role.question_sets_order
                ).map(
                  (
                    item: {
                      question:
                        | string
                        | number
                        | boolean
                        | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                        | React.ReactFragment
                        | React.ReactPortal;
                      answer:
                        | string
                        | number
                        | boolean
                        | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                        | React.ReactFragment
                        | React.ReactPortal;
                    },
                    index: React.Key
                  ) => (
                    <div key={index} className="mb1" id="application-questions">
                      <ApplicationQuestion>
                        <b>Q. {item.question}</b>
                      </ApplicationQuestion>
                      <ApplicationQuestion>A. {item.answer}</ApplicationQuestion>
                    </div>
                  )
                )}
              </Section>
            </div>
          )}

          {'answers' in user && user.answers && user.answers.length > 0 && (
            <div className="cards-section">
              <CardTitle
                className="cards-expander-title open_response_questions"
                onClick={() => toggleCardsSection('answers')}
              >
                <span className="plus-sign">+</span>{' '}
                <span className="darker-text">Candidate Profile Questions</span> -{' '}
                {user.answers.length === 1 ? '1 Entry' : `${user.answers.length} Entries`}
              </CardTitle>
              <Section isExpanded={expanded.answers}>
                {user.answers.map((answer, index) => (
                  <div className="mb1" key={index}>
                    <div>
                      <strong>
                        <b>{answer.question}</b>
                      </strong>
                    </div>
                    <div>{answer.answer}</div>
                  </div>
                ))}
              </Section>
            </div>
          )}

          {user.preference && (
            <QuickProfilePreferenceSection
              toggleCardsSection={toggleCardsSection}
              expanded={expanded}
              preference={user.preference}
              schoolPreferencesAnswer={districtApplication?.school_preferences_answer}
            />
          )}
        </Container>
      )}
      {isTalentMarketplace && (
        <MarketplaceProfileDetailsContainer>
          <div>
            <MarketplaceAttachmentsHeaderContainer>
              <MarketplaceHeaderLabel>Profile Details</MarketplaceHeaderLabel>
              <ExpandAllContainer>
                {expanded.all ? (
                  <div className="inline-block expand-all" onClick={collapseAllSections}>
                    Close all
                  </div>
                ) : (
                  <div
                    className="inline-block expand-all"
                    onClick={expandAllSections}
                    style={{ marginBottom: '8px' }}
                  >
                    Expand all
                  </div>
                )}
              </ExpandAllContainer>
            </MarketplaceAttachmentsHeaderContainer>
            <hr />
            {user.experiences !== undefined && user.experiences.length > 0 && (
              <CardSection>
                <div
                  className="cards-expander-title experiences"
                  onClick={() => toggleCardsSection('experiences')}
                >
                  <span className="plus-sign">+</span>{' '}
                  <span className="darker-text">Work History</span> -{' '}
                  {user.experiences && user.experiences.length === 1
                    ? '1 Entry'
                    : `${user.experiences.length} Entries`}
                </div>
                <TalentSection isExpanded={expanded.experiences}>
                  <WorkExperienceSection experiences={user.experiences} />
                </TalentSection>
              </CardSection>
            )}
            <hr />
            {user.education !== undefined && user.education.length > 0 && (
              <CardSection>
                <div
                  className="cards-expander-title education"
                  onClick={() => toggleCardsSection('education')}
                >
                  <span className="plus-sign">+</span>{' '}
                  <span className="darker-text">Education</span> -{' '}
                  {user.education && user.education.length === 1
                    ? '1 Entry'
                    : `${user.education.length} Entries`}
                </div>
                <TalentSection isExpanded={expanded.education}>
                  <Education education={user.education} simpleCard={true} />
                </TalentSection>
              </CardSection>
            )}
            <hr />
            {user.preference && (
              <CardSection>
                <QuickProfilePreferenceSection
                  toggleCardsSection={toggleCardsSection}
                  expanded={expanded}
                  preference={user.preference}
                  schoolPreferencesAnswer={districtApplication?.school_preferences_answer}
                  isTalentMarketplace
                />
              </CardSection>
            )}
            <hr />
            {'exams' in user && user.exams !== undefined && user.exams.length > 0 && (
              <CardSection>
                <div
                  className="cards-expander-title exams"
                  onClick={() => toggleCardsSection('exams')}
                  data-testid={ATSQuickProfileApplicationSection.EXAMS_EXPANDER}
                >
                  <span className="plus-sign">+</span>{' '}
                  <span className="darker-text" style={{ fontWeight: 800 }}>
                    Exams
                  </span>{' '}
                  -{' '}
                  {user.exams && user.exams.length === 1
                    ? '1 Entry'
                    : `${user.exams.length} Entries`}
                </div>
                <TalentSection isExpanded={expanded.exams}>
                  <Exam exams={user.exams.length > 0 ? user.exams : []} simpleCard={true} />
                </TalentSection>
              </CardSection>
            )}
          </div>
        </MarketplaceProfileDetailsContainer>
      )}
    </>
  );
}

const CardsSection = styled.div`
  @media print {
    border: none !important;
  }
`;

const ApplicationQuestion = styled.div`
  @media print {
    margin: 0 !important;
    padding: 0 !important;
  }
`;

const Container = styled.div`
  @media print {
    border: none !important;
    font-size: ${nimbleTheme.typography.print.fontSize} !important;
    padding: 0 !important;
  }
`;

const HeaderLabel = styled.p`
  color: #444444;
  font-size: 19px;
  font-weight: 400;
  margin-bottom: 1rem;

  @media print {
    font-size: ${nimbleTheme.typography.body3.fontSize} !important;
    font-weight: ${nimbleTheme.typography.fontWeightBold};
    margin: 0;
  }
`;

const MarketplaceHeaderLabel = styled.div`
  color: #4F4F4F;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  display; flex;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
`;

const MarketplaceProfileDetailsContainer = styled.div`
  box-sizing: border-box;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  width: 95%;
  padding-left: 19px;
`;

const MarketplaceAttachmentsHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 16px;
`;

const ExpandAllContainer = styled.div`
  display: flex;
  align-self: end;
  justify-self: end;
  justify-content: end;
  justify-items: end;
  width: 100%;
  padding-right: 35px;
  color: #00a37d;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  align-items: center;
`;

const Section = styled.div<SectionProps>`
  ${(props) =>
    props.isExpanded
      ? `
    max-height: max-content;
    overflow: auto;
    transition: max-height 0.3s ease-in-out;
    padding-top: 16px;

    @media print{
      max-height: initial;
      height: max-content;
      overflow: visible;
    }
  `
      : `
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  `}

  @media print {
    max-height: max-content;
    overflow: auto;
    padding-top: ${nimbleTheme.spacing(2)};
  }
`;

export const CardTitle = styled.div`
  @media print {
    font-weight: ${nimbleTheme.typography.fontWeightBold} !important;
  }
`;

const TalentSection = styled.div<SectionProps>`
  ${(props) =>
    props.isExpanded
      ? `
    max-height: fit-content;
    overflow: auto;
    transition: max-height 0.3s ease-in-out;
    padding-top: 16px;

    @media print{
      max-height: initial;
      height: max-content;
      overflow: visible;
    }
  `
      : `
    max-height: 0;
    overflow: hidden;
    padding-top: 16px;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  `}
`;

const CardSection = styled.div`
  padding-top: 16px;
`;

QuickProfileApplicationSection.propTypes = {
  user: PropTypes.object.isRequired,
};
