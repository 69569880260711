import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import JobPostingAPI from 'features/Connect/api/jobPostingsAPI';
import { sortVacancies } from 'features/Connect/features/JobDiscovery/utils';
import { useEffect, useState } from 'react';
import { SwipeableDrawer } from 'sharedComponents/Drawer';
import { Vacancy } from 'types/connectTypes';
import { MoreInfoSliderProps } from '../types';
import { VacancyAccordionRow } from '../VacancySection/VacancySectionAccordionRow';
import { HeaderSection } from './MoreInfoSliderHeaderSection';
import { SchoolDetailsSection } from './MoreInfoSliderSchoolDetailsSection';
import { LoadingSpinner } from 'sharedComponents/LoadingSpinner/loadingSpinner';
import { ConnectMoreInfoSliderDataTestIds } from 'data-testids/ConnectDataTestIds';
import { useConnectContext } from 'features/Connect/features/Context/ConnectContextProvider';

export const MoreInfoSlider: React.FC<MoreInfoSliderProps> = ({
  closeHandler,
  openHandler,
  anchor,
  school,
  dataTestId,
  isCandidateDashboard = false,
}) => {
  const { isMoreInfoSliderOpen, selectedVacancyId } = useConnectContext();
  const [sliderVacancies, setSliderVacancies] = useState<Vacancy[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [initialVacancyId] = useState<string>(selectedVacancyId);

  useEffect(() => {
    const fetchVacancies = (ncesSchoolId) => {
      setIsLoading(true);
      JobPostingAPI.fetchJobPostings(ncesSchoolId)
        .then((response) => {
          setSliderVacancies(response);
        })
        .catch((error) => {
          console.error('Error fetching vacancies:', error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    if (school) {
      fetchVacancies(school.nces_id);
    }
  }, [school]);

  const sortedVacancies =
    initialVacancyId !== '0' ? sortVacancies(sliderVacancies, initialVacancyId) : sliderVacancies;

  return (
    <SwipeableDrawer
      closeHandler={closeHandler}
      openHandler={openHandler}
      isOpen={isMoreInfoSliderOpen}
      anchor={anchor}
      dataTestId={`${dataTestId}-${school?.name}`}
    >
      <MoreInfoSliderContainer>
        <HeaderSection school={school} />
        <SchoolDetailsSection school={school} />
        {isLoading ? (
          <LoadingSpinner
            dataTestId={ConnectMoreInfoSliderDataTestIds.LOADING_SPINNER}
            style={{ textAlign: 'center', paddingTop: 12 }}
            color="primary"
          />
        ) : (
          <VacancyAccordionRow
            school={school}
            vacancies={sortedVacancies}
            vacancyId={selectedVacancyId}
            isCandidateDashboard={isCandidateDashboard}
          />
        )}
      </MoreInfoSliderContainer>
    </SwipeableDrawer>
  );
};

const MoreInfoSliderContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(3),
}));
