import { Box, useTheme } from '@mui/material';

import { Alert } from 'sharedComponents/Alert';
import { AlertContent, RecommendationText, ScoreAndCompletion } from '../styles';
import { ATSConnectHiringRequestCandidateFeedbackTestIds } from 'data-testids/ATS';
import { CompletedText, ScoreDisplay } from './Statuses';
import { getFinalRecommendationData } from '../utils';
import { RecommendationAlertProps } from '../../types';

export const RecommendationStatus: React.FC<RecommendationAlertProps> = ({
  completionText,
  cumulativeScore,
  finalRecommendation,
  maxCumulativeScore,
  showTotalOnPreview,
}) => {
  const theme = useTheme();
  const finalRecommendationToRender = getFinalRecommendationData(finalRecommendation);
  const hasTotalScore = Boolean(cumulativeScore);

  return (
    <Alert
      dataTestId={ATSConnectHiringRequestCandidateFeedbackTestIds.RECOMMENDATION_SECTION}
      type={finalRecommendationToRender.alertType}
      sx={{
        width: '100%',
        marginTop: theme.spacing(2),
        '.MuiAlert-message': {
          width: '100%',
        },
      }}
    >
      <AlertContent>
        <RecommendationText
          data-testid={ATSConnectHiringRequestCandidateFeedbackTestIds.OVERALL_RECOMMENDATION_TEXT}
        >
          {finalRecommendationToRender.text}
        </RecommendationText>
        <ScoreAndCompletion>
          {showTotalOnPreview && hasTotalScore && (
            <ScoreDisplay score={cumulativeScore} maxScore={maxCumulativeScore} />
          )}
          <Box
            sx={{ display: 'flex' }}
            data-testid={
              ATSConnectHiringRequestCandidateFeedbackTestIds.RECOMMENDATION_COMPLETED_TEXT
            }
          >
            <CompletedText text={completionText} />
          </Box>
        </ScoreAndCompletion>
      </AlertContent>
    </Alert>
  );
};
