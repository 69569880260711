import { Box, Card, Divider, styled, Theme, Typography, useMediaQuery } from '@mui/material';
import { ConnectCandidateMatchCardDataTestIds } from 'data-testids/ConnectDataTestIds';
import { MatchCardButtonGroup } from 'features/Connect/components/Buttons/MatchCardButtonGroup';
import { PrincipalMessage } from 'features/Connect/components/PrincipalMessage/PrincipalMessage';
import { MatchCardSchoolSection } from './MatchCardSchoolSection';
import { CandidateMatchCardInterface } from 'features/Connect/features/ConnectCandidatePortalDashboards/CandidateMatchDashboard/utils/types';
import { useConnectContext } from 'features/Connect/features/Context/ConnectContextProvider';
import { useEffect } from 'react';
import jobPostingsAPI from 'features/Connect/api/jobPostingsAPI';
import { PreferenceMatchesSection } from '../PreferenceMatches/PreferenceMatchesSection';

export const CandidateMatchCard: React.FC<CandidateMatchCardInterface> = ({
  school,
  principal,
  principalMessage,
  preferenceMatches,
  candidateMatchDashboardActions,
  matchCardVacancies,
}) => {
  const { connectContextActions, isLoading } = useConnectContext();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  useEffect(() => {
    const fetchVacancies = (ncesSchoolId) => {
      connectContextActions.setIsLoading(true);
      jobPostingsAPI
        .fetchJobPostings(ncesSchoolId)
        .then((response) => {
          candidateMatchDashboardActions.setMatchCardVacancies(response);
        })
        .catch((error) => {
          console.error('Error fetching vacancies:', error);
        })
        .finally(() => {
          connectContextActions.setIsLoading(false);
        });
    };

    if (school) {
      fetchVacancies(school.nces_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [school]);

  return (
    <MatchCardContainer>
      <MatchCardTitleContainer
        data-testid={ConnectCandidateMatchCardDataTestIds.CARD_TITLE_CONTAINER}
      >
        <MatchCardTitle data-testid={ConnectCandidateMatchCardDataTestIds.CARD_TITLE_TEXT}>
          This principal wants to connect!
        </MatchCardTitle>
      </MatchCardTitleContainer>
      <MatchCardHeaderContainer data-testid={ConnectCandidateMatchCardDataTestIds.HEADER_CONTAINER}>
        <PrincipalMessage principal={principal} principalMessage={principalMessage} />
        {preferenceMatches && (
          <PreferenceMatchesSection school={school} preferenceMatches={preferenceMatches} />
        )}
      </MatchCardHeaderContainer>
      <MatchCardDivider
        variant="middle"
        data-testid={ConnectCandidateMatchCardDataTestIds.DIVIDER}
      />
      {school && (
        <MatchCardBodyContainer data-testid={ConnectCandidateMatchCardDataTestIds.BODY_CONTAINER}>
          <MatchCardSchoolSection
            school={school}
            principal={principal}
            isLoading={isLoading}
            vacancies={matchCardVacancies}
          />
        </MatchCardBodyContainer>
      )}
      {!isMobile && (
        <MatchCardButtonGroupContainer
          data-testid={ConnectCandidateMatchCardDataTestIds.BUTTON_GROUP_CONTAINER}
        >
          <MatchCardButtonGroup
            candidateMatchDashboardActions={candidateMatchDashboardActions}
            school={school}
          />
        </MatchCardButtonGroupContainer>
      )}
    </MatchCardContainer>
  );
};

const MatchCardContainer = styled(Card)(({ theme }) => ({
  position: 'relative',
  height: '100%',
  width: '100%',
  display: 'flex',
  padding: '68px 48px 48px 48px',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(4),
  borderRadius: theme.shape.borderRadius * 2,
  border: '1px solid #E0E0E0',
  background: '#FFF',

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2, 2, 4, 2),
  },
}));

const MatchCardHeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    padding: `${theme.spacing(5)} 0 0`,
  },
}));

const MatchCardTitleContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  display: 'flex',
  width: '100%',
  height: '35px',
  padding: `10px ${theme.spacing(2)}`,
  alignItems: 'center',
  gap: '10px',
  flexShrink: 0,
  borderRadius: '15px 15px 0px 0px',
  background: '#F4FBFA',
}));

const MatchCardTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: theme.typography.body2.fontSize,
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '19px',
}));

const MatchCardDivider = styled(Divider)(({ theme }) => ({
  width: '100%',
  height: '1px',
  borderColor: '#E0E0E0',
  backgroundColor: theme.palette.divider,
}));

const MatchCardBodyContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: theme.spacing(2),
}));

const MatchCardButtonGroupContainer = styled(Box)({
  width: '100%',
});
