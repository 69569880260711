import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';

import ApplicationsNote from 'components/Profile/ApplicationsNote';
import SchoolapplicationsNote from 'components/Profile/SchoolapplicationsNote';
import SourceSection from 'components/CandidatesList/QuickProfile/SourceSection';
import ExternalInternalToggle from 'components/Profile/ExternalInternalToggle';
import ReferenceStatusPreview from 'components/Profile/ReferenceStatusPreview';

import { appStatusType } from 'utils/enums';
import { BulletPoint } from 'ui-kit/icons';
import { formatPhoneNumber } from 'utils/util';
import auth from 'utils/auth';
import { BoxContainer } from './QuickView.styles';
import { OverviewSectionQuickProfileProps } from './types';

export default function OverviewSectionQuickProfile({
  isDistrictAdmin,
  isDistrictUser,
  fullUser,
  application,
  schoolapplications,
  districtRole,
  newApplicationStatuses,
  replaceCandidateInState,
  refreshCandidateInList,
  updateFullUser,
  fetchEvents,
  urlPrefix,
  sources,
  isCandidateList,
  isTalentMarketplace,
}: OverviewSectionQuickProfileProps): JSX.Element {
  const getPhoneNumber = () => {
    let phone_number;

    if (fullUser.profile.phone_cell) {
      phone_number = fullUser.profile.phone_cell;
    } else if (fullUser.profile.phone_work) {
      phone_number = fullUser.profile.phone_work;
    } else if (fullUser?.district_phone) {
      phone_number = fullUser.district_phone;
    } else {
      phone_number = '';
    }

    if (phone_number) {
      return formatPhoneNumber(phone_number);
    } else {
      return null;
    }
  };

  const getContactInfo = () => {
    const phoneNumber = getPhoneNumber();
    if (phoneNumber) {
      return [phoneNumber, fullUser.email].join(', ');
    } else {
      return fullUser.email;
    }
  };

  return (
    <>
      {isCandidateList && (
        <BoxContainer>
          <HeaderLabel>Overview</HeaderLabel>

          {fullUser.applications.length > 0 && (
            <ApplicationsNote
              userFirstName={fullUser.first_name}
              applications={fullUser.applications}
            />
          )}

          {[
            appStatusType.hiring_pool,
            appStatusType.post_hiring_pool,
            appStatusType.hired,
            appStatusType.onboarding,
          ].includes(application?.new_status.status_type) && (
            <SchoolapplicationsNote
              user={fullUser}
              replaceCandidateInState={replaceCandidateInState}
              fetchEvents={fetchEvents}
              role={districtRole}
              schoolapplications={schoolapplications}
              newApplicationStatuses={newApplicationStatuses}
              isEditable={isDistrictUser}
            />
          )}

          <LineItemGridContainer>
            <LineItem>
              <BulletPoint />
              <p>
                <b>{fullUser.profile.years_experience} years</b> of experience
              </p>
            </LineItem>
            <LineItem>
              <BulletPoint />
              <p>
                <b style={{ marginRight: '4px' }}>Contact:</b>
                {getContactInfo()}
              </p>
            </LineItem>
            <LineItem>
              <BulletPoint />
              <div className="flex">
                {isDistrictAdmin ? (
                  <SourceSection
                    fullUser={fullUser}
                    onProspectList={false}
                    updateFullUser={updateFullUser}
                    sources={sources}
                  />
                ) : (
                  <>
                    <b style={{ marginRight: '4px' }}>Source:</b>
                    <p>
                      {fullUser.district_source && fullUser.district_source !== 'none'
                        ? fullUser.district_source.label
                        : 'None'}
                    </p>
                  </>
                )}
              </div>
            </LineItem>
            <LineItem>
              <BulletPoint />
              <ExternalInternalToggle
                user={fullUser}
                district={auth.getUser().profile.district}
                replaceCandidateInState={replaceCandidateInState}
                refreshCandidateInList={refreshCandidateInList}
                fetchEvents={fetchEvents}
              />
            </LineItem>
            <LineItem>
              <BulletPoint />
              <ReferenceStatusPreview
                urlPrefix={urlPrefix}
                userId={fullUser?.id}
                applicationId={application?.id}
                references={fullUser.district_references}
              />
            </LineItem>
            {fullUser.next_interview && (
              <LineItem>
                <BulletPoint />
                <div>
                  <span>
                    Signed up for{' '}
                    <span className="underline" data-tip data-for="interview-time">
                      interview
                    </span>{' '}
                    via {fullUser.next_interview.integration_label}
                  </span>
                  <ReactTooltip id="interview-time" effect="solid">
                    {moment(fullUser.next_interview.when).format('dddd, MMM D [at] h:mm a')}
                  </ReactTooltip>
                </div>
              </LineItem>
            )}
          </LineItemGridContainer>
        </BoxContainer>
      )}
      {isTalentMarketplace && (
        <MarketplaceOverviewContainer>
          <MarketplaceHeaderLabel>Overview</MarketplaceHeaderLabel>
          <MarketplaceLineItemContainer>
            <MarketplaceLineItem>
              <p>
                <b>{fullUser?.profile?.years_experience} years</b> of experience
              </p>
            </MarketplaceLineItem>
          </MarketplaceLineItemContainer>
        </MarketplaceOverviewContainer>
      )}
    </>
  );
}

const LineItemGridContainer = styled.div`
  margin-top: 14px;
  display: grid;
  grid-gap: 10px;
`;

const LineItem = styled.div`
  color: #777777 !important;
  font-weight: 400;
  min-height: 22px;

  display: grid;
  grid-template-columns: 10px 1fr;
  grid-gap: 12px;
  align-items: baseline;
`;

const HeaderLabel = styled.h4`
  color: #444444;
  font-size: 19px;
  font-weight: 400;
  margin-bottom: 1rem;
`;

const MarketplaceHeaderLabel = styled.div`
  color: #4f4f4f;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  display: flex;
  align-items: center;
  order: 0;
  flex-grow: 0;
  margin-bottom: 8px;
`;

const MarketplaceOverviewContainer = styled.div`
  box-sizing: border-box;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  width: 95%;
  padding-top: 16px;
  padding-left: 19px;
`;

const MarketplaceLineItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const MarketplaceLineItem = styled.div`
  color: #828282;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  font-size: 14px;
  font-style: normal;
  line-height: 24px;
  margin-bottom: 16px;
  align-items: center;
  order: 1;
  flex-grow: 0;
`;
