import { Theme } from '@mui/material';

export const baseLabelStyles = (theme: Theme) => ({
  color: theme.palette.text.primary,
  fontSize: theme.typography.h3.fontSize,
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightRegular,
  letterSpacing: '0.15px',
  lineHeight: '24px',
  '& .MuiFormControlLabel-label': {
    margin: `${theme.spacing(0.5)} 0`,
  },
});
