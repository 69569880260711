import { Autocomplete, Grid, styled, TextField, Typography } from '@mui/material';
import React from 'react';
import { MapPin } from 'lucide-react';
import { LocationSearchInputProps } from './types';

const AutocompleteOption = (props: React.HTMLAttributes<HTMLLIElement>, option: any) => {
  return (
    <li {...props}>
      <Grid container alignItems="center" gap={1}>
        <Grid item>
          <MapPin size={20} />
        </Grid>
        <Grid item xs>
          <Typography variant="subtitle1">{option.mainText}</Typography>
          {option.secondaryText && (
            <Typography variant="body2" color="text.secondary">
              {option.secondaryText}
            </Typography>
          )}
        </Grid>
      </Grid>
    </li>
  );
};

const LocationSearchInput = ({
  onLocationChange,
  predictions,
  inputValue,
  dataTestId,
  setInputValue,
  updateFreeTypedLocation,
  label,
  helperText,
  error,
  sx,
}: LocationSearchInputProps) => {
  return (
    <Autocomplete
      freeSolo
      options={predictions}
      inputValue={inputValue}
      data-testid={dataTestId}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      onInputChange={(_, newInputValue) => {
        if (!newInputValue) {
          onLocationChange(null);
        }
        setInputValue(newInputValue);
      }}
      onChange={(_, newValue: any) => {
        onLocationChange(newValue?.description);
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter' && predictions.length === 0) {
          event.preventDefault();

          updateFreeTypedLocation(inputValue.trim());
        }
      }}
      onBlur={() => {
        if (inputValue.trim()) {
          updateFreeTypedLocation(inputValue.trim());
        }
      }}
      renderInput={(params) => (
        <StyledTextField
          {...params}
          label={label}
          placeholder="Enter your street address (e.g., 123 Main St, New York, NY 10001)"
          size="small"
          helperText={helperText}
          error={error}
          data-testid={error ? `${dataTestId}-error` : null}
        />
      )}
      renderOption={AutocompleteOption}
      sx={sx}
    />
  );
};

export default LocationSearchInput;

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: theme.palette.white.main,
  },
}));
