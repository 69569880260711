import styled from 'styled-components';

import Row from './Row';

export default function List({ data, setQuestionSetToEdit, openQuestionSetModal }) {
  return (
    <>
      <HeaderContainer>
        <SectionLarger>Title</SectionLarger>
        <SectionLarger>Question Type</SectionLarger>
      </HeaderContainer>
      {(!data || data.length === 0) && (
        <EmptyContainer>
          <PlaceholderText>
            Click "+ New question set" button above to create a new question set
          </PlaceholderText>
        </EmptyContainer>
      )}
      {!!data &&
        data.map((questionSet) => (
          <Row
            key={questionSet.uuid}
            questionSet={questionSet}
            setQuestionSetToEdit={setQuestionSetToEdit}
            openQuestionSetModal={openQuestionSetModal}
          />
        ))}
    </>
  );
}

const EmptyContainer = styled.div`
  height: 208px;
  border: 1px dashed #949494;
  border-top: none;
  border-radius: 0 0 3px 3px;
  display: flex;
  align-items: center;
`;

const PlaceholderText = styled.p`
  font-size: 17px;
  color: #bdbdbd;
  margin: 0 auto;
`;

const HeaderContainer = styled.div`
  color: #999999;
  background-color: #ffffff;
  display: flex;
  line-height: 16px;
  font-size: 12px;
  padding: 7px 16px;
  border: 1px solid #eee;
`;

const Section = styled.p`
  flex: 0 0 20%;
  padding-right: 16px;
`;

const SectionLarger = styled(Section)`
  flex: 0 0 32%;
`;
