import { CandidatesListContainer } from '../styles';
import { ConnectCandidateRow } from './ConnectCandidateRow';
import { useState } from 'react';

import { ConnectCandidatesListProps, CurrentlyOpenModal } from '../types';
import { mockFeedbackData } from './CandidateFeedback/mockData';
import auth from 'utils/auth';
import { Box, Link, Typography, useTheme } from '@mui/material';

export const ConnectCandidatesList: React.FC<ConnectCandidatesListProps> = ({ results }) => {
  const [currentModalOpen, setCurrentModalOpen] = useState<CurrentlyOpenModal | null>(null);
  const theme = useTheme();

  const handleModalClose = () => setCurrentModalOpen(null);

  const handleModalOpen = ({ candidateId, modalType, roleId }: CurrentlyOpenModal) =>
    setCurrentModalOpen({ candidateId, modalType, roleId });

  if (results.length === 0) {
    const candidatesListLink = auth.isSchoolAdmin() ? '/school/candidates' : '/district/candidates';

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography sx={{ paddingTop: theme.spacing(2) }}>
          You have no hiring requests at the moment
        </Typography>

        <Link sx={{ paddingTop: theme.spacing(1) }} href={candidatesListLink}>
          View all candidates
        </Link>
      </Box>
    );
  }

  return (
    <CandidatesListContainer>
      {results.map((result) => (
        <ConnectCandidateRow
          actions={{
            scorecard: false,
            reference: false,
            application: false,
          }}
          candidate={result.candidate}
          created={result.created}
          currentModalOpen={currentModalOpen}
          isModalOpen={
            currentModalOpen?.candidateId === result.candidate.id &&
            currentModalOpen?.roleId === result.role.id
          }
          key={result.role.id}
          notesCount={result.notes_count}
          numberOfScorecards={mockFeedbackData.length}
          onCloseModal={handleModalClose}
          onOpenModal={handleModalOpen}
          requestor={result.requester}
          role={result.role}
        />
      ))}
    </CandidatesListContainer>
  );
};
