import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { PrimaryButton, SecondaryButton } from 'sharedComponents/Buttons';
import { ConnectProfileFlowButtonGroupProps } from 'types/connectTypes';
import { Grid, Theme, useTheme } from '@mui/material';

export function ConnectProfileFlowButtonGroup({
  secondaryButton,
  primaryButton,
  dataTestId,
}: ConnectProfileFlowButtonGroupProps): React.ReactElement {
  const theme = useTheme();
  return (
    <>
      {secondaryButton ? (
        <Grid
          container
          gap={theme.spacing(1)}
          direction="row"
          wrap="nowrap"
          data-testid={dataTestId}
        >
          <Grid item xs={6}>
            <SecondaryButton
              startIcon={<ArrowBackIosRoundedIcon />}
              onClick={secondaryButton.secondaryAction}
              dataTestId={secondaryButton.dataTestId}
              sx={buttonStyle(theme)}
              size="large"
            >
              {secondaryButton.secondaryButtonLabel}
            </SecondaryButton>
          </Grid>
          <Grid item xs={6}>
            <PrimaryButton
              dataTestId={primaryButton.dataTestId}
              onClick={primaryButton.primaryAction}
              isLoading={primaryButton.isLoading ? true : false}
              disabled={primaryButton.disabled}
              endIcon={<ArrowForwardIosRoundedIcon />}
              loadingSpinnerColor="inherit"
              sx={buttonStyle(theme)}
              size="large"
            >
              {primaryButton.primaryButtonLabel}
            </PrimaryButton>
          </Grid>
        </Grid>
      ) : (
        <PrimaryButton
          dataTestId={primaryButton.dataTestId}
          disabled={primaryButton.disabled}
          isLoading={primaryButton.isLoading ? true : false}
          size="large"
          loadingSpinnerColor="inherit"
          sx={buttonStyle(theme)}
          endIcon={<ArrowRightAltIcon fontSize="large" />}
          onClick={primaryButton.primaryAction}
        >
          {primaryButton.primaryButtonLabel}
        </PrimaryButton>
      )}
    </>
  );
}

const buttonStyle = (theme: Theme) => ({
  width: '100%',
  borderRadius: theme.shape.borderRadius * 0.5,
  marginTop: theme.spacing(3),
  height: '42px',
});
