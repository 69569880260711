import CloseIcon from '@mui/icons-material/Close';
import { IconButton, SxProps } from '@mui/material';

export interface CloseButtonProps {
  dataTestId?: string;
  disabled?: boolean;
  onClick: () => void;
  sx?: SxProps;
  size?: 'small' | 'medium' | 'large';
}

export const CloseButton: React.FC<CloseButtonProps> = ({
  dataTestId,
  disabled,
  onClick,
  sx,
  size,
}) => {
  return (
    <IconButton disabled={disabled} data-testid={dataTestId} onClick={onClick} sx={sx} size={size}>
      <CloseIcon />
    </IconButton>
  );
};
