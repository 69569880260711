import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import applicationsAPI from 'api/applicationsAPI';
import roleSearchAPI from 'api/roleSearchAPI';
import usersAPI from 'api/usersAPI';
import { User, JobviewJob, HasApplicationStatus, CandidateApplicationConnectData } from 'types';
import hiringRequestsAPI from 'api/hiringRequestsAPI';
import {
  NewHiringRequestsAPIResponse,
  OldHiringRequestsAPIResponse,
} from '../HiringRequests/types';

export const fetchActiveRoles = (inputValue: string): Promise<JobviewJob[]> => {
  return roleSearchAPI.searchActiveDistrictRoles(inputValue).then((res) => res.results);
};

export const useQuerySearchActiveRoles = (
  inputValue: string,
  options: Partial<UseQueryOptions<JobviewJob[]>> = {}
): UseQueryResult<JobviewJob[]> =>
  useQuery({
    ...options,
    queryKey: ['activeRoleSearchResults', inputValue],
    queryFn: () => fetchActiveRoles(inputValue),
  });

export const fetchCandidate = (candidateId: number): Promise<User> => {
  return usersAPI.fetchUser(candidateId).then((res) => res);
};

export const useQueryFetchCandidateForConnectATSIntegration = (
  candidateId: number
): UseQueryResult<User> => {
  return useQuery({
    queryKey: ['candidate', candidateId],
    queryFn: () => fetchCandidate(candidateId),
    retry: false,
    enabled: Number.isInteger(candidateId),
  });
};

export const fetchIfCandidateHasApplicationForRole = (
  roleId: number,
  candidateId: number
): Promise<HasApplicationStatus> => {
  return applicationsAPI.checkIfCandidateHasApplication(candidateId, roleId).then((res) => {
    return res;
  });
};

export const useQueryCheckIfCandidateHasApplication = (
  candidateId: number,
  roleId: number,
  enabled: boolean
): UseQueryResult<HasApplicationStatus> => {
  const queryKeyToUse = ['roleId', roleId, 'candidateId', candidateId];

  return useQuery({
    queryKey: queryKeyToUse,
    queryFn: () => fetchIfCandidateHasApplicationForRole(roleId, candidateId),
    enabled,
  });
};

export const createHiringRequestFromConnectWithNewEndpoint = (
  payload: CandidateApplicationConnectData
): NewHiringRequestsAPIResponse => {
  return hiringRequestsAPI.importCandidateFromConnect(payload).then((res) => res);
};

export const createHiringRequestFromConnectWithOldEndpoint = (
  payload: CandidateApplicationConnectData
): OldHiringRequestsAPIResponse => {
  return applicationsAPI.importCandidateFromConnect(payload).then((res) => res);
};
