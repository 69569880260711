import {
  ConnectPreferenceMatches,
  MatchActionsResponse,
  Principal,
  Vacancy,
} from 'types/connectTypes';
import { School } from 'types/connectTypes';
import { CandidateMatchDashboardActionsEnum } from './constants';
// TODO: Add types for the connect candidate portal dashboards.

export interface CandidateMatchDashboardActionsInterface {
  setIsModalOpen: (modalName: DashboardModalTypes, isOpen: boolean) => void;
  setMatchCardVacancies: (vacancies: Vacancy[]) => void;
  setTotalPendingMatches: (totalPendingMatches: number) => void;
  setHasPendingMatches: (hasPendingMatches: boolean) => void;
  setCurrentMatchIndex: (currentMatchIndex: number) => void;
  setIsEndOfMatches: (isEndOfMatches: boolean) => void;
  setDisplayTotal: (displayTotal: number) => void;
}

export type CandidateMatchDashboardActionTypes =
  | { type: CandidateMatchDashboardActionsEnum.SET_TOTAL_PENDING_MATCHES; payload: number }
  | { type: CandidateMatchDashboardActionsEnum.SET_CURRENT_MATCH_INDEX; payload: number }
  | {
      type: CandidateMatchDashboardActionsEnum.UPDATE_MATCH_STATUS;
      payload: { totalPendingMatches: number; currentMatchIndex: number };
    }
  | { type: CandidateMatchDashboardActionsEnum.INITIALIZE_MATCHES; payload: MatchActionsResponse }
  | { type: CandidateMatchDashboardActionsEnum.SET_HAS_PENDING_MATCHES; payload: boolean }
  | { type: CandidateMatchDashboardActionsEnum.SET_IS_END_OF_MATCHES; payload: boolean }
  | {
      type: CandidateMatchDashboardActionsEnum.SET_IS_MODAL_OPEN;
      modalName: DashboardModalTypes;
      payload: boolean;
    }
  | { type: CandidateMatchDashboardActionsEnum.SET_DISPLAY_TOTAL; payload: number }
  | { type: CandidateMatchDashboardActionsEnum.SET_MATCH_CARD_VACANCIES; payload: Vacancy[] };

export interface CandidateMatchDashboardState {
  totalPendingMatches: number;
  currentMatchIndex: number;
  isEndOfMatches: boolean;
  hasPendingMatches: boolean;
  totalPages: number;
  isLastMatch: boolean;
  displayTotal: number;
  modalStates: Record<DashboardModalTypes, boolean>;
  matchCardVacancies: Vacancy[];
}

export interface CandidateMatchDashboardPaginationHeaderProps {
  onChange: (newPage: number) => void;
  hasPendingMatches: boolean;
  totalPages: number;
  currentMatch: number;
  schoolName: string;
  isEndOfMatches: boolean;
  displayTotal: number;
}

export interface PrincipalMessageProps {
  principal: Principal;
  messageHeaderText?: string;
  principalMessage?: string;
  isMessageModal?: boolean;
}
export interface CandidateMatchCardInterface {
  school: School;
  principal?: Principal;
  principalMessage?: string;
  preferenceMatches?: ConnectPreferenceMatches;
  vacancies?: Vacancy[]; // TODO: Remove this once we have the api data
  selectedVacancyId?: string; // TODO: Remove this once we have the api data
  isLoading?: boolean;
  candidateMatchDashboardActions?: CandidateMatchDashboardActionsInterface;
  matchCardVacancies?: Vacancy[];
}

export interface MatchDashboardSchoolInfoProps {
  school: School;
  isMatchCard?: boolean;
}

export enum DashboardModalTypes {
  REJECT = 'REJECT',
  ACCEPT = 'ACCEPT',
  ALREADY_CONNECTED = 'ALREADY_CONNECTED',
  IN_APP_MESSAGING = 'IN_APP_MESSAGING',
}
