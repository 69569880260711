import { Typography, useTheme } from '@mui/material';

import { ATSConnectHiringRequestTestIds } from 'data-testids/ATS';
import { Detail, JobInfoContainer, Notes, Status, StatusAndNotes } from '../styles';
import { getStatusText } from 'components/CreateJobOrTemplateModal/utils';
import { RowJobInfoProps } from '../types';
import { useJobStatusToGetStylingForHiringRequests } from '../utils';
import CircleIcon from '@mui/icons-material/Circle';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';

export const RowJobInfo: React.FC<RowJobInfoProps> = ({ role, notesCount }) => {
  const theme = useTheme();
  const styling = useJobStatusToGetStylingForHiringRequests(role.active_status, role.status);
  const statusText = getStatusText(role.status, role.active_status);

  return (
    <JobInfoContainer>
      <Detail variant="heading7" data-testid={ATSConnectHiringRequestTestIds.JOB_TITLE}>
        {role.title}
      </Detail>
      <StatusAndNotes>
        <Status>
          <CircleIcon
            sx={{ color: styling.textColor, fontSize: '8px', marginRight: theme.spacing(0.5) }}
          />
          <Typography
            sx={{ color: styling.textColor }}
            data-testid={ATSConnectHiringRequestTestIds.JOB_STATUS}
          >
            {statusText}
          </Typography>
        </Status>
        <Notes data-testid={ATSConnectHiringRequestTestIds.NUMBER_OF_NOTES}>
          {notesCount}
          <ContentPasteIcon sx={{ fontSize: '13px', marginLeft: theme.spacing(0.5) }} />
        </Notes>
      </StatusAndNotes>
    </JobInfoContainer>
  );
};
