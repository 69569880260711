import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import { HiringRequestListAPIData } from './types';
import hiringRequestsAPI from 'api/hiringRequestsAPI';

export const useQueryGetHiringRequestListData = (
  districtId: number,
  options?: Omit<UseQueryOptions<HiringRequestListAPIData, AxiosError>, 'queryKey' | 'queryFn'>
): UseQueryResult<HiringRequestListAPIData, AxiosError> => {
  return useQuery({
    queryKey: ['hiringRequestsListData', districtId],
    queryFn: () => hiringRequestsAPI.getHiringRequests().then((res) => res),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options,
  });
};
