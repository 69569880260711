import auth from 'utils/auth';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';

import { AltModal } from 'ui-kit';
import ReferenceEditor from './ReferenceEditor';
import ReferenceList from './ReferenceList';
import ReferenceViewer from './ReferenceViewer';
import ReferenceNewRequest from './ReferenceNewRequest';

import usersAPI from 'api/usersAPI';
import ReferenceTemplateListAPI from 'api/referenceTemplateListAPI';

import { refStatus, refContactType } from 'utils/enums';
import { OnRequestArgs, ReferenceModalProps, ReferenceModalState } from './types';
import { Component } from 'react';
import { FetchedAuthUser, ReferenceCheck } from 'types';

const LIST = 'list';
const CREATE = 'create';
const VIEW = 'view';
const EDIT = 'edit';
const COMPLETING = 'completing';
const REQUEST = 'request';

class ReferenceModal extends Component<ReferenceModalProps, ReferenceModalState> {
  districtId: number;
  ignoreLastFetch: boolean;
  user: FetchedAuthUser;
  state: ReferenceModalState;

  constructor(props: ReferenceModalProps) {
    super(props);
    this.state = {
      defaultReferenceTemplateId: null,
      lastSavedTime: null,
      mode: props.referenceType === refContactType.phone ? CREATE : LIST,
      referenceCheck: {},
      referenceTemplates: [],
      requests: [],
      requestSendingSpinner: false,
      requestSuccessful: false,
      showListSection: props.referenceType !== refContactType.email,
      showRequestSection: props.referenceType === refContactType.email,
    };
    this.user = auth.getUser();
    this.districtId = this.user.profile.district.id;
  }

  componentDidMount(): void {
    this.fetchReferenceTemplates();
    this.fetchRequests();
    this.getUserDefaultReferenceTemplateId();
    if (this.props.selectedReference) {
      this.openExistingReferenceCheck();
    } else if (
      this.props.selectedDistrictReference &&
      this.props.referenceType === refContactType.email
    ) {
      this.showRequestSection();
    }
  }

  componentWillUnmount(): void {
    this.ignoreLastFetch = true;
  }

  close = (): void => {
    this.setState({ mode: LIST, referenceCheck: {}, requestSuccessful: false });
    this.props.onHide();
  };

  sendRequest = ({
    recipients,
    reference_template_id,
    message,
    subject,
    is_reminder,
    reference_id,
  }: OnRequestArgs): void => {
    this.setState({ requestSendingSpinner: true });
    axios
      .post(`/api/reference_requests/${this.props.user.id}/`, {
        recipients,
        reference_template_id,
        message,
        subject,
        candidate_id: this.props.user.id,
        application_id: this.props.application.id,
        role_title: this.props.application.role?.title ?? '',
        is_reminder,
        reference_id,
      })
      .then(() => {
        this.props.fetchEvents && this.props.fetchEvents();
        this.fetchRequests();
        this.setState({ requestSendingSpinner: false, requestSuccessful: true });
        setTimeout(this.removeCheckmark, 5000);
        if (this.props.updateReference) {
          recipients.forEach((reference) => {
            this.props.updateReference(reference.id, 'status', refStatus.pending, true);
          });
        }
      })
      .catch((err) => {
        console.error(err);
        this.setState({ requestSendingSpinner: false });
      });
  };

  getUserDefaultReferenceTemplateId = (): Promise<void> => {
    return usersAPI
      .getDefaultReferenceTemplateId(this.user.id)
      .then((defaultReferenceTemplateId) => this.setState({ defaultReferenceTemplateId }));
  };

  fetchReferenceTemplates = (): void => {
    ReferenceTemplateListAPI.fetchReferences().then((referenceTemplates) => {
      if (!this.ignoreLastFetch) {
        this.setState({ referenceTemplates });
      }
    });
  };

  fetchRequests(): void {
    axios.get(`/api/reference_requests/${this.props.user.id}/`).then((r) => {
      if (!this.ignoreLastFetch) {
        this.setState({ requests: r.data });
      }
    });
  }

  openExistingReferenceCheck = (): void => {
    let viewType = VIEW;

    if (this.user.email === this.props.selectedReference.submitted_by) {
      if (this.props.selectedReference.is_complete) {
        viewType = EDIT;
      } else {
        viewType = COMPLETING;
      }
    }

    this.setState({ mode: viewType, referenceCheck: { ...this.props.selectedReference } });
  };

  onBack = (): void => {
    this.setState({ mode: LIST, referenceCheck: {} });
  };

  showRequestSection = (): void => {
    this.setState({ mode: REQUEST });
  };

  removeCheckmark = (): void => {
    this.setState({ requestSuccessful: false });
  };

  onCreate = (): void => {
    if (this.state.referenceTemplates.length === 0) {
      return;
    }

    this.setState({
      referenceCheck: {},
      mode: CREATE,
    });
  };

  onEdit = (referenceCheck: ReferenceCheck): void => {
    this.setState({
      mode: EDIT,
      referenceCheck,
    });
  };

  onComplete = (referenceCheck: ReferenceCheck): void => {
    this.setState({
      mode: COMPLETING,
      referenceCheck,
    });
  };

  onView = (referenceCheck: ReferenceCheck): void => {
    this.setState({
      mode: VIEW,
      referenceCheck,
    });
  };

  referencePostSave = (
    referenceCheck: ReferenceCheck,
    referenceId: number,
    isAutosave: boolean,
    isDraft: boolean
  ): void => {
    if (isAutosave) {
      this.setState({ lastSavedTime: moment().format('MM/D/YYYY [at] hh:mm') });
    } else {
      // on actual submissions (non auto-save), go back to reference summary (LIST)
      this.setState({ mode: LIST });
      // update reference status
      let status = isDraft ? refStatus.draft : refStatus.complete;
      if (isAutosave && !referenceCheck.is_complete) {
        status = refStatus.draft;
      }

      this.props.updateReference(referenceId, 'status', status, true);
      this.props.fetchReferenceChecks();
    }
  };

  deleteReferenceCheck = (referenceCheckId: number): void => {
    this.props.deleteReferenceCheck(referenceCheckId);
    this.setState({
      mode: LIST,
    });
  };

  render(): React.ReactNode {
    return (
      <AltModal.AltModal isOpen={this.props.show} onClose={this.props.onHide}>
        <ReferenceHeader candidate={this.props.user} lastSavedTime={this.state.lastSavedTime} />
        {this.state.mode === LIST && (
          <ReferenceList
            referenceChecks={this.props.referenceChecks}
            requests={this.state.requests}
            onCreate={this.onCreate}
            onComplete={this.onComplete}
            onView={this.onView}
            onRequest={this.sendRequest}
            onCancel={this.props.onHide}
            loading={this.props.loading}
            showRequestSection={this.showRequestSection}
            updateReferenceType={this.props.updateReferenceType}
          />
        )}
        {this.state.mode === REQUEST && (
          <ReferenceNewRequest
            sendRequest={this.sendRequest}
            showRequestSection={this.showRequestSection}
            referenceTemplates={this.state.referenceTemplates}
            user={this.props.user}
            emailTemplatesList={this.props.emailTemplatesList}
            application={this.props.application}
            removeCheckmark={this.removeCheckmark}
            requestSuccessful={this.state.requestSuccessful}
            requestSendingSpinner={this.state.requestSendingSpinner}
            onBack={this.onBack}
            adminUsers={this.props.adminUsers}
            references={this.props.references}
            selectedDistrictReference={this.props.selectedDistrictReference}
          />
        )}
        {this.state.mode === VIEW && (
          <ReferenceViewer
            referenceCheck={this.state.referenceCheck}
            onBack={this.onBack}
            onEdit={this.onEdit}
          />
        )}
        {[EDIT, COMPLETING, CREATE].includes(this.state.mode) && (
          <ReferenceEditor
            mode={this.state.mode}
            referenceCheckToEdit={this.state.referenceCheck}
            // TODO: filter templates when permissions implemented
            referenceTemplates={this.state.referenceTemplates}
            adminUsers={this.props.adminUsers}
            additional_references={this.props.references}
            onBack={this.onBack}
            deleteReferenceCheck={this.deleteReferenceCheck}
            referencePostSave={this.referencePostSave}
            emailTaggedUsers={this.props.emailTaggedUsers}
            candidate={this.props.user}
            defaultReferenceTemplateId={this.state.defaultReferenceTemplateId}
            selectedDistrictReference={this.props.selectedDistrictReference}
          />
        )}
      </AltModal.AltModal>
    );
  }
}

export default ReferenceModal;

const ReferenceHeader = ({ candidate, lastSavedTime }) => (
  <AltModal.Title>
    <Header>
      References
      {candidate.first_name && ` for ${candidate.first_name} ${candidate.last_name}`}
    </Header>
    {lastSavedTime && <LightGreyText>(Last saved on {lastSavedTime})</LightGreyText>}
  </AltModal.Title>
);

const Header = styled.p`
  font-size: 20px;
  color: #000000;
`;

const LightGreyText = styled.span`
  font-size: 14px;
  color: #999999;
  margin-left: 26px;
`;
