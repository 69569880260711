import { Box, styled } from '@mui/material';

export const CPFContainer = styled(Box)(({ theme }) => ({
  flex: '0 1 50%',
  marginTop: '0 !important',
  paddingBottom: theme.spacing(1),
  paddingRight: theme.spacing(2),
  input: {
    border: '1px solid #d7d7d7',
    width: '100%',
  },
  [theme.breakpoints.down('mdlg')]: {
    flex: '0 1 100%',
  },
}));

export const DateContainer = styled(CPFContainer)({
  paddingRight: 0,
  input: {
    border: 'none',
  },
});
