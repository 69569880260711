import { UseMutationResult, UseMutationOptions } from '@tanstack/react-query';
import { ApplicationStatus } from './application';

declare global {
  interface Window {
    Intercom(update: string, params: { hide_default_launcher: boolean }): void;
    pendo?: any;
  }
}

export type Json = boolean | number | string | null | { [key: string]: Json } | Array<Json>;

export interface BasicLocationPreferencesProps {
  milesWithin: number;
  location: string;
  checked: boolean;
  showMapErrorInput: boolean;
  showMapErrorSelect: boolean;
  combinedPreferenceAndProfile: boolean;
  handleChangeSelect: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface Category {
  id: number;
  label: string;
  subcategories: Subcategory[];
  district_id: number;
}

export interface Credential {
  state: number | null;
  certificate: number | null;
  credential_exams_passed: Array<number>;
  credential_explanation: string;
  expiration_date: string | null;
  grade: CredentialDropdownField | null;
  id: number;
  issue_date: string | null;
  license_number: string | null;
  status: CredentialDropdownField | null;
  subject: CredentialDropdownField | null;
  nj_certificate?: number;
}

export interface Credential {
  id: number;
  value: number;
  label: string;
  key: number;
}

export interface CredentialDropdownField {
  id: number;
  label: string;
  value: string;
  key: number;
}

export interface CustomScoreCard {
  [key: string]: unknown;
}

export interface District {
  id: number;
  name: string;
  city: string | null;
  state: string | null;
  slug: string | null;
  ssn_required: boolean;
  birthday_required: boolean;
  indiana_district: boolean;
  has_onboarding_module: boolean;
  has_prospects_module: boolean;
  posting_only: boolean;
}

export interface DropdownSelectOption {
  value: number;
  label: string;
}

export interface FlatFileTransferInterface {
  id: string;
  name: string;
  district: Partial<District>;
  report_columns: ReportColumn[];
  application_statuses?: ApplicationStatus[];
  created?: string;
  modified?: string;
}

export interface FlatFileTransferPayloadPOST {
  name: string;
  district: Partial<District>;
  report_columns: string; // Note: backend expects JSON of "report_columns":["columns"]
  application_statuses?: ApplicationStatus[];
}

export interface FlatFileTransferResponseInterface {
  id: string;
  name: string;
  district: Partial<District>;
  report_columns: string;
  application_statuses?: ApplicationStatus[];
  created?: string;
  modified?: string;
}

export interface Grade {
  value: number;
  label: string;
  key: string;
}

export interface HRBPAssignment {
  id?: number;
  admin_users: number[];
  schools: number[];
  role: number | null;
}

export interface Jobboard {
  id: number;
}

export interface LocationPreferencesProps {
  milesWithin: number;
  location: string;
  checked: boolean;
  showMap: boolean;
  showMapErrorInput: boolean;
  showMapErrorSelect: boolean;
  mapUrl: string;
  combinedPreferenceAndProfile: boolean;
  handleChangeSelect: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  hadleSearchButton: (
    event: React.MouseEvent<HTMLButtonElement> | React.FocusEvent<HTMLInputElement>,
    location: string,
    milesWithin: number
  ) => void;
  handleAddressSearchOnEnter: (
    event: React.KeyboardEvent<HTMLInputElement>,
    location: string,
    milesWithin: number
  ) => void;
  handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export enum nimbleProduct {
  'connect' = 'connect',
  'ats' = 'ats',
}

export interface PathObj {
  innerText: string;
  alt: string | undefined;
  className: string | undefined;
}

export interface ReportColumn {
  id: string;
  title: string;
  type: string;
  column_value?: string;
  mapping?: Record<string, string>;
  profile_field?: string;
  custom_profile_field_id?: number;
  hellosign_template_id?: number | string;
  hellosign_field_name?: string;
  input_columns?: ReportColumn[];
  separator?: string;
  formats?: string[];
  template_id?: string;
  application_status_id?: string;
  role_field?: string;
  application_field?: string;
}

/**
 * Handles most instances of role/job templates
 */

export interface School {
  id: number;
  name: string;
  location: string;
  ftes_total?: number;
  visible_to_school_admin?: boolean;
  has_unfilled_vacancies?: boolean;
  isGroupHeader?: boolean;
  school_groups?: number[];
}

export interface Subcategory {
  id: number;
  label: string;
  state_id: number;
}

export interface Tag {
  id?: number;
  tag_id: number;
  option: 'Yes' | 'No' | 'Disagree' | 'Agree';
  label?: string;
}

export interface UniversalTask {
  id: number;
  title: string;
}

// types shares same generics as react-query itself
export type UseMutation<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
> = (
  options: UseMutationOptions<TData, TError, TVariables, TContext>
) => UseMutationResult<TData, TError, TVariables, TContext>;

export interface SignupSourcesCookie {
  referrer?: string;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_term?: string;
}

export enum JobSeekingStatus {
  ACTIVELY_SEEKING = 'actively_seeking',
  NOT_LOOKING = 'not_looking',
}
