import moment from 'moment';
import { CandidateFeedbackFinalRecommendation } from '../enum';
import { FinalRecommendation, FinalRecommendationDataReturned } from '../types';

export const getFinalRecommendationData = (
  finalRecommendation: FinalRecommendation
): FinalRecommendationDataReturned => {
  if (finalRecommendation === CandidateFeedbackFinalRecommendation.MAYBE) {
    return {
      alertType: 'warning',
      text: 'Maybe recommend',
    };
  } else if (finalRecommendation === CandidateFeedbackFinalRecommendation.YES) {
    return {
      alertType: 'success',
      text: 'Recommended',
    };
  } else {
    return {
      alertType: 'error',
      text: 'Do not recommend',
    };
  }
};

export const formatDateWithTime = (date: string): string => {
  return moment(date).format('M/D/YY [at] h:mma');
};

export const getCompletionText = (isComplete: boolean, date: string): string => {
  const formattedDate = formatDateWithTime(date);
  return isComplete ? `Completed on ${formattedDate}` : `Started on ${formattedDate}`;
};
