import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import FiveStarsImage from 'assets/five_stars.svg';
import { useTheme } from '@mui/material/styles';

import { Carousel } from 'sharedComponents/Carousel/Carousel';
import { valuePropSlides } from '../JobDiscovery/constants';

export function ValuePropCarousel(): React.ReactElement {
  const theme = useTheme();
  return (
    <SliderContainer>
      <Carousel>
        <StyledDiv>
          <Quote variant="h4">{valuePropSlides[0]?.quote}</Quote>
          <Typography variant="h6" sx={{ fontWeight: theme.typography.fontWeightMediumBold }}>
            {valuePropSlides[0]?.authorName}
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: theme.typography.fontWeightRegular }}>
            {valuePropSlides[0]?.authorJobTitle}
          </Typography>
          <StyledImage src={FiveStarsImage} alt="five stars" />
        </StyledDiv>
        <StyledDiv>
          <StyledImage
            src={valuePropSlides[1]?.image}
            alt={valuePropSlides[1]?.title}
            sx={{ marginBottom: 0 }}
          />
          <ImageHeading variant="h5" sx={{ marginBottom: theme.spacing(1) }}>
            {valuePropSlides[1]?.title}
          </ImageHeading>
          <ImageDescription variant="body1">{valuePropSlides[1]?.description}</ImageDescription>
        </StyledDiv>
        <StyledDiv>
          <StyledImage
            src={valuePropSlides[2]?.image}
            alt={valuePropSlides[2]?.title}
            sx={{ paddingLeft: theme.spacing(2) }}
          />
          <ImageHeading variant="h5">{valuePropSlides[2]?.title}</ImageHeading>
          <ImageDescription variant="body1">{valuePropSlides[2]?.description}</ImageDescription>
        </StyledDiv>
        <StyledDiv>
          <StyledImage
            src={valuePropSlides[3]?.image}
            alt={valuePropSlides[3]?.title}
            sx={{ paddingTop: theme.spacing(6) }}
          />
          <ImageHeading variant="h5">{valuePropSlides[3]?.title}</ImageHeading>
          <ImageDescription variant="body1">{valuePropSlides[3]?.description}</ImageDescription>
        </StyledDiv>
      </Carousel>
    </SliderContainer>
  );
}

const SliderContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 'calc(100vh - 150px)',
  background: `linear-gradient(45deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.medium} 100%)`,
  borderRadius: '20px',
  color: 'white',
  padding: '80px 50px 80px',
  marginTop: theme.spacing(1),
  marginLeft: `-${theme.spacing(1)}`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledDiv = styled('div')({
  textAlign: 'center',
});

const StyledImage = styled('img')(({ theme }) => ({
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(4),
  maxHeight: '400px',
  textRendering: 'optimizeLegibility',
}));

const Quote = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '38px',
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  padding: `0 ${theme.spacing(4)}`,

  [theme.breakpoints.up('sm')]: {
    paddingTop: '5vh',
  },

  [theme.breakpoints.up('lg')]: {
    paddingTop: '10vh',
  },

  [theme.breakpoints.up('xl')]: {
    paddingTop: '15vh',
  },
}));

const ImageHeading = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMediumBold,
  marginBottom: theme.spacing(1),
}));

const ImageDescription = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  marginBottom: theme.spacing(4),
  padding: `0 ${theme.spacing(6)}`,
}));
