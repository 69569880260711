import { useEffect, useRef, useState } from 'react';
import { Box, ButtonBase, Stack, Typography, useTheme } from '@mui/material';
import { FileUploadProps } from '../Buttons/types';
import { FileDownloadCompleteIcon, StyledFileUploadContainer } from './styles';
import { styled } from '@mui/material/styles';
import { SecondaryButton } from 'sharedComponents/Buttons';
import { FileUploadErrorModal } from './FileUploadErrorModal';
import { NO_FILE_SELECTED_ERROR_MESSAGE } from 'features/Connect/features/ExpressInterest/utils';
import { UpdateResumeConfirmationModal } from './UpdateResumeConfirmationModal';
import { ComponentsLoadingSpinnerTestIds } from 'data-testids/Components';
import { LoadingSpinner } from 'sharedComponents/LoadingSpinner/loadingSpinner';
const ACCEPTED_FILE_TYPES = ['.docx', '.pdf'];

export const FileUpload: React.FC<FileUploadProps> = ({
  uploadedFileName,
  handleChange,
  isLoading = false,
  errorMessage = '',
  setErrorMessage,
  sx,
  inputName,
  fileNameMaxWidth,
  showUpdateResumeConfirmationModal = false,
  dataTestId,
}) => {
  const fileInput = useRef(null);

  const [isUpdateConfirmationModalOpen, setIsUpdateConfirmationModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const closeErrorModal = () => {
    setIsErrorModalOpen(false);
    setErrorMessage('');
  };

  const handleClick = () => {
    if (isLoading) return;
    if (showUpdateResumeConfirmationModal) {
      setIsUpdateConfirmationModalOpen(true);
      return;
    }
    fileInput.current.click();
    closeErrorModal();
  };

  const handleUpdateConfirmationClick = () => {
    fileInput.current.click();
    setIsUpdateConfirmationModalOpen(false);
    closeErrorModal();
  };

  useEffect(() => {
    if (errorMessage && errorMessage !== NO_FILE_SELECTED_ERROR_MESSAGE) {
      setIsErrorModalOpen(true);
    }
  }, [errorMessage]);

  return (
    <>
      {showUpdateResumeConfirmationModal && (
        <UpdateResumeConfirmationModal
          open={isUpdateConfirmationModalOpen}
          onClose={null}
          onUpdate={() => handleUpdateConfirmationClick()}
          onCancel={() => setIsUpdateConfirmationModalOpen(false)}
        />
      )}

      <StyledFileUploadContainer
        data-testid={dataTestId}
        sx={{ ...sx, borderColor: errorMessage && 'error.main' }}
        onClick={handleClick}
      >
        {isLoading ? (
          <LoadingSpinner dataTestId={ComponentsLoadingSpinnerTestIds.LOADING_SPINNER} />
        ) : (
          <>
            {uploadedFileName ? (
              <UpdateFile
                uploadedFile={uploadedFileName}
                fileNameMaxWidth={fileNameMaxWidth}
                dataTestId={dataTestId}
              />
            ) : (
              <UploadNewFile dataTestId={dataTestId} />
            )}
            <input
              data-testid={`${dataTestId}-input`}
              name={inputName}
              id="file-upload"
              type="file"
              onChange={handleChange}
              ref={fileInput}
              style={{ display: 'none' }}
              accept={ACCEPTED_FILE_TYPES.join(',')}
            />
          </>
        )}
      </StyledFileUploadContainer>
      <FileUploadErrorModal
        isOpen={isErrorModalOpen}
        onClose={closeErrorModal}
        onClick={handleClick}
        errorMessage={errorMessage}
      />
      {errorMessage == NO_FILE_SELECTED_ERROR_MESSAGE && (
        <div>
          <Typography
            data-testid={`${dataTestId}-error-text`}
            color="error"
            variant="body1"
            sx={{ margin: '3px 14px 0px' }}
          >
            {errorMessage}
          </Typography>
        </div>
      )}
    </>
  );
};

const UpdateFile = ({ uploadedFile, fileNameMaxWidth, dataTestId }) => {
  const theme = useTheme();
  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      alignItems="center"
      spacing={{ xs: 2, sm: 4 }}
      justifyContent={'space-between'}
    >
      <Box>
        <Stack direction={'row'}>
          <FileDownloadCompleteIcon data-testid={`${dataTestId}-success-icon`} />
          <section
            style={{
              textAlign: 'left',
              maxWidth: fileNameMaxWidth,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            <Typography sx={{ color: 'text.light' }}>Current resume uploaded:</Typography>
            <Typography sx={{ color: 'text.light' }}>{uploadedFile}</Typography>
          </section>
        </Stack>
      </Box>
      <Box>
        <SecondaryButton
          data-testid={`${dataTestId}-update-button`}
          color="primary"
          sx={{
            maxWidth: 160,
            borderRadius: theme.shape.borderRadius * 0.5,
          }}
        >
          Update File
        </SecondaryButton>
      </Box>
    </Stack>
  );
};

const UploadNewFile = (dataTestId) => {
  const formattedFileTypes =
    ACCEPTED_FILE_TYPES.length > 1
      ? `${ACCEPTED_FILE_TYPES.slice(0, -1).join(', ')} or ${ACCEPTED_FILE_TYPES.slice(-1)}`
      : ACCEPTED_FILE_TYPES[0];

  return (
    <Stack alignItems="center">
      <FlexBox>
        <ClickToUploadButton data-testid={`${dataTestId}-upload-button`}>
          <Typography sx={{ fontWeight: 600 }}>Click to upload</Typography>
        </ClickToUploadButton>
        {/* Comment back in when drag and drop is implemented */}
        {/* <InstructionsText>or drag and drop</InstructionsText> */}
      </FlexBox>
      <InstructionsText variant="body2">{formattedFileTypes}</InstructionsText>
    </Stack>
  );
};

const FlexBox = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
});

const ClickToUploadButton = styled(ButtonBase)(({ theme }) => ({
  backgroundColor: 'none',
  border: 'none',
  color: theme.palette.primary.main,
  whiteSpace: 'nowrap',
  marginRight: theme.spacing(0.5),
  '&:hover': {
    textDecoration: 'underline',
  },
}));

const InstructionsText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.light,
  textAlign: 'center',
  whiteSpace: 'nowrap',
}));
