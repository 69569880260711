import { BaseNimbleAPI } from './baseAPI';
import { CustomProfileFieldsInterface } from 'types';

class FlatFileTransferAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/api/customfields' };
  }

  async getCustomProfileFields(): Promise<CustomProfileFieldsInterface[]> {
    const url = '/';
    const response = await this.get(url);

    return response.data;
  }
}

export default new FlatFileTransferAPI();
