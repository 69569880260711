import { ActionIcon } from './Icons';
import { actionInfo } from '../data';
import { ATSConnectHiringRequestTestIds } from 'data-testids/ATS';
import { IndividualHiringRequestAction } from '../../../types';
import { RequiredActionsCTAProps } from '../types';
import { TitleCTAContainer } from '../styles';

export const RequiredActionsCTA: React.FC<RequiredActionsCTAProps> = ({
  actions,
  candidateId,
  isExpanded,
  onOpenModal,
  roleId,
}) => {
  return (
    <TitleCTAContainer
      data-testid={ATSConnectHiringRequestTestIds.EXPANDED_CTA_CONTAINER}
      isexpanded={isExpanded}
    >
      {actionInfo.map((data, index) => {
        const actionKey: IndividualHiringRequestAction = data.tooltipText;
        const actionIsMissing = !actions[actionKey];

        const handleModalOpen = () =>
          onOpenModal({ candidateId, modalType: data.tooltipText, roleId });

        return (
          <ActionIcon
            icon={data.icon}
            isExpanded={isExpanded}
            isMissing={actionIsMissing}
            key={index}
            tooltipText={data.tooltipText}
            onClick={handleModalOpen}
          />
        );
      })}
    </TitleCTAContainer>
  );
};
