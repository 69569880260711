import { Box, styled, useTheme } from '@mui/material';
import { BaseSyntheticEvent } from 'react';
import FieldsCategory from './FieldsCategory';
import { SelectedFieldsController } from './SelectedFieldsController';
import { PrimaryButton, SecondaryButton } from 'sharedComponents/Buttons';
import { CategoryLabel, FieldsAndCategories } from './types';
import { ATSCandidateBulkExportTestIds } from 'data-testids/ATS';
import { useFormContext } from 'react-hook-form';

type SelectFieldsFormProps = {
  onSubmit: (e: BaseSyntheticEvent) => void;
  onClose: () => void;
  onProspectList: boolean;
};

type CTAButtonsProps = {
  onCancel: () => void;
  onSubmit: (e: BaseSyntheticEvent) => void;
};

export const SelectFieldsForm: React.FC<SelectFieldsFormProps> = ({
  onSubmit,
  onClose,
  onProspectList,
}) => {
  const theme = useTheme();

  const getFieldsAndCategories = (): FieldsAndCategories => {
    if (onProspectList) {
      return {
        categories: { 'Contact information': ['Name', 'Email'] },
        fields: [],
      };
    }

    return {
      categories: {
        'Contact information': ['Name', 'Email', 'Phone numbers', 'Address'],
        Qualifications: ['Education', 'Credentials', 'Languages', 'Exams', 'Certificates'],
      },
      fields: ['Work experience', 'Preferences', 'References', 'Tags', 'Application questions'],
    };
  };

  const { categories, fields } = getFieldsAndCategories();

  return (
    <Form>
      <FieldsListContainer>
        {Object.entries(categories).map(([categoryTitle, fields]) => (
          <FieldsCategory
            key={categoryTitle}
            category={categoryTitle as CategoryLabel}
            fields={fields}
          />
        ))}
        {fields.map((field) => (
          <FieldContainer key={field}>
            <SelectedFieldsController
              fieldName={field}
              label={field}
              labelSx={{
                fontWeight: theme.typography.fontWeightMediumBold,
              }}
            />
          </FieldContainer>
        ))}
      </FieldsListContainer>
      <CTAButtons onCancel={onClose} onSubmit={onSubmit} />
    </Form>
  );
};

const CTAButtons: React.FC<CTAButtonsProps> = ({ onCancel, onSubmit }) => {
  const { watch } = useFormContext();
  const selectedFields = watch('selectedFields', []);
  const theme = useTheme();

  const buttonSx = {
    borderRadius: theme.spacing(1.5),
    paddingTop: `${theme.spacing(1)}`,
    paddingBottom: `${theme.spacing(1)}`,
    flexGrow: 1,
  };

  return (
    <ButtonsContainer>
      <SecondaryButton
        sx={{
          ...buttonSx,
          color: theme.palette.text.secondary,
          borderColor: theme.palette.gray.mediumLight,
        }}
        onClick={onCancel}
        size="extraLarge"
        dataTestId={ATSCandidateBulkExportTestIds.CANCEL_BUTTON}
      >
        Cancel
      </SecondaryButton>
      <PrimaryButton
        sx={{
          ...buttonSx,
          backgroundColor: theme.palette.text.secondary,
        }}
        onClick={onSubmit}
        size="extraLarge"
        dataTestId={ATSCandidateBulkExportTestIds.REQUEST_EXPORT_BUTTON}
        disabled={selectedFields.length === 0}
      >
        Request Export
      </PrimaryButton>
    </ButtonsContainer>
  );
};

const ButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(2),
  marginTop: theme.spacing(2),
}));

const Form = styled('form')({});

const FieldsListContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: `${theme.spacing(2)} 0`,
  maxHeight: 'calc(100vh - 248px)',
  overflowY: 'scroll',
}));

const FieldContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  backgroundColor: theme.palette.white.darker,
  border: `1px solid ${theme.palette.border.main}`,
  borderRadius: theme.spacing(1.5),
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
}));
