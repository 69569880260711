import { Box, styled, Theme } from '@mui/material';

export const SecondaryButtonContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'end',
}));

export const PrimaryButtonContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'start',
}));

export const ButtonStyle = (theme: Theme) => ({
  width: '100%',
  height: '100%',
  minHeight: '42px',
  borderRadius: theme.shape.borderRadius * 0.5,
  fontSize: theme.typography.h3.fontSize,
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightMediumBold,
  lineHeight: theme.typography.h2.lineHeight,
  textWrap: 'nowrap',
});

export const SecondaryButtonStyle = (theme: Theme) => ({
  ...ButtonStyle(theme),
  border: `1px solid ${theme.palette.gray.darker}`,
});
