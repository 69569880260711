import { Box, Stack, styled, Typography } from '@mui/material';
import { PrincipalComponent } from 'features/Connect/components/PrincipalComponent/PrincipalComponent';
import { PrincipalMessageProps } from 'features/Connect/features/ConnectCandidatePortalDashboards/CandidateMatchDashboard/utils/types';

export const PrincipalMessage: React.FC<PrincipalMessageProps> = ({
  principal,
  messageHeaderText = null,
  principalMessage = null,
  isMessageModal = false,
}) => {
  return (
    <PrincipalMessageContainer>
      <PrincipalMessageAvatarNameContainer>
        <PrincipalComponent
          principal={principal}
          showIconOrAvatar={isMessageModal ? 'icon' : 'avatar'}
          isPrincipalMessage={isMessageModal ? false : true}
        />
      </PrincipalMessageAvatarNameContainer>
      {messageHeaderText && <MessageHeaderText>{messageHeaderText}</MessageHeaderText>}
      {principalMessage && (
        <MessageContainer isMessageModal={isMessageModal}>
          <MessageText
            dangerouslySetInnerHTML={{
              __html: principalMessage,
            }}
          />
        </MessageContainer>
      )}
    </PrincipalMessageContainer>
  );
};

const PrincipalMessageContainer = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  gap: theme.spacing(1.5),
}));

const PrincipalMessageAvatarNameContainer = styled(Box)(() => ({
  width: '100%',
}));

const MessageContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMessageModal',
})<{ isMessageModal?: boolean }>(({ theme, isMessageModal }) => ({
  width: '100%',
  height: '100%',
  background: '#F8F9FA',
  borderRadius: 8,
  border: isMessageModal ? 'none' : `1px ${theme.palette.success.light} solid`,
  padding: theme.spacing(2),
}));

const MessageHeaderText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: theme.typography.body2.fontSize,
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: theme.typography.h3.lineHeight,
  marginTop: theme.spacing(1),
}));

const MessageText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: theme.typography.body2.fontSize,
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightRegular,
  lineHeight: theme.typography.h3.lineHeight,
}));
