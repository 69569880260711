import { useTheme } from '@mui/material';

import { ActionIconProps } from '../types';
import { ATSConnectHiringRequestTestIds } from 'data-testids/ATS';
import { CTAIconBox, ExpandIconContainer, IconWrapper } from '../styles';
import { getConnectCandidateRowActionText } from '../../../utils';
import { TooltipIconButton } from 'sharedComponents/Buttons/TooltipIconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const ExpandIcon = () => {
  const theme = useTheme();

  return (
    <ExpandIconContainer>
      <IconWrapper>
        <ExpandMoreIcon
          data-testid={ATSConnectHiringRequestTestIds.ACTIONS_EXPAND_MORE_ICON}
          sx={{
            height: theme.spacing(2.625),
            width: theme.spacing(2.625),
          }}
        />
      </IconWrapper>
    </ExpandIconContainer>
  );
};

export const ActionIcon: React.FC<ActionIconProps> = ({
  icon,
  isExpanded,
  isMissing,
  onClick,
  tooltipText,
}) => {
  const theme = useTheme();

  return (
    <TooltipIconButton
      tooltipTitle={getConnectCandidateRowActionText(tooltipText, isMissing)}
      dataTestId={`${ATSConnectHiringRequestTestIds.ACTION_ICON_BUTTON}-${tooltipText}`}
      sx={{ padding: 0 }}
      onClick={onClick}
    >
      <CTAIconBox
        sx={{
          color: isMissing ? theme.palette.error.main : theme.palette.primary.medium,
          border: isExpanded ? null : isMissing ? `1px solid ${theme.palette.error.main}` : null,
          backgroundColor: isExpanded
            ? null
            : isMissing
              ? theme.palette.error.fill
              : theme.palette.primary.fill,
          marginRight: isExpanded ? 'inherit' : theme.spacing(1),
        }}
      >
        {icon}
      </CTAIconBox>
    </TooltipIconButton>
  );
};
