import { Dialog, useTheme } from '@mui/material';

import { ATSConnectHiringRequestCandidateFeedbackTestIds } from 'data-testids/ATS';
import { CandidateFeedbackModalPane, CandidateFeedbackModalProps } from '../types';

import { ModalContainer } from '../components/styles';

import { useState } from 'react';
import { CreatePane } from '../components/Panes/Create/CreatePane';
import { FeedbackListPane } from '../components/Panes/List/FeedbackListPane';

export const CandidateFeedbackModal: React.FC<CandidateFeedbackModalProps> = ({
  candidateName,
  currentModalOpen,
  numberOfScorecards,
  open,
  onClose,
  roleTitle,
}) => {
  const [currentModalPane, setCurrentModalPane] = useState<CandidateFeedbackModalPane>('list');
  const theme = useTheme();
  const titleText =
    currentModalOpen.modalType === 'scorecard'
      ? `Scorecards for ${candidateName}`
      : `References for ${candidateName}`;

  const handleSetCurrentModalPane = (pane: CandidateFeedbackModalPane) => setCurrentModalPane(pane);

  const updatedOnClose = () => {
    setCurrentModalPane('list');
    onClose();
  };

  const handleCancelClick = () => setCurrentModalPane('list');

  return (
    <Dialog
      data-testid={ATSConnectHiringRequestCandidateFeedbackTestIds.MODAL}
      fullWidth={true}
      maxWidth="lg"
      onClose={updatedOnClose}
      open={open}
      sx={{
        color: theme.palette.text.primary,
      }}
    >
      <ModalContainer>
        {currentModalPane === 'list' && (
          <FeedbackListPane
            onClose={updatedOnClose}
            numberOfScorecards={numberOfScorecards}
            roleTitle={roleTitle}
            titleText={titleText}
            onModalPaneChange={handleSetCurrentModalPane}
          />
        )}
        {currentModalPane === 'create' && <CreatePane onCancel={handleCancelClick} />}
      </ModalContainer>
    </Dialog>
  );
};
