import { Box, styled } from '@mui/material';

export const NoteContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(2),
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.white.darker,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));
