import { Typography, useTheme } from '@mui/material';

import { ATSTabbedJobViewSetUpTabTestIds } from 'data-testids/ATS';
import { ApplicationSectionsSettingsProps } from '../types';
import { Section, SwitchContainer } from '../styles';
import { Switch } from 'sharedComponents/Switch/Switch';
import { CandidateSettingsSection } from './CandidateSettingsSection';
import { separateArrayForInternalExternalSettings } from '../utils';
import { SectionTitle } from 'features/TabbedJobView/styles';

export const ApplicationSectionsSettings: React.FC<ApplicationSectionsSettingsProps> = ({
  hasInternalRequirementsSpecified,
  roleSections,
  isOpenOnlyForInternalCandidates,
}) => {
  const theme = useTheme();
  const { internalCandidateSettings, externalCandidateSettings } =
    separateArrayForInternalExternalSettings(roleSections);

  return (
    <Section sx={{ paddingTop: theme.spacing(4) }}>
      <SectionTitle
        data-testid={ATSTabbedJobViewSetUpTabTestIds.APPLICATION_SECTIONS_SETTINGS}
        sx={{ fontWeight: theme.typography.fontWeightMediumBold }}
        variant="h2"
      >
        Application Sections Settings
      </SectionTitle>
      <SwitchContainer>
        <Switch
          dataTestId={ATSTabbedJobViewSetUpTabTestIds.SETTINGS_TOGGLE}
          defaultChecked={hasInternalRequirementsSpecified}
          disabled
        />
        <Typography>Specify application experience for internal candidates</Typography>
      </SwitchContainer>

      <CandidateSettingsSection
        title="External candidates"
        settings={externalCandidateSettings}
        isInternal={false}
        isOpenOnlyForInternalCandidates={isOpenOnlyForInternalCandidates}
      />

      <CandidateSettingsSection
        title="Internal candidates"
        settings={internalCandidateSettings}
        isInternal={true}
      />
    </Section>
  );
};
