import { useState } from 'react';
import { Typography, useTheme } from '@mui/material';
import moment from 'moment';

import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { ATSJobPostingTabTestIds } from 'data-testids/ATS';
import { JobDetailsContainer } from '../styles';
import { JobDetailsProps } from '../types';
import { JobViewDetail, ReadMoreList } from 'features/JobView/components/JobViewDetails';
import { StyledLink } from 'features/JobView/components/styles';
import { getGradesForJobView, getSalaryRange, truncateText } from 'features/JobView/utils';
import { getDistrictJobBoardURL, shouldShowTabbedJobViewField } from '../utils';

export const JobDetails: React.FC<JobDetailsProps> = ({ job }) => {
  const theme = useTheme();

  const [readMore, setReadMore] = useState({
    schools: false,
    grades: false,
    subjects: false,
  });

  const deadlineText = job.deadline
    ? `${moment(job.deadline).format('ll')} at 8PM PST`
    : 'Until filled';
  const startDate = job.start_date ? moment(job.start_date).format('MMMM YYYY') : null;
  const grades = getGradesForJobView(job);

  return (
    <JobDetailsContainer>
      <Typography
        variant="h2"
        data-testid={ATSJobPostingTabTestIds.JOB_DETAILS_TITLE}
        sx={{
          paddingBottom: theme.spacing(2.5),
          color: `${theme.palette.text.primary} !important`,
        }}
      >
        Job Details
      </Typography>

      {shouldShowTabbedJobViewField(job, 'schools') && (
        <JobViewDetail subtitle="Schools" dataTestId={ATSJobPostingTabTestIds.SCHOOLS_TEXT}>
          {job.schools.join(', ')}
        </JobViewDetail>
      )}
      {shouldShowTabbedJobViewField(job, 'deadline') && (
        <JobViewDetail
          subtitle="Application Deadline"
          dataTestId={ATSJobPostingTabTestIds.DEADLINE_TEXT}
        >
          {deadlineText}
        </JobViewDetail>
      )}
      {shouldShowTabbedJobViewField(job, 'start_date') && (
        <JobViewDetail subtitle="Start Date" dataTestId={ATSJobPostingTabTestIds.START_DATE_TEXT}>
          {startDate}
        </JobViewDetail>
      )}
      {shouldShowTabbedJobViewField(job, 'salary_max') && (
        <JobViewDetail subtitle="Salary" dataTestId={ATSJobPostingTabTestIds.SALARY_TEXT}>
          {getSalaryRange(job)}
        </JobViewDetail>
      )}
      {shouldShowTabbedJobViewField(job, 'grades') && (
        <JobViewDetail subtitle="Grades" dataTestId={ATSJobPostingTabTestIds.GRADES_TEXT}>
          <ReadMoreList
            field="grades"
            text={truncateText(grades, readMore['grades'])}
            isMore={readMore.grades}
            onClick={() =>
              setReadMore((previousState) => ({
                ...previousState,
                grades: !readMore.grades,
              }))
            }
          />
        </JobViewDetail>
      )}
      {shouldShowTabbedJobViewField(job, 'subjects') && (
        <JobViewDetail subtitle="Subjects" dataTestId={ATSJobPostingTabTestIds.SUBJECTS_TEXT}>
          <ReadMoreList
            field="subjects"
            text={truncateText(job.subjects.join(', '), readMore['subjects'])}
            isMore={readMore.subjects}
            onClick={() =>
              setReadMore((previousState) => ({
                ...previousState,
                subjects: !readMore.subjects,
              }))
            }
          />
        </JobViewDetail>
      )}
      {shouldShowTabbedJobViewField(job, 'contact') && (
        <JobViewDetail
          subtitle="Contact for Questions"
          dataTestId={ATSJobPostingTabTestIds.CONTACT_TEXT}
        >
          <StyledLink href={`mailto:${job.contact}`}>
            Send message &#32;
            <ArrowOutwardIcon fontSize="small" />
          </StyledLink>
        </JobViewDetail>
      )}
      {shouldShowTabbedJobViewField(job, 'district_jobboard_slug') && (
        <JobViewDetail subtitle="Job board" dataTestId={ATSJobPostingTabTestIds.JOB_BOARD_TEXT}>
          <StyledLink target="_blank" href={getDistrictJobBoardURL(job.district_jobboard_slug)}>
            View district board &#32;
            <ArrowOutwardIcon fontSize="small" />
          </StyledLink>
        </JobViewDetail>
      )}
    </JobDetailsContainer>
  );
};
