import { Box, Slider, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { RadiusControlRenderProps } from './types';
import { DISTANCE_RADIUS_MARKS } from './constants';

export const RadiusControl = ({
  radius,
  onRadiusChange,
  dataTestId,
}: RadiusControlRenderProps): JSX.Element => (
  <StyledRadiusControl>
    <Typography>How many miles are you willing to commute?</Typography>
    <Slider
      value={radius}
      onChange={(_, newValue) => {
        onRadiusChange(newValue as number);
      }}
      min={5}
      max={100}
      marks={DISTANCE_RADIUS_MARKS}
      step={null}
      valueLabelDisplay="auto"
      size="small"
      data-testid={dataTestId}
    />
  </StyledRadiusControl>
);

const StyledRadiusControl = styled(Box)(({ theme }) => ({
  padding: 0,
  backgroundColor: 'none',
  borderRadius: theme.shape.borderRadius,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
}));
