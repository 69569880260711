import { Typography, useTheme } from '@mui/material';

import { AccordionCTAExpandedProps } from '../types';
import { ActionIcon } from './Icons';
import { actionInfo } from '../data';
import { ATSConnectHiringRequestTestIds } from 'data-testids/ATS';
import { ExpandedActionRow, ExpandedCTAContainer } from '../styles';
import { getConnectCandidateRowActionText } from '../../../utils';
import { IndividualHiringRequestAction } from '../../../types';

export const AccordionCTAExpanded: React.FC<AccordionCTAExpandedProps> = ({
  actions,
  isExpanded,
}) => {
  const theme = useTheme();

  return (
    <ExpandedCTAContainer
      isexpanded={isExpanded}
      data-testid={ATSConnectHiringRequestTestIds.EXPANDED_CTA_CONTAINER}
    >
      {actionInfo.map((data, index) => {
        const actionKey: IndividualHiringRequestAction = data.tooltipText;
        const actionIsMissing: boolean = !actions[actionKey];

        return (
          <ExpandedActionRow
            data-testid={ATSConnectHiringRequestTestIds.EXPANDED_CTA_ACTION_ROW}
            key={index}
          >
            <ActionIcon
              icon={data.icon}
              isExpanded={isExpanded}
              isMissing={actionIsMissing}
              tooltipText={data.tooltipText}
            />
            <Typography sx={{ paddingLeft: theme.spacing(0.25) }}>
              {getConnectCandidateRowActionText(actionKey, actionIsMissing)}
            </Typography>
          </ExpandedActionRow>
        );
      })}
    </ExpandedCTAContainer>
  );
};
