import { Box, styled, Typography, useTheme } from '@mui/material';
import { ATSCandidateBulkExportTestIds } from 'data-testids/ATS';
import { PrimaryButton } from 'sharedComponents/Buttons';
import { Modal } from 'sharedComponents/Modal';

const BulkExportSuccessModal: React.FC<{ open: boolean; onClose: () => void }> = ({
  open,
  onClose,
}) => {
  const theme = useTheme();

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={
        <ModalTitle variant="h2" data-testid={ATSCandidateBulkExportTestIds.SUCCESS_MODAL_TITLE}>
          Export requested successfully
        </ModalTitle>
      }
      bodySx={{
        borderRadius: theme.spacing(2),
        padding: theme.spacing(6),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography
        data-testid={ATSCandidateBulkExportTestIds.SUCCESS_MODAL_SUBTITLE}
        sx={{ textAlign: 'center' }}
      >
        You will receive an email shortly.
      </Typography>
      <ButtonContainer>
        <PrimaryButton
          size="extraLarge"
          sx={{
            backgroundColor: theme.palette.text.secondary,
            borderRadius: theme.spacing(1.5),
            marginTop: theme.spacing(2.5),
          }}
          onClick={onClose}
          dataTestId={ATSCandidateBulkExportTestIds.SUCCESS_MODAL_CLOSE_BUTTON}
        >
          Close
        </PrimaryButton>
      </ButtonContainer>
    </Modal>
  );
};

export default BulkExportSuccessModal;

const ModalTitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  color: theme.palette.text.primary,
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: `${theme.spacing(3)}`,
}));
