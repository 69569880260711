import { Typography, useTheme } from '@mui/material';

import { ATSConnectHiringRequestCandidateFeedbackTestIds } from 'data-testids/ATS';
import { LowerVisibilityText, VisibilityRow, VisibilityText } from './styles';
import { TemplateAndVisibilityProps } from '../types';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export const TemplateAndVisibility: React.FC<TemplateAndVisibilityProps> = ({ template }) => {
  const theme = useTheme();

  const visibilityText =
    template.visibility === 'me-only' ? 'Visible only to you' : 'Visible to all users';

  return (
    <VisibilityRow
      data-testid={ATSConnectHiringRequestCandidateFeedbackTestIds.TEMPLATE_AND_VISIBILITY}
    >
      <VisibilityText>
        <InsertDriveFileOutlinedIcon />
        <Typography
          data-testid={ATSConnectHiringRequestCandidateFeedbackTestIds.TEMPLATE_NAME}
          sx={{ paddingLeft: theme.spacing(0.5) }}
        >
          {template.title}
        </Typography>
      </VisibilityText>
      <LowerVisibilityText data-testid={ATSConnectHiringRequestCandidateFeedbackTestIds.VISIBILITY}>
        {template.visibility === 'me-only' ? (
          <VisibilityIcon
            data-testid={ATSConnectHiringRequestCandidateFeedbackTestIds.VISIBILITY_ME_ONLY}
          />
        ) : (
          <VisibilityOffIcon
            data-testid={ATSConnectHiringRequestCandidateFeedbackTestIds.VISIBILITY_PUBLIC}
          />
        )}
        &nbsp;
        <Typography sx={{ paddingLeft: theme.spacing(0.5) }}>{visibilityText}</Typography>
      </LowerVisibilityText>
    </VisibilityRow>
  );
};
