import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { UseMutation } from 'types/types';

type ExportRequestMutationVariables = {
  selectedFields: string[];
  userIds: number[];
  prospectExport: boolean;
};

export const useMutationRequestBulkExport: UseMutation<
  void,
  AxiosError,
  ExportRequestMutationVariables,
  unknown
> = (options) => {
  return useMutation({
    mutationFn: async ({ selectedFields, userIds, prospectExport }) => {
      await axios.post<void>('/api/user/get_users_for_bulk_export/', {
        selected_fields: selectedFields,
        user_ids: userIds,
        prospect_export: prospectExport,
      });
    },
    ...options,
  });
};
