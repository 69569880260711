import { ATSTabbedJobViewHiringTabTestIds } from 'data-testids/ATS';
import {
  ScorecardData,
  ScorecardDataWithQuestionCount,
  ScorecardRow,
  ScorecardViewPermissions,
} from './types';

export const getDisplayPermissions = (permissions: ScorecardViewPermissions) => {
  const valueToDisplay: { [key in ScorecardViewPermissions]: string } = {
    me_only: 'Me',
    district_admins: 'District admins / users',
    school_admins: 'School admins / users',
    everyone: 'Everyone',
    users_at_my_school: 'Users at my school',
  };

  return valueToDisplay[permissions];
};

export const constructScorecardRows = (
  scorecards: ScorecardDataWithQuestionCount[]
): ScorecardRow[] => {
  const mappedScorecards: ScorecardRow[] = scorecards.map((scorecard, i) => ({
    id: i,
    title: scorecard.title,
    questionCount: scorecard.questionCount,
    view_permissions: getDisplayPermissions(scorecard.view_permissions),
    dataTestId: `${ATSTabbedJobViewHiringTabTestIds.SCORECARD_TABLE_ROW}-${scorecard.id}`,
  }));

  mappedScorecards[mappedScorecards.length - 1].sx = {
    '& > .MuiTableCell-root': { borderBottom: 'none' },
  };

  return mappedScorecards;
};

export const getScorecardsWithQuestionCount = (
  scorecards: ScorecardData[]
): ScorecardDataWithQuestionCount[] =>
  scorecards.map((scorecard) => ({
    id: scorecard.id,
    title: scorecard.title,
    view_permissions: scorecard.view_permissions,
    questionCount: scorecard.questions.length,
  }));
