import { Theme, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material';
import DraftsIcon from '@mui/icons-material/Drafts';
import { Box } from '@mui/material';

export const CandidateDashboardViewsPrimaryText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: theme.typography.h1.fontSize,
  fontStyle: 'normal',
  whiteSpace: 'nowrap',
  [theme.breakpoints.down('lg')]: {
    fontSize: '26px',
    whiteSpace: 'pre-line',
  },
  ...textStyles(theme),
}));

export const CandidateDashboardViewsSecondaryText = styled(Typography)(({ theme }) => ({
  color: theme.palette.black.darker,
  fontSize: theme.typography.h2.fontSize,
  whiteSpace: 'nowrap',
  [theme.breakpoints.down('lg')]: {
    fontSize: theme.typography.h3.fontSize,
    whiteSpace: 'pre-line',
    lineHeight: '24px',
  },
  ...textStyles(theme),
}));

export const textStyles = (theme: Theme) => ({
  fontStyle: 'normal',
  lineHeight: '32px',
  letterSpacing: '0.123px',
  fontWeight: theme.typography.fontWeightLight,
});

export const NoPendingMatchesIcon: React.FC = () => {
  const theme = useTheme();
  return (
    <DraftsIcon
      fontSize="large"
      sx={{
        width: '85px',
        height: '85px',
        color: 'primary.main',
        [theme.breakpoints.down('lg')]: {
          width: '74px',
          height: '74px',
        },
      }}
    />
  );
};

export const EndOfMatchesIcon: React.FC = () => {
  const theme = useTheme();
  return (
    <Box
      component="span"
      sx={{
        fontSize: '74px',
        lineHeight: 1,
        [theme.breakpoints.down('lg')]: {
          fontSize: '50px',
        },
      }}
    >
      🎉
    </Box>
  );
};
