import { AxiosError } from 'axios';

export const defaultErrorMessages = {
  401: 'You do not have permission to view this page. Please contact your administrator for more information.',
  403: 'You do not have permission to view this page. Please contact your administrator for more information.',
  404: 'This page does not exist.',
  500: 'Something went wrong. Please refresh the page.',
};

export const getAxiosErrorMessage = (
  error: unknown | undefined,
  callback: (error: AxiosError, customErrorMessages: Record<number, string>) => string,
  customErrorMessages?: Record<number, string>
) => {
  if (!(error instanceof AxiosError)) return undefined;

  return callback(error, customErrorMessages);
};

export const getAxiosErrorMessageByStatusCode = (
  error: unknown | undefined,
  componentErrorMessages?: Record<number, string>
) => {
  return getAxiosErrorMessage(
    error,
    (error, customErrorMessages) => {
      const errorMessages = {
        ...defaultErrorMessages,
        ...customErrorMessages,
      };
      return errorMessages[error.response?.status] || 'Something went wrong';
    },
    componentErrorMessages
  );
};
