import './statusselect.css';

import { Component } from 'react';

import PropTypes from 'prop-types';
import Dropdown from 'react-dropdown';
import { colorMapping, appStatusType } from '../../../utils/enums';
import auth from '../../../utils/auth';
import { ATSCandidateListTestIds } from 'data-testids/ATS';

export default class StatusSelect extends Component {
  constructor(props) {
    super(props);
    this.isSchoolUser = auth.isSchoolUser();
  }

  static propTypes = {
    application: PropTypes.object.isRequired,
    statusOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    toggleStatus: PropTypes.func.isRequired,
  };

  render() {
    let status = this.props.application?.new_status;

    let color = colorMapping[status.color];

    let disabled =
      this.isSchoolUser &&
      (status.status_type === appStatusType.hired ||
        status.status_type === appStatusType.onboarding)
        ? true
        : false;

    const source = this.props.application?.source
      ? this.props.application?.source.value
      : this.props.application?.application_source;

    const isConnectCandidate =
      status.status_type !== appStatusType.hiring_pool && source === 'Nimble Connect';

    return (
      <div
        className="status-select"
        data-testid={
          isConnectCandidate
            ? ATSCandidateListTestIds.STATUS_SELECT_CONNECT
            : ATSCandidateListTestIds.STATUS_SELECT
        }
      >
        <div data-testid="select-container">
          <Dropdown
            options={this.props.statusOptions}
            onChange={(option) => this.props.toggleStatus(option)}
            value={status.label}
            className={`new-status-dropdown ${color} disabled-${disabled}`}
            disabled={disabled}
          />
        </div>
      </div>
    );
  }
}
