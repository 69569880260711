import { Stack, styled, useTheme } from '@mui/material';
import { Typography } from '@mui/material';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { PrimaryButton, SecondaryButton } from 'sharedComponents/Buttons';
import { BlankModal } from 'sharedComponents/Modal';
import { AuthenticatedAdminModalProps } from 'features/Connect/components/types';
import { getConnectUrlPath } from 'features/Connect/features/JobDiscovery/utils';
import { createButtonUrl } from 'features/Connect/features/ExpressInterest/utils';
import auth from 'utils/auth';
import { ConnectAuthenticatedAdminModalDataTestIds } from 'data-testids/ConnectDataTestIds';
import { useConnectContext } from 'features/Connect/features/Context/ConnectContextProvider';

export const AuthenticatedAdminModal: React.FC<AuthenticatedAdminModalProps> = ({
  district,
  jobboardState,
}) => {
  const { isAuthenticatedAdminModalOpen, connectContextActions } = useConnectContext();
  const theme = useTheme();

  const connectUrlPath = getConnectUrlPath(jobboardState);

  const handleCloseModal = (_?: unknown, reason?: string) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
    connectContextActions.setIsAuthenticatedAdminModalOpen(false);
  };

  const handleLogout = () => {
    const url = createButtonUrl({ basePath: connectUrlPath });
    auth.logout().then(() => window.location.assign(url));
  };

  return (
    <BlankModal
      dataTestId={ConnectAuthenticatedAdminModalDataTestIds.ADMIN_MODAL}
      open={isAuthenticatedAdminModalOpen}
      onClose={handleCloseModal}
      header={
        <HeaderContainer>
          <HeaderText>
            <StyledErrorIcon />
            You&apos;re signed in as an admin {district && ` at ${district?.name}`}
          </HeaderText>
        </HeaderContainer>
      }
      body={
        <BodyContainer>
          <BodyText>
            Please log out and sign up as a candidate using your personal email address to complete
            this action.
          </BodyText>
          <ButtonContainer>
            <SecondaryButton
              color="black"
              sx={ButtonStyles(theme)}
              onClick={handleCloseModal}
              size="medium"
              dataTestId={ConnectAuthenticatedAdminModalDataTestIds.CANCEL_BUTTON}
            >
              Cancel
            </SecondaryButton>
            <PrimaryButton
              onClick={() => handleLogout()}
              color="black"
              size="medium"
              sx={ButtonStyles(theme)}
              dataTestId={ConnectAuthenticatedAdminModalDataTestIds.LOGOUT_BUTTON}
            >
              Log Out
            </PrimaryButton>
          </ButtonContainer>
        </BodyContainer>
      }
      sx={ModalStyles(theme)}
    />
  );
};

const HeaderContainer = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '3px',
}));

const BodyContainer = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  margin: `${theme.spacing(2)} 0 0`,
  gap: '32px',
}));

const StyledErrorIcon = styled(ErrorOutlineOutlinedIcon)(({ theme }) => ({
  color: theme.palette.error.main,
  verticalAlign: 'middle',
  marginRight: theme.spacing(0.5),
  marginBottom: '4px',
  width: '22px',
  height: '22px',
  flexShrink: 0,
}));

const HeaderText = styled(Typography)(({ theme }) => ({
  color: theme.palette.gray.dark,
  textAlign: 'center',
  fontFeatureSettings: "'liga' off, 'clig' off",
  fontSize: theme.typography.h2.fontSize,
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '160%',
  letterSpacing: '0.15px',
}));

const BodyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  textAlign: 'center',
  fontSize: theme.typography.h3.fontSize,
  fontWeight: theme.typography.fontWeightRegular,
  lineHeight: '24px',
  letterSpacing: '0.15px',
}));

const ButtonContainer = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  gap: theme.spacing(2),
}));

const ButtonStyles = (theme) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
  borderRadius: theme.spacing(1),
  fontSize: theme.typography.h3.fontSize,
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '26px',
  textTransform: 'capitalize',
});

const ModalStyles = (theme) => ({
  width: '100%',
  maxWidth: '400px',
  borderRadius: theme.shape.borderRadius * 1,
  alignSelf: 'center',
  justifySelf: 'center',
  padding: theme.spacing(6),

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(3),
  },
});
