import { Chip, useTheme } from '@mui/material';

import { ATSConnectHiringRequestCandidateFeedbackTestIds } from 'data-testids/ATS';
import { ChipContainer, completedRowSx, draftRowSx } from './styles';
import { CompleteChipProps } from '../types';

export const CompletionChip: React.FC<CompleteChipProps> = ({ isComplete }) => {
  const theme = useTheme();

  return (
    <ChipContainer>
      <Chip
        data-testid={ATSConnectHiringRequestCandidateFeedbackTestIds.COMPLETION_CHIP}
        label={isComplete ? 'Complete' : 'Draft'}
        sx={isComplete ? completedRowSx(theme) : draftRowSx(theme)}
        variant="filled"
      />
    </ChipContainer>
  );
};
