import { ATSConnectHiringRequestCandidateFeedbackTestIds } from 'data-testids/ATS';
import { CandidateFeedbackRow } from '../../CandidateFeedbackRow';
import { FeedbackListPaneHeader } from './FeedbackListPaneHeader';
import { FeedbackListPaneProps } from '../../../types';
import { GreySecondaryButton } from 'sharedComponents/Buttons/GreySecondaryButton';
import { mockFeedbackData } from '../../../mockData';
import { Rows } from '../../styles';
import { SecondaryButtonContainer } from '../styles';
import { Typography } from '@mui/material';

export const FeedbackListPane: React.FC<FeedbackListPaneProps> = ({
  numberOfScorecards,
  onClose,
  onModalPaneChange,
  roleTitle,
  titleText,
}) => {
  return (
    <>
      <FeedbackListPaneHeader
        numberOfScorecards={numberOfScorecards}
        onClose={onClose}
        roleTitle={roleTitle}
        titleText={titleText}
      />
      <Rows>
        {numberOfScorecards === 0 ? (
          <Typography
            data-testid={ATSConnectHiringRequestCandidateFeedbackTestIds.NO_SCORECARDS_MESSAGE}
          >
            There are no scorecards for this candidate.
          </Typography>
        ) : (
          <>
            {mockFeedbackData.map((data) => (
              <CandidateFeedbackRow data={data} key={data.id} />
            ))}
          </>
        )}
      </Rows>
      <SecondaryButtonContainer>
        <GreySecondaryButton
          dataTestId={ATSConnectHiringRequestCandidateFeedbackTestIds.ADD_NEW_SCORECARD_BUTTON}
          onClick={() => onModalPaneChange('create')}
        >
          + Scorecard
        </GreySecondaryButton>
      </SecondaryButtonContainer>
    </>
  );
};
