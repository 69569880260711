import { Box, Typography } from '@mui/material';

import { ATSJobPostingTabTestIds } from 'data-testids/ATS';
import { Accordion } from 'sharedComponents/Accordion/Accordion';
import { DescriptionAndBenefitsProps } from '../types';
import { accordionSx, DescriptionAndBenefitsContainer } from '../styles';
import { getHTML } from 'features/JobView/utils';

export const DescriptionAndBenefits: React.FC<DescriptionAndBenefitsProps> = ({ job }) => {
  return (
    <DescriptionAndBenefitsContainer
      data-testid={ATSJobPostingTabTestIds.DESCRIPTION_AND_BENEFITS_CONTAINER}
    >
      <Accordion
        sx={accordionSx}
        defaultExpanded
        title={<Typography variant="h2">Description</Typography>}
        dataTestId={ATSJobPostingTabTestIds.DESCRIPTION_ACCORDION}
      >
        <Box
          data-testid={ATSJobPostingTabTestIds.DESCRIPTION_TEXT}
          className="ql-editor"
          dangerouslySetInnerHTML={getHTML(job, 'description')}
          sx={{ padding: 0 }}
        />
      </Accordion>
      {job.benefits && (
        <Accordion
          sx={accordionSx}
          defaultExpanded
          title={
            <Typography variant="h2" data-testid={ATSJobPostingTabTestIds.BENEFITS_TEXT}>
              Benefits
            </Typography>
          }
          dataTestId={ATSJobPostingTabTestIds.BENEFITS_ACCORDION}
        >
          <Box
            data-testid={ATSJobPostingTabTestIds.BENEFITS_TEXT}
            className="ql-editor"
            dangerouslySetInnerHTML={getHTML(job, 'benefits')}
            sx={{ padding: 0 }}
          />
        </Accordion>
      )}
    </DescriptionAndBenefitsContainer>
  );
};
