import { Box, styled } from '@mui/material';
import { formatTestId } from 'features/Connect/utils/connectUtils';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { StyledRadioCardProps } from '../ModalFormOptions/utils/types';

export const RadioOption = ({ option, selectedOption, handleChange, dataTestId }) => {
  const isSelected = selectedOption === option.value;

  const handleRadioClick = () => {
    handleChange(option.value);
  };

  const optionValue = formatTestId(option.value);
  const optionTestId = `${dataTestId}-${optionValue}-option`;
  const checkedIconTestId = `${dataTestId}-${optionValue}-option-checked-icon`;
  const uncheckedIconTestId = `${dataTestId}-${optionValue}-option-unchecked-icon`;
  const labelTestId = `${dataTestId}-${optionValue}-option-label`;

  return (
    <StyledRadioCard onClick={handleRadioClick} isSelected={isSelected} data-testid={optionTestId}>
      <CheckIconContainer>
        {isSelected ? (
          <CheckCircleRoundedIcon color="primary" data-testid={checkedIconTestId} />
        ) : (
          <RadioButtonUncheckedIcon color="disabled" data-testid={uncheckedIconTestId} />
        )}
      </CheckIconContainer>
      <RadioLabel data-testid={labelTestId}>{option.label}</RadioLabel>
    </StyledRadioCard>
  );
};

const StyledRadioCard = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<StyledRadioCardProps>(({ theme, isSelected }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  padding: '12px 16px',
  marginBottom: theme.spacing(2),
  borderRadius: '16px',
  border: `2px solid ${isSelected ? theme.palette.primary.main : theme.palette.divider}`,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const CheckIconContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: '24px',
  marginRight: '12px',
});

const RadioLabel = styled(Box)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: theme.typography.h3.fontSize,
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightRegular,
  letterSpacing: '0.15px',
  lineHeight: '24px',
}));
