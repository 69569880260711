import { RadioGroup, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import ExpressInterestAPI from 'features/Connect/api/expressInterestAPI';
import { useConnectFilterOptions } from 'hooks/data/useConnectFilterOptions';
import { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { MultiSelectWithSearch } from 'sharedComponents/Select';
import { Option } from 'sharedComponents/Select/types';
import { HorizontalLinearStepper } from 'sharedComponents/Stepper/HorizontalStepper';
import { PreferencesFormData } from 'types/connectTypes';
import auth from 'utils/auth';
import { ConnectProfileFlowButtonGroup } from './SharedComponents/ConnectProfileFlowButtonGroup';
import {
  getBannerContent,
  getGatedActionDisplayInfo,
  getPlaceholderText,
  updateConnectFirstProfileSetupDateTime,
  validateLocation,
} from './utils';
import {
  ConnectProfileFlowContainer,
  ConnectProfileFlowFormContainer,
  ConnectProfileFlowOuterContainer,
  ConnectProfileFlowPaperContainer,
  ConnectProfileFlowRadioCardMessageToggleStyles,
  ConnectProfileFlowSelectStyles,
  ConnectProfileFlowTitle,
} from './styles';
import RadioCardToggle from './SharedComponents/RadioCardToggle';
import { ConnectAnnouncementBanner } from 'features/Connect/components/ConnectAnnouncementBanner/ConnectAnnouncmentBanner';
import { CONNECT_OPPORTUNITY_RADIO_GROUP, JOB_SEEKING_STATUS_RADIO_GROUP } from './constants';
import { JobSeekingStatus } from 'types/types';
import { ValidationErrors, ValidationResult } from './types';
import { ConnectPreferencesFormDataTestIds } from 'data-testids/ConnectDataTestIds';
import { PreferencesAlertMessage } from './PreferencesForm/PreferencesAlertMessage';

import { useStateParam } from '../Context/ConnectStateCodeContextProvider';
import { LoadingSpinner } from 'sharedComponents/LoadingSpinner/loadingSpinner';
import {
  LocationMapSearch,
  PreferencesInputField,
} from 'features/Connect/components/LocationMapSearch/LocationMapSearch';
import { GoogleMapsService } from 'integrations/maps/googleMapsService';
import { ENVIRONMENT } from 'utils/constants/environment';
import { CONNECT_JOBBOARD_STATES } from 'utils/constants';

export function PreferencesForm(): React.ReactElement {
  const user = auth.getUser();
  const theme = useTheme();
  const jobboardState = useStateParam();
  const stateCode =
    user.preference?.state_code || CONNECT_JOBBOARD_STATES[jobboardState]?.stateCode;
  const isMounted = useRef(true);

  const { isLoadingFilterOptions, gradeOptions, subjectOptions, schoolOptions } =
    useConnectFilterOptions();

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const getInitialJobSeekingStatus = () =>
    user?.preference?.job_seeking_status || JobSeekingStatus.ACTIVELY_SEEKING;

  const getInitialOpenToConnectOpportunities = () =>
    user?.preference?.open_to_connect_opportunities ?? true;

  const getInitialSubjects = () => {
    if (user.preference.categories_connect) {
      return subjectOptions.filter((subject) =>
        user.preference.categories_connect.includes(subject.value)
      );
    }
    return [];
  };

  const getInitialGrades = () => {
    if (user.preference.grades) {
      return gradeOptions.filter((grade) => user.preference.grades.includes(Number(grade.value)));
    }
    return [];
  };

  const getInitialSchoolsToHideFrom = () => {
    if (user.preference.hide_user_from_schools_on_connect) {
      return schoolOptions.filter((school) =>
        user.preference.hide_user_from_schools_on_connect.includes(school.value)
      );
    }
    return [];
  };

  const isMissingRequiredFields = () => {
    const { openToConnectOpportunities, userLocation, subjects, grades } = userFormData;
    return openToConnectOpportunities && (!userLocation || !subjects.length || !grades.length);
  };

  const [userFormData, setUserFormData] = useState<PreferencesFormData>({
    jobSeekingStatus: getInitialJobSeekingStatus(),
    openToConnectOpportunities: getInitialOpenToConnectOpportunities(),
    distanceFromSchool: user.preference.miles_within || '25',
    userLocation: user.preference.location,
    subjects: getInitialSubjects(),
    grades: getInitialGrades(),
    schoolsToHideFrom: getInitialSchoolsToHideFrom(),
  });
  const [formIsSubmitting, setFormIsSubmitting] = useState<boolean>(false);
  const [canUserSkipForm, setCanUserSkipForm] = useState<boolean>(
    !userFormData.openToConnectOpportunities
  );
  const [error, setError] = useState<ValidationErrors>({
    jobSeekingStatus: null,
    openToConnectOpportunities: null,
    distance: null,
    subject: null,
    grade: null,
    userLocation: null,
  });
  const history = useHistory();
  const { flowDisplayInformation, pageDisplayInformation } =
    getGatedActionDisplayInfo(isMissingRequiredFields());
  const signupState = useStateParam();

  const mapService = new GoogleMapsService(ENVIRONMENT.GOOGLE_MAPS_API_KEY);

  const getPrimaryButtonText = () => {
    if (isMobile) {
      return pageDisplayInformation.primaryButtonLabelMobile;
    } else if (canUserSkipForm) {
      return 'Skip & Finish';
    }
    return pageDisplayInformation.primaryButtonLabel;
  };

  const handleJobSeekingStatusChange = (jobSeekingStatus: JobSeekingStatus) => {
    setUserFormData((prevState) => ({
      ...prevState,
      jobSeekingStatus,
      openToConnectOpportunities:
        jobSeekingStatus === JobSeekingStatus.ACTIVELY_SEEKING
          ? prevState.openToConnectOpportunities
          : false,
    }));
  };

  const handleConnectOpportunityChange = (value: string) => {
    setUserFormData((prevState) => ({
      ...prevState,
      openToConnectOpportunities: JSON.parse(value),
    }));
  };

  const handleUserLocationChange = (value: string) => {
    setError((prev) => ({
      ...prev,
      userLocation: null,
    }));
    setUserFormData((prevFormData) => ({
      ...prevFormData,
      userLocation: value,
    }));
  };

  const handleDistanceChange = (selectedOption: number) => {
    setError({ ...error, distance: null });
    setUserFormData({
      ...userFormData,
      distanceFromSchool: selectedOption.toString(),
    });
  };

  const handleGradeChange = (selectedOptions: Option[]) => {
    setError({ ...error, grade: null });
    setUserFormData({
      ...userFormData,
      grades: selectedOptions,
    });
  };

  const handleSubjectChange = (selectedOptions: Option[]) => {
    setError({ ...error, subject: null });
    setUserFormData({
      ...userFormData,
      subjects: selectedOptions,
    });
  };

  const handleSchoolsToHideFromChange = (selectedOptions: Option[]) => {
    setUserFormData({
      ...userFormData,
      schoolsToHideFrom: selectedOptions,
    });
  };

  const validateForm = async (formData: PreferencesFormData): Promise<ValidationResult> => {
    const {
      jobSeekingStatus,
      openToConnectOpportunities,
      distanceFromSchool,
      userLocation,
      grades,
      subjects,
    } = formData;

    if (jobSeekingStatus !== JobSeekingStatus.ACTIVELY_SEEKING || !openToConnectOpportunities) {
      return { isValid: true, errors: {} };
    }

    const errors: ValidationErrors = {};

    if (!userLocation) {
      errors.userLocation = 'Please enter a location';
    } else {
      const locationError = await validateLocation(userLocation, distanceFromSchool);
      if (locationError) {
        errors.userLocation = locationError;
      }
    }

    if (isFieldRequired('distance') && !distanceFromSchool) {
      errors.distance = 'Please select a distance';
    }

    if (isFieldRequired('grade') && grades.length === 0) {
      errors.grade = 'Please select at least one grade';
    }

    if (isFieldRequired('subject') && subjects.length === 0) {
      errors.subject = 'Please select at least one subject';
    }

    return { isValid: Object.keys(errors).length === 0, errors };
  };

  const handleSaveAndContinue = async () => {
    try {
      setFormIsSubmitting(true);
      const { isValid, errors } = await validateForm(userFormData);

      if (!isValid) {
        setError((prev) => ({ ...prev, ...errors }));
        setFormIsSubmitting(false);
        return;
      }

      const {
        jobSeekingStatus,
        openToConnectOpportunities,
        distanceFromSchool,
        userLocation,
        grades,
        subjects,
        schoolsToHideFrom,
      } = userFormData;

      const userPreferences = {
        jobSeekingStatus: jobSeekingStatus,
        openToConnectOpportunities,
        subjects: subjects.map((subject) => String(subject.value)),
        grades: grades.length ? grades.map((grade) => Number(grade.value)) : null,
        distanceFromSchool,
        userLocation,
        schoolsToHideFrom: schoolsToHideFrom.map((school) => String(school.value)),
      };

      await ExpressInterestAPI.updateUserPreferences(user.id, userPreferences);
      const updatedUser = await auth.getUserAsync();
      auth.setUser(updatedUser);
      history.push(pageDisplayInformation.continueToUrl);
    } finally {
      if (isMounted.current) {
        setFormIsSubmitting(false);
      }
    }
  };

  useEffect(() => {
    if (isLoadingFilterOptions) return;

    const initialSubjects = getInitialSubjects();
    const initialGrades = getInitialGrades();
    const initialSchoolsToHideFrom = getInitialSchoolsToHideFrom();

    setUserFormData((currentFormData) => ({
      ...currentFormData,
      subjects: initialSubjects,
      grades: initialGrades,
      schoolsToHideFrom: initialSchoolsToHideFrom,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingFilterOptions]);

  useEffect(() => {
    if (isLoadingFilterOptions) return;
    if (pageDisplayInformation.requiredFields.includes('distance')) {
      setCanUserSkipForm(false);
    } else {
      setCanUserSkipForm(!userFormData.openToConnectOpportunities);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userFormData]);

  useEffect(() => {
    updateConnectFirstProfileSetupDateTime(user, signupState);
  }, [user, signupState]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const isFieldRequired = (fieldName: string): boolean => {
    return pageDisplayInformation.requiredFields.includes(fieldName);
  };

  return (
    <ConnectProfileFlowOuterContainer>
      {!!getBannerContent() && <ConnectAnnouncementBanner />}
      <ConnectProfileFlowContainer>
        <HorizontalLinearStepper
          activeStep={pageDisplayInformation.step}
          steps={flowDisplayInformation.stepper.steps}
          mobileSteps={flowDisplayInformation.stepper.mobileSteps}
        />
        <ConnectProfileFlowPaperContainer elevation={4}>
          <ConnectProfileFlowFormContainer component="form">
            <TitleContainer>
              <ConnectProfileFlowTitle
                variant="h1"
                data-testid={ConnectPreferencesFormDataTestIds.HEADER_TEXT}
              >
                {pageDisplayInformation.headerText}
              </ConnectProfileFlowTitle>
            </TitleContainer>
            {isLoadingFilterOptions ? (
              <Stack>
                <LoadingSpinner
                  color="primary"
                  dataTestId={ConnectPreferencesFormDataTestIds.LOADING_SPINNER}
                />
              </Stack>
            ) : (
              <Stack spacing={2} px={{ xs: 2, sm: 8 }} pt={{ xs: 1, sm: 2 }} pb={{ xs: 1, sm: 3 }}>
                <Box>
                  <QuestionLabel
                    data-testid={
                      ConnectPreferencesFormDataTestIds.JOB_SEEKING_STATUS_QUESTION_LABEL
                    }
                    htmlFor="controlled-radio-buttons-group"
                    required
                    aria-required
                  >
                    Where are you in the job search process?
                  </QuestionLabel>
                  <RadioGroup
                    aria-labelledby="job-seeking-status-radio-buttons-group"
                    name={JOB_SEEKING_STATUS_RADIO_GROUP}
                    data-testid={ConnectPreferencesFormDataTestIds.JOB_SEEKING_STATUS_RADIO_GROUP}
                    value={userFormData.jobSeekingStatus}
                    onChange={(event) =>
                      handleJobSeekingStatusChange(event.target.value as JobSeekingStatus)
                    }
                  >
                    <Stack
                      direction={{
                        xs: 'column',
                        sm: 'row',
                      }}
                      spacing={{
                        xs: 1,
                        sm: 2,
                      }}
                    >
                      <RadioCardToggle
                        text="Actively looking for work"
                        dataTestId={ConnectPreferencesFormDataTestIds.JOB_SEEKING_STATUS_YES}
                        radioValue={JobSeekingStatus.ACTIVELY_SEEKING}
                        selectedValue={userFormData.jobSeekingStatus}
                        sx={ConnectProfileFlowRadioCardMessageToggleStyles(theme)}
                      />
                      <RadioCardToggle
                        text="Not looking for work"
                        dataTestId={ConnectPreferencesFormDataTestIds.JOB_SEEKING_STATUS_NO}
                        radioValue={JobSeekingStatus.NOT_LOOKING}
                        selectedValue={userFormData.jobSeekingStatus}
                        sx={ConnectProfileFlowRadioCardMessageToggleStyles(theme)}
                      />
                    </Stack>
                  </RadioGroup>
                </Box>
                {userFormData.jobSeekingStatus === JobSeekingStatus.ACTIVELY_SEEKING && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
                    <Box>
                      <QuestionLabel
                        data-testid={
                          ConnectPreferencesFormDataTestIds.CONNECT_OPPORTUNITY_QUESTION_LABEL
                        }
                        htmlFor="controlled-radio-buttons-group"
                        required
                        aria-required
                      >
                        Do you want to get matched with principals?
                      </QuestionLabel>
                      <RadioGroup
                        aria-labelledby="open-to-connect-opportunities-radio-buttons-group"
                        name={CONNECT_OPPORTUNITY_RADIO_GROUP}
                        value={userFormData.openToConnectOpportunities}
                        onChange={(event) => handleConnectOpportunityChange(event.target.value)}
                        data-testid={
                          ConnectPreferencesFormDataTestIds.CONNECT_OPPORTUNITY_RADIO_GROUP
                        }
                      >
                        <Stack
                          direction={{
                            xs: 'column',
                            sm: 'row',
                          }}
                          spacing={{
                            xs: 1,
                            sm: 2,
                          }}
                        >
                          <RadioCardToggle
                            text="Yes, I'm open to being matched with principals."
                            dataTestId={ConnectPreferencesFormDataTestIds.CONNECT_OPPORTUNITY_YES}
                            radioValue={true}
                            selectedValue={userFormData.openToConnectOpportunities}
                            height={'74px'}
                            sx={ConnectProfileFlowRadioCardMessageToggleStyles(theme)}
                          />
                          <RadioCardToggle
                            text="Don't allow principals to see my profile yet."
                            dataTestId={ConnectPreferencesFormDataTestIds.CONNECT_OPPORTUNITY_NO}
                            radioValue={false}
                            selectedValue={userFormData.openToConnectOpportunities}
                            height={'74px'}
                            sx={ConnectProfileFlowRadioCardMessageToggleStyles(theme)}
                          />
                        </Stack>
                      </RadioGroup>
                    </Box>
                    <PreferencesAlertMessage />
                  </Box>
                )}
                {userFormData.jobSeekingStatus === JobSeekingStatus.ACTIVELY_SEEKING &&
                  userFormData.openToConnectOpportunities && (
                    <Stack spacing={2}>
                      <Box>
                        <QuestionLabel
                          data-testid={ConnectPreferencesFormDataTestIds.LOCATION_QUESTION_LABEL}
                          required={userFormData.openToConnectOpportunities}
                          htmlFor="user-location"
                        >
                          Where will you commute from?
                        </QuestionLabel>
                        <LocationMapSearch
                          onLocationChange={handleUserLocationChange}
                          onRadiusChange={handleDistanceChange}
                          radius={parseInt(userFormData.distanceFromSchool, 10)}
                          location={userFormData.userLocation}
                          renderInputField={PreferencesInputField}
                          stateCode={stateCode}
                          mapService={mapService}
                          variant="preferences"
                          testIds={{
                            autocomplete: ConnectPreferencesFormDataTestIds.LOCATION_INPUT,
                            mapContainer: ConnectPreferencesFormDataTestIds.LOCATION_MAP_CONTAINER,
                            radiusControl: ConnectPreferencesFormDataTestIds.DISTANCE_SLIDER,
                          }}
                          helperText="Please enter your complete address for more accurate job matches"
                          error={!!error?.userLocation}
                          sx={ConnectProfileFlowSelectStyles(theme)}
                        />
                        {error?.distance && (
                          <Typography color="error" sx={{ mt: 1 }}>
                            {error.distance}
                          </Typography>
                        )}
                      </Box>
                      <Box>
                        <QuestionLabel
                          data-testid={ConnectPreferencesFormDataTestIds.SUBJECTS_QUESTION_LABEL}
                          required={userFormData.openToConnectOpportunities}
                        >
                          Preferred Subject(s)
                        </QuestionLabel>
                        <MultiSelectWithSearch
                          hiddenLabel
                          dataTestId={ConnectPreferencesFormDataTestIds.SUBJECTS_DROPDOWN}
                          handleChange={handleSubjectChange}
                          displayName=""
                          options={subjectOptions}
                          values={userFormData.subjects}
                          placeholder={getPlaceholderText(userFormData.subjects, 'Subjects')}
                          sx={ConnectProfileFlowSelectStyles(theme)}
                          showError={!!error?.subject}
                          helperText={error?.subject}
                          tagLimit={1}
                        />
                      </Box>
                      <Box>
                        <QuestionLabel
                          data-testid={ConnectPreferencesFormDataTestIds.GRADES_QUESTION_LABEL}
                          required={userFormData.openToConnectOpportunities}
                        >
                          Preferred Grade Level(s)
                        </QuestionLabel>
                        <MultiSelectWithSearch
                          hiddenLabel
                          dataTestId={ConnectPreferencesFormDataTestIds.GRADES_DROPDOWN}
                          handleChange={handleGradeChange}
                          displayName=""
                          placeholder={getPlaceholderText(userFormData.grades, 'Grades')}
                          options={gradeOptions}
                          values={userFormData.grades}
                          sx={ConnectProfileFlowSelectStyles(theme)}
                          showError={!!error?.grade}
                          helperText={error?.grade}
                          tagLimit={1}
                        />
                      </Box>
                      <Box>
                        <QuestionLabel
                          data-testid={
                            ConnectPreferencesFormDataTestIds.SCHOOLS_TO_HIDE_FROM_QUESTION_LABEL
                          }
                        >
                          Hide profile from the following school(s)
                          <InfoText>
                            Use this option to keep your current school from viewing your profile.
                          </InfoText>
                        </QuestionLabel>
                        <MultiSelectWithSearch
                          hiddenLabel
                          dataTestId={
                            ConnectPreferencesFormDataTestIds.SCHOOLS_TO_HIDE_FROM_DROPDOWN
                          }
                          handleChange={handleSchoolsToHideFromChange}
                          displayName=""
                          options={schoolOptions}
                          values={userFormData.schoolsToHideFrom}
                          placeholder={getPlaceholderText(
                            userFormData.schoolsToHideFrom,
                            'Schools'
                          )}
                          sx={ConnectProfileFlowSelectStyles(theme)}
                          tagLimit={1}
                          hasSubLabel={true}
                          subLabelKeys={['address.city']}
                        />
                      </Box>
                    </Stack>
                  )}
              </Stack>
            )}
          </ConnectProfileFlowFormContainer>
        </ConnectProfileFlowPaperContainer>
        <ConnectProfileFlowButtonGroup
          dataTestId={ConnectPreferencesFormDataTestIds.BUTTON_GROUP}
          primaryButton={{
            dataTestId: ConnectPreferencesFormDataTestIds.SAVE_AND_CONTINUE_BUTTON,
            primaryButtonLabel: getPrimaryButtonText(),
            primaryAction: handleSaveAndContinue,
            disabled:
              userFormData.openToConnectOpportunities &&
              (!!error.distance || !!error.grade || !!error.subject || !!error.userLocation),
            isLoading: formIsSubmitting,
          }}
          secondaryButton={{
            dataTestId: ConnectPreferencesFormDataTestIds.BACK_BUTTON,
            secondaryButtonLabel: 'Back',
            secondaryAction: () => history.push(pageDisplayInformation.backUrl),
          }}
        />
      </ConnectProfileFlowContainer>
    </ConnectProfileFlowOuterContainer>
  );
}

const TitleContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const QuestionLabel = styled(InputLabel)(({ theme }) => ({
  textWrap: 'wrap',
  color: theme.palette.text.primary,
}));

const InfoText = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.subtitle1.fontSize,
  color: theme.palette.text.tertiary,
  paddingTop: theme.spacing(0.5),
}));
