import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';

import { activeStatusByValue } from 'utils/enums';
import {
  TabbedJobViewHeaderData,
  TabbedJobViewHeaderDataForMutation,
  ToggleStatusMutationVariables,
} from './types';
import { UseMutation } from 'types/types';

export const useQueryGetHeaderDateByJobId = (
  jobId: number,
  options?: Omit<UseQueryOptions<TabbedJobViewHeaderData, AxiosError>, 'queryKey' | 'queryFn'>
): UseQueryResult<TabbedJobViewHeaderData, AxiosError> => {
  return useQuery({
    queryKey: ['tabbedJobViewHeaderDataByJobId', jobId],
    queryFn: () => axios.get(`/api/roles/${jobId}/overview`).then((res) => res.data),
    enabled: Number.isInteger(jobId),
    ...options,
  });
};

export const useToggleJobStatusMutation: UseMutation<
  TabbedJobViewHeaderDataForMutation,
  unknown,
  ToggleStatusMutationVariables,
  unknown
> = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (payload) => {
      const newActiveStatus =
        payload.activeStatus === activeStatusByValue.activeOpen
          ? activeStatusByValue.activeClosed
          : activeStatusByValue.activeOpen;

      return axios
        .patch(`/api/role/${payload.jobId}/update_active_status/`, {
          active_status: newActiveStatus,
        })
        .then((res) => {
          return {
            ...res.data,
            newActiveStatus,
          };
        });
    },

    onSuccess: (data, variables) => {
      const currentQueryData: TabbedJobViewHeaderData = queryClient.getQueryData([
        'tabbedJobViewHeaderDataByJobId',
        variables.jobId,
      ]);

      queryClient.setQueryData(['tabbedJobViewHeaderDataByJobId', variables.jobId], {
        ...currentQueryData,
        active_status: data.newActiveStatus,
      });
    },
  });
};
