import { styled, Box, Typography } from '@mui/material';
import { ConnectCandidateMatchCardSchoolSectionDataTestIds } from 'data-testids/ConnectDataTestIds';
import { SaveSchoolButton } from 'features/Connect/components/Buttons/SaveSchoolButton';
import { MatchDashboardSchoolInfoProps } from 'features/Connect/features/ConnectCandidatePortalDashboards/CandidateMatchDashboard/utils/types';

export const MatchCardSchoolSectionHeader: React.FC<MatchDashboardSchoolInfoProps> = ({
  school,
  isMatchCard = true,
}) => {
  return (
    <HeaderContainer
      data-testid={ConnectCandidateMatchCardSchoolSectionDataTestIds.SCHOOL_HEADER_CONTAINER}
    >
      <SchoolMainInfoContainer>
        <SchoolAndDistrictNameContainer>
          <SchoolNameText
            data-testid={ConnectCandidateMatchCardSchoolSectionDataTestIds.SCHOOL_NAME_TEXT}
          >
            {school?.name}
          </SchoolNameText>
          <DistrictNameText
            data-testid={ConnectCandidateMatchCardSchoolSectionDataTestIds.DISTRICT_NAME_TEXT}
          >
            {school?.district?.name}
          </DistrictNameText>
        </SchoolAndDistrictNameContainer>
      </SchoolMainInfoContainer>
      {isMatchCard && (
        <SaveSchoolButtonContainer
          data-testid={
            ConnectCandidateMatchCardSchoolSectionDataTestIds.SAVE_SCHOOL_BUTTON_CONTAINER
          }
        >
          <SaveSchoolButton
            school={school}
            size="extraLarge"
            data-testid={ConnectCandidateMatchCardSchoolSectionDataTestIds.SAVE_SCHOOL_BUTTON}
          />
        </SaveSchoolButtonContainer>
      )}
    </HeaderContainer>
  );
};

const HeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
  minWidth: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  padding: '0px',
  [theme.breakpoints.down('md')]: {
    alignItems: 'flex-start',
  },
}));

const SchoolMainInfoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
}));

const SaveSchoolButtonContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  alignContent: 'flex-start',
}));

const SchoolAndDistrictNameContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  gap: theme.spacing(0.5),
  color: theme.palette.gray.dark,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
}));

const SchoolNameText = styled(Typography)(({ theme }) => ({
  color: theme.palette.gray.dark,
  fontSize: theme.typography.subtitle.fontSize,
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightMediumBold,
  lineHeight: '28px',
}));

const DistrictNameText = styled(Typography)(({ theme }) => ({
  color: theme.palette.gray.medium,
  fontSize: theme.typography.subtitle.fontSize,
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightLight,
  lineHeight: '24px',
}));
