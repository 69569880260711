import {
  MapService,
  MapConfig,
  LatLng,
  GeocodingResult,
  Marker,
  Circle,
  Prediction,
} from './mapService';
import { milesToMeters } from 'features/Connect/utils/connectUtils';

export class GoogleMapsService implements MapService {
  private map: google.maps.Map | null = null;
  private geocoder: google.maps.Geocoder | null = null;
  private autocompleteService: google.maps.places.AutocompleteService | null = null;
  private apiKey: string;
  private isApiLoaded = false;

  constructor(apiKey?: string) {
    this.apiKey = apiKey || '';

    // Try to initialize services immediately if API is already loaded
    if (typeof google !== 'undefined' && google.maps) {
      this.initializeServices();
    }
  }

  private initializeServices(): void {
    if (typeof google === 'undefined' || !google.maps) {
      return;
    }

    try {
      // Initialize geocoder
      if (!this.geocoder) {
        this.geocoder = new google.maps.Geocoder();
      }

      // Initialize autocomplete service
      if (!this.autocompleteService && google.maps.places) {
        this.autocompleteService = new google.maps.places.AutocompleteService();
      }

      this.isApiLoaded = true;
    } catch (error) {
      console.error('Error initializing Google Maps services:', error);
    }
  }

  async initialize(element: HTMLElement, config: MapConfig): Promise<any> {
    if (!this.apiKey) {
      throw new Error('Google Maps API key is required');
    }

    try {
      // Load the API if not already loaded
      await this.loadGoogleMapsAPI();

      // Initialize services
      this.initializeServices();

      // Create the map instance
      const map = new google.maps.Map(element, {
        center: { lat: config.center.lat, lng: config.center.lng },
        zoom: config.zoom,
        styles: config.styles || [],
        disableDefaultUI: false,
        clickableIcons: false,
        fullscreenControl: false,
        mapTypeControl: false,
        zoomControl: true,
        streetViewControl: false,
      });

      // Store the map instance
      this.map = map;

      console.log('Google Maps Service - Map successfully created, returning instance');
      return map;
    } catch (error) {
      console.error('Failed to initialize Google Maps:', error);
      throw error;
    }
  }

  async geocodeAddress(address: string): Promise<GeocodingResult | null> {
    // Make sure API is loaded and services are initialized
    await this.ensureServicesInitialized();

    if (!this.geocoder) {
      throw new Error('Geocoder not initialized');
    }

    try {
      const result = await this.geocoder.geocode({ address });

      if (!result.results || result.results.length === 0) {
        return null;
      }

      const location = result.results[0].geometry.location;

      return {
        address: result.results[0].formatted_address,
        location: {
          lat: location.lat(),
          lng: location.lng(),
        },
      };
    } catch (error) {
      console.error('Error geocoding address:', error);
      return null;
    }
  }

  async getPlacePredictions(input: string): Promise<Prediction[]> {
    // Make sure API is loaded and services are initialized
    await this.ensureServicesInitialized();

    if (!this.autocompleteService) {
      throw new Error('AutocompleteService not initialized');
    }

    try {
      const response = await this.autocompleteService.getPlacePredictions({
        input,
        types: ['address'],
        componentRestrictions: { country: 'us' },
      });

      return (response.predictions || []).map((prediction) => ({
        description: prediction.description,
        mainText: prediction.structured_formatting.main_text,
        secondaryText: prediction.structured_formatting.secondary_text,
        placeId: prediction.place_id,
      }));
    } catch (error) {
      console.error('Error getting place predictions:', error);
      return [];
    }
  }

  // Helper method to ensure services are initialized before use
  private async ensureServicesInitialized(): Promise<void> {
    if (!this.isApiLoaded) {
      await this.loadGoogleMapsAPI();
      this.initializeServices();
    }
  }

  private async loadGoogleMapsAPI(): Promise<void> {
    // Check if Google Maps API is already loaded
    if (typeof google !== 'undefined' && google.maps) {
      return;
    }

    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}&libraries=places`;
      script.async = true;
      script.defer = true;

      script.onload = () => {
        this.initializeServices();
        resolve();
      };

      script.onerror = () => {
        reject(new Error('Failed to load Google Maps API'));
      };

      document.head.appendChild(script);
    });
  }

  createMarker(position: LatLng): Marker {
    // Don't throw an error if the map isn't initialized
    const marker = new google.maps.Marker({
      position,
      map: this.map || null, // Allow null map
      draggable: false,
    });

    return {
      setPosition(position: LatLng) {
        marker.setPosition(position);
      },
      getPosition(): LatLng {
        const pos = marker.getPosition();
        return {
          lat: pos ? pos.lat() : 0,
          lng: pos ? pos.lng() : 0,
        };
      },
      remove() {
        marker.setMap(null);
      },
      // Add a method to attach the marker to a map later
      attachToMap(map: google.maps.Map) {
        marker.setMap(map);
      },
    };
  }

  createCircle(center: LatLng, radiusInMiles: number): Circle {
    const radiusInMeters = milesToMeters(radiusInMiles);

    // Don't throw an error if map isn't initialized
    const circle = new google.maps.Circle({
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.35,
      map: this.map || null,
      center,
      radius: radiusInMeters,
    });

    return {
      setCenter(center: LatLng) {
        circle.setCenter(center);
      },
      setRadius(radiusInMeters: number) {
        circle.setRadius(radiusInMeters);
      },
      remove() {
        circle.setMap(null);
      },
      // Add a method to attach the circle to a map later
      attachToMap(map: google.maps.Map) {
        circle.setMap(map);
      },
    };
  }

  // Add a new method to get circle bounds
  getCircleBounds(center: LatLng, radiusMiles: number): google.maps.LatLngBounds | null {
    try {
      // Convert miles to meters
      const radiusInMeters = milesToMeters(radiusMiles);

      // Create a temporary circle to get its bounds
      const tempCircle = new google.maps.Circle({
        center: center,
        radius: radiusInMeters,
      });

      // Get the bounds of the circle
      return tempCircle.getBounds() || null;
    } catch (error) {
      console.error('Error calculating circle bounds:', error);
      return null;
    }
  }
}
