import { Box, Typography } from '@mui/material';
import { ATSQuestionComponentTestIds } from 'data-testids/ATS';
import { ReactNode } from 'react';
import { Header, QuestionSetContainer } from './styles';

export interface QuestionSetProps {
  questionSetTitle: string;
  children: ReactNode;
}

export const QuestionSet = ({ questionSetTitle, children }) => {
  return (
    <QuestionSetContainer>
      <Header>
        <Typography variant="subtitle" data-testid={ATSQuestionComponentTestIds.QUESTION_SET_TITLE}>
          {questionSetTitle}
        </Typography>
      </Header>
      <Box>{children}</Box>
    </QuestionSetContainer>
  );
};
