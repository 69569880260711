import { PROD_URL } from 'utils/constants';

export const ENVIRONMENT = {
  GOOGLE_MAPS_API_KEY:
    window.location.hostname === PROD_URL
      ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY_PROD
      : process.env.REACT_APP_GOOGLE_MAPS_API_KEY_STAGING,
  CONNECT_LOCATION_AUTOCOMPLETE_DEBOUNCE_TIME_MILLIS: parseInt(
    process.env.REACT_APP_CONNECT_LOCATION_AUTOCOMPLETE_DEBOUNCE_TIME_MILLIS || '300',
    10
  ),
};
