import { AnnouncementBanner } from 'sharedComponents/Banner/AnnouncementBanner';
import { AccessTime } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { getBannerContent } from 'features/Connect/features/ExpressInterest/utils';
import { ConnectProfileFlowDataTestIds } from 'data-testids/ConnectDataTestIds';

export function ConnectAnnouncementBanner(): React.ReactElement | null {
  const theme = useTheme();
  const content = getBannerContent();

  if (!content) {
    return null;
  }

  return (
    <AnnouncementBanner
      body={content}
      dataTestId={ConnectProfileFlowDataTestIds.CONNECT_ANNOUNCEMENT_BANNER}
      startIcon={<AccessTime />}
      iconStyle={{ fontSize: 'large', width: '24px', height: '24px' }}
      sx={{
        display: 'flex',
        direction: 'row',
        width: '100%',
        height: '47px',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.spacing(0.5),
        fontSize: theme.typography.h3.fontSize,
        lineHeight: '26px',
        fontWeight: theme.typography.fontWeightMediumBold,
      }}
    />
  );
}
