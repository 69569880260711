import { createContext, useContext, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

type Location = ReturnType<typeof useLocation>;

interface NavigationContextType {
  location: Location;
  previousLocation: Location | null;
}

const NavigationContext = createContext<NavigationContextType | null>(null);

export const NavigationProvider = ({ children }) => {
  const location = useLocation();
  const previousLocationRef = useRef(null);

  useEffect(() => {
    previousLocationRef.current = location;
  }, [location]);

  return (
    <NavigationContext.Provider
      value={{ location: location, previousLocation: previousLocationRef.current }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigation = (): NavigationContextType => {
  const context = useContext(NavigationContext);
  if (!context) {
    throw new Error('useNavigation must be used within a NavigationProvider');
  }
  return context;
};
