import { createTheme } from '@mui/material/styles';

export const nimbleTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 800,
      mdlg: 1000,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { size: 'extraLarge' },
          style: {
            fontSize: '16px',
            padding: '8px 24px',
            height: '42px',
          },
        },
      ],
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: { color: 'red' },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardInfo: {
          color: '#3050CE',
        },
      },
    },
    MuiSelect: {
      variants: [
        {
          props: { isPlaceholderLabel: true },
          style: {
            '& .MuiOutlinedInput-root': {
              '& legend': {
                width: 0,
              },
            },
            '& .MuiInputLabel-shrink': {
              display: 'none',
            },
          },
        },
      ],
    },
  },
  palette: {
    primary: {
      main: '#008060',
      light: '#7FBFAF',
      medium: '#00A37D',
      dark: '#00654E',
      fill: '#EFFAF8',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#5C7CFA',
      light: '#97ABFC',
      dark: '#3050CE',
      fill: '#F6F8FF',
      contrastText: '#FFFFFF',
    },
    error: {
      main: '#CC3340',
      light: '#E5999f',
      dark: '#B12330',
      fill: '#FFEAEA',
    },
    warning: {
      main: '#EC7729',
      light: '#F5BB94',
      dark: '#AD5918',
      fill: '#FFEFE4',
    },
    success: {
      main: '#008060',
      light: '#7FBFAF',
      medium: '#00A37D',
      dark: '#00654E',
      fill: '#D9F4EE',
    },
    info: {
      main: '#5C7CFA',
      light: '#97ABFC',
      medium: '#3050CD',
      dark: '#3050CE',
      fill: '#F6F8FF',
    },
    text: {
      primary: '#1A1A1A',
      light: '#475467',
      secondary: '#3A3A3A',
      disabled: '#999999',
      tertiary: '#636C7B',
    },
    black: {
      main: '#3A3A3A',
      dark: '#1A1A1A',
      darker: '#000000',
    },
    blue: {
      main: '#2F58D2',
      light: '#2175B9',
      medium: '#0071BA',
      dark: '#151e47',
      fill: '#EEF8FF',
    },
    gray: {
      light: '#EAECF0',
      mediumLight: '#BDBDBD',
      medium: '#5A5A5A',
      mediumDark: '#344054',
      dark: '#101828',
      darker: '#D0D5DD',
    },
    purple: {
      main: '#9E77ED',
      light: '#E9D7FE',
      dark: '#6941C6',
      fill: '#F9F5FF',
    },
    white: {
      main: '#FFFFFF',
      medium: '#F5F5F5',
      darker: '#FAFAFB',
    },
    yellow: {
      main: '#FFA601',
      light: '#FFDC95',
      fill: '#FFFAEB',
      dark: '#D38B04',
    },
    border: {
      main: '#E0E0E0',
      secondary: '#3A3A3A',
    },
  },
  typography: {
    h1: {
      fontSize: '30px',
      fontWeight: 700,
      lineHeight: '34px',
    },
    h2: {
      lineHeight: '24px',
      fontSize: '20px',
      fontWeight: 600,
    },
    h3: {
      lineHeight: '19px',
      fontWeight: 500,
      fontSize: '16px',
    },
    body2: {
      fontSize: '14px',
    },
    body3: {
      fontSize: '12px',
    },
    body4: {
      fontSize: '10px',
    },
    button: {
      textTransform: 'none',
    },
    fontFamily: ['Inter', 'Open Sans', 'Aeonik TRIAL'].join(','),
    fontWeightBold: 700,
    fontWeightMediumBold: 600,
    fontWeightMedium: 500,
    fontWeightRegular: 400,
    fontWeightLight: 300,
    heading7: {
      fontSize: '16px',
    },
    subtitle: {
      fontSize: '16px',
      fontWeight: 600,
    },
    smallerPrint: {
      fontSize: '9px',
    },
    print: {
      fontSize: '11px',
    },
  },
});
