import { Box, Link, styled, Theme, Typography } from '@mui/material';

export const AlertContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

export const Bottom = styled(Box)(({ theme }) => ({
  alignItems: 'start',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  [theme.breakpoints.down('md')]: {
    paddingTop: theme.spacing(0),
  },
}));

export const ChipContainer = styled(Box)(({ theme }) => ({
  paddingRight: theme.spacing(5),
  paddingTop: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    paddingBottom: theme.spacing(1),
  },
}));

export const completedRowSx = (theme: Theme) => ({
  backgroundColor: theme.palette.success.main,
  border: `2px solid ${theme.palette.success.main}`,
  color: 'white !important',
  fontWeight: theme.typography.fontWeightMediumBold,
  height: theme.spacing(4),
  padding: '6px 0px',
  width: '133px',
});

export const CompletionText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.tertiary,
  display: 'inline',
  fontStyle: 'italic',
  paddingLeft: theme.spacing(1),
}));

export const CreatePaneContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  minHeight: '300px',
});

export const draftRowSx = (theme: Theme) => ({
  backgroundColor: theme.palette.yellow.fill,
  border: `2px solid ${theme.palette.yellow.light}`,
  color: theme.palette.yellow.dark,
  fontWeight: theme.typography.fontWeightMediumBold,
  height: theme.spacing(4),
  padding: '6px 0px',
  width: '133px',
});

export const LowerVisibilityText = styled(Box)(({ theme }) => ({
  display: 'flex',
  fontWeight: theme.typography.fontWeightMedium,
  paddingLeft: theme.spacing(9),
  [theme.breakpoints.down('md')]: {
    paddingLeft: 0,
  },
}));

export const ModalContainer = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(4)}`,
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(1),
  },
}));

export const RecommendationText = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMediumBold,
  paddingRight: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export const ScoreAndCompletion = styled(Box)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

export const RowCTAContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'end',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'start',
    paddingTop: theme.spacing(1),
  },
}));

export const CandidateFeedbackRowContainer = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.gray.darker}`,
  borderRadius: theme.shape.borderRadius * 2,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(2.5),
  padding: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    padding: theme.spacing(1),
  },
}));

export const Rows = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

export const ScoreDisplayContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  fontWeight: theme.typography.fontWeightMediumBold,
  paddingRight: theme.spacing(1),
}));

export const ScoreText = styled(RecommendationText)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.tertiary,
  cursor: 'pointer',
  textDecorationColor: theme.palette.text.tertiary,
}));

export const SubmittedBy = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.tertiary,
  fontSize: theme.typography.body2.fontSize,
  fontStyle: 'italic',
}));

export const SubmittedByTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.heading7.fontSize,
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: theme.spacing(5),
}));

export const Top = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

export const TopLeft = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

export const VisibilityRow = styled(Box)(({ theme }) => ({
  color: theme.palette.text.tertiary,
  display: 'flex',
  justifyContent: ' center',
  paddingTop: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

export const VisibilityText = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
}));
