import { Divider, styled } from '@mui/material';
import HiringTeam from './components/HiringTeam';
import ScorecardSection from './components/ScorecardSection';
import auth from 'utils/auth';
import { JobClosureNotificationSection } from './components/JobClosureNotifications';

const HiringTeamTab: React.FC<{ jobId: number }> = ({ jobId }) => {
  return (
    <>
      <HiringTeam jobId={jobId} />
      <SectionDivider />
      <ScorecardSection jobId={jobId} />
      {auth.isDistrictAdmin() && (
        <>
          <SectionDivider />
          <JobClosureNotificationSection jobId={jobId} />
        </>
      )}
    </>
  );
};

export default HiringTeamTab;

const SectionDivider = styled(Divider)(({ theme }) => ({
  margin: `${theme.spacing(4)} 0`,
}));
