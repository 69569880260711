import { ReactNode } from 'react';
import { Box, Checkbox, FormControlLabel, FormGroup, SxProps, useTheme } from '@mui/material';
import { ComponentsSelectAllListTestIds } from 'data-testids/Components';
import { Controller, useFormContext } from 'react-hook-form';

export type SelectAllListItem = Record<string, string | number>;

export interface SelectAllListWithRHFProps<T extends SelectAllListItem> {
  dataTestId: string;
  formValue: string;
  itemKey: keyof T;
  items: T[];
  label: ReactNode;
  sx?: SxProps;
  rules: { [ruleName: string]: string };
}

export const SelectAllListWithRHF = <T extends SelectAllListItem>({
  dataTestId,
  formValue,
  itemKey,
  items,
  label,
  sx,
  rules,
}: SelectAllListWithRHFProps<T>) => {
  const theme = useTheme();
  const { control, setValue, getValues } = useFormContext();

  const handleSelectAll = (checked: boolean) => {
    const newSelected = checked ? items.map((item) => item[itemKey]) : [];
    setValue(formValue, newSelected, { shouldValidate: true });
  };

  const handleSelectItem = (id: number | string, checked: boolean) => {
    const selected = getValues(formValue) ?? [];
    const newValue = checked ? [...selected, id] : selected.filter((itemId) => itemId !== id);
    setValue(formValue, newValue, { shouldValidate: true });
  };

  return (
    <FormGroup data-testid={dataTestId} sx={sx}>
      <Controller
        name={formValue}
        control={control}
        rules={rules}
        defaultValue={[]}
        render={({ field }) => {
          const onChange = field.onChange as (value: (number | string)[]) => void;
          const value: (number | string)[] = field.value ?? [];
          const allSelected = items.length > 0 && value.length === items.length;
          const someSelected = value.length > 0 && value.length < items.length;

          return (
            <>
              <FormControlLabel
                label={label}
                control={
                  <Checkbox
                    checked={allSelected}
                    indeterminate={someSelected}
                    onChange={(e) => {
                      handleSelectAll(e.target.checked);
                      onChange(e.target.checked ? items.map((item) => item[itemKey]) : []);
                    }}
                    data-testid={ComponentsSelectAllListTestIds.LABEL_CHECKBOX}
                  />
                }
              />
              <Box
                sx={{ paddingLeft: theme.spacing(3.5), display: 'flex', flexDirection: 'column' }}
              >
                {items.map((item) => (
                  <FormControlLabel
                    key={item[itemKey]}
                    label={item.label}
                    control={
                      <Checkbox
                        checked={value.includes(item[itemKey])}
                        onChange={(e) => {
                          handleSelectItem(item[itemKey], e.target.checked);
                          onChange(
                            e.target.checked
                              ? [...value, item[itemKey]]
                              : value.filter((v) => v !== item[itemKey])
                          );
                        }}
                      />
                    }
                  />
                ))}
              </Box>
            </>
          );
        }}
      />
    </FormGroup>
  );
};
