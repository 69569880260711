import { BlankModal } from 'sharedComponents/Modal';
import { ModalButtonGroup } from '../../Buttons/ModalButtonGroup';
import { useState } from 'react';
import { ModalOptionGroup } from '../../ModalFormOptions/ModalOptionGroup';
import {
  ConnectCandidateMatchDashboardAlreadyConnectedModalDataTestIds,
  ConnectCandidateMatchDashboardDataTestIds,
} from 'data-testids/ConnectDataTestIds';
import { useTheme, Box } from '@mui/material';
import { ModalHeader, ModalStyles } from '../utils/styles';
import { ALREADY_CONNECTED_OPTIONS } from '../utils/constants';

export const AlreadyConnectedModal = ({ isOpen, onClose }) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const theme = useTheme();

  const CancelButton = {
    label: 'Cancel',
    action: onClose,
    dataTestId: ConnectCandidateMatchDashboardAlreadyConnectedModalDataTestIds.CANCEL_BUTTON,
  };

  const SubmitButton = {
    label: 'Submit',
    action: () => {
      // Join multiple selections with commas if there are multiple, otherwise just pass the single selection
      const response = selectedOptions.length > 1 ? selectedOptions.join(',') : selectedOptions[0];
      onClose(response);
    },
    dataTestId: ConnectCandidateMatchDashboardAlreadyConnectedModalDataTestIds.SUBMIT_BUTTON,
    color: 'black',
    disabled: selectedOptions.length === 0,
  };

  const handleOptionChange = (event) => {
    const value = event.target.value;
    setSelectedOptions((prevOptions) => {
      if (prevOptions.includes(value)) {
        return prevOptions.filter((option) => option !== value);
      } else {
        return [...prevOptions, value];
      }
    });
  };

  const modalHeader = (
    <ModalHeader
      data-testid={ConnectCandidateMatchDashboardAlreadyConnectedModalDataTestIds.HEADER_TEXT}
    >
      How have you connected with this Principal?
    </ModalHeader>
  );

  const modalButtonGroup = () => {
    return (
      <ModalButtonGroup
        secondaryButton={CancelButton}
        primaryButton={SubmitButton}
        dataTestId={ConnectCandidateMatchDashboardAlreadyConnectedModalDataTestIds.OPTION_GROUP}
        sx={{ marginTop: theme.spacing(1) }}
      />
    );
  };

  const modalBody = () => {
    return (
      <Box>
        <ModalOptionGroup
          modalOptions={ALREADY_CONNECTED_OPTIONS}
          selectedOption={selectedOptions}
          handleReasonChange={handleOptionChange}
          dataTestId={ConnectCandidateMatchDashboardAlreadyConnectedModalDataTestIds.OPTION_GROUP}
          optionType="checkbox"
          isMultiSelect={true}
        />
        {modalButtonGroup()}
      </Box>
    );
  };

  return (
    <BlankModal
      dataTestId={ConnectCandidateMatchDashboardDataTestIds.ALREADY_CONNECTED_MODAL}
      header={modalHeader}
      body={modalBody()}
      open={isOpen}
      onClose={onClose}
      sx={ModalStyles(theme)}
    />
  );
};
