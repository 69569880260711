import { FieldLabel } from './types';

export const getFieldFromLabel = (label: FieldLabel): string => {
  const labelToField: Partial<Record<FieldLabel, string>> = {
    Address: 'basic_info',
    'Phone numbers': 'phone',
    'Work experience': 'work_experience',
    'Application questions': 'questions',
    Preferences: 'preference',
  };

  return labelToField[label] ?? label.toLowerCase();
};
