import React from 'react';

import { SxProps, useTheme } from '@mui/material';
import { Chip } from 'sharedComponents/Chip';

export interface PrimaryChipProps {
  dataTestId: string;
  label: string;
  sx?: SxProps;
}

export const PrimaryChip: React.FC<PrimaryChipProps> = ({ dataTestId, label, sx }) => {
  const theme = useTheme();

  return (
    <Chip
      label={label}
      variant="filled"
      dataTestId={dataTestId}
      sx={{
        backgroundColor: theme.palette.success.fill,
        color: theme.palette.primary.main,
        fontSize: theme.typography.body2.fontSize,
        ...sx,
      }}
    />
  );
};
