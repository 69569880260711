import { JobPostingTab } from '../JobPostingTab/JobPostingTab';
import { JobViewTabData } from './types';
import { QuestionsTab } from '../QuestionsTab/QuestionsTab';
import { SetUpTab } from '../SetUpTab/SetUpTab';
import HiringTeamTab from '../HiringTeamTab';
import InternalNotesTab from '../InternalNotesTab';
import VacanciesTab from '../VacanciesTab/VacanciesTab';
import { ActivityFeedTab } from '../ActivityFeedTab/ActivityFeedTab';

export const getTopTabsData = (
  jobId: number,
  isDistrictAdmin: boolean,
  inPostingOnlyDistrict: boolean
): JobViewTabData[] => [
  { value: 0, label: 'Job Posting', content: <JobPostingTab jobId={jobId} />, isVisible: true },
  {
    value: 1,
    label: 'Questions',
    content: <QuestionsTab jobId={jobId} />,
    isVisible: !inPostingOnlyDistrict,
  },
  {
    value: 2,
    label: 'Vacancies',
    content: <VacanciesTab jobId={jobId} />,
    isVisible: isDistrictAdmin && !inPostingOnlyDistrict,
  },
  {
    value: 3,
    label: 'Job Set-up',
    content: <SetUpTab jobId={jobId} />,
    isVisible: !inPostingOnlyDistrict,
  },
  {
    value: 4,
    label: 'Hiring Team',
    content: <HiringTeamTab jobId={jobId} />,
    isVisible: isDistrictAdmin && !inPostingOnlyDistrict,
  },
];

export const getBottomTabsData = (jobId: number, isDistrictAdmin: boolean): JobViewTabData[] => [
  {
    value: 5,
    label: 'Internal Notes',
    content: <InternalNotesTab jobId={jobId} />,
    isVisible: true,
  },
  {
    value: 6,
    label: 'Activity Feed',
    content: <ActivityFeedTab jobId={jobId} />,
    isVisible: isDistrictAdmin,
  },
];
