import { ButtonProps } from './types';
import { Button } from '@mui/material';
import { LoadingSpinner } from 'sharedComponents/LoadingSpinner/loadingSpinner';

export const PrimaryButton: React.FC<ButtonProps> = ({
  children,
  disabled,
  isLoading,
  onClick,
  size,
  startIcon,
  endIcon,
  dataTestId,
  loadingSpinnerColor = 'inherit',
  loadingSpinnerSize,
  sx,
  type,
  color = 'primary',
}: ButtonProps) => {
  const buttonStyles = {
    backgroundColor: (theme) => theme.palette[color]?.main || theme.palette.primary.main,
    color: (theme) => theme.palette.white?.main,
    '&:hover': {
      backgroundColor: (theme) =>
        // Prevents hover color when the button is disabled or loading to avoid stuck hover state on mobile.
        !disabled && !isLoading
          ? theme.palette[color]?.dark || theme.palette.primary.dark
          : undefined,
    },
    ...sx,
  };

  return (
    <Button
      data-testid={dataTestId || 'primary-button'}
      disabled={isLoading || disabled}
      onClick={onClick}
      size={size}
      variant="contained"
      startIcon={!isLoading && startIcon}
      endIcon={!isLoading && endIcon}
      type={type}
      sx={buttonStyles}
    >
      {isLoading ? (
        <LoadingSpinner
          dataTestId={`${dataTestId}-loading-spinner` || 'primary-button-loading-spinner'}
          color={loadingSpinnerColor}
          size={loadingSpinnerSize}
        />
      ) : (
        children
      )}
    </Button>
  );
};
