import { Box, Paper, styled, TextField, Theme, Typography } from '@mui/material';

export const ConnectProfileFlowOuterContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  margin: `${theme.spacing(8)} 0 0`,
  alignItems: 'center',
  [theme.breakpoints.up('lg')]: {
    margin: `${theme.spacing(10)} auto 0`,
  },
}));

export const ConnectProfileFlowContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '620px',
  margin: `${theme.spacing(1)} auto 0`,
  padding: `${theme.spacing(2)} 0`,
  alignItems: 'center',
  [theme.breakpoints.up('sm')]: {
    width: theme.spacing(77.5),
    margin: `${theme.spacing(3)} auto 0`,
  },
  [theme.breakpoints.down('sm')]: {
    padding: `${theme.spacing(2)}`,
  },
}));

export const ConnectProfileFlowPaperContainer = styled(Paper)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 3,
  boxShadow: '0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)',
}));

export const ConnectProfileFlowFormContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  backgroundColor: 'white',
  padding: `${theme.spacing(3)} 0 ${theme.spacing(2)}`,
  marginTop: theme.spacing(3),
  borderRadius: theme.shape.borderRadius * 3,
  gap: theme.spacing(2),
}));

export const ConnectProfileFlowTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '28px',
  fontWeight: theme.typography.fontWeightRegular,
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '24px',
  },
}));

export const ConnectProfileFlowTextField = styled(TextField)(({ theme }) => ({
  width: '100%',

  '& .MuiOutlinedInput-root': {
    borderRadius: theme.shape.borderRadius * 3,
    minHeight: '44px',
    maxHeight: '44px',
  },
  '& .MuiInputLabel-root': {
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.body1.fontSize,
    },
  },
  '& .MuiInputBase-input': {
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.subtitle.fontSize,
    },
  },
}));

export const ConnectProfileFlowRadioCardMessageToggleStyles = (theme: Theme) => ({
  width: '50%',
  minHeight: '54px',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    height: '54px',
  },
});

export const ConnectProfileFlowSelectStyles = (theme: Theme) => ({
  width: '100%',
  minHeight: '44px',
  '&.has-tag-limit:not(:focus-within):has(.MuiAutocomplete-tag)': {
    maxHeight: '44px',
    flexWrap: 'nowrap',
  },

  '& .MuiOutlinedInput-root': {
    borderRadius: theme.shape.borderRadius * 0.75,
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(0.5),
    '&.has-tag-limit:not(:focus-within):has(.MuiAutocomplete-tag)': {
      flexWrap: 'nowrap',
    },
  },
  '& .MuiInputLabel-root': {
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.body1.fontSize,
    },
  },
  '& .MuiInputBase-input': {
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.subtitle.fontSize,
    },
    padding: `${theme.spacing(0.75)} ${theme.spacing(1)}`,
  },
  '& .MuiInputBase-root': {
    minHeight: '44px',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: theme.spacing(0.2),
    overflow: 'hidden',
    '&.has-tag-limit:not(:focus-within):has(.MuiAutocomplete-tag) .MuiInputBase-input': {
      width: '0%',
      minWidth: '0%',
      flexWrap: 'nowrap',
    },

    '&:focus-within .MuiInputBase-input': {
      width: '100%',
    },
  },
  '& .MuiAutocomplete-tag': {
    margin: theme.spacing(0.25),
  },
  '& .MuiFormHelperText-root': {
    marginLeft: 0,
  },
});

export const ConnectProfileFlowSearchbarSelectStyles = (theme: Theme) => ({
  width: '100%',
  minHeight: '44px',

  '& .MuiOutlinedInput-root': {
    borderRadius: theme.shape.borderRadius * 0.75,
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(0.5),
  },
  '& .MuiInputLabel-root': {
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.body1.fontSize,
    },
  },
  '& .MuiInputBase-input': {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: 'calc(100% - 16px)',
    padding: theme.spacing(0.75, 0),
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.subtitle.fontSize,
    },
  },
  '& .MuiInputBase-root': {
    minHeight: '44px',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    overflow: 'hidden',
    '& .MuiInputAdornment-root.MuiInputAdornment-positionStart': {
      margin: theme.spacing(0, 0, 0, 1),
      color: theme.palette.gray.mediumLight,
      '& .MuiSvgIcon-root': {
        color: 'inherit',
      },
    },
    '&:focus-within .MuiInputBase-input': {
      width: 'auto',
    },
  },
  '& .MuiAutocomplete-endAdornment, & .MuiAutocomplete-popupIndicator': {
    position: 'absolute',
    visibility: 'hidden',
    width: 0,
    height: 0,
    overflow: 'hidden',
    pointerEvents: 'none',
  },
  '&.MuiAutocomplete-hasPopupIcon .MuiOutlinedInput-root': {
    paddingRight: 0,
  },
});
