import { ATSConnectImportCandidateFormTestIds } from 'data-testids/ATS';
import { Box, useTheme } from '@mui/material';
import { Key, ModalContainer, Row, SuccessSubContainer, Title } from '../ImportForm/styles';
import { SuccessPageProps } from '../ImportForm/types';
import { Alert } from 'sharedComponents/Alert';

export const SuccessPage: React.FC<SuccessPageProps> = ({
  title,
  candidateName,
  candidateEmail,
  internalNote,
  locationText,
  contactMethods,
}) => {
  const theme = useTheme();

  return (
    <Box sx={{ width: '100%' }}>
      <ModalContainer>
        <Title data-testid={ATSConnectImportCandidateFormTestIds.SUCCESS_TITLE}>
          You have submitted your hiring request for {candidateName}!
        </Title>

        <SuccessSubContainer>
          <Key data-testid={ATSConnectImportCandidateFormTestIds.SUCCESS_TEXT}>
            Hiring Request Details
          </Key>
        </SuccessSubContainer>

        <SuccessSubContainer>
          <Row data-testid={ATSConnectImportCandidateFormTestIds.SUCCESS_CANDIDATE_NAME}>
            <Key>Candidate:</Key> &nbsp;{candidateName}
          </Row>
          <Row data-testid={ATSConnectImportCandidateFormTestIds.SUCCESS_CANDIDATE_EMAIL}>
            <Key>Candidate Email:</Key> &nbsp; {candidateEmail}
          </Row>
          <Row data-testid={ATSConnectImportCandidateFormTestIds.SUCCESS_ROLE_TITLE}>
            <Key>Role:</Key> &nbsp;{title}
          </Row>
          <Row data-testid={ATSConnectImportCandidateFormTestIds.SUCCESS_ROLE_LOCATION}>
            <Key>Location:</Key> &nbsp;{locationText}
          </Row>

          <Row data-testid={ATSConnectImportCandidateFormTestIds.CONTACT_METHODS_SUCCESS_PAGE}>
            <Key>Contact Method(s):</Key> &nbsp; {contactMethods?.join(', ')}
          </Row>
        </SuccessSubContainer>

        {internalNote && (
          <SuccessSubContainer
            data-testid={ATSConnectImportCandidateFormTestIds.SUCCESS_CANDIDATE_INTERNAL_NOTE}
          >
            <Key>Notes: &nbsp;</Key>
            {internalNote}
          </SuccessSubContainer>
        )}

        <SuccessSubContainer data-testid={ATSConnectImportCandidateFormTestIds.SUCCESS_NEXT_STEPS}>
          <Alert icon={false} sx={{ color: theme.palette.text.primary }}>
            <Key>Here&apos;s what&apos;s next: &nbsp;</Key>
            <ol>
              <li>1. We&apos;ve invited this candidate to apply to this role.</li>
              <li>
                2. Once they have submitted their application, it will be reviewed by your central
                HR team.
              </li>
              <li>
                3. After your central HR team has approved the application, it will become visible
                to you in Nimble.
              </li>
            </ol>
          </Alert>
        </SuccessSubContainer>
      </ModalContainer>
    </Box>
  );
};
