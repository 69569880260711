import { PrimaryButton, SecondaryButton } from 'sharedComponents/Buttons';
import { BlankModal } from 'sharedComponents/Modal';
import { Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { useConnectContext } from 'features/Connect/features/Context/ConnectContextProvider';

export const ExpressInterestExitModal: React.FC = () => {
  const { connectContextActions, isExpressInterestExitModalOpen } = useConnectContext();
  const isMobile = useMediaQuery(
    (theme: Theme) =>
      theme.breakpoints.down('md') || theme.breakpoints.down('sm') || theme.breakpoints.down('xs')
  );

  const exitButtonHandler = () => {
    connectContextActions.setIsExpressInterestExitModalOpen(false);
    connectContextActions.setIsExpressInterestModalOpen(false);
  };

  const handleCloseModal = (_?: unknown, reason?: string) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;

    connectContextActions.setIsExpressInterestExitModalOpen(false);
  };

  return (
    <BlankModal
      open={isExpressInterestExitModalOpen}
      onClose={handleCloseModal}
      header={
        <Typography
          sx={{
            color: '#101828',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 600,
            padding: '0px 0px 12px',
          }}
        >
          Are you sure you want to leave?
        </Typography>
      }
      body={
        <>
          <Typography sx={{ marginBottom: '24px' }}>
            If you exit, expressing interest in this school will not be saved or sent to the
            principal.
          </Typography>
          <Stack direction="row" justifyContent="flex-end">
            <SecondaryButton
              sx={{
                border: '1px solid #D0D5DD',
                borderRadius: '8px',
                color: '#344054',
                fontSize: isMobile ? '14px' : '16px',
                fontWeight: 600,
                marginRight: '12px',
              }}
              onClick={() => connectContextActions.setIsExpressInterestExitModalOpen(false)}
              size="large"
            >
              No, Stay
            </SecondaryButton>
            <PrimaryButton
              onClick={exitButtonHandler}
              size="large"
              sx={{
                fontWeight: 600,
                fontSize: isMobile ? '14px' : '16px',
                borderRadius: '8px',
              }}
            >
              Yes, Exit
            </PrimaryButton>
          </Stack>
        </>
      }
      sx={{ borderRadius: '12px' }}
    />
  );
};
