import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { styled, useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import lazyRegistrationAPI from 'api/lazyRegistrationAPI';
import { ConnectProfileFlowButtonGroup } from 'features/Connect/features/ExpressInterest/SharedComponents/ConnectProfileFlowButtonGroup';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { nimbleProduct } from 'types/types';
import auth from 'utils/auth';
import { isValidEmail } from 'utils/emailutils';
import { getGatedActionDisplayInfo } from '../ExpressInterest/utils';
import { MagicLinkModal } from 'features/Connect/components/Modals/MagicLinkModal/MagicLinkModal';
import { ValuePropCarousel } from './ValuePropCarousel';
import { Grid, GridItem } from 'sharedComponents/Grid';
import { Aeonik } from '../JobDiscovery/utils';
import { ConnectLoginSignUpPageDataTestIds } from 'data-testids/ConnectDataTestIds';
import { ConnectGatedActions } from 'features/Connect/utils/connectEnums';
import { connectUrlParams } from '../JobDiscovery/constants';
import { useNavigation } from 'context/navigationContext';

export function ConnectSignUpLogin(): React.ReactElement {
  const { search, pathname } = useLocation();
  const searchParams = new URLSearchParams(search);
  const defaultMail = searchParams.get('source_email') || '';

  const [error, setError] = useState({ hasError: false, errorMessage: '' });
  const [magicLinkModalOpen, setMagicLinkModalOpen] = useState(false);
  const [expiredLinkModalOpen, setExpiredLinkModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState<string>(defaultMail);

  const routedEmail = searchParams.get('email');
  const expiredStatus = searchParams.get('expired');
  const actionParam = searchParams.get('action');
  const redirectState = searchParams.get(connectUrlParams.REDIRECT);
  const { flowDisplayInformation, pageDisplayInformation } = getGatedActionDisplayInfo();

  const history = useHistory();
  const theme = useTheme();

  const { location, previousLocation } = useNavigation();

  // Add URL param for 'action=direct' when user is coming from
  // an external source or going directly to the Sign Up page
  useEffect(() => {
    if (!previousLocation) {
      const params = new URLSearchParams(search);
      params.set(connectUrlParams.ACTION, ConnectGatedActions.DIRECT);
      history.replace({ search: params.toString() });
    }
  }, [history, location, previousLocation, search]);

  useEffect(() => {
    const user = auth.getUser();
    if (user) history.push(flowDisplayInformation.alreadyLoggedInRedirect);

    if (expiredStatus) {
      setExpiredLinkModalOpen(true);
    }
  }, [flowDisplayInformation.alreadyLoggedInRedirect, expiredStatus, history]);

  const resetErrorState = () => setError({ hasError: false, errorMessage: '' });

  const handleSaveAndContinue = async () => {
    if (email === null) return;
    const emailValid = isValidEmail(email);

    if (!emailValid) {
      setError({ hasError: true, errorMessage: 'Please provide a valid email address' });
      return;
    }
    resetErrorState();
    setIsLoading(true);

    const currentPath = location.pathname;

    let signupAction = '';
    if (actionParam === ConnectGatedActions.EXPRESS_INTEREST) {
      signupAction = ConnectGatedActions.EXPRESS_INTEREST;
    } else if (actionParam === ConnectGatedActions.SAVE_SCHOOL) {
      signupAction = ConnectGatedActions.SAVE_SCHOOL;
    } else if (actionParam == ConnectGatedActions.DIRECT) {
      signupAction = ConnectGatedActions.DIRECT;
    } else if (currentPath.includes('signup')) {
      signupAction = ConnectGatedActions.SIGNUP_BUTTON;
    } else if (currentPath.includes('login')) {
      signupAction = ConnectGatedActions.LOGIN_BUTTON;
    }

    const userData = await lazyRegistrationAPI.registerUser(
      email,
      true,
      nimbleProduct.connect,
      pageDisplayInformation.continueToUrl,
      redirectState,
      signupAction
    );
    if (userData?.login_required) {
      setIsLoading(false);
      setMagicLinkModalOpen(true);
    } else {
      auth.setUser(userData);
      history.push(pageDisplayInformation.continueToUrl);
    }
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await handleSaveAndContinue();
  };

  return (
    <StackContainer direction={'row'}>
      <MagicLinkModal
        email={email}
        isOpen={magicLinkModalOpen}
        closeModal={() => setMagicLinkModalOpen(false)}
        expiredModal={false}
        currentPath={pathname}
        redirectState={redirectState}
      />
      <MagicLinkModal
        email={routedEmail}
        isOpen={expiredLinkModalOpen}
        closeModal={() => setExpiredLinkModalOpen(false)}
        expiredModal={true}
        currentPath={pathname}
        redirectState={redirectState}
      />
      <Box
        sx={{
          width: '100%',
        }}
      >
        <Grid justifyContent="center" sx={{ height: 'calc(100vh - 150px)' }}>
          <GridItem xs={12} sm={6}>
            <BodyForm onSubmit={handleFormSubmit}>
              <HeaderText
                variant="h1"
                data-testid={ConnectLoginSignUpPageDataTestIds.HEADER_TEXT}
                sx={{ marginBottom: pageDisplayInformation?.subHeadingText ? 0 : { xs: 1, sm: 3 } }}
              >
                {pageDisplayInformation?.headerText}
              </HeaderText>
              <Stack
                spacing={4}
                px={{ xs: 2, sm: 8 }}
                sx={{
                  pt: 0,
                  pb: { xs: 1, sm: 3 },
                }}
              >
                {pageDisplayInformation?.subHeadingText && (
                  <SubHeadingText variant="body1">
                    {pageDisplayInformation.subHeadingText}
                  </SubHeadingText>
                )}
                <div>
                  <QuestionLabel htmlFor="email" required aria-required>
                    Email
                  </QuestionLabel>
                  <TextField
                    hiddenLabel
                    id="email"
                    data-testid={ConnectLoginSignUpPageDataTestIds.EMAIL_INPUT}
                    name="email"
                    placeholder="Email"
                    fullWidth
                    size="small"
                    defaultValue={defaultMail}
                    onChange={(event) => {
                      resetErrorState();
                      setEmail(event.target.value);
                    }}
                    sx={{ backgroundColor: 'white' }}
                  />
                  {error.hasError && (
                    <Typography color="error" gutterBottom>
                      {error.errorMessage}
                    </Typography>
                  )}
                </div>
                <Typography
                  sx={{ size: '14px', color: theme.palette.text.light }}
                  data-testid={ConnectLoginSignUpPageDataTestIds.TERMS_OF_SERVICE_STATEMENT}
                >
                  {'I have read, understand, and agree to be bound by the Nimble '}
                  <Link
                    href="https://www.hirenimble.com/terms"
                    rel="noopener noreferrer"
                    target="_blank"
                    color="primary"
                  >
                    terms of use
                  </Link>
                  {'.'}
                </Typography>
                <ConnectProfileFlowButtonGroup
                  dataTestId={ConnectLoginSignUpPageDataTestIds.SUBMIT_BUTTON}
                  primaryButton={{
                    dataTestId: ConnectLoginSignUpPageDataTestIds.SUBMIT_BUTTON,
                    primaryButtonLabel: 'Continue',
                    primaryAction: handleSaveAndContinue,
                    disabled: error.hasError,
                    isLoading: isLoading,
                  }}
                  hasError={error.hasError}
                />
              </Stack>
            </BodyForm>
          </GridItem>
          <GridItem xs={6} sx={{ display: { xs: 'none', sm: 'block' } }}>
            <ValuePropCarousel />
          </GridItem>
        </Grid>
      </Box>
    </StackContainer>
  );
}

const StackContainer = styled(Stack)(({ theme }) => ({
  marginTop: '80px',

  [theme.breakpoints.down('md')]: {
    height: 'calc(100vh - 150px)',
    marginTop: '0px',
  },
}));

const BodyForm = styled('form')(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '14px',
  paddingBottom: '0px',

  [theme.breakpoints.up('sm')]: {
    paddingTop: '22px auto',
  },
}));

const QuestionLabel = styled(InputLabel)({
  textWrap: 'wrap',
});

const HeaderText = styled(Typography)(({ theme }) => ({
  color: theme.palette.gray.dark,
  fontFamily: Aeonik,
  fontSize: 28,
  fontWeight: 400,
  textAlign: 'center',
}));

const SubHeadingText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.light,
  fontSize: 16,
  fontWeight: 400,
  textAlign: 'center',
}));
