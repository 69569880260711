import { CircularProgress } from '@mui/material';

import { ActivityFeed } from 'features/JobActivityFeed/ActivityFeed';
import { ActivityFeedTabProps } from './types';
import { ATSTabbedJobViewActivityFeedTestIds } from 'data-testids/ATS';
import { useQueryGetActivityFeedDataByJobId } from './queries';

export const ActivityFeedTab: React.FC<ActivityFeedTabProps> = ({ jobId }) => {
  const { data, isLoading, isError } = useQueryGetActivityFeedDataByJobId(jobId);

  return (
    <>
      {isLoading && !data ? (
        <CircularProgress data-testid={ATSTabbedJobViewActivityFeedTestIds.LOADING_SPINNER} />
      ) : (
        <ActivityFeed
          hasActivity={data?.length > 0}
          hasErrorFetchingActivity={isError}
          isLoading={isLoading}
          allActivity={data}
          dataTestId={ATSTabbedJobViewActivityFeedTestIds.ACTIVITY_FEED}
        />
      )}
    </>
  );
};
