/**
 * Handles moving to the previous page if available
 */
export const handlePrevPage = (currentPage: number, onChange: (page: number) => void): void => {
  if (currentPage > 1) onChange(currentPage - 1);
};

/**
 * Handles moving to the next page if available
 */
export const handleNextPage = (
  currentPage: number,
  totalPages: number,
  onChange: (page: number) => void
): void => {
  if (currentPage < totalPages) onChange(currentPage + 1);
};

/**
 * Determines if a pagination button should be disabled
 */
export const isPaginationButtonDisabled = (
  buttonDirection: 'prev' | 'next',
  currentPage: number,
  totalPages: number,
  isDisabled: boolean,
  isLoading: boolean
): boolean => {
  if (buttonDirection === 'prev') return currentPage === 1 || !!isDisabled || !!isLoading;
  if (buttonDirection === 'next') return currentPage === totalPages || !!isDisabled || !!isLoading;
  return false;
};
