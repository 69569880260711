import { CircularProgress, useTheme } from '@mui/material';

import auth from 'utils/auth';
import { ATSJobPostingTabTestIds } from 'data-testids/ATS';
import { Alert } from 'sharedComponents/Alert';
import { Container, Right } from './styles';
import { DescriptionAndBenefits } from './components/DescriptionAndBenefits';
import { JobDetails } from './components/JobDetails';
import { JobPostingTabProps } from './types';
import { JobViewPublicShareLink } from 'features/JobView/components/JobViewPublicShareLink';
import { getAxiosErrorMessageByStatusCode } from 'hooks/errorHandling/errorHandlingHooks';
import { getJobViewUrl, shouldSeePublicLink } from 'features/JobView/utils';
import { useQueryGetsJobPostingDetailsByJobId } from './queries';

export const JobPostingTab: React.FC<JobPostingTabProps> = ({ jobId }) => {
  const { isLoading, data, isError, error } = useQueryGetsJobPostingDetailsByJobId(jobId);
  const errorMessage = getAxiosErrorMessageByStatusCode(error);
  const jobLink = getJobViewUrl(data?.id);
  const theme = useTheme();

  const allDetailFieldsAreNull = Object.values({
    categories: data?.categories?.length,
    deadline: data?.deadline,
    start_date: data?.start_date,
    salary_min: data?.salary_min,
    salary_max: data?.salary_max,
    subjects: data?.subjects?.length,
    grades: data?.grades?.length,
    schools: data?.schools?.length,
    contact: data?.contact?.length > 0,
    slug: data?.district_jobboard_slug && !auth.inPostingOnlyDistrict(),
  }).every((value) => {
    return value === null || value === 0 || value === '' || value === false;
  });

  if (isError) {
    return (
      <Alert type="error" dataTestId={ATSJobPostingTabTestIds.ERROR_TEXT}>
        {errorMessage || 'Something went wrong'}
      </Alert>
    );
  }

  return (
    <Container>
      {isLoading && !data ? (
        <CircularProgress data-testid={ATSJobPostingTabTestIds.LOADING_SPINNER} />
      ) : (
        <>
          <DescriptionAndBenefits job={data} />
          <Right>
            {shouldSeePublicLink(data) && (
              <JobViewPublicShareLink
                dataTestId={ATSJobPostingTabTestIds.PUBLIC_SHARE_LINK}
                link={jobLink}
                sx={{
                  padding: theme.spacing(4),
                  backgroundColor: theme.palette.white.darker,
                  borderRadius: theme.shape.borderRadius * 1,
                  marginBottom: theme.spacing(4),
                }}
              />
            )}
            {!allDetailFieldsAreNull && <JobDetails job={data} />}
          </Right>
        </>
      )}
    </Container>
  );
};
