import { Box, styled } from '@mui/material';

export const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  paddingBottom: theme.spacing(1),
}));

export const Container = styled(Box)({
  width: '100%',
});
