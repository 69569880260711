import { Stack, styled, Typography } from '@mui/material';
import { PreferenceMatchBadge } from '../InfoBadges/PreferenceMatchBadge';
import { formatDistance, getGradeRange } from 'features/Connect/features/JobDiscovery/utils';
import { ConnectPreferenceMatchesSectionInterface } from 'types/connectTypes';

export const PreferenceMatchesSection: React.FC<ConnectPreferenceMatchesSectionInterface> = ({
  school,
  preferenceMatches,
}) => {
  const distanceMatchLabel = `${formatDistance(school.distance_from_search_location_miles)} miles away`;

  const preferenceMatchList = [distanceMatchLabel];
  const gradesMatchedTotal = preferenceMatches?.grades_matched?.length;
  const subcategoriesMatchedTotal = preferenceMatches?.subcategories_matched?.length;

  if (gradesMatchedTotal > 0) {
    const gradeMatchLabel =
      (gradesMatchedTotal > 1 ? 'Grades' : 'Grade') +
      ' ' +
      getGradeRange(preferenceMatches.grades_matched);
    preferenceMatchList.push(gradeMatchLabel);
  }

  if (subcategoriesMatchedTotal > 0) {
    const firstThreeSubjects = preferenceMatches.subcategories_matched.slice(0, 3);
    let subcategoriesMatchLabel = firstThreeSubjects.join(', ');

    if (subcategoriesMatchedTotal > 3) {
      const remainingCount = subcategoriesMatchedTotal - 3;
      subcategoriesMatchLabel += ` + ${remainingCount} more`;
    }

    preferenceMatchList.push(subcategoriesMatchLabel);
  }

  return (
    <PreferenceMatchesSectionContainer>
      <PreferenceMatchesSectionTitle>What you matched on:</PreferenceMatchesSectionTitle>
      <PreferenceMatchesBadgeContainer>
        {preferenceMatchList.map((match) => (
          <PreferenceMatchBadge key={match} preferenceMatch={match} />
        ))}
      </PreferenceMatchesBadgeContainer>
    </PreferenceMatchesSectionContainer>
  );
};

const PreferenceMatchesSectionContainer = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const PreferenceMatchesSectionTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.tertiary,
  fontSize: theme.typography.subtitle.fontSize,
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: theme.typography.h2.lineHeight,
}));

const PreferenceMatchesBadgeContainer = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(1),
}));
