import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';
import { IndividualHRBP, VacancyData } from './types';

export const useQueryGetVacanciesByJobId = (
  jobId: number,
  options?: { [option: string]: boolean | number }
): UseQueryResult<VacancyData, unknown> => {
  return useQuery({
    ...options,
    queryKey: ['tabbedJobViewVacanciesByJobId', jobId],
    queryFn: () => axios.get(`/api/roles/${jobId}/vacancies`).then((res) => res.data),
    enabled: jobId !== undefined && jobId !== null,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};

export const useQueryGetHRBPByJobId = (
  jobId: number,
  options?: { [option: string]: boolean | number }
): UseQueryResult<IndividualHRBP[], unknown> => {
  return useQuery({
    ...options,
    queryKey: ['tabbedJobViewHRBPByJobId', jobId],
    queryFn: () => axios.get(`/api/roles/${jobId}/hrbp`).then((res) => res.data),
    enabled: jobId !== undefined && jobId !== null,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};
