import { Typography } from '@mui/material';

import { ATSConnectHiringRequestCandidateFeedbackTestIds } from 'data-testids/ATS';
import { CreatePaneContainer } from '../../styles';
import { CreatePaneProps } from '../types';
import { GreySecondaryButton } from 'sharedComponents/Buttons/GreySecondaryButton';

export const CreatePane: React.FC<CreatePaneProps> = ({ onCancel }) => {
  return (
    <CreatePaneContainer>
      <Typography>Placeholder</Typography>
      <GreySecondaryButton
        dataTestId={ATSConnectHiringRequestCandidateFeedbackTestIds.CREATE_PANE_CLOSE_BUTTON}
        onClick={onCancel}
      >
        Cancel
      </GreySecondaryButton>
    </CreatePaneContainer>
  );
};
