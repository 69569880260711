import moment from 'moment';

import { ATSConnectHiringRequestTestIds } from 'data-testids/ATS';
import { Box } from '@mui/material';
import { CandidateRequestorInfoContainer, Date, DateAndName, Detail } from '../styles';
import { RowCandidateAndRequestorInfoProps } from '../types';

export const RowCandidateAndRequestorInfo: React.FC<RowCandidateAndRequestorInfoProps> = ({
  candidate,
  created,
  requestor,
}) => {
  const date = moment(created).format('M/D/YY [at] h:mmA');

  return (
    <CandidateRequestorInfoContainer>
      <Detail variant="heading7" data-testid={ATSConnectHiringRequestTestIds.CANDIDATE_NAME}>
        {candidate.first_name} {candidate.last_name}
      </Detail>
      <DateAndName>
        <Box data-testid={ATSConnectHiringRequestTestIds.REQUESTOR}>
          Requester: {requestor.first_name} {requestor.last_name}
        </Box>
        <Date data-testid={ATSConnectHiringRequestTestIds.REQUESTOR_DATE}>{date}</Date>
      </DateAndName>
    </CandidateRequestorInfoContainer>
  );
};
