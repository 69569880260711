import { Typography, useTheme } from '@mui/material';

import { ATSConnectHiringRequestCandidateFeedbackTestIds } from 'data-testids/ATS';
import { CloseButton } from 'sharedComponents/Buttons';
import { FeedbackListPaneHeaderProps } from '../types';
import { PrimaryChip } from 'sharedComponents/Chip/PrimaryChip';
import { Title } from '../../styles';
import { TitleAndChip, Top } from '../styles';

export const FeedbackListPaneHeader: React.FC<FeedbackListPaneHeaderProps> = ({
  numberOfScorecards,
  onClose,
  roleTitle,
  titleText,
}) => {
  const theme = useTheme();

  return (
    <>
      <Top>
        <TitleAndChip>
          <Title
            data-testid={ATSConnectHiringRequestCandidateFeedbackTestIds.MODAL_TITLE}
            variant="heading7"
          >
            {titleText}
          </Title>
          <PrimaryChip
            dataTestId={ATSConnectHiringRequestCandidateFeedbackTestIds.FEEDBACK_COUNT_CHIP}
            label={`${numberOfScorecards} scorecards`}
            sx={{ marginLeft: theme.spacing(1) }}
          />
        </TitleAndChip>
        <CloseButton
          dataTestId={ATSConnectHiringRequestCandidateFeedbackTestIds.CLOSE_MODAL_LIST_BUTTON}
          onClick={onClose}
        />
      </Top>
      <Typography data-testid={ATSConnectHiringRequestCandidateFeedbackTestIds.ROLE_TITLE}>
        {roleTitle}
      </Typography>
    </>
  );
};
