import { DefaultActionInfo } from '../../types';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import StarBorderIcon from '@mui/icons-material/StarBorder';

export const actionInfo: DefaultActionInfo[] = [
  {
    tooltipText: 'scorecard',
    icon: <StarBorderIcon />,
  },
  {
    tooltipText: 'reference',
    icon: <PersonAddAltOutlinedIcon />,
  },
  {
    tooltipText: 'application',
    icon: <AttachFileOutlinedIcon sx={{ rotate: '45deg' }} />,
  },
];
