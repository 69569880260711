import { Box, styled, Typography } from '@mui/material';
import { EditButton } from 'sharedComponents/Buttons/EditButton';
import { InternalRoleNoteDisplayData as NoteData } from 'types';
import { makeAllUrlsValid } from 'utils/util';
import { NoteContainer } from './styles';
import { ATSTabbedJobViewInternalRoleNotesTestIds } from 'data-testids/ATS';
import DeleteNoteButton from './DeleteNoteButton';
import auth from 'utils/auth';
import moment from 'moment';

interface ExistingNoteProps {
  note: NoteData;
  handleEdit: (noteId: number) => void;
  jobId: number;
}

const ExistingNote: React.FC<ExistingNoteProps> = ({ note, handleEdit, jobId }) => {
  const getFooterText = () => {
    const authorName = `${note.created_by.first_name} ${note.created_by.last_name}`;

    if (!note.created_at) {
      return authorName;
    }

    const formattedDate = moment(note.created_at).format('L');
    return `${authorName} – ${formattedDate}`;
  };

  const userId = auth.getUser()?.id;
  const noteOwnerId = note.created_by?.id;

  const isNoteOwner = userId && noteOwnerId && userId === noteOwnerId;

  return (
    <NoteContainer>
      <NoteContent
        data-testid={ATSTabbedJobViewInternalRoleNotesTestIds.EXISTING_NOTE_CONTENT}
        dangerouslySetInnerHTML={{ __html: makeAllUrlsValid(note.text) }}
      />
      <NoteFooter>
        <FooterText data-testid={ATSTabbedJobViewInternalRoleNotesTestIds.NOTE_FOOTER_TEXT}>
          {getFooterText()}
        </FooterText>
        {isNoteOwner && (
          <ButtonsContainer>
            <DeleteNoteButton noteId={note.id} jobId={jobId} />
            <EditButton
              onClick={() => handleEdit(note.id)}
              dataTestId={ATSTabbedJobViewInternalRoleNotesTestIds.EDIT_NOTE_BUTTON}
            />
          </ButtonsContainer>
        )}
      </NoteFooter>
    </NoteContainer>
  );
};

export default ExistingNote;

const NoteContent = styled(Box)(({ theme }) => ({
  color: theme.palette.gray.dark,
  fontSize: theme.typography.heading7.fontSize,
}));

const NoteFooter = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const FooterText = styled(Typography)(({ theme }) => ({
  flexGrow: 1,
  fontSize: theme.typography.body2.fontSize,
  color: theme.palette.text.primary,
  opacity: 0.56,
}));

const ButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));
