import { useScreenSize } from 'hooks/styling/useScreenSize';

import { DesktopNavigation } from './components/DesktopNavigation';
import { MobileNavigation } from './components/MobileNavigation';
import { TabNavigationProps } from './types';

export const TabNavigation: React.FC<TabNavigationProps> = ({
  bottomTabsData,
  onChange,
  selectedTab,
  topTabsData,
}) => {
  const shouldShowDivider = bottomTabsData.some((item) => item.isVisible === true);
  const isDesktopScreenSize = useScreenSize('md');

  return (
    <>
      {isDesktopScreenSize ? (
        <DesktopNavigation
          bottomTabsData={bottomTabsData}
          onChange={onChange}
          selectedTab={selectedTab}
          shouldShowDivider={shouldShowDivider}
          topTabsData={topTabsData}
        />
      ) : (
        <MobileNavigation
          bottomTabsData={bottomTabsData}
          onChange={onChange}
          shouldShowDivider={shouldShowDivider}
          topTabsData={topTabsData}
        />
      )}
    </>
  );
};
