import { Alert } from 'sharedComponents/Alert';
import { useQueryAdminsInDistrict } from './queries';
import { useReactQuillModules } from 'hooks/useReactQuillModules';
import { ATSTabbedJobViewInternalRoleNotesTestIds } from 'data-testids/ATS';
import { StyledQuillInput } from 'sharedComponents/StyledQuillInput/StyledQuillInput';

type NoteInputProps = {
  value: string;
  onChange: (text: string) => void;
};

const NoteInput: React.FC<NoteInputProps> = ({ value, onChange }) => {
  const { data, isError } = useQueryAdminsInDistrict();
  const modules = useReactQuillModules(data);

  return (
    <>
      <div data-testid={ATSTabbedJobViewInternalRoleNotesTestIds.NOTE_INPUT_CONTAINER}>
        <StyledQuillInput value={value} onChange={onChange} modules={modules} />
      </div>
      {isError && (
        <Alert
          type="error"
          dataTestId={ATSTabbedJobViewInternalRoleNotesTestIds.FETCH_USERS_ERROR_ALERT}
        >
          Could not get list of users to tag. Please refresh the page and try again.
        </Alert>
      )}
    </>
  );
};

export default NoteInput;
