import { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { VariableSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import AutoSizer from 'react-virtualized-auto-sizer';
import auth from '../../utils/auth';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import axios from 'axios';

import applicationsAPI from 'api/applicationsAPI';
import districtAPI from 'api/districtAPI';
import prospectListAPI from 'api/prospectListAPI';

import CandidateRow from './CandidateRow';
import CandidateApplicationsList from './CandidateApplicationsList';
import QuickProfileProspect from './QuickProfile';
import QuickProfileDistrictApplication from './QuickProfileCandidate/DistrictApplication';
import QuickProfileSchoolApplication from './QuickProfileCandidate/SchoolApplication';
import CandidatesListFooter from './CandidatesListFooter';
import EmailModal from '../EmailModal';
import BulkEmail from '../BulkEmail';
import BulkExport from '../BulkExport';
import LoadingSpinner from '../loadingSpinner';
import { handleOutsideClickOnValidForm } from '../../utils/util';
import { fetchAdmins, fetchEmailTemplates } from '../../utils/statusviewutils';
import MarketplaceProspectQuickView from './MarketplaceProspectQuickView';
import { CANDIDATE_LIST_PAGE_SIZE } from '../../utils/constants';
import { ATSCandidateListBulkEmail, ATSCandidatesListFilterTestIds } from 'data-testids/ATS';
import BulkExportV2 from 'components/BulkExport/BulkExportV2';

export default class CandidatesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      candidates: this.props.candidates,
      showQuickProfile: false,
      oneApplicationQuickView: false,
      selections: new Set(), // Use at the candidate level, for bulk email and statuses changes in Candidates > By Role.
      selectedApplications: new Set(), // Use at application level, for bulk statuses changes in Candidates > View All, and Prospects List.
      showSelectionOptions: false,
      bulkEmailModalOpen: false,
      emailModalOpen: false,
      exportModalOpen: false,
      emailTemplateSelection: null,
      targetStatus: null,
      loadingSpinnerFooter: false,
      adminUser: [],
      emailTemplatesList: [],
      sources: [],
      district: {},
      applicationsBulkUpdate: [],
      showTalentMarketplaceQuickView: false,
    };

    this.isSchoolUser = auth.isSchoolUser();
    this.getUser = auth.getUser();
    this.fetchAdmins = fetchAdmins.bind(this);
    this.fetchEmailTemplates = fetchEmailTemplates.bind(this);
  }

  static propTypes = {
    candidates: PropTypes.arrayOf(PropTypes.object).isRequired,
    hasMore: PropTypes.bool.isRequired,
    fetchCandidates: PropTypes.func.isRequired,
    fetchProspects: PropTypes.func,
    fetchCandidatesDebounced: PropTypes.func.isRequired,
    search: PropTypes.func.isRequired,
    isRequisition: PropTypes.bool,
    totalCandidateCount: PropTypes.number.isRequired,
    isSchoolUser: PropTypes.bool,
    viewByPostingRoleId: PropTypes.number,
    jobTitle: PropTypes.string.isRequired,
    newApplicationStatuses: PropTypes.array.isRequired,
    role: PropTypes.object,
    districtRoleId: PropTypes.string,
    onProspectList: PropTypes.bool.isRequired,
    onViewByPosting: PropTypes.bool.isRequired,
    setSortFieldAndDirection: PropTypes.func,
    retrieveProspect: PropTypes.func,
    partialApplication: PropTypes.object.isRequired,
    application_type: PropTypes.oneOf(['application', 'schoolapplication']).isRequired,
    setApplicationForQuickView: PropTypes.func.isRequired,
    partialUser: PropTypes.object,
    job_status_list: PropTypes.array.isRequired,
    fetchCandidatesRunning: PropTypes.bool.isRequired,
    updateProspectStatus: PropTypes.func,
    refreshCandidateInList: PropTypes.func.isRequired,
    // Passed down on View by Posting, used for updating statuses after bulk status update.
    updateCandidatesInContainerState: PropTypes.func,
    showSpinner: PropTypes.bool.isRequired,
    // not required in View All
    incrementNoteCount: PropTypes.func,
    incrementScorecardCount: PropTypes.func,
    availableApplicationStatuses: PropTypes.array,
  };

  componentDidMount() {
    if (this.isSchoolUser && !this.props.onProspectList) {
      this.preparePrincipalApplications(this.props.candidates);
    }
    this.fetchAdmins();
    this.fetchEmailTemplates();
    districtAPI
      .getDistrict(this.getUser.profile.district.id)
      .then((district) => this.setState({ district }));
    axios.get('/api/candidate-source/').then((r) => this.setState({ sources: r.data }));
  }
  /*
   * If component gets new props (cause user scrolls to new page of candidates)
   * have to set the schoolapplications again
   */
  componentWillReceiveProps(nextProps) {
    if (nextProps.candidates) {
      if (this.isSchoolUser && !this.props.onProspectList) {
        this.preparePrincipalApplications(nextProps.candidates);
      } else {
        this.setState({ candidates: nextProps.candidates });
      }
    }

    if (this.props.onProspectList !== nextProps.onProspectList) {
      // We we changing from the prospect list to the candidate list, reset selections.
      this.setState({
        selections: new Set(),
        selectedApplications: new Set(),
      });
    }
  }

  componentDidUpdate(prevProps) {
    //Ensures that InfiniteLoader reset after changes to candidate (like copied app)
    if (this.props.partialUser && prevProps.candidates !== this.props.candidates) {
      let candidateIndex = this.state.candidates.findIndex(
        (cand) => cand.id === this.props.partialUser.id
      );
      this.listRef?.resetAfterIndex(candidateIndex, true);
    }
  }

  updateCandidateStatusInState = ({ target_status, candidate_id }) => {
    const candidate = this.state.candidates.find((c) => c.id === candidate_id);
    const statusObj = this.props.newApplicationStatuses.find((s) => s.id === target_status);
    const partialApplication = { ...this.props.partialApplication };
    partialApplication.new_status = statusObj;
    this.props.setApplicationForQuickView(partialApplication, candidate);
  };

  /*
    See which schoolapplications belong to this school admin's school
    Set these as `schoolapplicationsForThisSchool` on each candidate
    Only show those in the dropdown
    But pass all the applications and school applications to the quickview to show there
  */

  preparePrincipalApplications = (propsCandidates) => {
    /* If school user, show candidate's a) schoolapplications and b) district applications
     ** which have school_admin_view_access = true
     */
    let revisedCandidates = [];

    const candidates = [...propsCandidates];
    candidates.forEach((candidate, index) => {
      // hank variable name here, but it's necessary. we want the district role id
      // for each school application so that we can filter out district applications
      // that already have a school application being shown. As i noted below,
      // this can happen now that school admins can see district apps sometimes.
      const districtRoleIdsForSchoolApps = new Set(
        candidate.schoolapplications.map((s) => s.role.district_role)
      );

      // District applications
      const applications = candidate.applications.filter((a) => {
        // make sure the school admin doesn't see a district app if there is already a school
        // app for the same role. this can happen now that school admins can see district
        // applications sometimes.
        return !districtRoleIdsForSchoolApps.has(a.role.id);
      });

      candidate['allRelevantApplicationsForSchoolAdmin'] =
        candidate.schoolapplications.concat(applications);
      revisedCandidates[index] = candidate;
    });

    this.setState({
      candidates: revisedCandidates,
    });
  };

  sortBackend = async (sortField) => {
    let orderDir = this.props.orderDir;

    if (sortField === 'nimble_score' && this.props.orderBy === 'nimble_score') {
      // nimble_score can only be sorted descending according to business rules,
      // so clicking it while it's already sorted does nothing
      if (this.props.orderBy === 'nimble_score') {
        return;
      } else {
        orderDir = 'desc';
      }
    }

    // If sorting by same category but reversing orderBy
    if (this.props.orderBy === sortField) {
      orderDir = this.flipSortDirection();
    }

    // Set state on the container to new sortField and direction
    this.props.setSortFieldAndDirection(sortField, orderDir, () => {
      // Then get candidates from server
      this.props.search(true, true);
    });
  };

  flipSortDirection = () => {
    if (this.props.orderDir === 'desc') {
      return 'asc';
    } else {
      return 'desc';
    }
  };

  toggleQuickProfile = (app, candidate) => {
    if (app || candidate) {
      this.props.setApplicationForQuickView(app, candidate);
    }
    this.setState({ showQuickProfile: !this.state.showQuickProfile });

    if (candidate?.marketplace_fit_status === 'converted_to_prospect') {
      this.setState({ showTalentMarketplaceQuickView: true });
    } else {
      this.setState({ showTalentMarketplaceQuickView: false });
    }
  };

  handleClose = () => {
    this.setState({ showQuickProfile: !this.state.showQuickProfile });
  };

  /*
   * When a candidate row is clicked, expand the applications below it
   * Setting a reference to the state then modifying the reference and forcing
   * update will pass new props down.
   */
  toggleShowingApplications = (candidate) => {
    let nextStatus = candidate.expanded ? false : true;
    let finalCands = this.state.candidates;
    candidate.expanded = nextStatus;
    let candidateIndex = this.state.candidates.findIndex((cand) => cand.id === candidate.id);
    finalCands[candidateIndex] = candidate;
    this.listRef.resetAfterIndex(candidateIndex, true);
    this.forceUpdate();
  };

  /** ---- Begin candidates list selection code ---- **/

  toggleAllSelections = (e) => {
    e.stopPropagation();
    if (e.target.checked === true) {
      this.selectAllShown();
    } else {
      this.deselectAll();
    }
  };

  selectAllShown = () => {
    /** select all candidates loaded on page */
    const { candidates, selections } = this.state;
    let selectedApplications = [...this.state.selectedApplications];

    candidates.forEach((c) => {
      if (!(auth.getUser().linked_users || []).includes(c.id)) {
        selections.add(c.id);
        if (!this.props.onProspectList) {
          selectedApplications = [...selectedApplications, ...c.applications.map((a) => a.id)];
        }

        if (!c.expanded && auth.isDistrictUser()) this.toggleShowingApplications(c);
      }
    });

    this.setState({
      selections: new Set(selections),
      selectedApplications: new Set(selectedApplications),
    });
  };

  selectAllTotal = async () => {
    /** select all candidates returned by query, even those on next pages */
    let allCandidateIds = [];

    this.setState({ loadingSpinnerFooter: true });
    if (this.props.onProspectList) {
      const results = await this.props.fetchProspects(false, false);
      allCandidateIds = results.map((c) => c.id);
    } else {
      allCandidateIds = await this.props.fetchCandidates(false, false, null, true);
    }
    this.setState({ loadingSpinnerFooter: false });

    const selections = this.state.selections;
    allCandidateIds.forEach((id) => {
      if (!(auth.getUser().linked_users || []).includes(Number(id))) {
        selections.add(Number(id));
      }
    });
    this.setState({ selections: new Set(selections) });
  };

  deselectAll = () => {
    const el = document.getElementById('cl-select-all-checkbox');
    el.indeterminate = false;
    this.setState({ selections: new Set(), selectedApplications: new Set() });

    if (auth.isDistrictUser()) {
      this.state.candidates.forEach((c) => {
        if (c.expanded) {
          this.toggleShowingApplications(c);
        }
      });
    }
  };

  toggleCandidateSelect = (e, id) => {
    const selections = this.state.selections;
    const candidate = this.state.candidates.filter((c) => c.id === id)[0];
    let selectedApplications = [...this.state.selectedApplications];

    if (e.target.checked === true) {
      selections.add(id);
      if (!this.props.onProspectList) {
        selectedApplications = [
          ...selectedApplications,
          ...candidate.applications.map((c) => c.id),
        ];
      }
      if (!candidate.expanded) {
        this.toggleShowingApplications(candidate);
      }
    } else {
      selections.delete(id);
      if (!this.props.onProspectList) {
        selectedApplications = selectedApplications.filter((aid) => {
          return !candidate.applications.map((a) => a.id).includes(aid);
        });
      }
    }

    const el = document.getElementById('cl-select-all-checkbox');
    if (selections.size > 0 && selections.size !== this.state.candidates.length) {
      el.indeterminate = true;
    } else {
      el.indeterminate = false;
    }

    this.setState({
      selections: new Set(selections),
      selectedApplications: new Set(selectedApplications),
    });
  };

  outsideClickListener = (ev) =>
    handleOutsideClickOnValidForm(this.node, ev, this.toggleSelectionOptions);

  toggleSelectionOptions = (e) => {
    if (e && e.target.name === 'select-all-checkbox') {
      // target is the div's checkbox, not the dropdown menu. So ignore.
      return;
    }

    if (!this.state.showSelectionOptions) {
      document.addEventListener('click', this.outsideClickListener, { capture: true });
    } else {
      document.removeEventListener('click', this.outsideClickListener, { capture: true });
    }
    this.setState({ showSelectionOptions: !this.state.showSelectionOptions });
  };

  toggleApplicationSelection = (id, candidateId) => {
    if (this.state.selectedApplications.has(id)) {
      // if the intersection of candidate.applications and selectedApplications is only this id
      // then we also deselect the candidate:
      const candidateApplications = this.state.candidates
        .filter((c) => c.id === candidateId)[0]
        .applications.map((app) => app.id);
      const intersection = candidateApplications.filter((value) =>
        [...this.state.selectedApplications].includes(value)
      );

      if (intersection[0] === id && intersection.length === 1) {
        this.setState({
          selections: new Set([...this.state.selections].filter((cid) => cid !== candidateId)),
        });
      }

      this.setState({
        selectedApplications: new Set(
          [...this.state.selectedApplications].filter((aid) => aid !== id)
        ),
      });
    } else {
      this.setState({
        selectedApplications: new Set([...this.state.selectedApplications, id]),
        selections: new Set([...this.state.selections, candidateId]),
      });
    }
  };

  /** ---- End candidates list selection code ---- **/

  postUpdate = (statusObj, applicationsBulkUpdate) => {
    if (!statusObj) {
      statusObj = this.state.targetStatus;
    }

    if (!applicationsBulkUpdate) {
      applicationsBulkUpdate = this.state.applicationsBulkUpdate;
    }

    this.setState({ loadingSpinnerFooter: true });

    // Are we on prospects?
    if (this.props.onProspectList) {
      // applicationsBulkUpdate are actually DistrictProspect objects...
      prospectListAPI
        .updateProspectStatusInBulk(
          statusObj.value,
          applicationsBulkUpdate.map((p) => p.id)
        )
        .then(this.props.updateCandidatesInContainerState)
        .then(() => this.setState({ loadingSpinnerFooter: false }));

      return;
    }

    // If there is no Role, then it must be coming from Candidates > View All
    if (!this.props.role) {
      // Save to the backend the applications ids + the new status.
      applicationsAPI
        .bulkUpdateApplicationStatuses({
          applications_to_update: this.state.selectedApplications,
          status_id: statusObj.id,
          job_status_list: this.props.job_status_list,
        })
        .then(this.props.updateCandidatesInContainerState)
        .then(() => this.setState({ loadingSpinnerFooter: false }));
      this.onHide();
      return;
    }

    // Common case: we're on the candidates > by posting page:
    const payload = {
      applications_to_update: applicationsBulkUpdate,
      target_status_id: statusObj.id,
      job_status_list: this.props.job_status_list,
      role_id: this.props.role.id,
    };

    applicationsAPI
      .bulkUpdateApplicationStatusesForRole(payload)
      .then(this.props.updateCandidatesInContainerState)
      .then(() => this.setState({ loadingSpinnerFooter: false }));
    this.onHide();
    return;
  };

  /** Begin bulk email methods */
  bulkEmail = () => {
    this.setState({ bulkEmailModalOpen: true });
  };

  onHide = () => {
    this.setState({
      emailModalOpen: false,
      bulkEmailModalOpen: false,
      emailTemplateSelection: null,
      targetStatus: null,
      applicationsBulkUpdate: [],
    });
  };

  /** End bulk email methods */

  /** Begin bulk export methods */
  bulkExport = () => {
    this.setState({ exportModalOpen: true });
  };

  onHideExport = () => {
    this.setState({ exportModalOpen: false });
  };
  /** End bulk email methods */

  continueStatusUpdate = () => {
    /** close bulk email modal and reset template with onHide. Then proceed to notification if
     * applicable. Otherwise send the bulk status update.
     */
    this.setState({ bulkEmailModalOpen: false });
    this.postUpdate(this.state.targetStatus);
  };

  bulkStatusUpdate = (statusObj, applicationsBulkUpdate) => {
    // If it's prospects, we don't send emails:
    if (this.props.onProspectList) {
      this.postUpdate(statusObj, applicationsBulkUpdate);
      return;
    }

    if (statusObj.email_bool) {
      this.handleStatusChangeEmail(statusObj, applicationsBulkUpdate);
    } else {
      this.postUpdate(statusObj, applicationsBulkUpdate);
    }
  };

  handleStatusChangeEmail = (statusObj, applicationsBulkUpdate) => {
    // it's possible for email_bool to be true and emailtemplate to be null, so check
    let emailTemplateSelection = statusObj.emailtemplate ? statusObj.emailtemplate.id : null;

    this.setState({
      bulkEmailModalOpen: true,
      targetStatus: statusObj,
      emailTemplateSelection,
      applicationsBulkUpdate,
    });
  };

  skipEmailButton = () => (
    <button className="email-modal-button" onClick={() => this.postUpdate(this.state.targetStatus)}>
      Skip Email
    </button>
  );

  Row = ({ data, index, style }) => {
    if (!this._isItemLoaded(index)) {
      return <div style={style}>{'Loading...'}</div>;
    }

    const candidate = this.state.candidates[index];
    const isSelected = this.state.selections.has(candidate.id);

    return (
      <div style={style}>
        <CandidateRow
          candidate={candidate}
          toggleQuickProfile={this.toggleQuickProfile}
          toggleShowingApplications={this.toggleShowingApplications}
          isRequisition={this.props.isRequisition}
          isSchoolUser={this.props.isSchoolUser}
          viewByPostingRoleId={this.props.viewByPostingRoleId}
          selected={isSelected}
          toggleCandidateSelect={this.toggleCandidateSelect}
          districtRoleId={this.props.districtRoleId}
          onProspectList={this.props.onProspectList}
          adminUsers={this.state.adminUsers}
          index={index}
          incrementNoteCount={this.props.incrementNoteCount}
          incrementScorecardCount={this.props.incrementScorecardCount}
        />
        {!this.props.onProspectList && (
          <CandidateApplicationsList
            candidate={candidate}
            selectedApplications={this.state.selectedApplications}
            toggleSelection={this.toggleApplicationSelection}
            toggleQuickProfile={this.toggleQuickProfile}
            isSchoolUser={this.props.isSchoolUser}
            activeCandidatesOnly={data.activeCandidatesOnly}
            district={this.state.district}
            adminUsers={this.state.adminUsers}
          />
        )}
      </div>
    );
  };

  getItemSize = (index) => {
    let candidate = this.state.candidates[index];
    let applications = candidate.applications;
    if (this.props.isSchoolUser) {
      applications = candidate.allRelevantApplicationsForSchoolAdmin || [];
    }
    // Prospects don't have apps:
    if (this.props.onProspectList) return 48;
    return candidate.expanded ? 48 * (applications.length + 1) : 48;
  };

  // Every row is loaded except for our loading indicator row.
  _isItemLoaded = (index) => {
    return !this.props.hasMore || index < this.state.candidates.length;
  };

  selectAllCheckedStatus = () => {
    const allowedCandidatesTotal = this.state.candidates.filter((c) => {
      return !(auth.getUser().linked_users || []).includes(Number(c.id));
    }).length;

    return this.state.selections.size > 0 && this.state.selections.size >= allowedCandidatesTotal;
  };

  render() {
    if (this.props.showSpinner) {
      return <LoadingSpinner />;
    }

    let updatedField = this.props.onProspectList ? 'modified' : 'profile.updated';

    let activeCandidatesOnly =
      _.isEqual(this.props.job_status_list, [1, 2]) ||
      _.isEqual(this.props.job_status_list, [2, 1]);
    let itemData = { activeCandidatesOnly };

    // If there are more items to be loaded then add an extra row to hold a loading indicator.
    const itemCount = this.props.hasMore
      ? this.state.candidates.length + 1
      : this.state.candidates.length;

    // Only load 1 page of items at a time.
    // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
    const loadMoreItems = this.props.fetchCandidatesRunning
      ? () => {}
      : this.props.fetchCandidatesDebounced;

    return (
      <div
        className={
          'list-view list-view-container ' + (this.props.isRequisition ? 'is-requisition-page' : '')
        }
        style={{ width: '100%' }}
      >
        <HeaderRow
          $onProspectList={this.props.onProspectList}
          $onRequisitionList={this.props.isRequisition}
        >
          <div className="candidate-select-box">
            <div
              className="candidate-select-all-div"
              onClick={this.toggleSelectionOptions}
              ref={(node) => (this.node = node)}
            >
              <input
                type="checkbox"
                id="cl-select-all-checkbox"
                name="select-all-checkbox"
                checked={this.selectAllCheckedStatus()}
                onChange={this.toggleAllSelections}
                data-testid={ATSCandidateListBulkEmail.SELECT_ALL_CANDIDATES_BUTTON}
              />
              <span className="glyphicon glyphicon-menu-down sort" />
              <div
                className={`cl-select-all-dropdown ${
                  this.state.showSelectionOptions ? 'expanded' : ''
                }`}
              >
                <div key={1} className="cl-select-option-div" onClick={this.selectAllShown}>
                  All
                </div>
                <div key={2} className="cl-select-option-div" onClick={this.deselectAll}>
                  None
                </div>
              </div>
            </div>
          </div>
          <div
            data-testid={ATSCandidatesListFilterTestIds.SORT_BY_NAME_BUTTON}
            className={`candidate-name ${this.props.onProspectList ? 'prospectlist' : ''} pointer`}
            onClick={() => this.sortBackend('name')}
          >
            {this.props.onProspectList ? 'Prospect' : 'Candidate'} Name
            <span style={{ marginLeft: '6px' }} className="glyphicon glyphicon-menu-down sort" />
          </div>

          {!this.props.onProspectList && (
            <div className="pointer" onClick={() => this.sortBackend('nimble_score')}>
              Insights
              <span className="glyphicon glyphicon-menu-down sort" />
            </div>
          )}

          <span>Status</span>

          {this.props.onProspectList && <span className="candidate-action source">Source</span>}

          {!this.props.isRequisition && !this.props.onProspectList && (
            <div
              className="candidate-app-count pointer"
              onClick={() => this.sortBackend('applications.__len')}
            >
              # Apps <span className="glyphicon glyphicon-menu-down sort" />
            </div>
          )}

          <div className="candidate-action pointer" onClick={() => this.sortBackend(updatedField)}>
            Last {this.props.onProspectList ? 'Updated' : 'Action'}{' '}
            <span className="glyphicon glyphicon-menu-down sort" />
          </div>
        </HeaderRow>
        <div
          style={{
            display: 'flex',
            height: `calc(100vh - ${this.props.isRequisition ? '274' : '340'}px`,
            paddingBottom: '25px',
          }}
        >
          <InfiniteLoader
            isItemLoaded={this._isItemLoaded}
            itemCount={itemCount}
            loadMoreItems={loadMoreItems}
            minimumBatchSize={CANDIDATE_LIST_PAGE_SIZE}
            // number of candidates away from bottom at which the next backend fetch starts.
            // in other words, when you're scrolling down and are X candidates from the end,
            // start the next call. this results in smoother scrolling.
            threshold={6}
          >
            {({ onItemsRendered, ref }) => (
              <AutoSizer ref={ref}>
                {({ width, height }) => (
                  <VariableSizeList
                    onItemsRendered={onItemsRendered}
                    height={height}
                    itemCount={this.state.candidates.length}
                    itemSize={this.getItemSize}
                    itemData={itemData}
                    width={width}
                    // number of candidates to render off the bottom of the page.
                    // results in smoother scrolling
                    overscanCount={30}
                    ref={(ref) => (this.listRef = ref)}
                  >
                    {this.Row}
                  </VariableSizeList>
                )}
              </AutoSizer>
            )}
          </InfiniteLoader>
        </div>
        {this.state.showQuickProfile && this.state.showTalentMarketplaceQuickView && (
          <MarketplaceProspectQuickView
            adminUsers={this.state.adminUsers}
            partialProspect={this.props.partialUser}
            show={this.state.showQuickProfile}
            sources={this.state.sources}
            closeQuickview={this.toggleQuickProfile}
            onClose={this.handleClose}
            updateProspectStatus={this.props.updateProspectStatus}
            emailTemplatesList={this.state.emailTemplatesList}
          />
        )}
        {this.state.showQuickProfile &&
          this.props.onProspectList &&
          !this.state.showTalentMarketplaceQuickView && (
            <QuickProfileProspect
              partialUser={this.props.partialUser}
              show={this.state.showQuickProfile}
              toggleVisibility={this.toggleQuickProfile}
              adminUsers={this.state.adminUsers}
              emailTemplatesList={this.state.emailTemplatesList}
              sources={this.state.sources}
              retrieveProspect={this.props.retrieveProspect}
              updateProspectStatus={this.props.updateProspectStatus}
            />
          )}
        {this.state.showQuickProfile && this.props.application_type === 'schoolapplication' && (
          <QuickProfileSchoolApplication
            partialUser={this.props.partialUser}
            show={this.state.showQuickProfile}
            toggleVisibility={this.toggleQuickProfile}
            partialApplication={this.props.partialApplication}
            adminUsers={this.state.adminUsers}
            emailTemplatesList={this.state.emailTemplatesList}
            sources={this.state.sources}
            incrementScorecardCount={this.incrementScorecardCount}
            newApplicationStatuses={this.props.newApplicationStatuses}
            refreshCandidateInList={this.props.refreshCandidateInList}
          />
        )}
        {this.state.showQuickProfile &&
          !this.props.onProspectList &&
          this.props.application_type === 'application' && (
            <QuickProfileDistrictApplication
              partialUser={this.props.partialUser}
              show={this.state.showQuickProfile}
              toggleVisibility={this.toggleQuickProfile}
              partialApplication={this.props.partialApplication}
              adminUsers={this.state.adminUsers}
              emailTemplatesList={this.state.emailTemplatesList}
              sources={this.state.sources}
              incrementScorecardCount={this.incrementScorecardCount}
              newApplicationStatuses={this.props.newApplicationStatuses}
              refreshCandidateInList={this.props.refreshCandidateInList}
            />
          )}
        <CandidatesListFooter
          selections={this.state.selections}
          selectedApplications={this.state.selectedApplications}
          candidates={this.state.candidates}
          bulkEmail={this.bulkEmail}
          bulkExport={this.bulkExport}
          bulkStatusUpdate={this.bulkStatusUpdate}
          selectAllTotal={this.selectAllTotal}
          totalPossibleSelections={this.props.totalCandidateCount}
          isRequisition={this.props.isRequisition}
          onProspectList={this.props.onProspectList}
          role={this.props.role}
          newApplicationStatuses={this.props.newApplicationStatuses}
          availableApplicationStatuses={this.props.availableApplicationStatuses}
          loadingSpinnerFooter={this.state.loadingSpinnerFooter}
        />
        {this.state.bulkEmailModalOpen && (
          <BulkEmail
            show={this.state.bulkEmailModalOpen}
            onHide={this.onHide}
            emailTemplatesList={this.state.emailTemplatesList}
            selections={this.state.selections}
            deselectAll={this.deselectAll}
            candidates={this.state.candidates}
            fetchAllCandidates={this.props.fetchCandidates}
            fetchAllProspects={this.props.fetchProspects}
            jobTitle={this.props.jobTitle}
            emailTemplateSelection={this.state.emailTemplateSelection}
            // if targetStatus exists, we're in the process of a bulk status update,
            // which is the only time we'll need the continueStatusUpdate function.
            continueStatusUpdate={this.state.targetStatus ? this.continueStatusUpdate : null}
            onProspectList={this.props.onProspectList}
            getUser={this.getUser}
          />
        )}
        {this.state.exportModalOpen && !auth.hasExportCandidateTags() && (
          <BulkExport
            show={this.state.exportModalOpen}
            onHideExport={this.onHideExport}
            selections={this.state.selections}
            deselectAll={this.deselectAll}
            onProspectList={this.props.onProspectList}
          />
        )}
        {auth.hasExportCandidateTags() && (
          <BulkExportV2
            open={this.state.exportModalOpen}
            onClose={this.onHideExport}
            onProspectList={this.props.onProspectList}
            selectedCandidateIds={this.state.selections}
          />
        )}
        {this.state.emailModalOpen && (
          // EmailModal used for BulkEmail in this case
          <EmailModal
            recipientList={this.state.recipientList}
            show={this.state.emailModalOpen}
            subject={this.state.subject}
            message={this.state.message}
            onHide={this.onHide}
            onSend={this.postUpdate}
            emailTemplatesList={this.state.emailTemplatesList}
            emailTemplate={this.state.emailTemplate}
            updateEmailTemplateSelection={this.updateEmailTemplateSelection}
            showTemplates={true}
            footer={this.skipEmailButton()}
          />
        )}
        {/* Various tooltips used in each row */}
        <ReactTooltip id="top-candidate" effect="solid">
          <span>Highly relevant skills</span>
        </ReactTooltip>
        <ReactTooltip id="high-retention" effect="solid">
          <span>High retention potential</span>
        </ReactTooltip>
        <ReactTooltip id="internal" effect="solid">
          <span>Internal</span>
        </ReactTooltip>
        <ReactTooltip id="no-fit" effect="solid">
          <span>Not a Fit</span>
        </ReactTooltip>
        <ReactTooltip id="potential-fit" effect="solid">
          <span>PotentialFit</span>
        </ReactTooltip>
        <ReactTooltip id="converted-to-prospect" effect="solid">
          <span>Converted to Prospect</span>
        </ReactTooltip>
        <ReactTooltip
          id="scorecard_count"
          effect="solid"
          getContent={(scorecard_count) =>
            `${scorecard_count} scorecard${scorecard_count === '1' ? '' : 's'}`
          }
        />
        <ReactTooltip
          id="note_count"
          effect="solid"
          getContent={(note_count) => `${note_count} note${note_count === '1' ? '' : 's'}`}
        />
        <ReactTooltip id="role-title" effect="solid" getContent={(title) => title} />
      </div>
    );
  }
}

const HeaderRow = styled.div`
  display: grid;
  grid-template-columns: ${({ $onProspectList }) =>
    $onProspectList ? '70px 1fr 1fr' : '70px 2fr 1fr 2fr'};
  align-items: center;

  padding: 0 8px;
  height: 48px;
  min-width: 500px;
  font-size: 15px;
  color: rgba(57, 60, 73, 0.8);
  border-bottom: 1px solid #ddd;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;

  & > div,
  & > span {
    font-weight: bold;
  }

  @media screen and (min-width: 768px) {
    grid-template-columns: ${({ $onProspectList }) =>
      $onProspectList ? '70px 1.5fr 1.5fr 1fr 1fr' : '70px 1.5fr 1fr 1.5fr 1fr 1fr'};
  }

  @media screen and (min-width: 1024px) {
    grid-template-columns: ${({ $onProspectList, $onRequisitionList }) =>
      $onProspectList
        ? '70px 160px 159px 175px 1fr'
        : $onRequisitionList
          ? '70px 175px 105px 192px 1fr'
          : '70px 168px 98px 136px 90px 1fr'};
  }

  @media screen and (min-width: 1200px) {
    grid-template-columns: ${({ $onProspectList, $onRequisitionList }) =>
      $onProspectList
        ? '70px 200px 191px 240px 1fr'
        : $onRequisitionList
          ? '70px 225px 105px 250px 1fr'
          : '70px 185px 105px 190px 95px 125px 1fr'};
  }
`;
