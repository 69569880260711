import { Typography } from '@mui/material';

import { ATSTabbedJobViewSetUpTabTestIds } from 'data-testids/ATS';
import { CheckboxList } from 'sharedComponents/List/CheckboxList';
import { StatusWorkflowProps } from '../types';
import { listSx, WorkflowSection } from '../styles';
import { transformStatusesForListData } from '../utils';

export const StatusWorkflow: React.FC<StatusWorkflowProps> = ({ statuses }) => {
  const transformedStatuses = transformStatusesForListData(statuses);

  return (
    <WorkflowSection>
      <Typography
        data-testid={ATSTabbedJobViewSetUpTabTestIds.STATUS_WORKFLOW_TITLE}
        variant="subtitle"
      >
        Candidate status workflow
      </Typography>
      <CheckboxList
        dataTestId={ATSTabbedJobViewSetUpTabTestIds.STATUS_WORKFLOW_LIST}
        disableRipple
        isDense
        isReadOnly
        listSx={listSx}
        options={transformedStatuses}
      />
    </WorkflowSection>
  );
};
