import React from 'react';
import { Modal, Box, IconButton, styled, Theme, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ScrollableModalProps } from './types';

export const ScrollableModal: React.FC<ScrollableModalProps> = ({
  open,
  onClose,
  header,
  body,
  footer,
  maxWidth = '600px',
  showFooter = true,
  sx,
  showCloseButton = true,
  paddingY = 4,
  paddingX = 4,
  dataTestId,
}) => {
  const theme = useTheme();
  return (
    <Modal open={open} onClose={onClose} data-testid={dataTestId}>
      <ModalContainer maxWidth={maxWidth} sx={{ paddingY, paddingX, ...sx }}>
        {showCloseButton && (
          <IconButton onClick={onClose} sx={ModalExitButtonStyles(theme)}>
            <CloseIcon />
          </IconButton>
        )}
        <ModalHeader>{header}</ModalHeader>
        <ModalBody>{body}</ModalBody>

        {showFooter && <ModalFooter>{footer}</ModalFooter>}
      </ModalContainer>
    </Modal>
  );
};

const ModalContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '90vw',
  maxHeight: '90vh',
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  overflow: 'hidden',
  position: 'relative',
}));

const ModalHeader = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'relative',
}));

const ModalBody = styled(Box)(() => ({
  flex: 1,
  overflowY: 'auto',
  minHeight: 0,
}));

const ModalFooter = styled(Box)(() => ({
  display: 'flex',
  flexShrink: 0,
  marginTop: 'auto',
}));

const ModalExitButtonStyles = (theme: Theme) => ({
  position: 'absolute',
  zIndex: 1,
  top: theme.spacing(3),
  right: theme.spacing(3),

  [theme.breakpoints.down('sm')]: {
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
});
