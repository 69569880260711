import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SchoolCard } from 'features/Connect/components/SchoolCard';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { School } from 'types/connectTypes';
import { SchoolsListProps } from 'types/connectTypes';
import auth from 'utils/auth';
import { NoResultsFoundCard } from './NoResultsFoundCard';
import { EndOfResultsCard } from './EndOfResultsCard';
import { GatedResultsCard } from './GatedResultsCard';
import { ConnectJobDiscoverySchoolCardDataTestIds } from 'data-testids/ConnectDataTestIds';
import { useConnectContext } from '../../Context/ConnectContextProvider';
import { SchoolCardLoadingSkeleton } from 'features/Connect/components/LoadingSkeletons/SchoolCardLoadingSkeleton';

export const JobDiscoverySchoolsList: React.FC<SchoolsListProps> = ({
  schools,
  page,
  isFetchingNextPage,
  isLoadingInitialFilterResults,
  setIsFetchingNextPage,
  hasMore,
  jobDiscoveryActions,
  count,
}) => {
  const { isLoading } = useConnectContext();
  const generateNearbySchoolsText = (schools: School[]) => {
    if (!schools) {
      return '';
    } else if (count === 1) {
      return 'Showing 1 nearby school that matches your criteria';
    } else {
      return `Showing ${count} nearby schools matching your criteria`;
    }
  };

  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && hasMore && !isFetchingNextPage) {
      setIsFetchingNextPage(true);
      jobDiscoveryActions.setPage(page + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView, hasMore, isFetchingNextPage, page, setIsFetchingNextPage]);

  const isSearchResultsFeatureFlagEnabled = auth.hasConnectSearchResults();
  const schoolsToRender = isSearchResultsFeatureFlagEnabled ? schools.slice(0, 5) : schools;

  const renderLoadingSkeletons = () => (
    <Box data-testid={ConnectJobDiscoverySchoolCardDataTestIds.LOADING_SPINNER}>
      {[...Array(5)].map((_, index) => (
        <SchoolCardLoadingSkeleton key={index} />
      ))}
    </Box>
  );

  const renderSchoolsList = () => (
    <>
      {schoolsToRender.map((school) => (
        <SchoolCard key={school.nces_id} school={school} />
      ))}
      <div id="load-more-results-trigger" ref={ref} />
      {isFetchingNextPage && renderLoadingSkeletons()}
      {!hasMore && <EndOfResultsCard />}
      {isSearchResultsFeatureFlagEnabled && (
        <GatedResultsCard count={count} numberOfSchoolsRendered={schoolsToRender.length} />
      )}
    </>
  );

  const isInitialLoading = isLoading && !isFetchingNextPage;
  const shouldShowLoadingState = isInitialLoading || isLoadingInitialFilterResults;
  const shouldShowResults = !shouldShowLoadingState && schools?.length > 0;
  const shouldShowNoResults = !shouldShowLoadingState && !schools?.length;

  return (
    <SchoolListContainer width={'100%'}>
      <SchoolsFoundSubHeader>{generateNearbySchoolsText(schools)}</SchoolsFoundSubHeader>

      {shouldShowLoadingState && renderLoadingSkeletons()}

      {shouldShowResults && renderSchoolsList()}

      {shouldShowNoResults && <NoResultsFoundCard />}
    </SchoolListContainer>
  );
};

const SchoolListContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: 1,
  width: '100%',
  justifyItems: 'center',
  justifyContent: 'space-evenly',
}));

const SchoolsFoundSubHeader = styled(Typography)(() => ({
  fontSize: '18px',
  fontWeight: '600',
  fontStyle: 'normal',
  lineHeight: '28px',
  padding: '12px 0px 24px',
}));
