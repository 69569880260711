import { ATSQuestionComponentTestIds } from 'data-testids/ATS';
import { QuestionType } from 'utils/constants/roles';

import {
  IconContainer,
  IndividualQuestionContainer,
  QuestionHeader,
  QuestionText,
  QuestionTypeText,
} from './styles';
import { getQuestionHeaderText, getQuestionTypeIcon } from './utils';

export interface IndividualQuestionProps {
  dataTestId: string;
  questionType: QuestionType;
  questionText: string;
}

export const IndividualQuestion: React.FC<IndividualQuestionProps> = ({
  dataTestId,
  questionType,
  questionText,
}) => {
  const removeJSXFromText = (questionText: string): string => {
    if (questionType === QuestionType.direction_text) {
      return questionText.replace(/<[^>]*>?/gm, '');
    }

    return questionText;
  };

  return (
    <IndividualQuestionContainer data-testid={dataTestId}>
      <QuestionHeader>
        <IconContainer data-testid={ATSQuestionComponentTestIds.QUESTION_ICON_CONTAINER}>
          {getQuestionTypeIcon(questionType)}
        </IconContainer>
        <QuestionTypeText data-testid={ATSQuestionComponentTestIds.QUESTION_TYPE} variant="body2">
          {getQuestionHeaderText(questionType)}
        </QuestionTypeText>
      </QuestionHeader>
      <QuestionText data-testid={ATSQuestionComponentTestIds.QUESTION_TEXT}>
        {removeJSXFromText(questionText)}
      </QuestionText>
    </IndividualQuestionContainer>
  );
};
