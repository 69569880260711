import {
  CandidateDashboardViewsSecondaryText,
  CandidateDashboardViewsPrimaryText,
} from './utils/styles';
import { DashboardViewsProps } from './utils/types';
import { PrimaryButton } from 'sharedComponents/Buttons';
import { styled } from '@mui/material';
import { Box } from '@mui/material';

export const CandidateDashboardViews = ({ viewState }: DashboardViewsProps) => {
  const Icon = viewState.icon;
  return (
    <CandidateDashboardViewsContainer>
      <Icon />
      <CandidateDashboardViewsPrimaryText>
        {viewState.primaryText}
      </CandidateDashboardViewsPrimaryText>
      <CandidateDashboardViewsSecondaryText>
        {viewState.secondaryText}
      </CandidateDashboardViewsSecondaryText>
      {viewState.buttonText && (
        <PrimaryButton color="primary" onClick={viewState.buttonAction}>
          {viewState.buttonText}
        </PrimaryButton>
      )}
    </CandidateDashboardViewsContainer>
  );
};

const CandidateDashboardViewsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '50vh',
  margin: 'auto',
  gap: theme.spacing(2),
  width: '100%',
  maxWidth: '600px',
  padding: theme.spacing(3),
  textAlign: 'center',
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(2),
  },
}));
