import { useHistory } from 'react-router';

import { ApplyButton, CTAContainer } from './styles';
import { JobViewTitleButtonsProps } from '../types';
import { PrimaryButton, SecondaryButton } from 'sharedComponents/Buttons';
import { activeStatusByValue, roleStatusByValue } from 'utils/enums';
import auth from 'utils/auth';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SendIcon from '@mui/icons-material/Send';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { ATSJobViewDataTestIds } from '../../../data-testids/ATS';
import { useTheme } from '@mui/material';
import {
  primaryButtonOverrideSx,
  secondaryButtonOverrideSx,
} from 'sharedComponents/Buttons/styles';

export const JobViewTitleButtons: React.FC<JobViewTitleButtonsProps> = ({
  handleOpenShareModal,
  isPreview,
  isInternalCandidate,
  job,
  onSubmit,
  userDistrict,
  isPublicJobView,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const jobDistrict = job?.district?.id;
  const shouldShowEditButton = jobDistrict === userDistrict;
  const jobIsOldJobViewJobAndVisibleToCandidates = 'link_to_ats' in job && isPublicJobView;
  const isPostingOnlyAndVisibleToCandidates = jobIsOldJobViewJobAndVisibleToCandidates
    ? job?.district.posting_only
    : false;
  const isDistrictAdminOrJobIsPending =
    auth.isDistrictAdmin() || job?.status === roleStatusByValue.submitted;
  const backToJobEdit = () => history.push(`/district/jobedit/${job?.id}`);
  const isSchoolUser = auth.isSchoolUser();
  const showSocialMediaShareButton =
    job?.status === roleStatusByValue.active &&
    job?.active_status === activeStatusByValue.activeOpen &&
    !auth.inPostingOnlyDistrict();

  if (isPreview) {
    const postButtonText = auth.isDistrictUserOnly() ? 'Submit to Admin' : 'Post Job';

    return (
      <CTAContainer>
        <SecondaryButton
          dataTestId="back-to-edit-button"
          onClick={backToJobEdit}
          sx={secondaryButtonOverrideSx(theme)}
          startIcon={<CreateOutlinedIcon />}
        >
          Back to Edit
        </SecondaryButton>
        <SecondaryButton
          dataTestId="print-button"
          onClick={() => window.print()}
          startIcon={<LocalPrintshopOutlinedIcon />}
          sx={secondaryButtonOverrideSx(theme)}
        >
          Print
        </SecondaryButton>
        <PrimaryButton
          dataTestId={ATSJobViewDataTestIds.POST_BUTTON}
          onClick={onSubmit}
          startIcon={<SendIcon />}
          sx={primaryButtonOverrideSx(theme)}
        >
          {postButtonText}
        </PrimaryButton>
      </CTAContainer>
    );
  }

  if (isDistrictAdminOrJobIsPending) {
    return (
      <>
        {shouldShowEditButton && (
          <CTAContainer>
            {showSocialMediaShareButton && (
              <SecondaryButton
                dataTestId="share-button"
                onClick={() => handleOpenShareModal()}
                startIcon={<ShareOutlinedIcon />}
                sx={secondaryButtonOverrideSx(theme)}
              >
                Share
              </SecondaryButton>
            )}
            <SecondaryButton
              dataTestId="print-button"
              onClick={() => window.print()}
              startIcon={<LocalPrintshopOutlinedIcon />}
              sx={secondaryButtonOverrideSx(theme)}
            >
              Print
            </SecondaryButton>
            <PrimaryButton
              dataTestId={ATSJobViewDataTestIds.EDIT_BUTTON}
              onClick={backToJobEdit}
              startIcon={<CreateOutlinedIcon />}
              sx={primaryButtonOverrideSx(theme)}
            >
              Edit Posting
            </PrimaryButton>
          </CTAContainer>
        )}
      </>
    );
  } else if (isSchoolUser || auth.isDistrictUserOnly()) {
    return (
      <CTAContainer>
        {showSocialMediaShareButton && (
          <SecondaryButton
            dataTestId="share-button"
            onClick={() => handleOpenShareModal()}
            startIcon={<ShareOutlinedIcon />}
            sx={secondaryButtonOverrideSx(theme)}
          >
            Share
          </SecondaryButton>
        )}
        <SecondaryButton
          dataTestId="print-button"
          onClick={() => window.print()}
          startIcon={<LocalPrintshopOutlinedIcon />}
          sx={secondaryButtonOverrideSx(theme)}
        >
          Print
        </SecondaryButton>
      </CTAContainer>
    );
  } else if (isPostingOnlyAndVisibleToCandidates) {
    const encodedJobTitleAndEmailSubject = encodeURIComponent('Application for: ' + job.title);
    let linkToApply: string = jobIsOldJobViewJobAndVisibleToCandidates ? job.link_to_ats : '';

    linkToApply = linkToApply.includes('http')
      ? linkToApply
      : `mailto:${linkToApply}?subject=${encodedJobTitleAndEmailSubject}`;

    return (
      <a href={linkToApply.length > 0 ? linkToApply : ''} data-testid="apply-link">
        <ApplyButton data-testid="apply-button" variant="contained">
          Apply
        </ApplyButton>
      </a>
    );
  } else if (jobIsOldJobViewJobAndVisibleToCandidates) {
    const urlQuery = isInternalCandidate ? `?internal=${window.btoa('true')}` : '';
    const districtRole = job?.district_role;
    const roleId = window.location.search.indexOf('is_school_role=1') > -1 ? districtRole : job?.id;

    return (
      <CTAContainer>
        <ApplyButton
          data-testid={ATSJobViewDataTestIds.APPLY_BUTTON}
          onClick={() => history.push(`/applicant-profile/${roleId}/${urlQuery}`)}
          startIcon={<OpenInNewIcon />}
          variant="contained"
        >
          Apply
        </ApplyButton>
      </CTAContainer>
    );
  } else {
    return <></>;
  }
};
