import { Box, styled, Typography } from '@mui/material';

export const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

export const listSx = {
  li: {
    padding: 0,
  },

  div: {
    cursor: 'default',
    minWidth: 0,
    padding: 0,

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
};

export const Row = styled(Typography)(({ theme }) => ({
  lineHeight: '160%',
  paddingBottom: theme.spacing(1),
}));

export const Section = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: theme.spacing(4),
}));

export const Subtitle = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
}));

export const SwitchContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  paddingBottom: theme.spacing(2),
  paddingTop: theme.spacing(2),
}));

export const WorkflowSection = styled(Section)(({ theme }) => ({
  paddingTop: theme.spacing(4),
}));
