import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import { BlankModal } from 'sharedComponents/Modal';
import magicLinkBackground from './../../../../../assets/magic-link-modal-background.svg';

import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

import { MagicLinkModalProps } from 'types/connectTypes';
import { connectRedirectUrls } from 'features/Connect/features/JobDiscovery/constants';
import { ConnectMagicLinkModalTestIds } from 'data-testids/ConnectDataTestIds';

function getModalHeaderTextContent(expiredModal: boolean, isMobile: boolean, email: string | null) {
  let headerText, headerBodyContent;

  if (expiredModal && (email === null || email === 'None')) {
    headerText = 'Looks like you already used this link';
    headerBodyContent = null;
  } else {
    headerText = expiredModal
      ? isMobile
        ? 'You need a new link'
        : 'Looks like you need a new link'
      : 'Please check your email';
    headerBodyContent = (
      <>
        <BodyTypography>
          We&apos;ve sent a login link to {isMobile ? null : <b>{email}</b>}
        </BodyTypography>
        {isMobile && (
          <BodyTypography>
            <b>{email}</b>
          </BodyTypography>
        )}
      </>
    );
  }

  return { headerText, headerBodyContent };
}

export const MagicLinkModal: React.FC<MagicLinkModalProps> = ({
  email,
  isOpen,
  closeModal,
  expiredModal,
  redirectState,
}) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const { headerText, headerBodyContent } = getModalHeaderTextContent(
    expiredModal,
    isMobile,
    email
  );

  const getRedirectUrl = (path, redirectState) => {
    if (redirectState) {
      return `${path}?redirect_state=${redirectState}`;
    } else return path;
  };

  const redirectUrl = getRedirectUrl(connectRedirectUrls.LOGIN, redirectState);

  const renderBodyContent = () => {
    if (expiredModal && (email === null || email === 'None')) {
      return (
        <BodyTypography sx={{ padding: '0px 24px' }}>
          <Link
            onClick={closeModal}
            color="primary"
            sx={{
              cursor: 'pointer',
            }}
          >
            Start over and log in again
          </Link>
        </BodyTypography>
      );
    }
    return (
      <BodyTypography sx={{ padding: '0px 24px' }}>
        {`Didn't get the link? `}
        <Link href={redirectUrl} color="primary">
          Enter your email again.
        </Link>
      </BodyTypography>
    );
  };

  return (
    <BlankModal
      open={isOpen}
      onClose={closeModal}
      sx={{
        backgroundImage: `url(${magicLinkBackground})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        padding: isMobile ? '16px' : '24px',
        borderRadius: '8px',
      }}
      header={
        <HeaderBox>
          <IconButton onClick={closeModal} sx={{ position: 'fixed', right: 8, top: 8 }}>
            <CloseIcon />
          </IconButton>
          <HeaderTypography data-testid={ConnectMagicLinkModalTestIds.HEADER_TEXT}>
            {headerText}
          </HeaderTypography>
          {headerBodyContent}
        </HeaderBox>
      }
      body={<BodyBox>{renderBodyContent()}</BodyBox>}
    />
  );
};

export const BodyTypography = styled(Typography)({
  color: '#344054',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '20px',
});

export const HeaderTypography = styled(Typography)({
  color: '#101828',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '28px',
});

export const HeaderBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '4px',
  padding: '80px 0px 24px',
});

export const BodyBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignContent: 'center',
  justifyContent: 'center',
  gap: '16px',
  padding: '32px 0px 0px 0px',
});
