import { Chip, styled } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

interface PreferenceMatchBadgeProps {
  preferenceMatch: string;
}

export const PreferenceMatchBadge: React.FC<PreferenceMatchBadgeProps> = ({ preferenceMatch }) => {
  return (
    <StyledChip icon={<StyledChipIcon color="inherit" />} label={preferenceMatch} size="small" />
  );
};

const StyledChip = styled(Chip)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  alignSelf: 'stretch',
  border: `1px solid ${theme.palette.purple.dark}`,
  background: theme.palette.purple.fill,
  '& .MuiChip-label': {
    padding: theme.spacing(0, 1),
    color: theme.palette.purple.dark,
    fontSize: theme.typography.body3.fontSize,
    fontStyle: 'normal',
    fontWeight: theme.typography.fontWeightMediumBold,
    lineHeight: theme.typography.body3.lineHeight,
  },
}));

const StyledChipIcon = styled(CheckIcon)(({ theme }) => ({
  color: theme.palette.purple.dark,
  marginLeft: theme.spacing(1),
}));
