import { Box, useTheme } from '@mui/material';

import { ATSTabbedJobViewVacanciesTabTestIds } from 'data-testids/ATS';
import { TabTitle } from 'features/TabbedJobView/styles';
import { TabbedJobViewChip } from 'features/TabbedJobView/components/TabbedJobViewChip';
import { VacanciesTabHeaderProps } from '../types';

export const VacanciesTabHeader: React.FC<VacanciesTabHeaderProps> = ({
  isLoading,
  totalNumberOfVacancies,
}) => {
  const theme = useTheme();

  return (
    <Box sx={{ display: 'flex' }}>
      <TabTitle
        variant="h2"
        data-testid={ATSTabbedJobViewVacanciesTabTestIds.VACANCIES_TAB_TITLE}
        sx={{ marginRight: theme.spacing(2) }}
      >
        Select vacancy locations
      </TabTitle>
      {!isLoading && (
        <TabbedJobViewChip
          dataTestId={ATSTabbedJobViewVacanciesTabTestIds.NUMBER_OF_VACANCIES_CHIP}
          label={totalNumberOfVacancies === 1 ? '1 vacancy' : `${totalNumberOfVacancies} vacancies`}
        />
      )}
    </Box>
  );
};
