import { CircularProgress, useTheme } from '@mui/material';

import { ATSTabbedJobViewQuestionsTabTestIds } from 'data-testids/ATS';
import { Alert } from 'sharedComponents/Alert';
import { QuestionList } from './components/QuestionList';
import { QuestionTabHeader } from './components/QuestionTabHeader';
import { QuestionsTabProps } from './types';
import { sortAndCombineQuestionsAndAttachments } from './utils';
import { useQueryGetQuestionsByJobId } from './queries';
import { Container } from './styles';

const tooManyQuestionsWarningThreshold = 10;

export const QuestionsTab: React.FC<QuestionsTabProps> = ({ jobId }) => {
  const theme = useTheme();

  const { data: questions, isLoading: isQuestionsDataLoading } = useQueryGetQuestionsByJobId(jobId);
  const sortedQuestions = questions && sortAndCombineQuestionsAndAttachments(questions);
  const chipText =
    sortedQuestions?.length === 1 ? '1 question' : `${sortedQuestions?.length} questions`;

  if (isQuestionsDataLoading)
    return <CircularProgress data-testid={ATSTabbedJobViewQuestionsTabTestIds.LOADING_SPINNER} />;

  return (
    <Container>
      <QuestionTabHeader chipText={chipText} />

      {sortedQuestions?.length >= tooManyQuestionsWarningThreshold && (
        <Alert
          type="warning"
          sx={{ marginBottom: theme.spacing(2) }}
          dataTestId={ATSTabbedJobViewQuestionsTabTestIds.MORE_THAN_TEN_QUESTIONS_WARNING}
        >
          We recommend fewer than 10 questions to avoid candidate drop-off
        </Alert>
      )}

      {sortedQuestions && <QuestionList questions={sortedQuestions} />}
    </Container>
  );
};
