import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';

import { TabbedJobViewSettingsData } from './types';

export const useQueryGetJobSettingsByJobId = (
  jobId: number,
  options?: Omit<UseQueryOptions<TabbedJobViewSettingsData, AxiosError>, 'queryKey' | 'queryFn'>
): UseQueryResult<TabbedJobViewSettingsData, AxiosError> => {
  return useQuery({
    queryKey: ['tabbedJobViewJobSettingsByJobId', jobId],
    queryFn: () => axios.get(`/api/roles/${jobId}/settings`).then((res) => res.data),
    enabled: Number.isInteger(jobId),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    ...options,
  });
};
