import Autocomplete from '@mui/material/Autocomplete';
import { FormControl, InputAdornment, TextField, useTheme } from '@mui/material';
import { SearchbarMultiselectProps } from './types';
import SearchIcon from '@mui/icons-material/Search';
import { renderOptionContent } from './utils';

const filterOptionsHandler = (options, inputValue) => {
  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(inputValue.toLowerCase())
  );

  return filteredOptions.sort((a, b) => {
    const startsWithInputA = a.label.toLowerCase().startsWith(inputValue.toLowerCase());
    const startsWithInputB = b.label.toLowerCase().startsWith(inputValue.toLowerCase());

    if (startsWithInputA && !startsWithInputB) {
      return -1;
    }
    if (!startsWithInputA && startsWithInputB) {
      return 1;
    }

    return a.label.localeCompare(b.label);
  });
};

export const SearchbarMultiSelect: React.FC<SearchbarMultiselectProps> = ({
  values = [],
  options = [],
  displayName,
  placeholder,
  handleChange,
  tagLimit = 1,
  size,
  sx,
  hiddenLabel,
  dataTestId,
  hasSubLabel = false,
  subLabelKeys = [],
}) => {
  const theme = useTheme();

  const onChangeHandler = (_event, value) => {
    handleChange(value);
  };

  return (
    <FormControl fullWidth variant="outlined" size={size} sx={sx}>
      <Autocomplete
        id="multi-select-with-search"
        data-testid={dataTestId || 'multi-select-with-search'}
        disableCloseOnSelect
        multiple
        getOptionLabel={(option) => option.label}
        limitTags={tagLimit}
        options={options}
        size={size}
        onChange={onChangeHandler}
        value={values}
        filterOptions={(opts, { inputValue }) => filterOptionsHandler(opts, inputValue)}
        sx={{
          ...sx,
          '& .MuiAutocomplete-popupIndicator': { display: 'none' },
        }}
        renderOption={(props, option) => (
          <li
            {...props}
            key={option.value}
            data-testid={`${dataTestId}-${option.label.toLowerCase()}-option`}
          >
            {renderOptionContent(option, hasSubLabel, subLabelKeys, dataTestId)}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={values.length > 0 ? displayName : ''}
            placeholder={placeholder}
            hiddenLabel={hiddenLabel}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <InputAdornment
                    position="start"
                    sx={{
                      margin: `${theme.spacing(1)} 0 ${theme.spacing(1)} ${theme.spacing(1)}`,
                    }}
                  >
                    <SearchIcon />
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
            sx={sx}
          />
        )}
      />
    </FormControl>
  );
};
