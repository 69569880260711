import { HiringRequestListAPIData } from 'features/Connect/features/ConnectATSIntegration/HiringRequests/types';
import { BaseNimbleAPI } from './baseAPI';

import { CandidateApplicationConnectData } from 'types';
import { NewHiringRequestsAPIResponse } from 'features/Connect/features/ConnectATSIntegration/HiringRequests/types';

class HiringRequestsAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/api/hiring-requests' };
  }

  async getHiringRequests(): Promise<HiringRequestListAPIData> {
    const url = '/';
    const response = await this.get(url);
    return response.data;
  }

  async importCandidateFromConnect(
    payload: CandidateApplicationConnectData
  ): Promise<NewHiringRequestsAPIResponse> {
    const url = '/';
    const response = await this.post(url, payload);
    return response.data;
  }
}

export default new HiringRequestsAPI();
