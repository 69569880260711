import { ChildrenContainer, ModalContainer, Title, Top } from './styles';
import { Modal as MuiModal } from '@mui/material';
import { ModalProps } from './types';
import { nimbleTheme } from 'theme';

export const Modal: React.FC<ModalProps> = ({
  ctaButtons,
  children,
  childrenSx,
  closeButton,
  open,
  onClose,
  sx,
  title,
  borderRadius,
  hasBoxShadow = false,
  isTitleCentered = false,
  bodySx,
}) => {
  const modalBodyCustomStyles = {
    borderRadius: borderRadius,
    boxShadow:
      hasBoxShadow &&
      '0px 1px 8px 0px rgba(0, 0, 0, 0.12), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.20);',
    ...bodySx,
  };

  return (
    <MuiModal open={open} onClose={(event, reason) => onClose(event, reason)} sx={sx}>
      <ModalContainer theme={nimbleTheme} className="modalBody" sx={modalBodyCustomStyles}>
        <Top>
          {typeof title === 'string' ? (
            <Title
              data-testid="modal-title"
              id="modal-modal-title"
              sx={isTitleCentered && { width: '100%' }}
            >
              {title}
            </Title>
          ) : (
            <>{title}</>
          )}
          {closeButton}
        </Top>
        <ChildrenContainer sx={childrenSx}>{children}</ChildrenContainer>
        {ctaButtons}
      </ModalContainer>
    </MuiModal>
  );
};
