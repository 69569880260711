import { styled, SxProps } from '@mui/material';
import ReactQuill from 'react-quill';

type StyledQuillInputProps = {
  value: string;
  onChange: (text: string) => void;
  modules?: any;
  sx?: SxProps;
};

export const StyledQuillInput: React.FC<StyledQuillInputProps> = ({
  value,
  onChange,
  modules = {},
  sx = {},
}) => {
  return <StyledReactQuill value={value} onChange={onChange} modules={modules} sx={sx} />;
};

const StyledReactQuill = styled(ReactQuill)`
  margin-bottom: 16px;
  border: 1px solid #e1e1e2;

  &:hover {
    border-color: #dcdcdc !important;
  }
  .ql-editor {
    min-height: 160px;
  }

  .ql-editor.ql-blank::before {
    color: rgba(57, 60, 73, 0.3);
  }
  .ql-mention-list-container-bottom {
    background-color: white;
    width: 225px;
    max-height: 223px;
    overflow: scroll;
    position: absolute;
    bottom: 14;
    left: -19;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1);
    list-style: none;
    ul {
      list-style-type: none;
      li {
        padding: 4px;
      }
      li:hover {
        background-color: #f0f0f0;
        cursor: pointer;
      }
    }
    padding: 4px;
  }

  .ql-mention-list-container-top {
    background-color: white;
    width: 225px;
    max-height: 200px;
    overflow: scroll;
    position: absolute;
    bottom: 14;
    left: -19;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1);
    list-style: none;
    ul {
      list-style-type: none;
      li {
        padding: 4px;
      }
      li:hover {
        background-color: #f0f0f0;
        cursor: pointer;
      }
    }
    padding: 4px;
  }

  .mention {
    background-color: #d9e1f0;
    font-weight: bold;
  }
`;
