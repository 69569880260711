import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Typography } from '@mui/material';

import { UnsureSubtitle, UnsureTextContainer, VisibilityRow } from './styles';
import { VacancySchoolRole } from './types';

export const constructVacancyRows = (rows: VacancySchoolRole[]) => {
  return rows?.map((row, index) => ({
    location: row.school_name,
    id: index,
    vacancies:
      row.ftes_total > 0 ? (
        row.ftes_total
      ) : (
        <UnsureTextContainer>
          <Typography>Unsure</Typography>
          <UnsureSubtitle>This site will not show up on the job board</UnsureSubtitle>
        </UnsureTextContainer>
      ),
    isVisibleToSchoolAdmin:
      row.visible_to_school_admin === true ? (
        <VisibilityRow>
          <RemoveRedEyeIcon />
          &nbsp; Can see
        </VisibilityRow>
      ) : (
        <VisibilityRow>
          <VisibilityOffIcon color="disabled" />
          &nbsp; Hidden
        </VisibilityRow>
      ),
    align: 'left',
  }));
};
