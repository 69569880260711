import { useState } from 'react';
import { useMutationDeleteInternalRoleNote } from '../queries';
import { DeleteButton } from 'sharedComponents/Buttons/DeleteButton';
import { ATSTabbedJobViewInternalRoleNotesTestIds } from 'data-testids/ATS';
import { DeletionModal } from 'sharedComponents/Modal/DeletionModal';
import { getAxiosErrorMessageByStatusCode } from 'hooks/errorHandling/errorHandlingHooks';
import { ErrorSnackbar } from 'sharedComponents/Snackbar';

const DeleteNoteButton: React.FC<{ noteId: number; jobId: number }> = ({ noteId, jobId }) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const { mutate, error, isError, reset } = useMutationDeleteInternalRoleNote(jobId);
  const errorMessage = getAxiosErrorMessageByStatusCode(error, {
    500: 'Could not delete note. Please try again.',
  });

  return (
    <>
      <ErrorSnackbar
        message={errorMessage ?? 'Something went wrong'}
        open={isError}
        onClose={reset}
        duration={6000}
        dataTestId={ATSTabbedJobViewInternalRoleNotesTestIds.DELETE_NOTE_ERROR_SNACKBAR}
      />
      <DeleteButton
        onClick={() => setIsDeleteModalOpen(true)}
        dataTestId={ATSTabbedJobViewInternalRoleNotesTestIds.DELETE_NOTE_BUTTON}
      />
      <DeletionModal
        onDelete={() => mutate({ noteId })}
        onClose={() => setIsDeleteModalOpen(false)}
        open={isDeleteModalOpen}
        text={'Are you sure you want to delete this note? You will not be able to recover it.'}
        title={'Delete Note'}
      />
    </>
  );
};

export default DeleteNoteButton;
