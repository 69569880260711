import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CreateIcon from '@mui/icons-material/Create';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StarIcon from '@mui/icons-material/Star';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import TableRowsIcon from '@mui/icons-material/TableRows';
import TitleOutlinedIcon from '@mui/icons-material/TitleOutlined';

import NimbleQuestionIcon from './assets/nimble-icon.svg';
import { IconBackground } from './styles';
import { QuestionType } from 'utils/constants/roles';
import { ATSQuestionComponentTestIds } from 'data-testids/ATS';

const questionTitleByQuestionType = {
  [QuestionType.attachment]: 'Attachment question',
  [QuestionType.direction_text]: 'Direction text',
  [QuestionType.multiple_choice]: 'Multiple choice',
  [QuestionType.nimble]: 'Nimble question',
  [QuestionType.open_response]: 'Open response',
  [QuestionType.schoolPreferences]: 'School preferences',
  [QuestionType.statementCheckbox]: 'Statement checkbox',
  [QuestionType.text_question_model]: 'Text question',
  [QuestionType.videoLink]: 'Video link',
  [QuestionType.yes_no]: 'Yes/No question',
};

const questionIconByQuestionType = {
  [QuestionType.attachment]: (
    <AttachFileOutlinedIcon
      color="primary"
      data-testid={ATSQuestionComponentTestIds.ATTACHMENT_ICON}
    />
  ),
  [QuestionType.direction_text]: (
    <IconBackground>
      <EastOutlinedIcon data-testid={ATSQuestionComponentTestIds.DIRECTION_TEXT} />
    </IconBackground>
  ),
  [QuestionType.multiple_choice]: (
    <TableRowsIcon color="primary" data-testid={ATSQuestionComponentTestIds.MULTIPLE_CHOICE} />
  ),
  [QuestionType.nimble]: (
    <img src={NimbleQuestionIcon} data-testid={ATSQuestionComponentTestIds.NIMBLE_QUESTION} />
  ),
  [QuestionType.open_response]: (
    <IconBackground>
      <CreateIcon color="primary" data-testid={ATSQuestionComponentTestIds.OPEN_RESPONSE} />
    </IconBackground>
  ),
  [QuestionType.schoolPreferences]: (
    <StarIcon color="primary" data-testid={ATSQuestionComponentTestIds.SCHOOL_PREFERENCES} />
  ),
  [QuestionType.statementCheckbox]: (
    <CheckCircleIcon color="primary" data-testid={ATSQuestionComponentTestIds.STATEMENT_CHECKBOX} />
  ),
  [QuestionType.text_question_model]: (
    <IconBackground>
      <TitleOutlinedIcon color="primary" data-testid={ATSQuestionComponentTestIds.TEXT_QUESTION} />
    </IconBackground>
  ),
  [QuestionType.videoLink]: (
    <IconBackground>
      <PlayArrowIcon color="primary" data-testid={ATSQuestionComponentTestIds.VIDEO_LINK} />
    </IconBackground>
  ),
  [QuestionType.yes_no]: (
    <IconBackground>
      <SwapHorizIcon color="primary" data-testid={ATSQuestionComponentTestIds.YES_NO_QUESTION} />
    </IconBackground>
  ),
};

export const getQuestionHeaderText = (questionType: QuestionType): string => {
  return questionTitleByQuestionType[questionType] || '';
};

export const getQuestionTypeIcon = (questionType: QuestionType): JSX.Element => {
  return questionIconByQuestionType[questionType] || <></>;
};
