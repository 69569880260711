import { useState } from 'react';

import { Content, Container, SpinnerContainer, Top } from './styles';
import { JobViewHeader } from './components/JobViewHeader';
import { JobViewInformation } from './components/JobViewInformation';
import { JobViewMetadata } from './components/JobViewMetadata';
import { JobViewProps, ShouldShow } from './types';
import { extractTextFromHTML, shouldShowJobViewField } from './utils';
import auth from 'utils/auth';
import CircularProgress from '@mui/material/CircularProgress';
import ShareToSocialMedia from 'components/ShareToSocialMedia';
import { useDynamicMetaTags } from 'hooks/SEO/useDynamicMetadata';
import { useDynamicStructuredData } from 'hooks/SEO/useDynamicStructuredData';
import { getJobPostingStructuredData } from 'utils/seo';
import { useLocation } from 'react-router';

export const JobView: React.FC<JobViewProps> = ({
  isInternalCandidate,
  isLoading,
  isPreview,
  job,
  onSubmit,
  toggleJobStatus,
}) => {
  const [shouldShowShareModal, setShouldShowShareModal] = useState(false);
  const currentUser = auth.getUser();
  const shouldShow: ShouldShow = {
    internalNotes: shouldShowJobViewField(job, 'internal_role_notes'),
    attachments: shouldShowJobViewField(job, 'requiredapplicationattachment_set'),
    description: shouldShowJobViewField(job, 'description'),
    benefits: shouldShowJobViewField(job, 'benefits'),
  };

  const shouldShowJobViewInformation: boolean =
    shouldShow.internalNotes ||
    shouldShow.attachments ||
    shouldShow.description ||
    shouldShow.benefits;

  const htmlTitle = `${job.title} at ${job.district.name} - Nimble`;
  const beginningOfDescription = extractTextFromHTML(job.description);
  useDynamicMetaTags(htmlTitle, beginningOfDescription);
  const structuredData = getJobPostingStructuredData(job);
  useDynamicStructuredData(JSON.stringify(structuredData));

  const location = useLocation();
  const isPublicJobView =
    !location.pathname.includes('/school') && !location.pathname.includes('district');

  return (
    <Container data-testid="jobview-v2">
      <Top component="header">
        {isLoading && (
          <SpinnerContainer data-testid="loading-spinner">
            <CircularProgress />
          </SpinnerContainer>
        )}
        {!isLoading && (
          <JobViewHeader
            handleOpenShareModal={() => setShouldShowShareModal(true)}
            isPreview={isPreview}
            job={job}
            userDistrict={currentUser?.profile.district?.id}
            onSubmit={onSubmit}
            isInternalCandidate={isInternalCandidate}
            toggleJobStatus={toggleJobStatus}
            isPublicJobView={isPublicJobView}
          />
        )}
      </Top>
      <Content>
        {shouldShowJobViewInformation && (
          <JobViewInformation
            shouldShow={shouldShow}
            job={job}
            isInternalCandidate={isInternalCandidate}
          />
        )}
        <JobViewMetadata job={job} />
      </Content>

      {shouldShowShareModal && (
        <ShareToSocialMedia
          isOpen={shouldShowShareModal}
          onClose={() => {
            setShouldShowShareModal(false);
          }}
          roleToShare={job}
        />
      )}
    </Container>
  );
};
