import { Box, styled } from '@mui/material';
import React, { useState } from 'react';

import { TabNavigation } from './TabNavigation/TabNavigation';
import { getBottomTabsData, getTopTabsData } from './TabNavigation/utils';
import { TabValue } from './TabNavigation/types';
import auth from 'utils/auth';

const JobViewTabs: React.FC<{ jobId: number }> = ({ jobId }) => {
  const [selectedTab, setSelectedTab] = useState<TabValue>(0);

  const handleTabChange = (_: React.SyntheticEvent, newValue: TabValue): void => {
    setSelectedTab(newValue);
  };

  const isDistrictAdmin = auth.isDistrictAdmin();
  const inPostingOnlyDistrict = auth.inPostingOnlyDistrict();

  const topTabsData = getTopTabsData(jobId, isDistrictAdmin, inPostingOnlyDistrict);
  const bottomTabsData = getBottomTabsData(jobId, isDistrictAdmin);
  const allTabsData = [...topTabsData, ...bottomTabsData];

  return (
    <Content>
      <TabNavigation
        onChange={handleTabChange}
        selectedTab={selectedTab}
        jobId={jobId}
        topTabsData={topTabsData}
        bottomTabsData={bottomTabsData}
      />
      <TabContentContainer>
        {allTabsData.find((data) => data.value === selectedTab).content}
      </TabContentContainer>
    </Content>
  );
};

export default JobViewTabs;

const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  paddingTop: theme.spacing(2),
  maxWidth: '1200px',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const TabContentContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  marginBottom: theme.spacing(1),
  marginLeft: theme.spacing(8),
  marginTop: theme.spacing(2),
  maxWidth: theme.breakpoints.values.lg,
  width: '100%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    margin: 0,
    maxWidth: '100%',
  },
}));
