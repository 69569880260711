import { useMemo } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { JobDiscoveryFilterContainerProps } from 'types/connectTypes';
import { JobDiscoveryLocationMapFilter } from './JobDiscoveryLocationMapFilter';
import { Filters } from './JobDiscoveryFilters';
import { ConnectFilterName } from 'features/Connect/utils/connectEnums';
import { SearchbarMultiSelect } from 'sharedComponents/Select/SearchbarMultiSelect';
import { ConnectJobDiscoveryFiltersDataTestIds } from 'data-testids/ConnectDataTestIds';

import { mapOptionsToValues } from 'sharedComponents/Select';
import { useConnectContext } from '../../Context/ConnectContextProvider';
export const JobDiscoveryFilterContainer: React.FC<JobDiscoveryFilterContainerProps> = ({
  filterValues,
  jobDiscoveryActions,
  filterOptions,
  activeFilters,
}) => {
  const { connectContextActions } = useConnectContext();

  const theme = useTheme();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.between('xs', 'lg'));

  const getSelectedOptions = (selectedOptions) => {
    if (!Array.isArray(selectedOptions)) {
      return [];
    }
    return selectedOptions.map((option) => option.value);
  };

  const updateSelectedFilterOptions = (
    selectedValues,
    options,
    updateSelectedValues,
    setFilterIsActive,
    filtername
  ) => {
    // ignore events until options is loaded
    if (filterOptions.isLoadingFilterOptions) {
      return;
    }
    connectContextActions.setIsLoading(true);
    jobDiscoveryActions.setIsLoadingInitialFilterResults(true);
    const selectedOptions = getSelectedOptions(selectedValues);

    if (selectedValues?.length === options?.length || selectedValues?.length === 0) {
      updateSelectedValues([]);
      setFilterIsActive(filtername, false);
    } else {
      updateSelectedValues(selectedOptions);
      setFilterIsActive(filtername, true);
    }
    connectContextActions.setIsLoading(false);
  };

  const setSelectedSubjectOptions = (selectedSubjectOptions) => {
    updateSelectedFilterOptions(
      selectedSubjectOptions,
      filterOptions.subjectOptions,
      jobDiscoveryActions.setSelectedSubjectIds,
      jobDiscoveryActions.setFilterIsActive,
      ConnectFilterName.SUBCATEGORIES
    );
  };

  const setSelectedGradeOptions = (selectedGradeOptions) => {
    updateSelectedFilterOptions(
      selectedGradeOptions,
      filterOptions.gradeOptions,
      jobDiscoveryActions.setSelectedGrades,
      jobDiscoveryActions.setFilterIsActive,
      ConnectFilterName.GRADES
    );
  };

  const setSelectedSchoolOptions = (selectedSchoolOptions) => {
    updateSelectedFilterOptions(
      selectedSchoolOptions,
      filterOptions.schoolOptions,
      jobDiscoveryActions.setSelectedSchoolIds,
      jobDiscoveryActions.setFilterIsActive,
      ConnectFilterName.SCHOOLS
    );
  };

  const setSelectedDistrictOptions = (selectedDistrictOptions) => {
    updateSelectedFilterOptions(
      selectedDistrictOptions,
      filterOptions.districtOptions,
      jobDiscoveryActions.setSelectedDistrictIds,
      jobDiscoveryActions.setFilterIsActive,
      ConnectFilterName.DISTRICTS
    );
  };

  const schoolValues = useMemo(() => {
    return mapOptionsToValues(filterOptions.schoolOptions, filterValues.school_nces_ids);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filterValues.school_nces_ids), filterOptions.schoolOptions.length]);

  return (
    <FilterContainer>
      <Grid container spacing={1.5} justifyContent={'space-between'}>
        <Grid item xs={12}>
          <JobDiscoveryLocationMapFilter jobDiscoveryActions={jobDiscoveryActions} />
        </Grid>
        <Grid item xs={12}>
          <SearchbarMultiSelect
            displayName="School search"
            size="small"
            placeholder="Search for school"
            options={filterOptions.schoolOptions}
            values={schoolValues}
            handleChange={setSelectedSchoolOptions}
            dataTestId={ConnectJobDiscoveryFiltersDataTestIds.SCHOOL_SEARCH_FILTER}
            sx={{ ...InputStyles(theme), paddingBottom: 0.5 }}
            hasSubLabel={true}
            subLabelKeys={['address.city']}
          />
          <Filters
            filterValues={filterValues}
            filterOptions={filterOptions}
            isMobile={isMobile}
            setSelectedGradeOptions={setSelectedGradeOptions}
            setSelectedSubjectOptions={setSelectedSubjectOptions}
            setSelectedDistrictOptions={setSelectedDistrictOptions}
            activeFilters={activeFilters}
          />
        </Grid>
      </Grid>
    </FilterContainer>
  );
};

const FilterContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    margin: `0px 0px ${theme.spacing(3)}`,
  },
}));

export const InputStyles = (theme: Theme) => ({
  width: '100%',
  fontSize: theme.typography.subtitle.fontSize,
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.shape.borderRadius * 0.5,
  },
  '& .MuiInputLabel-root': {
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.body1.fontSize,
    },
  },
  '& .MuiInputBase-input': {
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.subtitle.fontSize,
    },
  },
});
