import moment from 'moment';

import { ATSTabbedJobViewSetUpTabTestIds } from 'data-testids/ATS';
import { Alert } from 'sharedComponents/Alert';
import { Row, Section, Subtitle } from '../styles';
import { VisibilityAndRequisitionNumberProps } from '../types';

export const VisibilityAndRequisitionNumber: React.FC<VisibilityAndRequisitionNumberProps> = ({
  externalDate,
  internalOnly,
  password,
  requisitionNumber,
}) => {
  const formattedDate = externalDate ? moment(externalDate).format('l') : null;

  return (
    <>
      <Section>
        <Subtitle variant="subtitle" data-testid={ATSTabbedJobViewSetUpTabTestIds.VISIBILITY_TITLE}>
          Visibility
        </Subtitle>
        <Row data-testid={ATSTabbedJobViewSetUpTabTestIds.INTERNAL_OR_EXTERNAL}>
          {internalOnly ? 'Internal only' : 'All applicants'}
        </Row>

        {externalDate && (
          <Row
            data-testid={ATSTabbedJobViewSetUpTabTestIds.OPEN_EXTERNAL_DATE}
          >{`Open externally on: ${formattedDate}`}</Row>
        )}

        <Alert dataTestId={ATSTabbedJobViewSetUpTabTestIds.JOB_PASSWORD}>
          Your job&apos;s password is: {password}
        </Alert>
      </Section>

      {requisitionNumber && (
        <Section>
          <Subtitle variant="subtitle">Requisition Number</Subtitle>
          <Row data-testid={ATSTabbedJobViewSetUpTabTestIds.REQUISITION_NUMBER}>
            {requisitionNumber}
          </Row>
        </Section>
      )}
    </>
  );
};
