import { Checkbox, FormControlLabel, SxProps, Typography, useTheme } from '@mui/material';
import { ATSCandidateBulkExportTestIds } from 'data-testids/ATS';
import { Controller, useFormContext } from 'react-hook-form';
import { FieldLabel } from './types';

type SelectedFieldsControllerProps = {
  fieldName: FieldLabel;
  label: string;
  labelSx?: SxProps;
};

export const SelectedFieldsController: React.FC<SelectedFieldsControllerProps> = ({
  fieldName,
  label,
  labelSx,
}) => {
  const { control, watch } = useFormContext();
  const selectedFields = watch('selectedFields', []);
  const theme = useTheme();

  const getChangedValue = (isChecked: boolean, value: FieldLabel[]): FieldLabel[] => {
    return isChecked ? [...value, fieldName] : value.filter((f: FieldLabel) => f !== fieldName);
  };

  return (
    <Controller
      name="selectedFields"
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormControlLabel
          label={
            <Typography sx={{ color: theme.palette.text.primary, ...labelSx }}>{label}</Typography>
          }
          data-testid={`${ATSCandidateBulkExportTestIds.SELECTED_FIELDS_CONTROLLER}-${fieldName}`}
          control={
            <Checkbox
              sx={{ margin: `0 ${theme.spacing(1)}` }}
              checked={selectedFields.includes(fieldName)}
              data-testid={`${ATSCandidateBulkExportTestIds.SELECTED_FIELDS_CONTROLLER_CHECKBOX}-${fieldName}`}
              onChange={(e) => {
                const isChecked = e.target.checked;
                onChange(getChangedValue(isChecked, value));
              }}
            />
          }
        />
      )}
    />
  );
};
