import { Skeleton, useTheme } from '@mui/material';
import { CandidateRequestorInfoContainer, CandidateRow, CandidatesListContainer } from '../styles';

const skeletonRowSx = [{ height: '100%', width: '37.5%' }, { width: '35%' }, { width: '30%' }];

export const ConnectCandidateSkeletonRow = () => {
  const theme = useTheme();

  return (
    <CandidatesListContainer>
      <CandidateRow sx={{ paddingRight: theme.spacing(3) }}>
        {skeletonRowSx.map((sx, i) => (
          <CandidateRequestorInfoContainer sx={sx} key={i}>
            <Skeleton variant="text" sx={{ fontSize: theme.typography.heading7.fontSize }} />
            <Skeleton variant="text" sx={{ fontSize: theme.typography.body1.fontSize }} />
          </CandidateRequestorInfoContainer>
        ))}
      </CandidateRow>
    </CandidatesListContainer>
  );
};

export const ConnectCandidateRowSkeleton = () => {
  return (
    <>
      <ConnectCandidateSkeletonRow />
      <ConnectCandidateSkeletonRow />
      <ConnectCandidateSkeletonRow />
      <ConnectCandidateSkeletonRow />
      <ConnectCandidateSkeletonRow />
    </>
  );
};
