import { Box, styled } from '@mui/material';

export const SecondaryButtonContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'end',
});

export const TitleAndChip = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

export const Top = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
});
